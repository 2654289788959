import { history } from '../api'
import { folderConstants as FC } from '../constants'
import { folderService as FS, tokenService as TS } from '../services'
import { alertActions as AA, contractActions } from './'

const remove = data => {
  const failure = error => ({ type: FC.DELETE_FOLDER_ERROR, error })
  const request = () => ({ type: FC.DELETE_FOLDER_REQUEST })
  const success = () => ({ type: FC.DELETE_FOLDER_SUCCESS })

  const { folderId } = data

  return dispatch => {
    if (!folderId) return dispatch(AA.error(FC.FOLDER_ID_REQUIRED))

    dispatch(request())

    return FS.remove(folderId)
      .then(() => {
        dispatch(success())
        dispatch(AA.success(FC.FOLDER_DELETED))

        history.push('/folder')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getAll = () => {
  const failure = error => ({ type: FC.GET_ALL_ERROR, error })
  const request = () => ({ type: FC.GET_ALL_REQUEST })
  const success = folders => ({ type: FC.GET_ALL_SUCCESS, folders })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      FS.getAll().then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const getById = (id, includes = []) => {
  const failure = error => ({ type: FC.GET_BY_ID_ERROR, error })
  const request = folder => ({ type: FC.GET_BY_ID_REQUEST, folder })
  const success = folder => ({ type: FC.GET_BY_ID_SUCCESS, folder })

  return dispatch => {
    dispatch(request({ id }))

    TS.refreshToken().then(() =>
      FS.getById(id, includes).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const getCustomEnergyCompareConsumption = (id, period, start, end) => {
  const failure = error => ({
    type: FC.GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_ERROR,
    error,
  })
  const request = () => ({
    type: FC.GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_REQUEST,
  })
  const success = item => ({
    type: FC.GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_SUCCESS,
    item,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      FS.getCustomEnergyCompareConsumption(id, period, start, end).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const getCustomEnergyConsumption = (
  id,
  period,
  start,
  end,
  isMultipleCall = false
) => {
  let energyErrorType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_ERROR
  let energyRequestType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST
  let energySuccessType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS

  const failure = error => ({ type: energyErrorType, error })
  const request = () => ({ type: energyRequestType })
  const success = item => ({ type: energySuccessType, item })

  if (isMultipleCall)
    switch (period) {
      case 'y':
        energyErrorType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_YEARLY
        energyRequestType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_YEARLY
        energySuccessType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_YEARLY
        break

      case 'm':
        energyErrorType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_MONTHLY
        energyRequestType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_MONTHLY
        energySuccessType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_MONTHLY
        break

      case 'd':
        energyErrorType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_24
        energyRequestType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_24
        energySuccessType = FC.GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_24
        break

      default:
        break
    }

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      FS.getCustomEnergyConsumption(id, period, start, end).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const getCounterReading = (id, period, start, end) => {
  const failure = error => ({ type: FC.GET_COUNTER_READING_ERROR, error })
  const request = () => ({ type: FC.GET_COUNTER_READING_REQUEST })
  const success = item => ({ type: FC.GET_COUNTER_READING_SUCCESS, item })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      FS.getCounterReading(id, period, start, end).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const getEnergyConsumption = id => {
  const failure = error => ({ type: FC.GET_ENERGY_CONSUMPTION_ERROR, error })
  const request = () => ({ type: FC.GET_ENERGY_CONSUMPTION_REQUEST })
  const success = item => ({ type: FC.GET_ENERGY_CONSUMPTION_SUCCESS, item })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      FS.getEnergyConsumption(id).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const recordData = data => {
  const {
    air_conditioning,
    area_in_sqm,
    city,
    country,
    elevator,
    escalator,
    folder_installations,
    hired_out_area,
    image_url,
    kind_of_use,
    kpi_benchmark,
    latitude,
    longitude,
    name,
    name_short,
    none_temperature_controlled_area,
    number_of_employees,
    parent_id,
    push_sensors,
    rooms_mode,
    sensor_id,
    street,
    target_value_annual_electricity_consumption,
    target_value_annual_gas_consumption,
    temperature_controlled_area,
    usable_area,
    user_id,
    zip,
    energy_benchmark,
    water_benchmark,
    gas_benchmark,
  } = data.folder

  return {
    data: {
      attributes: {
        air_conditioning: air_conditioning || null,
        area_in_sqm: area_in_sqm || null,
        city: city || null,
        country: country || null,
        elevator: elevator || null,
        escalator: escalator || null,
        hired_out_area: hired_out_area || null,
        image_url: image_url || null,
        kind_of_use: kind_of_use || null,
        kpi_benchmark: kpi_benchmark || null,
        latitude: latitude || null,
        longitude: longitude || null,
        name: name || null,
        name_short: name_short || null,
        none_temperature_controlled_area:
          none_temperature_controlled_area || null,
        number_of_employees: number_of_employees || null,
        parent_id: parent_id ? parent_id.value : null,
        rooms_mode: rooms_mode || null,
        street: street || null,
        target_value_annual_electricity_consumption:
          target_value_annual_electricity_consumption || null,
        target_value_annual_gas_consumption:
          target_value_annual_gas_consumption || null,
        temperature_controlled_area: temperature_controlled_area || null,
        usable_area: usable_area || null,
        zip: zip || null,
        energy_benchmark: energy_benchmark || null,
        water_benchmark: water_benchmark || null,
        gas_benchmark: gas_benchmark || null,
      },
      relationships: {
        installations: {
          data: folder_installations.map(item => ({
            type: 'installation',
            name: item.name,
            entity_id: item.entity_id,
            delete_installation: item.delete_installation
              ? item.delete_installation
              : 0,
          })),
        },
        push_sensors: {
          data: push_sensors.map(item => ({
            type: 'push_sensor',
            id: item.value,
          })),
        },
        sensors: {
          data: sensor_id.map(item => ({ type: 'sensor', id: item.value })),
        },
        users: {
          data: user_id.map(item => ({ type: 'user', id: item.value })),
        },
      },
    },
  }
}

const save = (data, contractRecord) => {
  const failure = error => ({ type: FC.SAVE_FOLDER_ERROR, error })
  const request = () => ({ type: FC.SAVE_FOLDER_REQUEST })
  const success = folderId => ({ type: FC.SAVE_FOLDER_SUCCESS, folderId })

  const saveData = data => {
    const { exceptionDate, officeHours } = data

    return dispatch => {
      dispatch(request())
      FS.save(recordData(data))
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(FC.FOLDER_SAVED))

          if (officeHours.length > 0 || exceptionDate > 0)
            dispatch(saveOfficeHours(officeHours, exceptionDate, result.id))
          dispatch(contractActions.save(contractRecord))

          history.push(`/folder/${result.id}`)
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  const { image_url, name } = data.folder

  return dispatch => {
    if (!name) return dispatch(AA.error(FC.FOLDER_NAME_REQUIRED))

    if (image_url instanceof Object) {
      FS.uploadAws(image_url, 'folders')
        .then(result => {
          data.folder.image_url = result.location

          dispatch(saveData(data))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    } else dispatch(saveData(data))
  }
}

const saveOfficeHours = (hours, exceptions, folder_id) => {
  const failure = error => ({ type: FC.SAVE_OFFICE_HOURS_ERROR, error })
  const request = () => ({ type: FC.SAVE_OFFICE_HOURS_REQUEST })
  const success = officeHours => ({
    type: FC.SAVE_OFFICE_HOURS_SUCCESS,
    officeHours,
  })

  return dispatch => {
    const excCollection = exceptions.map(item => {
      const { closed, date_exception, end_time, name, start_time } = item

      return {
        data: {
          attributes: {
            folder_id,
            day_of_week: null,
            name,
            date_exception,
            start_time: start_time ? start_time : '00:00',
            end_time: end_time ? end_time : '00:00',
            closed,
            exception: 1,
          },
        },
      }
    })

    const officeHoursCollection = hours.map(item => {
      const { closed, day_of_week, end_time, start_time } = item

      return {
        data: {
          attributes: {
            folder_id,
            day_of_week,
            name: null,
            date_exception: null,
            start_time: start_time ? start_time : '00:00',
            end_time: end_time ? end_time : '00:00',
            closed,
            exception: 0,
          },
        },
      }
    })

    officeHoursCollection.concat(excCollection)

    dispatch(request())

    FS.saveOfficeHours({
      collection: officeHoursCollection.concat(excCollection),
    })
      .then(result => dispatch(success(result)))
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const update = data => {
  const failure = error => ({ type: FC.UPDATE_FOLDER_ERROR, error })
  const request = () => ({ type: FC.UPDATE_FOLDER_REQUEST })
  const success = folderId => ({ type: FC.UPDATE_FOLDER_SUCCESS, folderId })

  const updateData = data => {
    const { exceptionDate, folderId, officeHours } = data

    return dispatch => {
      dispatch(request())
      FS.update(recordData(data), folderId)
        .then(() => {
          dispatch(success(folderId))
          dispatch(AA.success(FC.FOLDER_UPDATED))

          if ((officeHours && officeHours.length > 0) || exceptionDate > 0)
            dispatch(saveOfficeHours(officeHours, exceptionDate, folderId))

          history.push(`/folder/${folderId}`)
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  const { folder, folderId } = data
  const { image_url, name } = folder

  return dispatch => {
    if (!folderId) return dispatch(AA.error(FC.FOLDER_ID_REQUIRED))
    if (!name) return dispatch(AA.error(FC.FOLDER_NAME_REQUIRED))

    if (image_url instanceof Object) {
      FS.uploadAws(image_url, 'folders')
        .then(result => {
          data.folder.image_url = result.location

          dispatch(updateData(data))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    } else dispatch(updateData(data))
  }
}

export const folderActions = {
  getAll,
  getById,
  getCustomEnergyCompareConsumption,
  getCustomEnergyConsumption,
  getCounterReading,
  getEnergyConsumption,
  remove,
  save,
  update,
}
