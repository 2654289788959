import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolder } from '..'

export const useFolderSensors = folderId => {
  const { data: folderData, isLoading } = useFolder(folderId)

  const [data, setData] = useState([])

  useEffect(() => {
    if (isLoading === true) return

    if (folderData?.sensors) setData(folderData?.sensors)
  }, [folderId, folderData, isLoading])

  return data
}

useFolderSensors.propTypes = {
  folderId: PropTypes.number,
}
