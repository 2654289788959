import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { SVGStatus } from '../../api'
import {
  useFolderImage,
  useFolderName,
  useFolderShortName,
  useFolderStatus,
} from '../../hooks'
import defaultImage from '../../assets/img/image_placeholder.jpg'

const descriptions = {
  green: 'Alles OK',
  yellow: 'Mindestens ein Sensor befindet sich nahe dem kritischen Bereich',
  red: 'Mindestens ein Sensor befindet sich im kritischen Bereich',
  neutral: 'Kein Sensor registriert',
}

export const FolderStatus = ({ folderId }) => {
  const history = useHistory()

  const folderImage = useFolderImage(folderId)
  const folderName = useFolderName(folderId)
  const folderStatus = useFolderStatus(folderId)
  const folderShortName = useFolderShortName(folderId)

  const targetUrl = `/folder/${folderId}/home`
  const src = folderImage || defaultImage

  const onCardClick = useCallback(
    () => history.push(targetUrl),
    [history, targetUrl]
  )

  const onCategoryClick = useCallback(
    category => history.push(`${targetUrl}/${category}`),
    [history, targetUrl]
  )

  return (
    <Card sx={{ border: '1px solid #e0e0e0', borderRadius: 5 }}>
      <CardHeader
        onClick={onCardClick}
        subheader={folderShortName}
        sx={{ cursor: 'pointer' }}
        title={folderName}
      />

      <Divider />

      <CardContent
        sx={{ height: 360, overflow: 'hidden', p: 0, position: 'relative' }}
      >
        <Box
          component={'img'}
          draggable={false}
          onClick={onCardClick}
          src={src}
          sx={{
            cursor: 'pointer',
            height: 1,
            left: 0,
            minWidth: 1,
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            width: 1,
            '&:hover': { transform: 'scale(1.1)' },
          }}
        />
      </CardContent>

      <Divider />

      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {Object.keys(folderStatus)
          .filter(f => f !== 'canCorrelate')
          .map((category, key) => {
            const onClick = () => onCategoryClick(category)
            const placement = 'top'
            const status = folderStatus[category]
            const title = descriptions[status]

            return (
              <Tooltip key={key} placement={placement} title={title}>
                <Box onClick={onClick} sx={{ cursor: 'pointer', width: 50 }}>
                  <SVGStatus type={category} status={status} />
                </Box>
              </Tooltip>
            )
          })}
      </CardActions>
    </Card>
  )
}

FolderStatus.propTypes = {
  folderId: PropTypes.number.isRequired,
}
