import { API } from 'aws-amplify'
import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { authHeader } from '../../api'

export const useDataPointsByPanelIds = (dashboardId, panels) => {
  const dispatch = useDispatch()
  const [isError, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const dataPointsRef = useRef({ items: [] })
  const [numPendingRequests, setNumPendingRequests] = useState(0)

  useEffect(() => {
    if (panels.length === 0) return

    setIsLoading(true)
    dataPointsRef.current = { items: [] }
    setNumPendingRequests(panels.length)

    const fetchDataPoints = async panelId => {
      let result = 'unset'
      const API_NAME = 'karmaApi'
      const endpoint = `/dashboard/${dashboardId}/panel/${panelId}/datapoint`
      const options = { method: 'GET', headers: authHeader() }

      result = await API.get(API_NAME, endpoint, options)
        .then(response => {
          return response
        })
        .catch(error => setError(error))

      if (result?.data) {
        const newItems = { ...dataPointsRef.current.items }
        const sensorIds = result.data.map(point => point.attributes.sensor_id)
        newItems[panelId] = sensorIds
        dataPointsRef.current = { items: newItems }
      }

      setNumPendingRequests(prev => prev - 1)
    }

    panels.forEach(panel => fetchDataPoints(panel.id))
  }, [dashboardId, dispatch, panels])

  useEffect(() => {
    if (numPendingRequests === 0) {
      setIsLoading(false)
    }
  }, [numPendingRequests])

  return { isLoading, isError, dataPoints: dataPointsRef.current }
}
