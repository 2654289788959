import { CloseTwoTone } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { FILTER_MAIN_FOLDER, FILTER_SUB_FOLDER } from '../../api'
import { HStack, ToggleDateType } from '..'
import { useFolderPool } from '../../hooks'

const StatRecord = ({ label, value, ...rest }) => (
  <HStack spacing={2} sx={{ width: 1 }} {...rest}>
    <Stack sx={{ flex: 1, textAlign: 'right' }}>
      <Typography>{label}</Typography>
    </Stack>

    <Stack sx={{ flex: 1, textAlign: 'left' }}>
      <Typography>{value}</Typography>
    </Stack>
  </HStack>
)

StatRecord.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
}

const FolderPoolStat = () => {
  const { data: folderPool, fetchDate } = useFolderPool()

  return (
    <Stack sx={{ mt: 3 }}>
      <StatRecord label={'Poolgröße'} value={folderPool.length} />

      {[
        {
          label: 'Hauptgebäude',
          value: [...new Set(folderPool.filter(FILTER_MAIN_FOLDER))].length,
        },
        {
          label: 'Untergebäude',
          value: [...new Set(folderPool.filter(FILTER_SUB_FOLDER))].length,
        },
      ].map((item, key) => (
        <StatRecord key={key} label={item.label} value={item.value} />
      ))}

      <StatRecord
        label={'gefetcht'}
        sx={{ mb: 1, mt: 2, pr: 1, textAlign: 'right' }}
        value={<ToggleDateType date={fetchDate} />}
      />
    </Stack>
  )
}

export const DlgFolderPoolInfo = ({
  onClose,
  open,
  title = 'Gebäude Pool Info',
}) => (
  <Dialog fullWidth open={open}>
    <DialogTitle
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontSize: 20 }}>{title}</Typography>

      <IconButton
        onClick={onClose}
        sx={{
          '&:hover': { transform: 'rotate(90deg)' },
          transform: 'rotate(0deg)',
          transition: 'transform 0.2s',
        }}
        variant={'close'}
      >
        <CloseTwoTone />
      </IconButton>
    </DialogTitle>

    <DialogContent>
      <FolderPoolStat />
    </DialogContent>
  </Dialog>
)

DlgFolderPoolInfo.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}
