import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { consumptionActions } from '../../actions'
import { colorSchemeConsumption } from '../../api/colorSchemes'

export const useConsumptionByFolderId = (
  folderId,
  period,
  fromDate,
  toDate
) => {
  const dispatch = useDispatch()
  const { allConsumption } = useSelector(state => state.consumption)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isFactorError, setFactorError] = useState(null)
  const [factorErrorSensor, setFactorErrorSensor] = useState('')
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    let newPeriod = 'h'
    switch (period) {
      case 'y':
        newPeriod = 'm'
        break
      case 'm':
        newPeriod = 'd'
        break
      case 'd':
        newPeriod = 'h'
        break
      default:
        newPeriod = 'h'
    }
    dispatch(consumptionActions.getAll(folderId, newPeriod, fromDate, toDate))
    setLoading(true)
  }, [dispatch, folderId, fromDate, toDate, period])

  useEffect(() => {
    if (allConsumption) {
      const { error, item } = allConsumption
      if (item) {
        const { data, factorError, sensor } = item
        if (factorError) {
          setFactorError(true)
          setFactorErrorSensor(sensor)
          setLoading(false)
        }
        if (!data) return
        setData(
          data.map((item, key) => ({
            ...item.attributes,
            entity_id: Number(item.id),
            consumption: item.attributes.consumptions,
            chartColor:
              colorSchemeConsumption[key % colorSchemeConsumption.length],
          }))
        )
        setLoading(false)
      }

      if (error) {
        setError(error)
        setLoading(false)
      }
    }
  }, [allConsumption])

  return { data, isError, isFactorError, factorErrorSensor, isLoading }
}
