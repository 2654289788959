import { Typography } from '@mui/material'
import React from 'react'

export const CopyrightNotice = ({ ...rest }) => {
  const y = new Date(Date.now()).getFullYear()

  return (
    <Typography {...rest}>
      Copyright &copy; {y} KARMA. Die digitale Lösung für jedes Gebäude.
    </Typography>
  )
}
