import {
  AddTwoTone,
  DeleteTwoTone,
  DoNotDisturbTwoTone,
  ImportExportTwoTone,
  MoreVertTwoTone,
  PeopleTwoTone,
  PowerOffTwoTone,
  PowerTwoTone,
  RemoveTwoTone,
  SaveTwoTone,
  SettingsTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { sensorActions, userActions } from '../../actions'
import {
  categoryByType,
  getRelativeDate,
  getStorage,
  sanitizeJSON,
  SensorIcon,
  sensorType as SensorType,
  setStorage,
  SORT_ATTR_FIRSTNAME,
  SORT_ATTR_NAME,
  SORT_NAME,
} from '../../api'
import {
  AM,
  AMItem,
  DlgSensorDelete,
  HStack,
  KPage,
  KSelect,
  KTextField,
  Preloader,
} from '../../components'
import {
  useContractPool,
  useFolderInstallations,
  useFolderName,
  useFolderPool,
  useFolderSensors,
  usePayloadPool,
  useSensor,
  useUserPool,
} from '../../hooks'
import { SensorBenchmarks, SensorFactors } from './components'

import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
// If dark mode is needed, import `dark.css`.
// import 'react18-json-view/src/dark.css'

export const SensorEdit = ({ PAGE_CONFIG = 'settings_sensor_edit' }) => {
  const theme = useTheme()
  const { white: color } = theme.palette

  const dispatch = useDispatch()
  const history = useHistory()
  const { id: sensorId } = useParams()

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))

  const { timeSeries } = useSelector(state => state.sensor)

  const [timeSeriesData, setTimeSeriesData] = useState({
    isLoading: true,
    items: [],
  })

  const { data: contractPool, isLoading: isContractPoolLoading } =
    useContractPool()
  const { data: folderPool } = useFolderPool()
  const { data: payloadPool } = usePayloadPool()
  const { data: userPool } = useUserPool()

  // default sensor object shape
  const [sensor, setSensor] = useState({
    attributes_values: null,
    battery_comment: '',
    battery_date: '',
    counter_reading: 0,
    counter_reading_date: '',
    folder_id: null,
    installation: null,
    is_active: true,
    name: '',
    parent_id: '',
    sensor_target: '',
    sensor_type: null,
    sub_sensor_target: 0,
    subcategory: 0,
    type_in_folder: '',
    unit: '',
    sampling_mode: '',
    meter_number: '',
  })

  const [sensorPayload, setSensorPayload] = useState({})
  const [contractId, setContractId] = useState([])
  const [installation, setInstallation] = useState()

  const [actionMenu, setActionMenu] = useState(null)
  const [showAssignment, setShowAssignment] = useState(false)

  const [attributes, setAttributes] = useState([])
  const [factorPeriodList, setFactorPeriodList] = useState([])

  const [sensorUsers, setSensorUsers] = useState([])

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const germanNotation = value => value && value.toString().replace(',', '.')

  const [rangeMin, setRangeMin] = useState()
  const [rangeMax, setRangeMax] = useState()

  const {
    data: sensorData,
    isError: isSensorError,
    isLoading: sensorLoading,
  } = useSensor(sensorId)

  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  /* attributes */
  useEffect(() => {
    for (const attr of attributes) {
      const { attribute_code, value } = attr

      if (attribute_code === 'range_min') setRangeMin(value)
      if (attribute_code === 'range_max') setRangeMax(value)
    }
  }, [attributes])

  const folderSensors = useFolderSensors(sensorData?.folder_id)
  const folderInstallations = useFolderInstallations(sensorData?.folder_id)

  /* sensorData */
  useEffect(() => {
    if (!sensorData) return
    const { contracts, periods, users } = sensorData

    const { attributes_values, device_id, installation, is_active } = sensorData

    setSensor(prev => ({
      ...prev,
      ...sensorData,
      is_active: is_active === 1,
    }))

    if (installation[0]) setInstallation(installation[0].entity_id)

    if (contracts)
      setContractId(contracts.map(contract => contract.entity_id)[0])

    if (periods)
      setFactorPeriodList(
        periods.map(period => ({
          period_start: period.period.period_start,
          period_end: period.period.period_end,
          sensor_id: period.period.sensor_id,
          factor: period.period.factor,
        }))
      )

    setAttributes(
      attributes_values.map(item => {
        const { attribute_code, label } = item
        let { value } = item
        if (attribute_code) {
          if (value) value = value.replace(',', '.')
        }

        return { attribute_code, value, label }
      })
    )

    if (users) setSensorUsers(users)

    if (payloadPool) {
      const hasPayload = payloadPool.find(f => f.device_id === device_id)

      if (hasPayload) setSensorPayload(sanitizeJSON(hasPayload.payload))
    }
  }, [payloadPool, sensorData])

  /* sensorId */
  useEffect(() => {
    const TZO = new Date().getTimezoneOffset() * 60 * 1000
    const _1H = 60 * 60 * 1000
    const _24H = 24 * _1H
    const _NOW = new Date(Date.now()).getTime()
    const _YESTERDAY = _NOW - _24H

    let from = new Date(_YESTERDAY)
    from.setTime(from.getTime() - TZO)
    let to = new Date(_NOW)
    to.setTime(to.getTime() - TZO)

    from =
      from.toISOString().split('T')[0] +
      ' ' +
      from.toISOString().split('T')[1].split('.')[0]

    to =
      to.toISOString().split('T')[0] +
      ' ' +
      to.toISOString().split('T')[1].split('.')[0]

    dispatch(sensorActions.getTimeSeries(sensorId, from, to))
  }, [dispatch, sensorId])

  /* timeSeries */
  useEffect(() => {
    const { error, items, loading } = timeSeries

    if (items)
      setTimeSeriesData({
        isLoading: loading,
        ...items.data.attributes,
        id: items.data.id,
      })

    if (error) console.error(error)
  }, [timeSeries])

  const folderName = useFolderName(Number(sensor.folder_id))

  /* sensor.folder_id */
  useEffect(() => {
    const contractId = contractPool
      .filter(f => f.attributes.name === folderName)
      .map(item => Number(item.id))[0]

    setContractId(contractId)
  }, [sensor.folder_id, contractPool, folderName])

  const onSubmit = action => {
    const record = {
      sensorId,
      area_in_sqm: sensor.area_in_sqm,
      attributes,
      battery_comment: sensor.battery_comment,
      battery_date: sensor.battery_date,
      contract_id: [contractId],
      counter_reading: sensor.counter_reading,
      meter_number: sensor.meter_number,
      counter_reading_date: sensor.counter_reading_date,
      device_id: sensor.device_id,
      factor: sensor.factor,
      folder_id: sensor.folder_id,
      fuel_value: sensor.fuel_value,
      installation,
      is_active: sensor.is_active,
      name: sensor.name,
      number_of_employees: sensor.number_of_employees,
      parent_id: sensor.parent_id,
      provider: sensor.provider,
      sensor_target: sensor.sensor_target,
      state_number: sensor.state_number,
      sub_sensor_target: sensor.sub_sensor_target,
      subcategory: sensor.subcategory,
      time_periods: factorPeriodList,
      type_in_folder: sensor.type_in_folder,
      converter_factor: sensor.converter_factor,
      co2_factor: sensor.co2_factor,
      unit: sensor.unit,
      sampling_mode: sensor.sampling_mode,
      correction_value: sensor.correction_value,
      successor_id: sensor.successor_id,
    }

    switch (action) {
      case 'save':
        dispatch(sensorActions.save(record))
        dispatch(userActions.setUsersBySensorId(sensorId, sensorUsers))
        break

      case 'update':
        dispatch(sensorActions.update(record))
        dispatch(userActions.setUsersBySensorId(sensorId, sensorUsers))
        break

      case 'delete':
        dispatch(sensorActions.remove(record))
        break

      default:
        break
    }
  }

  const handleChangeAttr = (event, name) => {
    const { value } = event.target

    const updatedAttribute = attributes.map(item => {
      const { attribute_code } = item

      if (attribute_code === name)
        return {
          ...item,
          ...{
            attribute_code: name,
            value: value.toString().replace(',', '.'),
          },
        }

      return item
    })

    setAttributes(updatedAttribute)
  }

  let series
  const { time_series } = timeSeriesData

  if (time_series && sensor && sensor.entity_id)
    if (Number(timeSeriesData.id) === Number(sensor.entity_id)) {
      series = []

      series.push([{ type: 'datetime', label: 'Time' }, sensor.unit])

      time_series.map(item => {
        const { datetime, value } = item

        series.push([new Date(datetime), Number(value)])
        return true
      })
    }

  const isConsumptionSensor =
    categoryByType(
      sensor.sensor_type,
      sensor.sensor_target,
      sensor.sub_sensor_target
    ) === 'performance'
  const isPageError = isSensorError
  const isPageLoading = isContractPoolLoading || sensorLoading

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Sensoren'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'sensors'}
      >
        <AMItem
          caption={'Importieren'}
          icon={<ImportExportTwoTone />}
          onClick={() => history.push(`/sensors/${sensorId}/import`)}
        />

        <AMItem
          caption={'Aktivieren / Deaktivieren'}
          icon={sensor.is_active ? <PowerTwoTone /> : <PowerOffTwoTone />}
          onClick={() =>
            setSensor(prev => ({ prev, is_active: !sensor.is_active }))
          }
        />

        <AMItem
          caption={'Speichern'}
          icon={<SaveTwoTone />}
          onClick={() => onSubmit('update')}
        />

        <AMItem
          caption={'Löschen'}
          icon={<DeleteTwoTone />}
          onClick={() => setIsDialogOpen(true)}
        />
      </AM>
      {/* action menu end */}

      {/* delete confirmation */}
      <DlgSensorDelete
        onClose={() => setIsDialogOpen(false)}
        onDelete={() => onSubmit('delete')}
        open={isDialogOpen}
      />
      {/* delete confirmation end */}

      {/* users assignment */}
      <Dialog
        maxWidth={'md'}
        open={showAssignment}
        onClose={() => setShowAssignment(false)}
      >
        <DialogTitle>Benutzerzuweisung für Sensor ID {sensorId}</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <List sx={{ maxHeight: 500, overflow: 'auto' }}>
            {userPool.sort(SORT_ATTR_FIRSTNAME).map((user, key) => {
              const { firstname, image_url, label, lastname } = user.attributes
              const isSelected = Boolean(
                sensorUsers.find(f => f === Number(user.id))
              )

              return (
                <ListItem
                  button
                  key={key}
                  onClick={() => {
                    sensorUsers.includes(user.id)
                      ? setSensorUsers(sensorUsers.filter(f => f !== user.id))
                      : setSensorUsers([...sensorUsers, Number(user.id)])

                    if (!isSelected) {
                      setSensorUsers([...sensorUsers, Number(user.id)])
                    } else {
                      const users = sensorUsers.filter(
                        f => f !== Number(user.id)
                      )

                      setSensorUsers(users)
                    }
                  }}
                >
                  <ListItemAvatar>
                    {image_url ? (
                      <Avatar src={image_url} />
                    ) : (
                      <Avatar>
                        <PeopleTwoTone />
                      </Avatar>
                    )}
                  </ListItemAvatar>

                  <ListItemText
                    primary={`${firstname} ${lastname}`}
                    secondary={label}
                    sx={{ width: '70%' }}
                  />

                  <ListItemText
                    primary={`ID: ${user.id}`}
                    sx={{ width: '30%' }}
                  />

                  <Checkbox edge={'end'} checked={isSelected} />
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
        <DialogActions>
          {sensorUsers.length} Benutzer zugewiesen
          <Tooltip title={'Dem Sensor alle Benutzer entziehen'}>
            <IconButton
              onClick={() => setSensorUsers([])}
              sx={{ ml: 5, color }}
            >
              <RemoveTwoTone />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Dem Sensor alle Benutzer zuweisen'}>
            <IconButton
              onClick={() =>
                setSensorUsers(userPool.map(user => Number(user.id)))
              }
              sx={{ color }}
            >
              <AddTwoTone />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
      {/* users assignment end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <Box
                alt={''}
                component={'img'}
                src={
                  sensorData &&
                  SensorIcon(sensor.sensor_type, sensorData.status || 'neutral')
                }
              />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={`ID: ${sensorId}`}
        >
          {/* read only */}
          <HStack justifyContent={'space-evenly'} sx={{ mb: 5 }}>
            <KTextField
              disabled
              error={!sensor.provider}
              label={'Provider'}
              value={sensor.provider || ''}
            />
            <KTextField
              disabled
              error={!sensor.device_id}
              label={'Device ID'}
              value={sensor.device_id}
            />
            <KTextField disabled label={'Typ'} value={sensor.sensor_type} />
          </HStack>
          {/* read only end */}

          <Tabs
            centered
            indicatorColor={'primary'}
            onChange={(event, tab) => setConfig(prev => ({ ...prev, tab }))}
            sx={{ mb: 5 }}
            textColor={'primary'}
            value={config.tab}
          >
            <Tab label={'Einstellungen'} />
            <Tab label={'Payload'} />
            <Tab label={'Aktivität'} />
            <Tab disabled={!isConsumptionSensor} label={'Faktor Zeitraum'} />
            <Tab disabled={!isConsumptionSensor} label={'Benchmark'} />
            <Tab label={'Zuweisungen'} />
          </Tabs>

          {/* Einstellungen */}
          <Collapse in={config.tab === 0}>
            <KTextField
              disabled={!sensor.is_active}
              label={'Label'}
              onChange={event => {
                const name = event.target.value

                setSensor(prev => ({ ...prev, name }))
              }}
              value={sensor.name || ''}
            />

            <KTextField
              disabled={!sensor.is_active}
              label={'Zählernummer'}
              onChange={event => {
                const meter_number = event.target.value

                setSensor(prev => ({ ...prev, meter_number }))
              }}
              value={sensor.meter_number || ''}
            />

            <KSelect
              disabled={!sensor.is_active}
              label={'Kategorie'}
              onChange={event => {
                const subcategory = event.target.value

                setSensor(prev => ({ ...prev, subcategory }))
              }}
              value={sensor.subcategory || ''}
            >
              <MenuItem value={null}>Keine Angabe</MenuItem>
              {[
                { label: 'Allgemein', value: 'general' },
                { label: 'Beleuchtung', value: 'lighting' },
                { label: 'Anlage', value: 'machine' },
                { label: 'BT A', value: 'bta' },
                { label: 'BT B1', value: 'btb1' },
                { label: 'BT B2', value: 'btb2' },
                { label: 'Sonstige', value: 'other' },
              ].map((category, key) => (
                <MenuItem key={key} value={category.value}>
                  {category.label}
                </MenuItem>
              ))}
            </KSelect>

            <ListSubheader>Targets</ListSubheader>
            <HStack sx={{ mb: 3 }}>
              <ToggleButtonGroup
                disabled={!sensor.is_active}
                exclusive
                onChange={event => {
                  const sensor_target = event.target.value

                  if (sensor_target !== null)
                    setSensor(prev => ({ ...prev, sensor_target }))
                }}
                sx={{ m: 1, height: 55 }}
                value={sensor.sensor_target}
              >
                <ToggleButton value={''} sx={{ width: 160 }}>
                  <DoNotDisturbTwoTone sx={{ mr: 1 }} />
                  Keine Angabe
                </ToggleButton>

                <ToggleButton value={'performance'}>
                  <Box
                    component={'img'}
                    src={SensorIcon('performance')}
                    sx={{ mr: 1, width: 22 }}
                  />
                  Verbrauch
                </ToggleButton>

                <ToggleButton value={'feeling'}>
                  <Box
                    component={'img'}
                    src={SensorIcon('feeling')}
                    sx={{ mr: 1, width: 22 }}
                  />
                  Wohlbefinden
                </ToggleButton>

                <ToggleButton value={'security'}>
                  <Box
                    component={'img'}
                    src={SensorIcon('security')}
                    sx={{ mr: 1, width: 22 }}
                  />
                  Sicherheit
                </ToggleButton>
              </ToggleButtonGroup>

              <KSelect
                disabled={!sensor.is_active}
                label={'Sub Target'}
                onChange={event =>
                  setSensor(prev => ({
                    ...prev,
                    sub_sensor_target: event.target.value,
                  }))
                }
                value={sensor.sub_sensor_target || ''}
              >
                <MenuItem value={null}>Keine Angabe</MenuItem>
                {[
                  { label: 'Strom', value: 'energy' },
                  { label: 'Strom Detail', value: 'energy_detail' },
                  { label: 'Gas', value: 'gas' },
                  { label: 'Gas Detail', value: 'gas_detail' },
                  { label: 'Wasser', value: 'water' },
                  { label: 'Wasser Detail', value: 'water_detail' },
                  { label: 'Temperatur', value: 'temperature' },
                  { label: 'Luftqualität', value: 'co2' },
                  { label: 'Luftfeuchtigkeit', value: 'humidity' },
                  { label: 'Lichtstärke', value: 'light' },
                  { label: 'Wassermelder', value: 'waterDetected' },
                  {
                    label: 'Schließanlage (Schloss)',
                    value: 'tor1CurrentState',
                  },
                  { label: 'Schließanlage (Tür)', value: 'door' },
                  { label: 'Technische Anlagen', value: 'lifting' },
                  { label: 'Aufzug', value: 'elevator' },
                  { label: 'Anlagendruck', value: 'pressure' },
                  { label: 'Anlagentemperatur', value: 'heating' },
                  { label: 'Rauchmelder', value: 'smokeDetektor' },
                ].map((sub, key) => (
                  <MenuItem key={key} value={sub.value}>
                    {sub.label}
                  </MenuItem>
                ))}
              </KSelect>
            </HStack>

            <HStack>
              <KSelect
                disabled={!sensor.is_active}
                error={!sensor.folder_id}
                label={`Gebäude ${
                  sensor.folder_id
                    ? ' (ID:' + sensor.folder_id + ')'
                    : ' wählen ...'
                }`}
                onChange={event =>
                  setSensor(prev => ({
                    ...prev,
                    folder_id: event.target.value,
                  }))
                }
                value={sensor.folder_id || ''}
              >
                <MenuItem disabled={!sensor.folder_id} value={null}>
                  Keine Angabe
                </MenuItem>

                {folderPool.sort(SORT_ATTR_NAME).map((folder, key) => (
                  <MenuItem key={key} value={folder.id}>
                    {folder.attributes.name}
                  </MenuItem>
                ))}
              </KSelect>

              <KSelect
                disabled={true}
                error={!contractId}
                label={`Vertrag ${
                  contractId ? ' (ID:' + contractId + ')' : ' wählen ...'
                }`}
                onChange={event => setContractId(event.target.value)}
                value={contractId || ''}
              >
                <MenuItem disabled={!contractId} value={null}>
                  Keine Angabe
                </MenuItem>

                {contractPool.sort(SORT_ATTR_NAME).map((contract, key) => (
                  <MenuItem key={key} value={contract.id}>
                    {contract.attributes.name}
                  </MenuItem>
                ))}
              </KSelect>
            </HStack>

            <KSelect
              disabled={!sensor.is_active}
              label={'Anlage'}
              onChange={event => setInstallation(event.target.value)}
              value={installation || ''}
            >
              <MenuItem disabled={!installation} value={0}>
                Anlage wählen ...
              </MenuItem>

              {folderInstallations.sort(SORT_NAME).map((item, key) => (
                <MenuItem key={key} value={item.entity_id}>
                  {item.name}
                </MenuItem>
              ))}
            </KSelect>

            <KSelect
              disabled={!sensor.is_active}
              error={
                sensor.parent_id &&
                !folderSensors.find(f => Number(f.id) === sensor.parent_id)
              }
              label={`Übergeordneter Sensor (ID:
                        ${sensor.parent_id})`}
              onChange={event => {
                const parent_id = event.target.value

                setSensor(prev => ({ ...prev, parent_id }))
              }}
              value={sensor.parent_id || ''}
            >
              <MenuItem disabled={!sensor.parent_id} value={null}>
                Übergeordneten Sensor wählen ...
              </MenuItem>
              {folderSensors
                .filter(f => f.id !== sensor.entity_id)
                .sort((a, b) => (a.device_id > b.device_id ? 1 : -1))
                .map((item, key) => {
                  const { entity_id, device_id, provider, sensor_type } = item

                  return (
                    <MenuItem key={key} value={entity_id}>
                      (ID:
                      {entity_id}) {provider} :: {device_id} :: {sensor_type}
                    </MenuItem>
                  )
                })}
            </KSelect>
            {sensor.parent_id &&
              !folderSensors.find(
                f => Number(f.entity_id) === sensor.parent_id
              ) && (
                <FormHelperText>
                  Der übergeordnete Sensor ist kein Sensor dieses Gebäudes
                </FormHelperText>
              )}

            <KSelect
              disabled={!sensor.is_active}
              error={
                sensor.successor_id &&
                !folderSensors.find(f => Number(f.id) === sensor.successor_id)
              }
              label={`Nachfolge Sensor (ID:
                        ${sensor.successor_id})`}
              onChange={event => {
                const successor_id = event.target.value

                setSensor(prev => ({ ...prev, successor_id }))
              }}
              value={sensor.successor_id || ''}
            >
              <MenuItem disabled={!sensor.successor_id} value={null}>
                Übergeordneten Sensor wählen ...
              </MenuItem>
              {folderSensors
                .filter(f => f.id !== sensor.entity_id)
                .sort((a, b) => (a.device_id > b.device_id ? 1 : -1))
                .map((item, key) => {
                  const { entity_id, device_id, provider, sensor_type } = item

                  return (
                    <MenuItem key={key} value={entity_id}>
                      (ID:
                      {entity_id}) {provider} :: {device_id} :: {sensor_type}
                    </MenuItem>
                  )
                })}
            </KSelect>

            <KSelect
              disabled={!sensor.is_active}
              label={'Energietyp'}
              onChange={event => {
                const type_in_folder = event.target.value

                setSensor(prev => ({ ...prev, type_in_folder }))
              }}
              value={sensor.type_in_folder || ''}
            >
              {[
                { label: 'Keine Angabe', value: '' },
                { label: 'Strom', value: 'energy' },
                { label: 'Strom Detail', value: 'energy_detail' },
                { label: 'Gas', value: 'gas' },
                { label: 'Gas Detail', value: 'gas_detail' },
                { label: 'Wasser', value: 'water' },
                { label: 'Wasser Detail', value: 'water_detail' },
              ].map((item, key) => (
                <MenuItem key={key} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </KSelect>

            <KSelect
              disabled={!sensor.is_active}
              label={'Einheit'}
              onChange={event => {
                const unit = event.target.value

                setSensor(prev => ({ ...prev, unit }))
              }}
              value={sensor.unit || ''}
            >
              {[
                { label: 'Keine Angabe', value: '' },
                { label: 'kWh', value: 'kWh' },
                { label: 'm³', value: 'm³' },
                { label: '°C', value: '°C' },
                { label: '%', value: '%' },
                { label: 'Lux', value: 'Lux' },
                { label: 'bar', value: 'bar' },
                { label: 'ppm ', value: 'ppm' },
              ].map((item, key) => (
                <MenuItem key={key} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </KSelect>

            <KSelect
              disabled={!sensor.is_active}
              label={'Messverfahren'}
              onChange={event => {
                const sampling_mode = event.target.value

                setSensor(prev => ({ ...prev, sampling_mode }))
              }}
              value={sensor.sampling_mode || ''}
            >
              {[
                { label: 'Keine Angabe', value: null },
                { label: 'Zählerstand', value: 1 },
                { label: 'Verbrauch', value: 2 },
              ].map((item, key) => (
                <MenuItem key={key} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </KSelect>

            <HStack justifyContent={'space-evenly'}>
              <KTextField
                disabled={!sensor.is_active}
                label={'Zählerstand'}
                onChange={event =>
                  setSensor(prev => ({
                    ...prev,
                    counter_reading: event.target.value,
                  }))
                }
                value={germanNotation(sensor.counter_reading) || ''}
              />

              <KTextField
                disabled={!sensor.is_active}
                label={'Zählerstand Datum'}
                InputLabelProps={{ shrink: true }}
                onChange={event => {
                  const counter_reading_date = event.target.value

                  setSensor(prev => ({ ...prev, counter_reading_date }))
                }}
                type={'date'}
                value={sensor.counter_reading_date}
              />
            </HStack>

            <HStack justifyContent={'space-evenly'}>
              <KTextField
                disabled={!sensor.is_active}
                label={'Batterie Kommentar'}
                onChange={event => {
                  const battery_comment = event.target.value

                  setSensor(prev => ({ ...prev, battery_comment }))
                }}
                value={germanNotation(sensor.battery_comment) || ''}
                variant={'outlined'}
              />

              <KTextField
                disabled={!sensor.is_active}
                label={'Batterie Datum'}
                InputLabelProps={{ shrink: true }}
                onChange={event =>
                  setSensor(prev => ({
                    ...prev,
                    battery_date: event.target.value,
                  }))
                }
                type={'date'}
                value={sensor.battery_date || ''}
              />
            </HStack>

            <KTextField
              disabled={!sensor.is_active}
              label={'Korrekturwert'}
              onChange={event => {
                const correction_value = event.target.value

                setSensor(prev => ({ ...prev, correction_value }))
              }}
              value={germanNotation(sensor.correction_value) || ''}
            />

            {attributes.length > 0 &&
              attributes.map((item, key) => {
                const { attribute_code, label, value } = item

                return (
                  <KTextField
                    disabled={!sensor.is_active}
                    key={key}
                    label={label}
                    onChange={event => handleChangeAttr(event, attribute_code)}
                    type={attribute_code === 'invoicing' ? 'date' : 'number'}
                    value={value}
                  />
                )
              })}
          </Collapse>
          {/* Einstellungen End */}

          {/* Payload */}
          <Collapse in={config.tab === 1}>
            <JsonView collapsed={false} src={sensorPayload} />
          </Collapse>
          {/* Payload */}

          {/* Aktivität */}
          <Collapse in={config.tab === 2} sx={{ textAlign: 'center' }}>
            <Typography component={'h4'} variant={'h4'}>
              {SensorType(sensor.sensor_type, sensor.type_in_folder)}
            </Typography>

            <Chart
              chartLanguage={'de_DE'}
              chartType={'AreaChart'}
              loader={<Box>Loading Chart</Box>}
              data={series}
              options={{
                annotations: { stem: { color: '#097138' }, style: 'line' },
                chartArea: { height: '80%', width: '90%' },
                colors: ['#9ECE67'],
                curveType: 'function',
                legend: 'none',
                hAxis: { format: 'HH:mm' },
                vAxis: {
                  format: `#,###.## ${sensor.unit}`,
                  viewWindow: { min: rangeMin, max: rangeMax },
                },
              }}
            />

            <HStack justifyContent={'space-evenly'} sx={{ mt: 5 }}>
              <KTextField
                label={
                  sensor
                    ? SensorType(sensor.sensor_type, sensor.type_in_folder)
                    : ''
                }
                readOnly
                unit={sensor?.unit}
                value={sensorData?.value ?? ''}
              />

              <KTextField
                label={'letzte Aktivität'}
                readOnly
                value={
                  sensorData
                    ? getRelativeDate(
                        String(new Date(Date.parse(sensorData.time)))
                      )
                    : ''
                }
              />
            </HStack>
          </Collapse>
          {/* Aktivität */}

          {/* Faktor Zeitraum */}
          <Collapse in={config.tab === 3} sx={{ textAlign: 'center' }}>
            {isConsumptionSensor ? (
              <SensorFactors sensor={sensor} contractId={contractId} />
            ) : (
              <Alert severity={'info'}>
                <AlertTitle>Funktion nicht verfügbar</AlertTitle>
                Dieser Sensor ist kein Verbrauchssensor und hat keine Faktoren
              </Alert>
            )}
          </Collapse>

          {/* Benchmarks */}
          <Collapse in={config.tab === 4} sx={{ textAlign: 'center' }}>
            {isConsumptionSensor ? (
              <SensorBenchmarks sensor={sensor} contractId={contractId} />
            ) : (
              <Alert severity={'info'}>
                <AlertTitle>Funktion nicht verfügbar</AlertTitle>
                Dieser Sensor ist kein Verbrauchssensor und hat keine Benchmarks
              </Alert>
            )}
          </Collapse>
          {/* Benchmarks */}

          {/* Benutzer */}
          <Collapse in={config.tab === 5}>
            <HStack justifyContent={'space-between'}>
              <AvatarGroup max={10}>
                {sensorUsers.map((id, key) => {
                  const record = userPool.find(f => Number(f.id) === id)
                  if (!record) return <Box key={key} />

                  const { firstname, image_url, lastname } = record.attributes
                  const title = `${firstname} ${lastname}`

                  return (
                    <Tooltip key={key} title={title}>
                      <Avatar src={image_url} />
                    </Tooltip>
                  )
                })}
              </AvatarGroup>

              <IconButton onClick={() => setShowAssignment(true)}>
                <SettingsTwoTone />
              </IconButton>
            </HStack>
          </Collapse>
        </KPage>
      )}
    </>
  )
}

SensorEdit.propTypes = {
  PAGE_CONFIG: PropTypes.string,
}
