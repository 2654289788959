import {
  AddTwoTone,
  DownloadTwoTone,
  InfoTwoTone,
  LocalShippingTwoTone,
  MemoryTwoTone,
  MoreVertTwoTone,
  PollTwoTone,
  SettingsRemoteTwoTone,
  StorageTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Button,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import matchSorter from 'match-sorter'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { ASC, DESC, getStorage, setStorage } from '../../api'
import {
  AM,
  AMItem,
  CellBoilerplate,
  CellDate,
  CellIsActive,
  DefaultColumnFilter,
  DlgSensorsExport,
  DlgSensorPoolInfo,
  FilterByStatus,
  FilterStatus,
  HStack,
  KPage,
} from '../../components'
import { useFolderPool, useOperator, useSensorPool } from '../../hooks'

export const SensorAll = ({ PAGE_CONFIG = 'settings_sensors_all' }) => {
  const history = useHistory()

  const { data: operator } = useOperator()
  const { data: folderPool, isLoading: isFolderPoolLoading } = useFolderPool()
  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const exportDialog = config?.exportDialog ?? false
  const maxResults = config?.maxResults ?? 10
  const orderby = config?.orderby ?? 'id'
  const order = config?.order ?? 'desc'
  const showStats = config?.showStats ?? false
  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  const [actionMenu, setActionMenu] = useState(null)
  const [dataPool, setDataPool] = useState([])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
    rows,
  } = useTable(
    {
      columns: useMemo(
        () => [
          {
            accessor: 'id',
            Header: 'ID',
            sortType: (a, b) => Number(a.original.id) - Number(b.original.id),
            width: 80,
          },
          {
            accessor: 'is_active',
            Cell: CellIsActive,
            disableFilters: true,
            disableSortBy: true,
            Header: 'aktiviert',
            width: 80,
          },
          { accessor: 'name', Header: 'Name' },
          { accessor: 'device_id', Header: 'Device ID' },
          { accessor: 'sensor_type', Header: 'Typ' },
          { accessor: 'provider', Cell: CellBoilerplate, Header: 'Provider' },
          { accessor: 'value', Header: 'Wert' },
          { accessor: 'folder_name', Cell: CellBoilerplate, Header: 'Gebäude' },
          {
            accessor: 'status',
            Cell: FilterByStatus,
            Filter: FilterStatus,
            Header: 'Status',
          },
          { accessor: 'time', Cell: CellDate, Header: 'letzte Aktivität' },
        ],
        []
      ),
      data: useMemo(
        () => dataPool.map(sensor => ({ ...sensor.attributes, id: sensor.id })),
        [dataPool]
      ),
      defaultColumn: useMemo(() => ({ Filter: DefaultColumnFilter }), []),
      filterTypes: useMemo(
        () => ({
          text: (rows, id, filterValue) =>
            matchSorter(rows, filterValue, { keys: [id] }),

          statusFilter: (rows, id, filterValue) => {
            if (filterValue === 'all') return rows
            return rows.filter(row => row.values[id] === filterValue)
          },
        }),
        []
      ),
      initialState: useMemo(
        () => ({
          sortBy: [{ id: orderby, desc: order !== 'ASC' }],
          filters: [{ id: 'folder_id', value: 'true' }],
          pageSize: maxResults,
        }),
        [maxResults, order, orderby]
      ),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  useEffect(() => {
    if (folderPool.length === 0 || sensorPool.length === 0) return

    const data = sensorPool.map(sensor => {
      const folderId = Number(sensor.attributes.folder_id)

      const folder_name =
        folderPool.find(folder => Number(folder.id) === folderId)?.attributes
          ?.name ?? '-'

      return {
        ...sensor,
        attributes: { ...sensor.attributes, folder_name },
      }
    })

    setDataPool(data)
  }, [folderPool, sensorPool])

  const gotoSensorPage = useCallback(
    id => history.push(`/sensors/${id}`),
    [history]
  )

  const onPageChange = useCallback(
    (event, newPage) => gotoPage(newPage),
    [gotoPage]
  )

  const onRowsPerPageChange = useCallback(
    event => {
      const maxResults = Number(event.target.value)

      setPageSize(maxResults)
      setConfig(prev => ({ ...prev, maxResults }))
    },
    [setPageSize]
  )

  const onAddSensor = () => history.push('/sensors/new')
  const onCloseAM = () => setActionMenu(null)
  const onOpenAM = event => setActionMenu(event.currentTarget)

  const onCloseExport = () =>
    setConfig(prev => ({ ...prev, exportDialog: false }))
  const onCloseStats = () => setConfig(prev => ({ ...prev, showStats: false }))
  const onExport = () => setConfig(prev => ({ ...prev, exportDialog: true }))
  const onOpenStats = () => setConfig(prev => ({ ...prev, showStats: true }))

  const onGotoVirtualSensors = () => history.push('/virtualsensors')
  const onGotoDeviations = () => history.push('/sensors/calculon')
  const onGotoPayloads = () => history.push('/sensors/payloads')
  const onGotoData = () => history.push('/data')

  const isPageLoading = isFolderPoolLoading || isSensorPoolLoading

  return operator?.isAdmin ? (
    <KPage
      action={
        <IconButton onClick={onOpenAM}>
          <MoreVertTwoTone />
        </IconButton>
      }
      avatar={
        <Avatar>
          <MemoryTwoTone />
        </Avatar>
      }
      isLoading={isPageLoading}
      title={`${maxResults}/${rows.length} Sensoren`}
    >
      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Sensoren'}
        onClose={onCloseAM}
        open={Boolean(actionMenu)}
        historyUrlTarget={'dashboard'}
      >
        {[
          { caption: 'Neu', icon: <AddTwoTone />, fn: onAddSensor },
          { caption: 'Info', icon: <InfoTwoTone />, fn: onOpenStats },
          { separator: <Divider /> },
          { caption: 'Exportieren', icon: <DownloadTwoTone />, fn: onExport },
          {
            caption: 'Virtuelle Sensoren',
            icon: <SettingsRemoteTwoTone />,
            fn: onGotoVirtualSensors,
          },
          {
            caption: 'Abweichungen',
            icon: <PollTwoTone />,
            fn: onGotoDeviations,
          },
          {
            caption: 'Payloads',
            icon: <LocalShippingTwoTone />,
            fn: onGotoPayloads,
          },
          {
            caption: 'Daten',
            icon: <StorageTwoTone />,
            fn: onGotoData,
          },
        ].map((item, key) =>
          !item.separator ? (
            <AMItem
              caption={item.caption}
              icon={item.icon}
              key={key}
              onClick={item.fn}
            />
          ) : (
            item.separator
          )
        )}
      </AM>
      {/* action menu end */}

      <DlgSensorPoolInfo open={showStats} onClose={onCloseStats} />

      <DlgSensorsExport
        open={exportDialog}
        onClose={onCloseExport}
        rows={rows}
        page={page}
      />

      <TableContainer>
        <Table sx={{ width: 1 }} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <>
                <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, innerKey) => (
                    <TableCell
                      key={innerKey}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      sx={{ fontSize: 16, width: column.width }}
                    >
                      {column.render('Header')}
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? DESC : ASC}
                      />
                    </TableCell>
                  ))}
                </TableRow>

                <TableRow>
                  {headerGroup.headers.map((column, innerKey) => (
                    <TableCell
                      key={innerKey}
                      sx={{ p: 0 }}
                      {...column.getHeaderProps()}
                    >
                      {column.canFilter ? column.render('Filter') : null}
                    </TableCell>
                  ))}
                </TableRow>
              </>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              const onClick = () => gotoSensorPage(row.original.id)

              prepareRow(row)

              return (
                <TableRow
                  key={key}
                  onClick={onClick}
                  sx={{ cursor: 'pointer' }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, innerKey) => (
                    <TableCell
                      key={innerKey}
                      {...cell.getCellProps()}
                      sx={{ fontSize: 16 }}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <HStack sx={{ width: 1 }}>
        <TablePagination
          component={'div'}
          count={pageCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={pageIndex}
          rowsPerPage={Number(pageSize)}
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        />

        <Button disabled={pageIndex === 0} onClick={previousPage}>
          Zurück
        </Button>

        <Button disabled={pageIndex >= pageCount - 1} onClick={nextPage}>
          Weiter
        </Button>
      </HStack>
    </KPage>
  ) : (
    <Alert severity={'info'}>Admin Bereich</Alert>
  )
}

SensorAll.propTypes = {
  PAGE_CONFIG: PropTypes.string,
}
