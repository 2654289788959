import { CancelTwoTone, DeleteTwoTone, SaveTwoTone } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../actions'
import { useAppConfig } from '../../hooks'

export const DlgDashboardEdit = ({
  dashboardId,
  data = [
    { label: 'letzter voller Monat', value: 'lastFullMonth' },
    { label: 'letzten 30 Tage', value: 'lastThirtyDays' },
    { label: 'letzten 2 Monate', value: 'lastTwoMonths' },
    { label: 'letzten 6 Monate', value: 'lastSixMonths' },
    { label: 'dieses Jahr (Januar bis heute)', value: 'thisYear' },
    { label: 'letztes Jahr', value: 'lastYear' },
  ],
  granularity: initialGranularity,
  name: initialName,
  onChangeGranularity,
  onChangeName,
  onClose,
  open,
  title = 'Dashboard Einstellungen',
}) => {
  const dispatch = useDispatch()

  const user_id = useAppConfig()?.appConfig?.user_id

  const [name, setName] = useState()
  const [granularity, setGranularity] = useState()

  useEffect(() => setName(initialName), [initialName])
  useEffect(() => setGranularity(initialGranularity), [initialGranularity])

  const changeName = event => setName(event.target.value)
  const changeGranularity = event => setGranularity(event.target.value)

  const deleteDashboard = () => dispatch(dashboardActions.remove(dashboardId))
  const updateDashboard = () => {
    dispatch(dashboardActions.update(dashboardId, { name, user_id }))

    onChangeName(name)
    onChangeGranularity(granularity)
  }

  const NO_MOD = name === initialName && granularity === initialGranularity

  return !name || !granularity ? null : (
    <Dialog maxWidth={'xl'} open={open}>
      <DialogTitle>
        <Stack>{title}</Stack>
      </DialogTitle>

      <DialogContent>
        <TextField
          error={name === ''}
          fullWidth
          label={'Titel'}
          onChange={changeName}
          sx={{ mx: 0 }}
          value={name}
        />

        <TextField
          fullWidth
          label={'Zeitrahmen'}
          onChange={changeGranularity}
          select
          sx={{ mx: 0 }}
          value={granularity}
        >
          {data.map((item, key) => (
            <MenuItem key={key} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>

      <DialogActions>
        <Button onClick={deleteDashboard}>
          <DeleteTwoTone />
          Löschen
        </Button>

        <Divider />

        <Button disabled={NO_MOD} onClick={updateDashboard}>
          <SaveTwoTone />
          Aktualisieren
        </Button>

        <Button onClick={onClose}>
          <CancelTwoTone />
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgDashboardEdit.propTypes = {
  dashboardId: PropTypes.number.isRequired,
  data: PropTypes.array,
  granularity: PropTypes.string,
  name: PropTypes.string,
  onChangeGranularity: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
}
