import 'fix-date'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  A_MONTH_AGO,
  germanDecimals,
  MMMMYYYY,
  sensorUnit,
  SORT_TIMEPOINT,
  userLocales,
} from '../../api'
import { ConsumptionChart, PackageUpgrade, Preloader } from '../../components'
import { svgConstants as SVG } from '../../constants'
import {
  useBridgeId,
  useCoachData,
  useConsumptionSensors,
  useDailyConsumptionToDate,
  useFolderSensors,
  useMonthlyConsumptionToDate,
  useSensorPool,
  useSensorPoolIds,
  useYearlyConsumptionToDate,
} from '../../hooks'
import { Collapse } from '@mui/material'

export const Performance = ({ folderId }) => {
  const bridgeSensor = useBridgeId(folderId)
  const coachData = useCoachData(bridgeSensor)
  const { data: sensorPool, isLoading: sensorPoolLoading } = useSensorPool()
  const opSensorIds = useSensorPoolIds()
  const folderSensors = useFolderSensors(folderId)

  const { data: consumption24, isLoading: isConsumption24Loading } =
    useDailyConsumptionToDate(folderId)

  const { data: consumptionMonthly, isLoading: isConsumptionMonthlyLoading } =
    useMonthlyConsumptionToDate(folderId)

  const { data: consumptionYearly, isLoading: isConsumptionYearlyLoading } =
    useYearlyConsumptionToDate(folderId)

  const cSensors = useConsumptionSensors(folderId)

  const [dailyEnergy, setDailyEnergy] = useState([])
  const [dailyWater, setDailyWater] = useState([])
  const [dailyGas, setDailyGas] = useState([])

  const [monthlyEnergy, setMonthlyEnergy] = useState([])
  const [monthlyWater, setMonthlyWater] = useState([])
  const [monthlyGas, setMonthlyGas] = useState([])

  const [yearlyEnergy, setYearlyEnergy] = useState([])
  const [yearlyWater, setYearlyWater] = useState([])
  const [yearlyGas, setYearlyGas] = useState([])

  const [hasMainEnergySensor, setHasMainEnergySensor] = useState(false)
  const [hasMainWaterSensor, setHasMainWaterSensor] = useState(false)
  const [hasMainGasSensor, setHasMainGasSensor] = useState(false)

  const [energySensors, setEnergySensors] = useState([])
  const [watersSensors, setWatersSensors] = useState([])
  const [gasSensors, setGasSensors] = useState([])

  const energy = []
  const water = []
  const gas = []

  const DATA_REDUCER = (acc, cur) => {
    const { timepoint } = cur

    acc[timepoint] = acc[timepoint] || {}

    for (const attr in cur) {
      if (attr === 'tick')
        acc[timepoint][attr] = acc[timepoint][attr]
          ? acc[timepoint][attr]
          : cur[attr]

      if (attr === 'timepoint')
        acc[timepoint][attr] = acc[timepoint][attr]
          ? acc[timepoint][attr]
          : cur[attr]

      if (attr === 'value')
        acc[timepoint][attr] = acc[timepoint][attr]
          ? acc[timepoint][attr] + cur[attr]
          : cur[attr]

      // status handling needs to be done; but status comes neutral so far
      if (attr === 'status')
        acc[timepoint][attr] = acc[timepoint][attr]
          ? acc[timepoint][attr] + ' und ' + cur[attr]
          : cur[attr]
    }

    return acc
  }

  const FILTER_OLDER_THAN_YESTERDAY = f => {
    const _1S = 1000
    const _1M = 60 * _1S
    const _1H = 60 * _1M
    const _24H = 24 * _1H
    const NOW = Date.now()
    const YESTERDAY = NOW - _24H
    const TZO = new Date().getTimezoneOffset() * _1M
    const date = new Date(f.timepoint)
    date.setTime(date.getTime() - TZO)

    return date.getTime() > YESTERDAY
  }

  const FILTER_OLDER_THAN_A_MONTH = f =>
    new Date(f.timepoint).getTime() > A_MONTH_AGO

  const FILTER_OLDER_THAN_A_YEAR = f => {
    const NOW = Date.now()
    const A_YEAR_AGO = new Date(NOW).setFullYear(
      new Date(NOW).getFullYear() - 1
    )

    return new Date(f.timepoint).getTime() > A_YEAR_AGO
  }

  const generateColumns = energyType => [
    '',
    sensorUnit(energyType),
    { p: { html: true }, role: 'tooltip', type: 'string' },
  ]

  useEffect(() => {
    const consumptionIds = [
      ...new Set([
        ...consumption24.map(item => item.entity_id),
        ...consumptionMonthly.map(item => item.entity_id),
        ...consumptionYearly.map(item => item.entity_id),
      ]),
    ]

    const findMainSensor = type =>
      sensorPool.some(
        sensor =>
          sensor.attributes.parent_id === null &&
          sensor.attributes.type_in_folder === type &&
          Number(sensor.attributes.folder_id) === folderId &&
          consumptionIds.includes(Number(sensor.attributes.entity_id))
      )

    setHasMainEnergySensor(findMainSensor('energy'))
    setHasMainWaterSensor(findMainSensor('water'))
    setHasMainGasSensor(findMainSensor('gas'))
  }, [
    consumption24,
    consumptionMonthly,
    consumptionYearly,
    folderId,
    sensorPool,
  ])

  useEffect(() => {
    let dailyEnergy = consumption24
      .filter(f =>
        hasMainEnergySensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'energy' ||
          f.sensor_type === 'energy_detail' ||
          f.type_in_folder === 'energy' ||
          f.type_in_folder === 'energy_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    // reduce consumption values of each sensor into single list
    // remove records older than 24h
    dailyEnergy = Object.keys(dailyEnergy)
      .reduce((acc, cur) => acc.concat(dailyEnergy[cur]), [])
      .filter(FILTER_OLDER_THAN_YESTERDAY)

    // sum up and reduce consumption values based on timestamp
    dailyEnergy = Object.values(dailyEnergy.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('energy')}`

        return [new Date(timepoint), value, tooltip]
      })

    setDailyEnergy([generateColumns('energy'), ...dailyEnergy])

    let dailyWater = consumption24
      .filter(f =>
        hasMainWaterSensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'water' ||
          f.sensor_type === 'water_detail' ||
          f.type_in_folder === 'water' ||
          f.type_in_folder === 'water_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    // remove records older than 24h
    dailyWater = Object.keys(dailyWater)
      .reduce((acc, cur) => acc.concat(dailyWater[cur]), [])
      .filter(FILTER_OLDER_THAN_YESTERDAY)

    // sum up and reduce consumption values based on timestamp
    dailyWater = Object.values(dailyWater.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('water')}`

        return [new Date(timepoint), value, tooltip]
      })

    setDailyWater([generateColumns('water'), ...dailyWater])

    let dailyGas = consumption24
      .filter(f =>
        hasMainGasSensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'gas' ||
          f.sensor_type === 'gas_detail' ||
          f.type_in_folder === 'gas' ||
          f.type_in_folder === 'gas_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    // remove records older than 24h
    dailyGas = Object.keys(dailyGas)
      .reduce((acc, cur) => acc.concat(dailyGas[cur]), [])
      .filter(FILTER_OLDER_THAN_YESTERDAY)

    // sum up and reduce consumption values based on timestamp
    dailyGas = Object.values(dailyGas.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('gas')}`

        return [new Date(timepoint), value, tooltip]
      })

    setDailyGas([generateColumns('gas'), ...dailyGas])
  }, [
    consumption24,
    hasMainEnergySensor,
    hasMainGasSensor,
    hasMainWaterSensor,
    opSensorIds,
  ])

  useEffect(() => {
    let monthlyEnergy = consumptionMonthly
      .filter(f =>
        hasMainEnergySensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'energy' ||
          f.sensor_type === 'energy_detail' ||
          f.type_in_folder === 'energy' ||
          f.type_in_folder === 'energy_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    // filter older than a month
    monthlyEnergy = Object.keys(monthlyEnergy)
      .reduce((acc, cur) => acc.concat(monthlyEnergy[cur]), [])
      .filter(FILTER_OLDER_THAN_A_MONTH)

    // sum up and reduce consumption values based on timestamp
    monthlyEnergy = Object.values(monthlyEnergy.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('energy')}`

        return [new Date(timepoint), value, tooltip]
      })

    setMonthlyEnergy([generateColumns('energy'), ...monthlyEnergy])

    let monthlyWater = consumptionMonthly
      .filter(f =>
        hasMainWaterSensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'water' ||
          f.sensor_type === 'water_detail' ||
          f.type_in_folder === 'water' ||
          f.type_in_folder === 'water_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    monthlyWater = Object.keys(monthlyWater)
      .reduce((acc, cur) => acc.concat(monthlyWater[cur]), [])
      .filter(FILTER_OLDER_THAN_A_MONTH)

    monthlyWater = Object.values(monthlyWater.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('water')}`

        return [new Date(timepoint), value, tooltip]
      })

    setMonthlyWater([generateColumns('water'), ...monthlyWater])

    let monthlyGas = consumptionMonthly
      .filter(f =>
        hasMainGasSensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'gas' ||
          f.sensor_type === 'gas_detail' ||
          f.type_in_folder === 'gas' ||
          f.type_in_folder === 'gas_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    monthlyGas = Object.keys(monthlyGas)
      .reduce((acc, cur) => acc.concat(monthlyGas[cur]), [])
      .filter(FILTER_OLDER_THAN_A_MONTH)

    monthlyGas = Object.values(monthlyGas.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('gas')}`

        return [new Date(timepoint), value, tooltip]
      })

    setMonthlyGas([generateColumns('gas'), ...monthlyGas])
  }, [
    consumptionMonthly,
    hasMainEnergySensor,
    hasMainGasSensor,
    hasMainWaterSensor,
    opSensorIds,
  ])

  useEffect(() => {
    let yearlyEnergy = consumptionYearly
      .filter(f =>
        hasMainEnergySensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'energy' ||
          f.sensor_type === 'energy_detail' ||
          f.type_in_folder === 'energy' ||
          f.type_in_folder === 'energy_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    yearlyEnergy = Object.keys(yearlyEnergy)
      .reduce((acc, cur) => acc.concat(yearlyEnergy[cur]), [])
      .filter(FILTER_OLDER_THAN_A_YEAR)

    yearlyEnergy = Object.values(yearlyEnergy.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const date = new Date(timepoint).toLocaleString(userLocales, MMMMYYYY)
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('energy')}`

        return [date, value, tooltip]
      })

    setYearlyEnergy([generateColumns('energy'), ...yearlyEnergy])

    let yearlyWater = consumptionYearly
      .filter(f =>
        hasMainWaterSensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'water' ||
          f.sensor_type === 'water_detail' ||
          f.type_in_folder === 'water' ||
          f.type_in_folder === 'water_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    yearlyWater = Object.keys(yearlyWater)
      .reduce((acc, cur) => acc.concat(yearlyWater[cur]), [])
      .filter(FILTER_OLDER_THAN_A_YEAR)

    yearlyWater = Object.values(yearlyWater.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const date = new Date(timepoint).toLocaleString(userLocales, MMMMYYYY)
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('water')}`

        return [date, value, tooltip]
      })

    setYearlyWater([generateColumns('water'), ...yearlyWater])

    let yearlyGas = consumptionYearly
      .filter(f =>
        hasMainGasSensor ? f.parent_id === null : f.parent_id !== null
      )
      .filter(
        f =>
          f.sensor_type === 'gas' ||
          f.sensor_type === 'gas_detail' ||
          f.type_in_folder === 'gas' ||
          f.type_in_folder === 'gas_detail'
      )
      .filter(f => opSensorIds.includes(f.entity_id))
      .map(item => item.consumptions.values)

    yearlyGas = Object.keys(yearlyGas)
      .reduce((acc, cur) => acc.concat(yearlyGas[cur]), [])
      .filter(FILTER_OLDER_THAN_A_YEAR)

    yearlyGas = Object.values(yearlyGas.reduce(DATA_REDUCER, {}))
      .sort(SORT_TIMEPOINT)
      .map(item => {
        const { timepoint, value } = item
        const date = new Date(timepoint).toLocaleString(userLocales, MMMMYYYY)
        const timestamp = value.toLocaleString(userLocales, germanDecimals)
        const tooltip = `${timestamp} ${sensorUnit('gas')}`

        return [date, value, tooltip]
      })

    setYearlyGas([generateColumns('gas'), ...yearlyGas])
  }, [
    consumptionYearly,
    hasMainEnergySensor,
    hasMainGasSensor,
    hasMainWaterSensor,
    opSensorIds,
  ])

  useEffect(() => {
    if (
      isConsumption24Loading ||
      isConsumptionMonthlyLoading ||
      isConsumptionYearlyLoading
    )
      return

    const getSensorNamesByType = type => {
      const HAS_MAIN_SENSOR =
        type === 'water'
          ? hasMainWaterSensor
          : type === 'gas'
            ? hasMainGasSensor
            : hasMainEnergySensor

      return folderSensors
        ? folderSensors
            .filter(f =>
              HAS_MAIN_SENSOR
                ? f.sensor_type === type || f.sub_sensor_target === type
                : f.sensor_type === type ||
                  f.sensor_type === type + '_detail' ||
                  f.sub_sensor_target === type ||
                  f.sub_sensor_target === type + '_detail'
            )
            .filter(f =>
              HAS_MAIN_SENSOR ? f.parent_id === null : f.parent_id !== null
            )
            .map(sensor => sensor.name)
            .sort((a, b) => a.localeCompare(b))
        : []
    }

    const energySensors = getSensorNamesByType('energy')
    const watersSensors = getSensorNamesByType('water')
    const gasSensors = getSensorNamesByType('gas')

    setEnergySensors(energySensors)
    setWatersSensors(watersSensors)
    setGasSensors(gasSensors)
  }, [
    folderSensors,
    isConsumption24Loading,
    isConsumptionMonthlyLoading,
    isConsumptionYearlyLoading,
    hasMainEnergySensor,
    hasMainWaterSensor,
    hasMainGasSensor,
  ])

  const isPageLoading =
    sensorPoolLoading ||
    isConsumption24Loading ||
    isConsumptionMonthlyLoading ||
    isConsumptionYearlyLoading

  if (!isPageLoading) {
    energy['day'] = dailyEnergy
    water['day'] = dailyWater
    gas['day'] = dailyGas

    energy['month'] = monthlyEnergy
    water['month'] = monthlyWater
    gas['month'] = monthlyGas

    energy['year'] = yearlyEnergy
    water['year'] = yearlyWater
    gas['year'] = yearlyGas
  }

  return isPageLoading ? (
    /* preloader */
    <Preloader isLoading={isPageLoading} />
  ) : (
    <>
      {!isPageLoading && cSensors.length === 0 && coachData ? (
        <PackageUpgrade coach={coachData} />
      ) : (
        <>
          <Collapse
            in={
              energy.day.length > 2 ||
              energy.month.length > 2 ||
              energy.year.length > 2
            }
          >
            <ConsumptionChart
              data={energy}
              sensors={energySensors}
              folderId={folderId}
              name={'Strom'}
              type={'energy'}
              icon={SVG.MEDIUM_ENERGY}
            />
          </Collapse>

          <Collapse
            in={
              water.day.length > 2 ||
              water.month.length > 2 ||
              water.year.length > 2
            }
          >
            <ConsumptionChart
              data={water}
              sensors={watersSensors}
              folderId={folderId}
              name={'Wasser'}
              type={'water'}
              icon={SVG.MEDIUM_WATER}
            />
          </Collapse>

          <Collapse
            in={
              gas.day.length > 2 || gas.month.length > 2 || gas.year.length > 2
            }
          >
            <ConsumptionChart
              data={gas}
              sensors={gasSensors}
              folderId={folderId}
              name={'Wärme'}
              type={'gas'}
              icon={SVG.MEDIUM_HEATING}
            />
          </Collapse>
        </>
      )}
    </>
  )
}

Performance.propTypes = {
  folderId: PropTypes.number,
}
