import { ArrowDropDownTwoTone, ArrowDropUpTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ASC, DESC } from '../../api'

export const ToggleOrder = ({ onChange, order: initialOrder, width = 45 }) => {
  const [order, setOrder] = useState(initialOrder)
  const toggle = () => setOrder(prev => (prev === ASC ? DESC : ASC))

  const icon = order === ASC ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />
  const tooltip = order === ASC ? 'Sortieren: A-Z' : 'Sortieren: Z-A'

  useEffect(() => onChange(order), [onChange, order])

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={toggle} sx={{ m: 0, width }}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

ToggleOrder.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.oneOf([ASC, DESC]).isRequired,
  width: PropTypes.number,
}
