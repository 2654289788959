import {
  AddTwoTone,
  InfoTwoTone,
  MoreVertTwoTone,
  PeopleTwoTone,
  SearchTwoTone,
} from '@mui/icons-material'
import { Avatar, Collapse, IconButton, List } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ASC, DESC, getStorage, isFilterMatch, setStorage } from '../../api'
import {
  AM,
  AMItem,
  BtnAddUser,
  BtnClearFilter,
  DlgUserPoolInfo,
  DomainFilterSelector,
  HitsInfo,
  HStack,
  KPage,
  KTextField,
  Preloader,
  UserListRow,
  UserRoleSelector,
  UserSortSelector,
  UserStatusSelector,
} from '../../components'
import { userConstants as UC } from '../../constants'
import { useUserPool } from '../../hooks'
import useRenderCount from '../../hooks/tools/useRenderCount'

export const UserAll = ({
  PAGE_CONFIG = 'settings_users_all',
  serviceName = 'Benutzer',
  URL_USER_NEW = '/users/new',
}) => {
  const rc = useRenderCount()
  console.log('Users Overview', rc)

  const history = useHistory()
  const { data, isError, isLoading } = useUserPool()

  // config

  const [actionMenu, setActionMenu] = useState(null)
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  useEffect(() => setStorage(PAGE_CONFIG, config), [PAGE_CONFIG, config])
  const { filter, order, orderby, poolInfo, role, status } = config

  // filter

  const FILTER_ROLE = item => role?.includes(item.attributes.label) ?? item

  const FILTER_SEARCH = item =>
    filter
      ? isFilterMatch(filter, item.attributes.firstname) ||
        isFilterMatch(filter, item.attributes.lastname) ||
        isFilterMatch(filter, item.attributes.email) ||
        isFilterMatch(filter, item.attributes.label) ||
        Number(filter) === Number(item.id)
      : item

  const FILTER_STATUS = item =>
    status?.includes(
      item.attributes.is_active === 1
        ? UC.USER_STATUS_ACTIVE
        : UC.USER_STATUS_INACTIVE
    ) ?? item

  const SORT_FILTER = (a, b) => {
    // catch: orderby = lastname & lastname = null
    if (a.attributes[orderby] === null) a.attributes[orderby] = ''
    if (b.attributes[orderby] === null) b.attributes[orderby] = ''

    if (
      (orderby === 'firstname' || orderby === 'lastname') &&
      a.attributes[orderby].toLowerCase() < b.attributes[orderby].toLowerCase()
    )
      return order === DESC ? 1 : -1

    if (orderby === 'entity_id' && Number(a.id) < Number(b.id))
      return order === DESC ? 1 : -1

    return order === DESC ? -1 : 1
  }

  // amount of user pool hits after applying all filters

  const SEARCHFILTER_COUNT = data
    .filter(FILTER_STATUS)
    .filter(FILTER_ROLE)
    .filter(FILTER_SEARCH).length

  // functions / handler

  const changeOrderByFilter = (_, orderby) => {
    if (orderby !== null) setConfig(prev => ({ ...prev, orderby }))
    else {
      const newOrder = order === DESC ? ASC : DESC
      setConfig(prev => ({ ...prev, order: newOrder }))
    }
  }

  const changeRoleFilter = (_, role) => {
    if (role !== null) setConfig(prev => ({ ...prev, role }))
  }

  const changeSearchFilter = event =>
    setConfig(prev => ({ ...prev, filter: event.target.value }))

  const changeStatusFilter = (_, status) => {
    if (status !== null) setConfig(prev => ({ ...prev, status }))
  }

  const clearSearchFilter = () => setConfig(prev => ({ ...prev, filter: '' }))

  const closeActionMenu = () => setActionMenu(null)

  const closeStatistics = () =>
    setConfig(prev => ({ ...prev, poolInfo: false }))

  const gotoCreateUser = () => history.push(URL_USER_NEW)

  const openActionMenu = event => setActionMenu(event.currentTarget)

  const openStatistics = () => setConfig(prev => ({ ...prev, poolInfo: true }))

  // render component

  return isLoading === true ? null : (
    <>
      <Preloader error={isError} isLoading={isLoading} />

      <AM
        anchorEl={actionMenu}
        caption={serviceName}
        onClose={closeActionMenu}
        open={Boolean(actionMenu)}
        historyUrlTarget={'dashboard'}
      >
        {[
          { caption: 'Neu', icon: <AddTwoTone />, fn: gotoCreateUser },
          { caption: 'Info', icon: <InfoTwoTone />, fn: openStatistics },
        ].map((item, key) => (
          <AMItem
            caption={item.caption}
            icon={item.icon}
            key={key}
            onClick={item.fn}
          />
        ))}
      </AM>

      <DlgUserPoolInfo open={poolInfo} onClose={closeStatistics} />

      <KPage
        action={
          <IconButton onClick={openActionMenu}>
            <MoreVertTwoTone />
          </IconButton>
        }
        avatar={
          <Avatar>
            <PeopleTwoTone />
          </Avatar>
        }
        title={serviceName}
      >
        {/* toolbar */}
        <HStack justifyContent={'space-between'} sx={{ mx: 1 }}>
          <HStack>
            <UserRoleSelector onChange={changeRoleFilter} value={role} />
            <UserStatusSelector onChange={changeStatusFilter} value={status} />
            <DomainFilterSelector
              filter={filter}
              onClear={clearSearchFilter}
              onSelect={filter => setConfig(prev => ({ ...prev, filter }))}
            />

            <BtnAddUser onClick={gotoCreateUser} />
          </HStack>

          <UserSortSelector
            onChange={changeOrderByFilter}
            order={order}
            orderby={orderby}
          />
        </HStack>

        {/* search */}
        <KTextField
          icon={<SearchTwoTone />}
          onChange={changeSearchFilter}
          unit={filter && <BtnClearFilter onClick={clearSearchFilter} />}
          value={filter}
        />
        <HitsInfo
          count={SEARCHFILTER_COUNT}
          filter={filter}
          serviceName={serviceName}
        />

        {/* user pool */}
        <Collapse in={!isLoading}>
          <List
            sx={{
              m: 1,
              maxHeight: 200,
              minHeight: `calc(100vh - 560px)`,
              overflow: 'auto',
            }}
          >
            {data
              .filter(FILTER_STATUS)
              .filter(FILTER_ROLE)
              .filter(FILTER_SEARCH)
              .sort(SORT_FILTER)
              .map((user, key) => (
                <UserListRow key={key} user={user} />
              ))}
          </List>
        </Collapse>
      </KPage>
    </>
  )
}

UserAll.propTypes = {
  PAGE_CONFIG: PropTypes.string,
  serviceName: PropTypes.string,
  URL_USER_NEW: PropTypes.string,
}
