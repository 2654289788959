import { ExpandMoreTwoTone, InfoTwoTone } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { DashboardDisplayFolders, DashboardDisplaySensors, HStack } from '..'
import {
  useFolderPool,
  useOperator,
  useSensorPool,
  useSensorPricePool,
} from '../../hooks'
import useDashboardPagination from '../../hooks/dashboard/useDashboardPagination'

const graphicTypes = [
  { label: 'Kreisdiagramm', value: 1 },
  { label: 'Balkendiagramm', value: 2 },
  { label: 'Ranking', value: 3 },
]

const indicatorMenuData = [
  { label: 'Verbrauch', value: 1 },
  { label: 'Verbrauch/m²', value: 2 },
  { label: 'Kosten', value: 3 },
  { label: 'Kosten/m²', value: 4 },
  { label: 'CO₂-Ausstoß', value: 5 },
  { label: 'CO₂-Ausstoß/m²', value: 6 },
]

const indicatorType = {
  consumption: 1,
  consumption_per_sqm: 2,
  cost: 3,
  cost_per_sqm: 4,
  co2: 5,
  co2_per_sqm: 6,
}

export const DlgPanelAdd = ({
  panelData,
  setPanelData,
  onClose,
  open,
  selectedFolders,
  setSelectedFolders,
  selectedSensors,
  setSelectedSensors,
  PER_PAGE = 10,
  onGotoSummary,
  title = 'Neue Kachel',
}) => {
  const { data: folderPool } = useFolderPool()
  const { data: operatorData } = useOperator()
  const { data: sensorPool } = useSensorPool()
  const { data: sensorPricePool } = useSensorPricePool()

  const [energyChecked, setEnergyChecked] = useState(false)
  const [waterChecked, setWaterChecked] = useState(false)
  const [gasChecked, setGasChecked] = useState(false)

  const [sensorsSearch, setSensorsSearch] = useState('')

  const [showFolderSensors, setShowFolderSensors] = useState(false)

  const [energyData, setEnergyData] = useState([])
  const [waterData, setWaterData] = useState([])
  const [gasData, setGasData] = useState([])

  const [count, setCount] = useState(0)

  const [page, setPage] = useState(1)

  const disabled = !panelData?.presentation_level || !panelData?.name

  const ENERGYDATA = useDashboardPagination(energyData)
  const WATERDATA = useDashboardPagination(waterData)
  const GASDATA = useDashboardPagination(gasData)

  const handlePageChange = (event, page) => {
    setPage(page)
    ENERGYDATA.jump(page)
    WATERDATA.jump(page)
    GASDATA.jump(page)
  }

  const handleToggleAllSensors = (event, type) => {
    const sensors =
      type === 'energy'
        ? energyData
        : type === 'water'
          ? waterData
          : type === 'gas'
            ? gasData
            : null

    const sensorIds = []
    const folderIds = []

    sensors.map(sensor => {
      const { entity_id: sensor_id, folder_id } = sensor.attributes
      const folderId = Number(folder_id)
      const folderData = folderPool.find(f => Number(f.id) === folderId)

      sensorIds.push(sensor_id)
      folderIds.push(folderId)

      if (folderData && !selectedFolders.includes(folderId))
        setSelectedFolders(prev => [...prev, folderId])

      return null
    })

    let updatedSelectedSensorIds = event.target.checked
      ? [...selectedSensors, ...sensorIds]
      : selectedSensors.filter(f => !sensorIds.includes(f))

    if (
      event.target.checked === false &&
      updatedSelectedSensorIds.length === 0
    ) {
      setSelectedFolders([])
      updatedSelectedSensorIds = []
    }

    setSelectedSensors(updatedSelectedSensorIds)
  }

  const onChangeGraphicsType = event => {
    const graphic_type = event.target.value
    setPanelData(prev => ({ ...prev, graphic_type }))
  }

  const onChangeIndicator = event => {
    const indicator = event.target.value
    setPanelData(prev => ({ ...prev, indicator }))
  }

  const onChangePanelName = event => {
    const name = event.target.value
    setPanelData(prev => ({ ...prev, name }))
  }

  const onToggleEnergy = event => {
    handleToggleAllSensors(event, 'energy')
    setEnergyChecked(prev => !prev)
  }

  const onToggleGas = event => {
    handleToggleAllSensors(event, 'gas')
    setGasChecked(prev => !prev)
  }

  const onToggleWater = event => {
    handleToggleAllSensors(event, 'water')
    setWaterChecked(prev => !prev)
  }

  useEffect(() => {
    if (sensorPool.length === 0 || folderPool.length === 0) return

    const FILTER_INDICATOR = sensor =>
      panelData?.indicator === indicatorType.consumption
        ? true
        : panelData?.indicator === indicatorType.consumption_per_sqm
          ? sensor.attributes.area_in_sqm !== null
          : panelData?.indicator === indicatorType.cost
            ? sensorPricePool.find(
                f => f.sensor_id === sensor.attributes.entity_id
              )
            : panelData?.indicator === indicatorType.cost_per_sqm
              ? sensorPricePool.find(
                  f => f.sensor_id === sensor.attributes.entity_id
                ) && sensor.attributes.area_in_sqm !== null
              : panelData?.indicator === indicatorType.co2
                ? sensor.attributes.co2_factor !== null
                : sensor.attributes.co2_factor !== null &&
                  sensor.attributes.area_in_sqm !== null

    const FILTER_FOLDERS = f =>
      panelData?.presentation_level === 1
        ? selectedFolders.includes(Number(f.attributes.folder_id))
        : true

    const FILTER_FOLDER_SENSORS = sensor =>
      showFolderSensors ? sensor.attributes.folder_id !== null : true

    const FILTER_SEARCH = sensor => {
      const { name: sensorName, folder_id } = sensor.attributes
      const folder = folderPool.find(f => Number(f.id) === Number(folder_id))
      const label = `${sensorName} | Gebäude ${folder ? folder.attributes.name : ''}`

      return label.toLowerCase().includes(sensorsSearch.toLowerCase())
    }

    const FILTER_BY_TYPE = type =>
      sensorPool
        .filter(
          f =>
            f.attributes.name !== null &&
            (f.attributes.type_in_folder === type ||
              f.attributes.type_in_folder === `${type}_detail`)
        )
        .filter(FILTER_INDICATOR)
        .filter(FILTER_SEARCH)
        .filter(FILTER_FOLDERS)
        .filter(FILTER_FOLDER_SENSORS)

    const count = Math.ceil(FILTER_BY_TYPE('energy').length / PER_PAGE)
    setCount(count)

    setEnergyData(FILTER_BY_TYPE('energy'))
    setWaterData(FILTER_BY_TYPE('water'))
    setGasData(FILTER_BY_TYPE('gas'))
  }, [
    selectedFolders,
    folderPool,
    panelData,
    PER_PAGE,
    sensorPool,
    sensorPricePool,
    sensorsSearch,
    showFolderSensors,
  ])

  useEffect(() => {
    if (open === false) {
      setEnergyChecked(false)
      setWaterChecked(false)
      setGasChecked(false)
    }
  }, [open])

  return (
    <Dialog fullWidth maxWidth={'xl'} onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ my: 1 }}>
        <HStack sx={{ width: 1, my: 1 }}>
          <TextField
            fullWidth
            label={'Kachel Titel'}
            onChange={onChangePanelName}
            value={panelData?.name}
          />

          <TextField
            fullWidth
            label={'Indikator'}
            onChange={onChangeIndicator}
            select
            value={panelData?.indicator || ''}
          >
            {indicatorMenuData.map((item, key) => (
              <MenuItem key={key} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            label={'Grafiktyp'}
            onChange={onChangeGraphicsType}
            select
            value={panelData?.graphic_type || ''}
          >
            {graphicTypes.map((item, key) => (
              <MenuItem key={key} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </TextField>
        </HStack>

        {/* presentation Level */}
        <FormControl>
          <FormLabel>
            Darstellungsebene{' '}
            <Tooltip
              title={
                'Eine Kachel kann auf Gebäude- oder Messpunktebene erstellt werden. In der Gebäudeebene werden alle ausgewählten Messpunkte unter dem Gebäudenamen zusammengefasst. In der Messpunktebene wird jeder Messpunkt einzeln aufgelistet.'
              }
            >
              <InfoTwoTone />
            </Tooltip>
          </FormLabel>

          <RadioGroup
            row
            onChange={event => {
              const presentation_level = Number(event.target.value)
              setPanelData(prev => ({ ...prev, presentation_level }))

              setSelectedFolders([])
              setSelectedSensors([])
              setEnergyChecked(false)
              setWaterChecked(false)
              setGasChecked(false)
            }}
          >
            {[
              { label: 'Gebäude', value: '1' },
              { label: 'Messpunkte', value: '2' },
            ].map((item, key) => (
              <FormControlLabel
                control={<Radio />}
                key={key}
                label={item.label}
                labelPlacement={'start'}
                value={item.value}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <DashboardDisplayFolders
          disabled={panelData?.presentation_level !== 1}
          onChange={setSelectedFolders}
          selected={selectedFolders}
        />

        <Accordion disabled={panelData?.presentation_level === null}>
          <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
            <HStack sx={{ width: 1 }}>
              <Typography>Messpunkte</Typography>

              <Chip label={`${selectedSensors.length}/${sensorPool.length}`} />
            </HStack>
          </AccordionSummary>

          <Divider />

          <AccordionDetails>
            {operatorData?.isAdmin && (
              <HStack sx={{ width: 1 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showFolderSensors}
                        onClick={() => setShowFolderSensors(prev => !prev)}
                      />
                    }
                    label={'Nur zugeordnete Sensoren anzeigen (Admin Fn.)'}
                  />
                </FormGroup>
              </HStack>
            )}

            <HStack
              alignItems={'top'}
              justifyContent={'space-between'}
              sx={{ display: 'flex' }}
            >
              {/* Strom */}
              <FormGroup sx={{ width: 'calc(100% / 3)' }}>
                <FormControlLabel
                  label={'Strom'}
                  control={
                    <Checkbox
                      checked={energyChecked}
                      onChange={onToggleEnergy}
                    />
                  }
                />

                <DashboardDisplaySensors
                  selectedFolders={selectedFolders}
                  selectedSensors={selectedSensors}
                  data={ENERGYDATA.currentData()}
                  presentationLevel={panelData?.presentation_level}
                  setSelectedFolders={setSelectedFolders}
                  setSelectedSensors={setSelectedSensors}
                />
              </FormGroup>

              <Divider orientation={'vertical'} flexItem />

              {/* Water */}
              <FormGroup sx={{ width: 'calc(100% / 3)' }}>
                <FormControlLabel
                  label={'Wasser'}
                  control={
                    <Checkbox checked={waterChecked} onChange={onToggleWater} />
                  }
                />

                <DashboardDisplaySensors
                  data={WATERDATA.currentData()}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                  presentationLevel={panelData?.presentation_level}
                />
              </FormGroup>

              <Divider orientation={'vertical'} flexItem />

              {/* Gas */}
              <FormGroup sx={{ width: 'calc(100% / 3)' }}>
                <FormControlLabel
                  label={'Wärme'}
                  control={
                    <Checkbox checked={gasChecked} onChange={onToggleGas} />
                  }
                />

                <DashboardDisplaySensors
                  data={GASDATA.currentData()}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                  presentationLevel={panelData?.presentation_level}
                />
              </FormGroup>
            </HStack>

            <Stack>
              <Input
                onChange={event => setSensorsSearch(event.target.value)}
                placeholder={'Suchen...'}
                sx={{ m: 0, minHeight: 23, pl: 1 }}
                value={sensorsSearch}
                variant={'standard'}
              />

              <Pagination
                count={count}
                onChange={handlePageChange}
                page={page}
                shape={'rounded'}
                size={'large'}
                variant={'outlined'}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions>
        <Button disabled={disabled} onClick={onGotoSummary}>
          Zusammenfassung
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgPanelAdd.propTypes = {
  panelData: PropTypes.object,
  setPanelData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedFolders: PropTypes.array.isRequired,
  setSelectedFolders: PropTypes.func.isRequired,
  selectedSensors: PropTypes.array.isRequired,
  setSelectedSensors: PropTypes.func.isRequired,
  PER_PAGE: PropTypes.number,
  onGotoSummary: PropTypes.func,
  title: PropTypes.string,
}
