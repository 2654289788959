import { CloseTwoTone } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import {
  FILTER_ACTIVE_SENSOR,
  FILTER_INACTIVE_SENSOR,
  FILTER_MAIN_SENSOR,
  FILTER_SUB_SENSOR,
} from '../../api'
import { HStack, ToggleDateType } from '../../components'
import { useSensorPool } from '../../hooks'

const StatRecord = ({ label, value, ...rest }) => (
  <HStack spacing={2} sx={{ width: 1 }} {...rest}>
    <Stack sx={{ flex: 1, textAlign: 'right' }}>
      <Typography>{label}</Typography>
    </Stack>

    <Stack sx={{ flex: 1, textAlign: 'left' }}>
      <Typography>{value}</Typography>
    </Stack>
  </HStack>
)

StatRecord.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
}

const SensorPoolStat = () => {
  const { data: sensorPool, fetchDate } = useSensorPool()

  return (
    <Stack sx={{ mt: 3 }}>
      <StatRecord label={'Poolgröße'} value={sensorPool.length} />

      {[
        {
          label: 'aktiviert',
          value: sensorPool.filter(FILTER_ACTIVE_SENSOR).length,
        },
        {
          label: 'deaktiviert',
          value: sensorPool.filter(FILTER_INACTIVE_SENSOR).length,
        },
        {
          label: 'Provider',
          value: [...new Set(sensorPool.map(i => i.attributes.provider))]
            .length,
        },
        {
          label: 'Sensor Typen',
          value: [...new Set(sensorPool.map(i => i.attributes.sensor_type))]
            .length,
        },
        {
          label: 'Sensor Targets',
          value: [...new Set(sensorPool.map(i => i.attributes.sensor_target))]
            .length,
        },
        {
          label: 'Sub Sensor Targets',
          value: [
            ...new Set(sensorPool.map(i => i.attributes.sub_sensor_target)),
          ].length,
        },
        {
          label: 'Hauptzähler',
          value: sensorPool.filter(FILTER_MAIN_SENSOR).length,
        },
        {
          label: 'Unterzähler',
          value: sensorPool.filter(FILTER_SUB_SENSOR).length,
        },
      ].map((item, key) => (
        <StatRecord key={key} label={item.label} value={item.value} />
      ))}

      <StatRecord
        label={'gefetcht'}
        sx={{ mb: 1, mt: 2, pr: 1, textAlign: 'right' }}
        value={<ToggleDateType date={fetchDate} />}
      />
    </Stack>
  )
}

export const DlgSensorPoolInfo = ({
  onClose,
  open,
  title = 'Sensor Pool Info',
}) => (
  <Dialog fullWidth open={open}>
    <DialogTitle
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontSize: 20 }}>{title}</Typography>

      <IconButton
        onClick={onClose}
        sx={{
          '&:hover': { transform: 'rotate(90deg)' },
          transform: 'rotate(0deg)',
          transition: 'transform 0.2s',
        }}
        variant={'close'}
      >
        <CloseTwoTone />
      </IconButton>
    </DialogTitle>

    <DialogContent>
      <SensorPoolStat />
    </DialogContent>
  </Dialog>
)

DlgSensorPoolInfo.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}
