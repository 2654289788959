import { FormControl, InputAdornment, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const KSelect = ({ children, icon, ...rest }) => (
  <FormControl fullWidth>
    <TextField
      InputProps={{
        startAdornment: icon && (
          <InputAdornment position={'start'}>{icon}</InputAdornment>
        ),
      }}
      select
      {...rest}
    >
      {children}
    </TextField>
  </FormControl>
)

KSelect.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.object,
}
