import { Box, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { HStack } from './HStack'

export const ColorPicker = ({ color: initialColor, onChange }) => {
  const theme = useTheme()

  const colorMap = [
    '#000',
    '#00A',
    '#0A0',
    '#0AA',
    '#A00',
    '#A0A',
    '#A50',
    '#AAA',
    '#555',
    '#55F',
    '#5F5',
    '#5FF',
    '#F55',
    '#F5F',
    '#FF5',
    '#FFF',
  ]

  const handleChange = color => onChange(color)

  return (
    <HStack spacing={0.1}>
      {colorMap.map((color, key) => (
        <Box
          key={key}
          onClick={() => handleChange(color)}
          sx={{
            bgcolor: color,
            cursor: 'pointer',
            width: 20,
            height: 20,
            border: `5px solid ${
              color === initialColor ? theme.palette.primary.main : color
            }`,
          }}
          value={color}
        />
      ))}
    </HStack>
  )
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
