import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'

export const useFolderShortName = folderId => {
  const [data, setData] = useState(null)

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const record = folderPool.find(f => Number(f.id) === folderId)
    if (!record) return

    const name = record?.attributes?.name_short
    setData(name)
  }, [folderPool, folderId])

  return data
}

useFolderShortName.propTypes = {
  folderId: PropTypes.number,
}
