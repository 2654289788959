import {
  CloseTwoTone,
  DownloadTwoTone,
  MemoryTwoTone,
} from '@mui/icons-material'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { CSVLink } from 'react-csv'
import { sensorType, sensorUnit } from '../../api'
import { exportToExcel } from '../../pages/Folder/PerformanceDetail.export'
import {
  useFolder,
  useFolderImage,
  useFolderName,
  useRoomsMode,
  useSensorPricePool,
  useViewOptions,
} from '../../hooks'

const granularityTitle = granularity =>
  granularity === 'd'
    ? 'Stündlich'
    : granularity === 'm'
      ? 'Täglich'
      : granularity === 'y'
        ? 'Monatlich'
        : granularity

export const DlgConsumptionExport = ({
  exportAsJPEG,
  exportAsPNG,
  fileName,
  folderId,
  granularity,
  kpiData,
  medium,
  onClose,
  open,
  periodData,
  tableDataExport,
  title = 'Exportieren',
}) => {
  const { data: folderData } = useFolder(folderId)
  const folderName = useFolderName(folderId)
  const folderImage = useFolderImage(folderId)
  const roomsMode = useRoomsMode(folderId)

  const { data: sensorPricePool } = useSensorPricePool()

  const theme = useTheme()
  const { main: bgcolor } = theme.palette.secondary
  const { white: color } = theme.palette

  const viewCols = useViewOptions(folderId)

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={open}
      /* slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 1)' } } }} */
    >
      <DialogTitle
        alignItems={'center'}
        justifyContent={'space-between'}
        onClose={onClose}
        sx={{ display: 'flex' }}
      >
        <Typography sx={{ mr: 1, fontSize: 20 }}>{title}</Typography>

        <IconButton onClick={onClose} sx={{ bgcolor, color }} variant={'close'}>
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <Avatar src={folderImage} />
            </ListItemIcon>

            <ListItemText primary={'Gebäude'} secondary={folderName} />
            <ListItemText primary={'Medium'} secondary={sensorType(medium)} />
            <ListItemText primary={'Einheit'} secondary={kpiData.label} />
            <ListItemText
              primary={'Granularität'}
              secondary={granularityTitle(granularity)}
            />
          </ListItem>

          <Divider />

          <ListItem>
            <ListItemText primary={'Zeitraum'} secondary={periodData.title} />
          </ListItem>

          <Divider />

          <ListItem>
            <Stack sx={{ width: 1 }}>
              <Typography variant={'subtitle1'}>
                Messpunkte (
                {
                  Object.keys(tableDataExport).filter(
                    f => f !== 'Gesamtverbrauch'
                  ).length
                }
                )
              </Typography>

              <List sx={{ width: 1, maxHeight: 200, overflow: 'hidden' }}>
                {Object.keys(tableDataExport)
                  .filter(f => f !== 'Gesamtverbrauch')
                  .map((item, key) => (
                    <ListItem key={key}>
                      <ListItemIcon>
                        <MemoryTwoTone />
                      </ListItemIcon>

                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
              </List>
            </Stack>
          </ListItem>
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={exportAsPNG}>
          <DownloadTwoTone />
          PNG
        </Button>
        <Button onClick={exportAsJPEG}>
          <DownloadTwoTone />
          JPEG
        </Button>
        <Divider />
        <CSVLink
          data={
            tableDataExport['Gesamtverbrauch']
              ? tableDataExport['Gesamtverbrauch']
              : []
          }
          filename={`${fileName}.csv`}
          headers={[
            'Datum',
            `Verbrauch (${sensorUnit(medium)})`,
            'Trend',
            `Verbrauch / m² (${sensorUnit(medium)})`,
            `Verbrauch / ${roomsMode} (${sensorUnit(medium)} / MA)`,
            'Kosten (€)',
            'Kosten / Fläche (€)',
            'Kosten / Fläche (€)',
            'Kosten / Fläche (€)',
            periodData.title,
          ]}
          target={'_blank'}
        >
          <Button>
            <DownloadTwoTone />
            CSV
          </Button>
        </CSVLink>{' '}
        <Button
          onClick={() =>
            exportToExcel(
              tableDataExport,
              `${fileName}.xlsx`,
              sensorPricePool,
              folderData,
              roomsMode,
              medium,
              granularity,
              viewCols.columns,
              { header: viewCols.header, values: viewCols.values }
            )
          }
        >
          <DownloadTwoTone />
          Excel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgConsumptionExport.propTypes = {
  exportAsJPEG: PropTypes.func.isRequired,
  exportAsPNG: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  folderId: PropTypes.number.isRequired,
  granularity: PropTypes.string.isRequired,
  kpiData: PropTypes.object.isRequired,
  medium: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  periodData: PropTypes.object.isRequired,
  tableDataExport: PropTypes.array.isRequired,
  title: PropTypes.string,
}
