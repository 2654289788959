import {
  HighlightOffTwoTone,
  MemoryTwoTone,
  MoreVertTwoTone,
  SearchTwoTone,
  SettingsRemoteTwoTone,
} from '@mui/icons-material'
import { Avatar, Divider, IconButton, List, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  getStorage,
  isFilterMatch,
  setStorage,
  SORT_ATTR_NAME,
} from '../../api'
import {
  AM,
  AMItem,
  KPage,
  KTextField,
  Preloader,
  VirtualSensorItem,
} from '../../components'
import { useVirtualSensorPool } from '../../hooks'

export const AllVirtual = ({
  PAGE_CONFIG = 'settings_virtual_sensors_all',
}) => {
  const history = useHistory()
  const { data, isError, isLoading } = useVirtualSensorPool()

  const [badge, setBadge] = useState('laden')
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const [actionMenu, setActionMenu] = useState(null)

  const inputFilter = vSensor =>
    config.filter
      ? isFilterMatch(config.filter, vSensor.attributes.name) ||
        isFilterMatch(
          config.filter,
          vSensor.attributes.combined_virtual_sensor_id
        ) ||
        isFilterMatch(
          config.filter,
          vSensor.attributes.combined_virtual_sensor_device_id
        ) ||
        isFilterMatch(config.filter, vSensor.attributes.original_sensors_id) ||
        isFilterMatch(config.filter, vSensor.attributes.virtual_sensor_type) ||
        Number(config.filter) === Number(vSensor.id)
      : vSensor

  useEffect(() => setStorage(PAGE_CONFIG, config), [PAGE_CONFIG, config])

  useEffect(
    () => setBadge(isLoading ? 'laden' : data.length),
    [data, isLoading]
  )

  return (
    <>
      {/* preloader */}
      <Preloader error={isError} isLoading={isLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Sensoren'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'sensors'}
      >
        <AMItem
          caption={'Neu'}
          icon={<MemoryTwoTone />}
          onClick={() => history.push('/virtualsensors/new')}
        />

        <Divider />
      </AM>
      {/* action menu end */}

      {!isLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <SettingsRemoteTwoTone />
            </Avatar>
          }
          isLoading={isLoading}
          title={`${badge} virtuelle Sensoren`}
          subheader={
            config?.filter?.length > 0 &&
            `${data.filter(inputFilter).length} Treffer`
          }
        >
          {/* filter */}
          <KTextField
            icon={<SearchTwoTone />}
            onChange={event => {
              const filter = event.target.value

              setConfig(prev => ({ ...prev, filter }))
            }}
            sx={{ mb: 5 }}
            unit={
              config?.filter?.length > 0 && (
                <Tooltip title={'Filter löschen'}>
                  <IconButton
                    onClick={() => setConfig(prev => ({ ...prev, filter: '' }))}
                  >
                    <HighlightOffTwoTone />
                  </IconButton>
                </Tooltip>
              )
            }
            value={config.filter}
          />

          <List
            sx={{
              maxHeight: 600,
              mt: 3,
              overflow: 'auto',
              overflowX: 'hidden',
              width: 1,
            }}
          >
            {data
              .filter(inputFilter)
              .sort(SORT_ATTR_NAME)
              .map((sensor, key) => (
                <VirtualSensorItem
                  key={key}
                  sensor={sensor}
                  sensorId={Number(sensor.id)}
                />
              ))}
          </List>
        </KPage>
      )}
    </>
  )
}

AllVirtual.propTypes = {
  PAGE_CONFIG: PropTypes.string,
}
