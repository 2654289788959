import { Alert, AlertTitle, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

export const AboutAbos = ({
  data = [
    {
      text: '&bull; durch das Abonnement erhalten Sie automatisiert monatlich einen  Bericht per E-Mail',
    },
    {
      text: '&bull; abonnierte Berichte werden automatisiert am 1. des Monats für den jeweiligen Vormonat erstellt und an die Empfänger-Liste per E-Mail verschickt',
    },
    { text: '&bull; die Zustellung per E-Mail erfolgt gegen 08:00 Uhr' },
    {
      text: '&bull; Sensoren können für die Berichterstellung beliebig an- oder abgewählt werden',
    },
  ],
  severity = 'info',
  title = 'Was ist ein Abonnement?',
  ...rest
}) => (
  <Alert severity={severity} {...rest}>
    <AlertTitle>{title}</AlertTitle>

    {data.map((item, key) => (
      <Typography dangerouslySetInnerHTML={{ __html: item.text }} key={key} />
    ))}

    <Typography>
      &bull; Abonnements können unter dem Reiter <strong>Berichte Abos</strong>{' '}
      in den <Link to={'/user-page/3'}>Accounteinstellungen</Link> eingesehen
      werden
    </Typography>
  </Alert>
)

AboutAbos.propTypes = {
  data: PropTypes.array,
  severity: PropTypes.string,
  title: PropTypes.string,
}
