import { NotificationsTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useAppConfig } from '../../hooks'

export const BtnUpdateAvailable = ({ title = 'Aktualisierung verfügbar' }) => {
  const { setAppConfig } = useAppConfig()
  const theme = useTheme()
  const color = theme.palette.error.main

  const onClick = () =>
    setAppConfig(prev => ({ ...prev, showSettings: true, setupTab: 2 }))

  const wobbleAnimation = {
    '@keyframes pulse': {
      '0%': { transform: 'scale(1)' },
      '50%': { transform: 'scale(1.2)' },
      '100%': { transform: 'scale(1)' },
    },
    animation: 'pulse 1.5s infinite',
    color,
  }

  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>
        <NotificationsTwoTone sx={wobbleAnimation} />
      </IconButton>
    </Tooltip>
  )
}

BtnUpdateAvailable.propTypes = {
  title: PropTypes.string,
}
