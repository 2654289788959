import { Alert, Collapse, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { installationStatus, SORT } from '../../api'
import { InstallationMenu, Preloader } from '../../components'
import {
  useFolderInstallations,
  useFolderSensors,
  useSensorPool,
  useSensorPoolIds,
} from '../../hooks'

export const Installation = ({ folderId }) => {
  const [installations, setInstallations] = useState([])
  const { isLoading: isSensorPoolLoading, isError } = useSensorPool()

  const operatorSensorIds = useSensorPoolIds()
  const folderSensors = useFolderSensors(folderId)
  const folderInstallations = useFolderInstallations(folderId)

  useEffect(() => {
    if (!folderInstallations || !folderSensors) return

    if (!folderSensors?.[0]) return

    folderInstallations.forEach(installation => {
      const installationSensors = folderSensors
        .filter(f => operatorSensorIds.includes(Number(f.entity_id)))
        .filter(f => f.installation_id === installation.entity_id)

      installation['sensors'] = installationSensors
    })

    if (folderInstallations?.[0]) setInstallations(folderInstallations)
  }, [folderSensors, folderInstallations, folderId, operatorSensorIds])

  const isPageLoading = isSensorPoolLoading

  return isPageLoading ? (
    <Preloader error={isError} isLoading={isPageLoading} />
  ) : (
    <Collapse in={!isPageLoading}>
      {installations.length === 0 && (
        <Alert severity={'info'} sx={{ mb: 2, border: 'solid 1px #9e9e9e' }}>
          Die Funktion ist nicht verfügbar, da keine Anlage im Gebäude angelegt
          wurde.
        </Alert>
      )}

      <Grid container spacing={5}>
        {!isPageLoading &&
          installations.sort(SORT).map((installation, key) => {
            const { sensors, name, entity_id } = installation

            if (sensors.length > 0) {
              let time = sensors.find(
                f => f.installation_id === installation.entity_id
              )

              time = time.time || ''
              const status = installationStatus(sensors)
              const hours =
                new Date(Date.now() - new Date(time)).getTime() / 1000 / 60 / 60

              const days = Math.trunc(hours / 24)

              let severity = 'success'
              if (days >= 3) severity = 'error'
              if (days >= 1 && days < 3) severity = 'warning'
              if (days <= 0) severity = 'success'

              return (
                <Grid item xs={12} md={12} lg={12} key={key}>
                  <InstallationMenu
                    folderId={folderId}
                    installationId={entity_id}
                    name={name}
                    status={status}
                    severity={severity}
                    sensors={sensors}
                  />
                </Grid>
              )
            }

            return true
          })}
      </Grid>
    </Collapse>
  )
}

Installation.propTypes = {
  folderId: PropTypes.number,
}
