import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material'
import { DeleteTwoTone, PublishTwoTone } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useOperator } from '../../hooks'
import avatar from '../../assets/img/default-avatar.png'

export const DlgProfilePicture = ({ onClose, open }) => {
  const { fileUpload } = useSelector(state => state.user)
  const { data: operatorData } = useOperator()
  const [userData, setUserData] = useState({})
  const [imagePreviewUrl, setImagePreviewUrl] = useState(avatar)

  const CLEAR_DISABLED = !userData.image_url && userData.image_url === ''

  let ref = useRef(null)

  const onClear = () => {
    setImagePreviewUrl(avatar)
    setUserData(prev => ({ ...prev, image_url: avatar }))
    ref.value = null
  }
  const onClick = () => ref.click()
  const onUpload = event => {
    const image_url = event.target.files[0]

    event.preventDefault()

    const reader = new FileReader()
    reader.readAsDataURL(image_url)
    reader.onloadend = () => {
      setUserData(prev => ({ ...prev, image_url }))
      setImagePreviewUrl(reader.result)
    }
  }

  useEffect(() => {
    if (!operatorData) return

    const { image_url } = operatorData
    const { fileUploading, location } = fileUpload

    setUserData({ image_url: fileUploading ? image_url : location })

    setImagePreviewUrl(image_url || avatar)
  }, [operatorData, fileUpload])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Profilbild von {operatorData?.first_name} {operatorData?.last_name}
      </DialogTitle>

      <DialogContent variant={'profilePicture'}>
        <Box alt={''} component={'img'} src={imagePreviewUrl} />
      </DialogContent>

      <DialogActions>
        <Tooltip title={'Hochladen'}>
          <Button onClick={onClick}>
            <PublishTwoTone sx={{ mr: 1 }} /> Upload
          </Button>
        </Tooltip>

        <input
          accept={'image/*'}
          hidden
          onChange={onUpload}
          ref={r => (ref = r)}
          type={'file'}
        />

        <Tooltip title={'Löschen'}>
          <Button disabled={CLEAR_DISABLED} onClick={onClear}>
            <DeleteTwoTone sx={{ mr: 1 }} /> Löschen
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}

DlgProfilePicture.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
