import { CheckTwoTone, CloseTwoTone, MemoryTwoTone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Chip,
  Collapse,
  LinearProgress,
  ListItem,
  Stack,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSensorPool } from '../../hooks'

export const Preflight = ({ reportDate, sensors, factors, onChange }) => {
  const { data: sensorPool } = useSensorPool()
  const theme = useTheme()
  const [showPreflight, setShowPreflight] = useState(false)
  const [problematic, setProblematic] = useState([])

  // if reportDate changes, reset problematic sensorIds
  useEffect(() => setProblematic([]), [reportDate, sensors, factors])

  // factors are not allowed to overlap dates
  // expected behavior: 1 factor is remaining after applying filters,
  // because only 1 factor must be left
  // if factor length is other than 1, add current sensorId to problematic sensorIds

  useEffect(() => {
    const calculateProblematic = (sensors, factors, reportDate) => {
      sensors.forEach(sensorId => {
        const SENSORID = factor => factor[0]?.attributes?.sensor_id === sensorId
        const FACTOR_WITHIN_DATE = factor => {
          const period_start = new Date(factor.attributes.period_start)
          const period_end = new Date(factor.attributes.period_end)
          const reportLastDay = new Date(
            reportDate.getFullYear(),
            reportDate.getMonth(),
            new Date(
              reportDate.getFullYear(),
              reportDate.getMonth(),
              0
            ).getDate()
          )
          const isWithin =
            reportDate >= period_start && reportLastDay <= period_end

          return isWithin
        }
        const record = factors.filter(SENSORID)[0]?.filter(FACTOR_WITHIN_DATE)

        setProblematic(
          record?.length !== 1
            ? prev => (prev.includes(sensorId) ? prev : [...prev, sensorId])
            : prev => prev
        )
      })
    }

    if (sensors.length === 0 || factors.length === 0 || !reportDate) return

    calculateProblematic(sensors, factors, reportDate)
  }, [sensors, factors, reportDate])

  useEffect(() => {
    onChange(problematic)
  }, [onChange, problematic])

  if (sensors.length === 0 || factors.length === 0 || !reportDate)
    return <LinearProgress />

  const success = theme.palette.success.main
  const error = theme.palette.error.main

  const onTogglePreflight = () => setShowPreflight(prev => !prev)

  return (
    <Alert
      severity={problematic.length === 0 ? 'success' : 'error'}
      sx={{ mx: 5 }}
    >
      <AlertTitle onClick={onTogglePreflight} sx={{ cursor: 'pointer' }}>
        Preflight:{' '}
        {problematic.length === 0
          ? 'Für alle ausgewählten Verbrauchssensoren ist die Erstellung des Reports möglich.'
          : 'Für folgende, ausgewählte Verbrauchssensoren ist die Generierung des Reports nicht möglich.'}
        {showPreflight ? '▲' : '▼'}
      </AlertTitle>

      <Collapse in={showPreflight}>
        {sensors.map((sensorId, key) => {
          const FILTER_SENSORID = factor =>
            Number(factor[0]?.attributes.sensor_id) === Number(sensorId)
          const record = sensorPool.find(f => Number(f.id) === sensorId)
          const sensorName = record?.attributes?.name

          return (
            <ListItem key={key}>
              <Chip
                avatar={
                  <Avatar>
                    <MemoryTwoTone />
                  </Avatar>
                }
                label={sensorName}
              />

              <Stack spacing={1} sx={{ ml: 1 }}>
                {factors.filter(FILTER_SENSORID)[0]?.map((factor, key) => {
                  const period_start = new Date(factor.attributes.period_start)

                  const period_end = new Date(factor.attributes.period_end)
                  const label = `${period_start.toLocaleDateString()} - ${period_end.toLocaleDateString()}`
                  const year = reportDate.getFullYear()
                  const month = reportDate.getMonth()
                  const lastDay = new Date(
                    year,
                    month,
                    new Date(year, month, 0).getDate()
                  )
                  const isWithinRange =
                    reportDate >= period_start && lastDay <= period_end
                  const bgcolor = isWithinRange ? success : error

                  return (
                    <Chip
                      avatar={
                        <Avatar>
                          {isWithinRange ? <CheckTwoTone /> : <CloseTwoTone />}
                        </Avatar>
                      }
                      label={label}
                      key={key}
                      sx={{ bgcolor, mr: 1 }}
                    />
                  )
                })}
              </Stack>
            </ListItem>
          )
        })}
      </Collapse>
    </Alert>
  )
}

Preflight.propTypes = {
  reportDate: PropTypes.instanceOf(Date),
  sensors: PropTypes.array,
  factors: PropTypes.array,
  onChange: PropTypes.func,
}
