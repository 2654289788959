import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { authHeader } from '../../api'
import { API } from 'aws-amplify'

export const useConsumptionByDashboardId = dashboardId => {
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const [isFactorError, setFactorError] = useState(null)
  const [factorErrorSensor, setFactorErrorSensor] = useState('')

  useEffect(() => {
    if (Number(dashboardId) > 0) {
      setLoading(true)

      const fetchPanels = async dashboardId => {
        let result = 'unset'

        const API_NAME = 'karmaApi'
        const endpoint = `/consumption/dashboard/${dashboardId}`
        const options = { method: 'GET', headers: authHeader() }

        result = await API.get(API_NAME, endpoint, options).catch(error => {
          const { data, status } = error.response
          const { message } = data

          if (status === 400 && message.includes('Bad Request')) {
            const sensor = message.split('for')[1].replace(/.$/, '')

            setFactorError(true)
            setFactorErrorSensor(sensor)

            return { factorError: true, sensor }
          }

          setError(error)
          setLoading(false)
        })

        setData(result?.data ? result.data : [])
        setLoading(false)
      }

      fetchPanels(dashboardId)
    }
  }, [dispatch, dashboardId])

  return { isLoading, isError, isFactorError, factorErrorSensor, data }
}
