import { AccountBalanceTwoTone, MoreVertTwoTone } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { SensorIcon, SVGStatus } from '../../api'
import { AM, AMItem, HStack, KPage } from '../../components'
import { useFolderCity, useFolderStatus } from '../../hooks'
import { Weather } from './components'
import { Correlation, Feeling, Installation, Performance, Security } from './'

export const HomeFolder = () => {
  const history = useHistory()

  const [actionMenu, setActionMenu] = useState(null)
  const topRef = useRef(null)

  const { id, category } = useParams()

  const fId = Number(id)
  if (isNaN(fId) || fId === 0) console.error('invalid folder id', id)

  const fStatus = useFolderStatus(fId)

  const city = useFolderCity(fId)

  // tab data
  const TAB_CONSUMPTION = 0
  const TAB_INSTALLATION = 1
  const TAB_CORRELATION = 2
  const TAB_SECURITY = 3
  const TAB_FEELING = 4

  const [tab, setTab] = useState(TAB_FEELING)
  const [loadCorrelation, setLoadCorrelation] = useState(false)

  const tabData = [
    {
      category: 'performance',
      color: fStatus.performance,
      title: 'Leistung',
    },
    {
      category: 'installation',
      color: fStatus.installation,
      title: 'Anlagen',
    },
    {
      category: 'correlation',
      color: fStatus.correlation,
      title: 'Korrelation',
    },
    {
      category: 'security',
      color: fStatus.security,
      title: 'Sicherheit',
    },
    {
      category: 'feeling',
      color: fStatus.feeling,
      title: 'Wohlbefinden',
    },
  ]

  useEffect(() => {
    switch (category) {
      case 'feeling':
        setTab(TAB_FEELING)
        break
      case 'security':
        setTab(TAB_SECURITY)
        break
      case 'performance':
        setTab(TAB_CONSUMPTION)
        break
      case 'correlation':
        setTab(TAB_CORRELATION)
        break
      case 'installation':
        setTab(TAB_INSTALLATION)
        break
      default:
        console.error('unhandled category', category)
        break
    }

    if (topRef.current)
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [category])

  useEffect(() => closeAM(), [tab])

  useEffect(() => {
    if (tab === TAB_CORRELATION) {
      setLoadCorrelation(true)
    } else {
      setLoadCorrelation(false)
    }
  }, [tab])

  const onChangeCategory = (event, category) => {
    setTab(category)

    const targetUrl = `/folder/${fId}/home/${
      ['performance', 'installation', 'correlation', 'security', 'feeling'][
        category
      ]
    }`

    history.push(targetUrl)
  }

  const closeAM = () => setActionMenu(null)
  const openAM = event => setActionMenu(event.currentTarget)

  const onGotoDetails = () => history.push(`/folder/${fId}`)
  const onGotoPerformance = () => setTab(TAB_CONSUMPTION)
  const onGotoInstallation = () => setTab(TAB_INSTALLATION)
  const onGotoCorrelation = () => setTab(TAB_CORRELATION)
  const onGotoSecurity = () => setTab(TAB_SECURITY)
  const onGotoFeeling = () => setTab(TAB_FEELING)

  return (
    <>
      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Gebäude'}
        open={Boolean(actionMenu)}
        onClose={closeAM}
        historyUrlTarget={'dashboard'}
        historyUrlId={fId}
      >
        {[
          {
            caption: 'Details zum Gebäude',
            icon: <AccountBalanceTwoTone />,
            fn: onGotoDetails,
          },
          null,
          {
            caption: 'Leistung',
            icon: (
              <SVGStatus type={'performance'} status={fStatus.performance} />
            ),
            fn: onGotoPerformance,
          },
          {
            caption: 'Anlagen',
            icon: (
              <SVGStatus type={'installation'} status={fStatus.installation} />
            ),
            fn: onGotoInstallation,
          },
          {
            caption: 'Korrelation',
            icon: (
              <SVGStatus type={'correlation'} status={fStatus.correlation} />
            ),
            fn: onGotoCorrelation,
          },
          {
            caption: 'Sicherheit',
            icon: <SVGStatus type={'security'} status={fStatus.security} />,
            fn: onGotoSecurity,
          },
          {
            caption: 'Wohlbefinden',
            icon: <SVGStatus type={'feeling'} status={fStatus.feeling} />,
            fn: onGotoFeeling,
          },
        ].map((item, key) =>
          item ? (
            <AMItem
              caption={item.caption}
              icon={
                <Stack alignItems={'center'} sx={{ width: 25 }}>
                  {item.icon}
                </Stack>
              }
              key={key}
              onClick={item.fn}
            />
          ) : (
            <Divider key={key} />
          )
        )}
      </AM>
      {/* action menu end */}

      <KPage
        action={
          <IconButton onClick={openAM}>
            <MoreVertTwoTone />
          </IconButton>
        }
      >
        <HStack
          sx={{ justifyContent: 'space-between', width: 1, mb: 5, px: 5 }}
        >
          <Stack ref={topRef} />

          <Tabs
            centered
            indicatorColor={'primary'}
            onChange={onChangeCategory}
            scrollButtons={'auto'}
            textColor={'primary'}
            value={tab}
          >
            {/* tabData */}
            {tabData.map((item, key) => (
              <Tab
                icon={
                  <Box
                    component={'img'}
                    src={SensorIcon(item.category, item.color)}
                    sx={{ width: 80 }}
                    title={item.title}
                  />
                }
                key={key}
                label={item.title}
              />
            ))}
          </Tabs>

          <Weather city={city} />
        </HStack>

        <Collapse in={tab === TAB_CONSUMPTION}>
          <Performance folderId={fId} />
        </Collapse>

        <Collapse in={tab === TAB_INSTALLATION}>
          <Installation folderId={fId} />
        </Collapse>

        <Collapse in={tab === TAB_CORRELATION}>
          {loadCorrelation && (
            <Correlation
              folderId={fId}
              isCorrelational={fStatus.canCorrelate}
            />
          )}
        </Collapse>

        <Collapse in={tab === TAB_SECURITY}>
          <Security folderId={fId} />
        </Collapse>

        <Collapse in={tab === TAB_FEELING}>
          <Feeling folderId={fId} />
        </Collapse>
      </KPage>
    </>
  )
}
