import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { FolderMap } from '../../pages/Folder/components'

export const DlgFolderMap = ({ onClose, open, title = 'Standorte' }) => (
  <Dialog fullWidth={true} maxWidth={'md'} onClose={onClose} open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent variant={'profilePicture'}>
      <FolderMap />
    </DialogContent>
  </Dialog>
)

DlgFolderMap.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}
