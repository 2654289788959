import { ExpandMoreTwoTone } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  List,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { colorSchemeFeeling, normalizedSensorType, sensorType } from '../../api'
import { useFolderSensors } from '../../hooks'
import { CorrelationFeelingItem, HStack } from '..'

export const AccFeeling = ({
  folderId,
  feelingSensors,
  selectedSensors,
  setSelectedSensors,
  selectedFeelingSensors,
  setFeelingSensorId,
  changeFeelingSensors,
  selectedBaselineFeelingSensors,
  setSelectedFeelingSensors,
  setSelectedBaselineFeelingSensors,
}) => {
  const folderSensors = useFolderSensors(folderId)

  const borderLeft = `solid 10px ${colorSchemeFeeling[0]}`

  return ['temperature', 'co2', 'humidity', 'light'].map((sensor_type, key) => {
    const fSensors = feelingSensors.filter(
      s => normalizedSensorType(s.sensor_type) === sensor_type
    )

    const ALL_SELECTED = fSensors.every(sensor =>
      selectedFeelingSensors.includes(sensor.entity_id)
    )

    const SOME_SELECTED = fSensors.some(sensor =>
      selectedFeelingSensors.includes(sensor.entity_id)
    )

    const onClick = event => {
      event.stopPropagation()

      let _selectedSensors = [...selectedFeelingSensors]
      let _selectedBaselineFeelingSensors = [...selectedBaselineFeelingSensors]

      fSensors.forEach(sensor => {
        const sensorId = Number(sensor.entity_id)

        if (!ALL_SELECTED) {
          _selectedSensors.push(sensorId)
        } else {
          _selectedSensors = _selectedSensors.filter(item => item !== sensorId)
          _selectedBaselineFeelingSensors =
            _selectedBaselineFeelingSensors.filter(
              f => f.entity_id !== sensorId
            )
        }
      })

      setSelectedFeelingSensors(_selectedSensors)
      setSelectedBaselineFeelingSensors(_selectedBaselineFeelingSensors)
    }

    const isConsumptionSensor = ['energy', 'gas', 'water'].includes(sensor_type)

    const FILTER_SENSOR_TYPE = f =>
      normalizedSensorType(f.sensor_type, f.type_in_folder) === sensor_type ||
      normalizedSensorType(f.sensor_type, f.type_in_folder) ===
        `${sensor_type}_detail`

    const isSelected = id =>
      isConsumptionSensor
        ? selectedSensors.includes(id)
        : selectedFeelingSensors.includes(id)

    return (
      feelingSensors.find(
        f => normalizedSensorType(f.sensor_type) === sensor_type
      ) && (
        <Accordion key={key} sx={{ '&.Mui-expanded': { m: '0 !important' } }}>
          <AccordionSummary
            expandIcon={<ExpandMoreTwoTone />}
            sx={{ borderLeft, justifyContent: 'space-between' }}
          >
            <HStack justifyContent={'space-between'} sx={{ width: 1 }}>
              <Typography>{sensorType(sensor_type)}</Typography>

              <Checkbox
                checked={ALL_SELECTED}
                color={'primary'}
                indeterminate={SOME_SELECTED && !ALL_SELECTED}
                onClick={onClick}
              />
            </HStack>
          </AccordionSummary>

          <AccordionDetails sx={{ borderLeft, p: 0 }}>
            <List sx={{ width: 1 }}>
              <Divider />

              {fSensors.filter(FILTER_SENSOR_TYPE).map((sensor, key) => {
                const sensorId = Number(sensor.entity_id)

                const onClick = () => {
                  const record = folderSensors.find(
                    f => f.entity_id === sensorId
                  )
                  if (!record) return

                  if (isConsumptionSensor)
                    setSelectedSensors(prev =>
                      prev.includes(sensorId)
                        ? prev.filter(f => f !== sensorId)
                        : [...prev, sensorId]
                    )
                  else {
                    setFeelingSensorId(sensorId)
                    changeFeelingSensors(sensorId)
                  }
                }

                return (
                  <CorrelationFeelingItem
                    key={key}
                    onClick={onClick}
                    selected={isSelected(sensorId)}
                    sensorId={sensorId}
                  />
                )
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      )
    )
  })
}

AccFeeling.propTypes = {
  folderId: PropTypes.number.isRequired,
  feelingSensors: PropTypes.array.isRequired,
  selectedSensors: PropTypes.array.isRequired,
  setSelectedSensors: PropTypes.func.isRequired,
  selectedFeelingSensors: PropTypes.array.isRequired,
  setFeelingSensorId: PropTypes.func.isRequired,
  changeFeelingSensors: PropTypes.func.isRequired,
  selectedBaselineFeelingSensors: PropTypes.array.isRequired,
  setSelectedFeelingSensors: PropTypes.func.isRequired,
  setSelectedBaselineFeelingSensors: PropTypes.func.isRequired,
}
