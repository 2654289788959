import PropTypes from 'prop-types'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../actions'

const operatorContext = createContext({})

export const ProvideOperator = ({ children }) => {
  const value = useOp()

  return (
    <operatorContext.Provider value={value}>
      {children}
    </operatorContext.Provider>
  )
}
ProvideOperator.propTypes = { children: PropTypes.any }

export const useOperator = () => useContext(operatorContext)

const useOp = () => {
  const dispatch = useDispatch()

  const { oneUser } = useSelector(state => state.user)

  const [data, setData] = useState(null)
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(userActions.getOperator())
  }, [dispatch])

  useEffect(() => {
    const { error, isAdmin, item } = oneUser

    if (item) {
      const {
        attributes,
        contracts,
        devices,
        folders,
        id,
        reports,
        sensors,
        view_options,
      } = item.data

      setData({
        ...attributes,
        contracts: contracts
          ? contracts.data.map(contract => Number(contract.id))
          : [],
        devices: devices ? devices.map(device => device.attributes) : [],
        folders: folders ? folders.data.map(folder => Number(folder.id)) : [],
        reports: reports ? reports.map(report => report.attributes) : [],
        sensors: sensors ? sensors.data.map(sensor => Number(sensor.id)) : [],
        id: Number(id),
        isAdmin,
        view_options: view_options ? view_options : [],
      })

      setLoading(false)
    }

    if (error) {
      const { message } = error

      setError(message)
      setLoading(false)
    }
  }, [oneUser])

  return { isLoading, isError, data }
}
