import {
  getSession,
  getStorage,
  removeStorage,
  setSession,
  setStorage,
  initialChartConfig,
} from './'
import {
  folderConstants as FC,
  newsConstants as NC,
  userConstants as UC,
} from '../constants'
import { ASC } from './'

export const createStorageConfig = () => {
  // global

  if (!getStorage('settings_global'))
    setStorage('settings_global', {
      alertsEnabled: true,
      alertsAcknowledged: [],
      alertsMax: 3,
      mask: 7,
      salutationMode: 'default',
      timeMode: 'relative',
      showSettings: false,
      setupTab: 0,
      isMaintenance: 0,
      maintenanceMessage: '',
    })

  // dashboard

  if (!getStorage('settings_dashboard'))
    setStorage('settings_dashboard', { objectFilter: '' })

  if (!getStorage('settings_dashboards'))
    setStorage('settings_dashboards', { selectedId: 0 })

  if (!getStorage('settings_dashboard_id'))
    setStorage('settings_dashboard_id', { maxColItems: 2 })

  // reports

  if (!getStorage('settings_reports_all'))
    setStorage('settings_reports_all', { tab: 'mbr' })

  if (!getStorage('settings_report_generator'))
    setStorage('settings_report_generator', { tab: 0 })

  // notifications

  if (!getStorage('settings_notifications_all'))
    setStorage('settings_notifications_all', {
      filter: '',
      order: ASC,
      orderby: 'name',
      poolInfo: false,
    })

  // folders

  if (!getStorage('settings_folders_all'))
    setStorage('settings_folders_all', {
      filter: '',
      itemView: 'list',
      map: false,
      order: ASC,
      orderby: 'name',
      poolInfo: false,
      type: FC.TYPE_ANY,
    })

  // sensors

  if (!getStorage('settings_sensors_all'))
    setStorage('settings_sensors_all', {
      actionMenu: null,
      exportDialog: false,
      exportMode: 'page',
      maxResults: '10',
      order: ASC,
      orderby: 'name',
    })

  if (!getStorage('settings_sensor_edit'))
    setStorage('settings_sensor_edit', { tab: 0 })

  // virtual sensors

  if (!getStorage('settings_virtual_sensors_all'))
    setStorage('settings_virtual_sensors_all', { maxResults: 20 })

  // news

  if (!getStorage('settings_news_all'))
    setStorage('settings_news_all', {
      filter: '',
      order: ASC,
      orderby: 'title',
      poolInfo: false,
      type: NC.NEWS_TYPE_ANY,
      category: [NC.NEWS_CATEGORY_CONTRACT, NC.NEWS_CATEGORY_OBJECT],
    })

  // users

  if (!getStorage('settings_users_all'))
    setStorage('settings_users_all', {
      filter: '',
      order: ASC,
      orderby: 'firstname',
      poolInfo: false,
      role: [
        UC.USER_ROLE_GUEST,
        UC.USER_ROLE_USER,
        UC.USER_ROLE_COACH,
        UC.USER_ROLE_ADMIN,
      ],
      status: [UC.USER_STATUS_ACTIVE, UC.USER_STATUS_INACTIVE],
    })

  if (!getStorage('settings_user_edit'))
    setStorage('settings_user_edit', {
      assignedContractsOnly: false,
      assignedFoldersOnly: false,
      assignedSensorsOnly: false,
      assignmentSection: 0,
      confirmDelete: false,
      editAvatar: false,
      maxResults: 10,
      order: 'desc',
      orderBy: 'entity_id',
      tab: 0,
    })

  // consumption + detail

  if (!getStorage('settings_performance'))
    setStorage('settings_performance', {
      chartType: 'ColumnChart',
      view: 'daily',
    })

  if (!getSession('settings_performance_detail'))
    setSession('settings_performance_detail', initialChartConfig)

  if (!getStorage('settings_feeling_history'))
    setStorage('settings_feeling_history', {
      limitsDialog: false,
      exportDialog: false,
      chartType: 'AreaChart',
      lineColor: '#A00',
    })

  // security history

  if (!getStorage('settings_security_history'))
    setStorage('settings_security_history', {
      limitsDialog: false,
      exportDialog: false,
      chartType: 'AreaChart',
      lineColor: '#A00',
    })

  // correlation
  if (!getSession('settings_correlation'))
    setSession('settings_correlation', initialChartConfig)
}

export const deleteStorageConfig = () => {
  removeStorage('settings_global')
  removeStorage('settings_dashboard')
  removeStorage('settings_dashboards')
  removeStorage('settings_dashboard_id')
  removeStorage('settings_reports_all')
  removeStorage('settings_report_generator')
  removeStorage('settings_notifications_all')
  removeStorage('settings_folders_all')
  removeStorage('settings_sensors_all')
  removeStorage('settings_sensor_edit')
  removeStorage('settings_virtual_sensors_all')
  removeStorage('settings_news_all')
  removeStorage('settings_users_all')
  removeStorage('settings_user_edit')
  removeStorage('settings_performance')
  removeStorage('settings_performance_detail')
  removeStorage('settings_feeling_history')
  removeStorage('settings_security_history')
  removeStorage('settings_correlation')
}
