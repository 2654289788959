import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolder } from '..'

export const useFolderInstallations = folderId => {
  const [data, setData] = useState([])

  const { data: folderData } = useFolder(folderId)

  useEffect(() => {
    if (!folderId || !folderData?.installations) return

    setData(folderData?.installations)
  }, [folderData, folderId])

  return data
}

useFolderInstallations.propTypes = {
  folderId: PropTypes.number,
}
