import { FormControl, MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { SORT_ATTR_NAME } from '../../api'
import { useCurrentFolder, useFolderPool } from '../../hooks'

export const FolderSelector = ({ caption = 'Meine Gebäude', width = 600 }) => {
  const folderId = useCurrentFolder()
  const { data, isLoading } = useFolderPool()
  const history = useHistory()

  const onChange = event => {
    const folderId = event.target.value
    const targetUrl = `/folder/${folderId}/home/performance`

    history.push(targetUrl)
  }

  return isLoading ? null : (
    <FormControl sx={{ width }}>
      <TextField onChange={onChange} select value={folderId}>
        <MenuItem disabled value={0}>
          {caption}
        </MenuItem>

        {data.sort(SORT_ATTR_NAME).map((folder, key) => (
          <MenuItem key={key} value={folder.id}>
            {folder.attributes.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}

FolderSelector.propTypes = {
  caption: PropTypes.string,
  width: PropTypes.number,
}
