// reused functions in 'all'-pages

export const getInitials = name => {
  let initials = name.split(' ')

  if (initials.length > 1)
    initials = initials.shift().charAt(0) + initials.pop().charAt(0)
  else initials = name.substring(0, 2)

  return initials.toUpperCase()
}

export const isFilterMatch = (input, data) =>
  Boolean(String(data).toLowerCase().includes(String(input).toLowerCase()))
