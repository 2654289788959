import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useSensorStatus = sensorId => {
  const [data, setData] = useState('')

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const sensorData = sensorPool.find(f => Number(f.id) === sensorId)
    if (!sensorData?.attributes?.status) return

    const status = sensorData?.attributes?.status
    if (status) setData(status)
  }, [sensorPool, sensorId])

  return data
}

useSensorStatus.propTypes = {
  sensorId: PropTypes.number,
}
