import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const useCurrentFolder = () => {
  const { oneFolder } = useSelector(state => state.folder)
  const location = useLocation()
  const [folderId, setFolderId] = useState(0)

  useEffect(() => {
    const match = location.pathname.match(/\/folder\/(\d+)/)
    const urlFolderId = match ? Number(match[1]) : null

    if (urlFolderId) {
      setFolderId(urlFolderId)
      return
    }

    if (!oneFolder?.item?.data?.id) return
    const currentFolder = Number(oneFolder.item.data.id)
    setFolderId(currentFolder)
  }, [oneFolder, location.pathname])

  return folderId
}
