import PropTypes from 'prop-types'
import React from 'react'
import {
  ProvideAlertsPool,
  ProvideAppConfig,
  ProvideAppVersion,
  ProvideContractPool,
  ProvideDashboardPool,
  ProvideFolderPool,
  ProvideNewsPool,
  ProvideNewsTypes,
  ProvideNotificationPool,
  ProvideOperator,
  ProvidePayloadPool,
  ProvideSensorPool,
  ProvideSensorPrices,
  ProvideUserPool,
  ProvideUserRoles,
  ProvideVirtualSensorPool,
} from '../../hooks'

export const ProviderWrapper = ({ children }) => (
  <ProvideOperator>
    <ProvideAlertsPool>
      <ProvideAppConfig>
        <ProvideAppVersion>
          <ProvideContractPool>
            <ProvideDashboardPool>
              <ProvideFolderPool>
                <ProvideNewsPool>
                  <ProvideNewsTypes>
                    <ProvideNotificationPool>
                      <ProvidePayloadPool>
                        <ProvideSensorPool>
                          <ProvideSensorPrices>
                            <ProvideUserPool>
                              <ProvideUserRoles>
                                <ProvideVirtualSensorPool>
                                  {children}
                                </ProvideVirtualSensorPool>
                              </ProvideUserRoles>
                            </ProvideUserPool>
                          </ProvideSensorPrices>
                        </ProvideSensorPool>
                      </ProvidePayloadPool>
                    </ProvideNotificationPool>
                  </ProvideNewsTypes>
                </ProvideNewsPool>
              </ProvideFolderPool>
            </ProvideDashboardPool>
          </ProvideContractPool>
        </ProvideAppVersion>
      </ProvideAppConfig>
    </ProvideAlertsPool>
  </ProvideOperator>
)

ProviderWrapper.propTypes = {
  children: PropTypes.node,
}
