import { CancelTwoTone, DeleteTwoTone } from '@mui/icons-material'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const DlgPanelDelete = ({
  name,
  onCancel,
  onConfirm,
  open,
  title = 'Bestätigung erforderlich',
}) => {
  return (
    <Dialog fullWidth={true} maxWidth={'lg'} onClose={onCancel} open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Alert severity={'info'} sx={{ p: 2 }}>
          Möchten Sie Kachel <strong>{name}</strong> wirklich löschen?
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button color={'error'} onClick={onConfirm}>
          <DeleteTwoTone />
          Löschen
        </Button>

        <Button onClick={onCancel}>
          <CancelTwoTone />
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgPanelDelete.propTypes = {
  name: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
}
