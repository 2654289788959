import { PersonAddTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const BtnAddUser = ({ onClick, serviceName = 'Benutzer' }) => (
  <Tooltip title={`${serviceName} hinzufügen`}>
    <IconButton onClick={onClick} sx={{ bgcolor: '#DDD' }}>
      <PersonAddTwoTone />
    </IconButton>
  </Tooltip>
)

BtnAddUser.propTypes = {
  onClick: PropTypes.func,
  serviceName: PropTypes.string,
}
