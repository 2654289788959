import { AppBar, Box, Toolbar, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getStorage } from '../api'
import {
  BtnUpdateAvailable,
  CopyrightNotice,
  DlgSettingsGlobal,
  FolderSelector,
  HStack,
  OperatorMenu,
  ProviderWrapper,
  RouteSwitcher,
} from '../components'
import routes from '../routes/routes'
import { Sidebar } from './'
import { useSnackbar } from 'notistack'

export const Layout = ({ ...rest }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { message, type } = useSelector(state => state.alert)

  const theme = useTheme()
  const { white: bgcolor } = theme.palette

  const { appVersion } = useSelector(state => state.system)

  const buildInfo = getStorage('buildInfo') || {
    environment: '',
    build: '',
    branch: '',
    commit_hash: '',
  }

  const [isOutdated, setIsOutDated] = useState(false)

  useEffect(() => {
    const checkVersion = () => {
      const actual = appVersion?.item?.data?.actual_version
      const build = buildInfo.build

      const latest = Date.parse(actual)
      const current = Date.parse(build)

      setIsOutDated(current < latest)
    }

    checkVersion()
  }, [appVersion, buildInfo.build])

  useEffect(() => {
    if (!message) return
    enqueueSnackbar(message, { variant: type })
  }, [enqueueSnackbar, message, type])

  const NOT_LOGGED_IN = getStorage('user') === null

  return NOT_LOGGED_IN ? (
    <Redirect to={'/pages/login-page'} />
  ) : (
    <ProviderWrapper>
      {/* einstellungen */}
      <DlgSettingsGlobal />
      {/* einstellungen end */}

      <Sidebar {...rest} />

      <Box
        sx={{
          float: 'right',
          maxHeight: '100%',
          overflow: 'auto',
          overflowScrolling: 'touch',
          position: 'relative',
          width: '100%',
          [theme.breakpoints.up('md')]: { width: `calc(100% - 260px)` },
        }}
      >
        <AppBar
          elevation={0}
          sx={{ bgcolor, pl: '260px', borderBottom: 'solid 1px #999' }}
        >
          <Toolbar
            sx={{
              minHeight: '50px',
              px: '15px',
              mx: 'auto',
              '&:before, &:after': { display: 'table', content: '" "' },
              '&:after': { clear: 'both' },
              [theme.breakpoints.down('xs')]: { px: '0px !important' },
              width: '95%',
              alignItems: 'center',
              color: 'black',
              display: 'flex',
              justifyContent: 'space-between',
              '& > button': { color: 'black' },
              '& > div > button': { color: 'black' },
            }}
          >
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <FolderSelector />
            </Box>

            <HStack spacing={3}>
              {isOutdated && <BtnUpdateAvailable />}
              <OperatorMenu />
            </HStack>
          </Toolbar>
        </AppBar>

        <Box
          sx={{
            '&:before, &:after': { display: 'table', content: '" "' },
            '&:after': { clear: 'both' },
            mx: 0,
            mt: '75px',
            //minHeight: '50px',
            minHeight: 'calc(100vh - 120px)',
            px: 0,
            [theme.breakpoints.down('xs')]: {
              m: '5px !important',
              mt: '80px !important',
              p: '30px 0px !important',
            },
          }}
        >
          <RouteSwitcher routes={routes} />
        </Box>

        <CopyrightNotice sx={{ p: 1, textAlign: 'center' }} />
      </Box>
    </ProviderWrapper>
  )
}
