import { Alert, AlertTitle, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const AboutAboRecipients = ({
  data = [
    {
      text: 'Tragen Sie eine E-Mail Adresse ein, die zur Empfänger-Liste hinzugefügt werden soll. Klicken Sie zum Bestätigen auf <strong>+</strong>.',
    },
    {
      text: 'Wiederholen Sie diesen Vorgang, bis Sie mit der Empfänger-Liste zufrieden sind und klicken Sie dann auf <strong>Abonnieren</strong> bzw. <strong>Abo aktualisieren</strong>.',
    },
    {
      sx: { mt: 2 },
      text: 'Hinweis: Das <strong>+</strong> Zeichen ist deaktiviert, bis die E-Mail Adresse ein gültiges Schema aufweist.',
    },
    {
      text: 'Hinweis: Unerwünschte E-Mail Adressen lassen sich über den Papierkorb wieder entfernen.',
    },
  ],
  severity = 'info',
  title = 'Hinzufügen von E-Mail Adressen zur Empfänger-Liste',
  ...rest
}) => (
  <Alert severity={severity} {...rest}>
    <AlertTitle>{title}</AlertTitle>

    {data.map((item, key) => (
      <Typography
        dangerouslySetInnerHTML={{ __html: item.text }}
        key={key}
        sx={item?.sx}
      />
    ))}
  </Alert>
)

AboutAboRecipients.propTypes = {
  data: PropTypes.array,
  severity: PropTypes.string,
  title: PropTypes.string,
}
