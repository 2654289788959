import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { usePagination, useTable } from 'react-table'

const dataColumns = [
  {
    accessor: 'entity_id',
    filterAll: true,
    Header: 'entity_id',
    id: 'entity_id',
  },
  {
    accessor: 'sensor_id',
    filterAll: true,
    Header: 'Sensor ID',
    id: 'sensor_id',
  },
  {
    accessor: 'value',
    filterAll: true,
    Header: 'Wert',
    id: 'value',
  },
  {
    accessor: 'time',
    filterAll: true,
    Header: 'Zeit',
    id: 'time',
  },
  {
    accessor: 'timestamp',
    filterAll: true,
    Header: 'Zeitstempel',
    id: 'timestamp',
  },
  {
    accessor: 'timestamp_sensor',
    filterAll: true,
    Header: 'Zeitstempel Sensor',
    id: 'timestamp_sensor',
  },
]

export const DlgRecalculateSensorData = ({ onClose, open, previewData }) => {
  const formatPreviewData = data => {
    const missing_data = data.data.missing_data.map((item, key) => ({
      ...item,
      entity_id: key + 1,
    }))

    missing_data.unshift(data.data.first_sensor_message)
    missing_data.push(data.data.second_sensor_message)

    return missing_data
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    //state: { pageSize },
  } = useTable(
    {
      columns: useMemo(() => dataColumns, []),
      data: useMemo(() => formatPreviewData(previewData), [previewData]),
      initialState: { pageSize: 50 },
    },
    usePagination
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle onClose={onClose}>
        <Typography align={'center'}>Vorschau</Typography>
      </DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table {...getTableProps()} style={{ width: '100%' }}>
            <TableHead>
              {headerGroups.map((headerGroup, key) => (
                <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, innerKey) => (
                    <TableCell key={innerKey} {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, key) => {
                prepareRow(row)
                return (
                  <TableRow key={key} {...row.getRowProps()}>
                    {row.cells.map((cell, innerKey) => (
                      <TableCell key={innerKey} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

DlgRecalculateSensorData.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  previewData: PropTypes.object,
}
