import React from 'react'
import { useParams } from 'react-router-dom'
import {
  DlgRegistrationConfirmation,
  DlgRegistrationForm,
} from '../../components/'

export const UserRegister = () => {
  const { status } = useParams()

  return (
    <>
      <DlgRegistrationForm open={!status} />

      <DlgRegistrationConfirmation open={status === 'confirm'} />
    </>
  )
}
