import { Alert, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { userLocales } from '../../api'
import { useMeterNumber, useOperator, useSensorName } from '../../hooks'

export const FactorWarning = ({
  reportDate,
  sensorFactors,
  sensorId,
  severity = 'warning',
}) => {
  const { data: operatorData } = useOperator()
  const sensorName = useSensorName(sensorId)
  const meterNumber = useMeterNumber(sensorId)

  const options = { month: 'long', year: 'numeric' }
  const reportDateFormated = reportDate.toLocaleString(userLocales, options)

  const FILTER_SENSORID = f => f[0]?.sensor_id === sensorId

  const factors = sensorFactors.filter(FILTER_SENSORID)

  const firstRecord = new Date(
    factors && factors[0]?.period_start
  ).toLocaleDateString(userLocales)

  return (
    <Alert severity={severity} sx={{ mb: 1, mx: 5 }}>
      <Typography sx={{ mb: 2 }}>
        Monatsberichte können nur für vollständige Monate generiert werden. Bei
        folgendem Messpunkt liegen für {reportDateFormated} keine vollständigen
        Daten vor:
      </Typography>

      <Typography sx={{ mb: 2 }}>
        Name: <strong>{sensorName}</strong> | Zählernummer:
        <strong>{meterNumber}</strong> |
        {operatorData?.isAdmin && ` SensorID: ${sensorId} | `}
        Daten seit: <strong>{firstRecord}</strong>
      </Typography>

      <Typography>
        Der Messpunkt wird bei der Erstellung des Monatsberichts nicht
        berücksichtigt.
      </Typography>
    </Alert>
  )
}

FactorWarning.propTypes = {
  reportDate: PropTypes.instanceOf(Date),
  sensorFactors: PropTypes.array,
  sensorId: PropTypes.number,
  severity: PropTypes.string,
}
