import { List, ListItem, ListItemText } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { userLocales } from '../../api'

export const Statistics = ({ average, minimum, maximum, unit }) => {
  const format = value =>
    `${Number(value).toLocaleString(userLocales, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })} ${unit}`

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 1,
        p: 0,
      }}
    >
      <ListItem sx={{ p: 0 }}>
        <ListItemText
          primary={'Minimum'}
          secondary={format(minimum)}
          sx={{ textAlign: 'center' }}
        />
      </ListItem>

      <ListItem sx={{ p: 0 }}>
        <ListItemText
          primary={'Durchschnitt'}
          secondary={format(average)}
          sx={{ textAlign: 'center' }}
        />
      </ListItem>

      <ListItem sx={{ p: 0 }}>
        <ListItemText
          primary={'Maximum'}
          secondary={format(maximum)}
          sx={{ textAlign: 'center' }}
        />
      </ListItem>
    </List>
  )
}

Statistics.propTypes = {
  average: PropTypes.number.isRequired,
  minimum: PropTypes.number.isRequired,
  maximum: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
}
