import {
  ArrowDropDownTwoTone,
  ArrowDropUpTwoTone,
  Star,
} from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ASC, DDMMYYYY, DESC, userLocales } from '../../api'
import { useFolderName, useOperator } from '../../hooks'
import {
  HStack,
  ToggleOrder,
  ToggleSelectedOnly,
  ToggleShowSensorInfo,
} from '..'
import { ChildSensorSelector } from '.'

export const SensorSelector = ({
  border = 'solid 1px #DDD',
  data,
  height = 645,
  onChange,
  onOrderChange,
  medium,
  selected: initialSelected,
  toolbarColor = 'white',
  width = 300,
  order: initialOrder,
  exportModal,
  fullWidth,
  folderId,
}) => {
  const folderName = useFolderName(folderId)

  const theme = useTheme()
  const { white: bgcolor } = theme.palette

  const { data: operatorData } = useOperator()

  const [order, setOrder] = useState(initialOrder)
  const [search, setSearch] = useState('')
  const [selectedOnly, setSelectedOnly] = useState(false)
  const [selected, setSelected] = useState(initialSelected)
  const [showSensorInfo, setShowSensorInfo] = useState(false)
  const [openIds, setOpenIds] = useState([])

  const handleOpenIDs = id => {
    setOpenIds(prevOpenIds =>
      prevOpenIds.includes(id)
        ? prevOpenIds.filter(openId => openId !== id)
        : [...prevOpenIds, id]
    )
  }
  const FILTER_SEARCH = f =>
    f.name.toLowerCase().includes(search.toLowerCase()) ||
    (f.meter_number &&
      f.meter_number.toLowerCase().includes(search.toLowerCase()))

  const FILTER_SELECTED_ONLY = f =>
    selectedOnly === true || exportModal === true
      ? selected.includes(f.entity_id)
      : f

  const FILTER_NOT_SUCCESSOR_SENSOR = f => f.successor_id === null

  const FILTER_SELECTED_SUCCESSOR_SENSOR = f => {
    const sensor = data.find(sensor => sensor.entity_id === f)
    return sensor ? sensor.successor_id === null : false
  }

  const SORT_HANDLER = (a, b) => {
    const result = a.name.localeCompare(b.name)

    return order === ASC ? result : -result
  }

  const handleKeyPress = event => {
    // deselect all // 109 = NumPad-; 189 = -;
    if (event.keyCode === 109 || event.keyCode === 189) setSelected([])

    // select all; 107 = NumPad+; 187 = +;
    if (event.keyCode === 107 || event.keyCode === 187)
      setSelected(data.sort(SORT_HANDLER).map(item => item.entity_id))
  }

  const handleToggleAll = () => {
    if (selected.length !== data.length) {
      setSelected(data.sort(SORT_HANDLER).map(item => item.entity_id))
    } else {
      setSelected([])
    }
  }

  const getAllWithSuccessorId = entity_id => {
    return data
      .filter(FILTER_SELECTED_ONLY)
      .filter(FILTER_SEARCH)
      .filter(data => data.successor_id === entity_id)
      .sort(
        (a, b) =>
          new Date(b.data_sampling_date) - new Date(a.data_sampling_date)
      )
  }

  const getAllWitParentId = entity_id => {
    return data
      .filter(FILTER_SELECTED_ONLY)
      .filter(FILTER_SEARCH)
      .filter(data => data.parent_id === entity_id)
  }

  const getPredessorData = data
    ? data.filter(data => data.successor_id !== null)
    : []

  document.onkeydown = handleKeyPress

  useEffect(() => onOrderChange(order), [onOrderChange, order])
  useEffect(() => onChange(selected), [onChange, order, selected])
  useEffect(() => setSelected([]), [medium])
  useEffect(() => setSelected(selected), [selected])

  const operatorHasParentSensor = data.some(entry => entry.parent_id === null)

  return (
    <>
      <HStack
        sx={{
          background: toolbarColor,
          borderBottom: border,
          borderLeft: border,
        }}
      >
        <Typography
          sx={{
            borderRight: border,
            fontSize: '0.75rem',
            height: 42,
            lineHeight: 1,
            px: 2,
            py: 1,
          }}
        >
          Messpunkte ({selected.filter(FILTER_SELECTED_SUCCESSOR_SENSOR).length}
          /{data.filter(FILTER_NOT_SUCCESSOR_SENSOR).length})
        </Typography>

        <Input
          onChange={event => setSearch(event.target.value)}
          placeholder={'Filter...'}
          sx={{ m: 0, minHeight: 23, pl: 1 }}
          value={search}
          variant={'standard'}
        />

        <ToggleOrder order={order} onChange={order => setOrder(order)} />
        <ToggleSelectedOnly onChange={only => setSelectedOnly(only)} />
        <ToggleShowSensorInfo
          onChange={showSensorInfo => setShowSensorInfo(showSensorInfo)}
        />
      </HStack>
      <Stack>
        <List
          height={height}
          sx={{
            bgcolor,
            borderLeft: 'solid 1px #D3D3D3',
            maxHeight: height,
            minHeight: fullWidth ? 1 : height,
            overflow: 'auto',
            pt: 0,
          }}
          width={width}
        >
          <Collapse in={exportModal}>
            <ListSubheader
              sx={{ bgcolor: '#EEE', borderBottom: 'solid 1px #D3D3D3' }}
            >
              {folderName}
            </ListSubheader>
          </Collapse>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected.length === data.length}
                  onChange={handleToggleAll}
                  sx={{
                    marginLeft: '18px',
                    marginRight: '22px',
                  }}
                  indeterminate={
                    selected.length > 0 && selected.length !== data.length
                  }
                />
              }
              label={'Alle auswählen'}
            />
          </FormGroup>

          {data
            .filter(FILTER_SELECTED_ONLY)
            .sort(SORT_HANDLER)
            .map((sensor, key) => {
              const {
                chartColor,
                entity_id,
                name,
                meter_number,
                data_sampling_date,
                parent_id,
                sampling_mode,
              } = sensor
              const date = new Date(data_sampling_date).toLocaleDateString(
                userLocales,
                DDMMYYYY
              )

              const filteredArray = getPredessorData.filter(
                item => item.entity_id === entity_id
              )

              if (filteredArray.length > 0) return null

              const successorData = getAllWithSuccessorId(entity_id)
              const childSensorData = getAllWitParentId(entity_id)
              const sensorInfo = (
                <Box>
                  <Box>Zählernummer: {meter_number ?? 'n/a'}</Box>
                  <Box>Datenbeginn: {date}</Box>
                  <Box>
                    Messverfahren:{' '}
                    {sampling_mode === 1
                      ? 'Zählerstand'
                      : sampling_mode === 2
                        ? 'Verbrauch'
                        : '-'}
                  </Box>
                  {operatorData?.isAdmin && <Box>Sensor ID: {entity_id}</Box>}
                </Box>
              )

              const isSelected = selected.includes(entity_id)

              const toggle = entity_id => {
                const newValue = isSelected
                  ? selected.filter(f => f !== entity_id)
                  : [...selected, entity_id]

                setSelected(
                  data
                    .sort(SORT_HANDLER)
                    .map(sensor => sensor.entity_id)
                    .filter(f => newValue.includes(f))
                )
              }

              if (
                (parent_id !== null && operatorHasParentSensor) ||
                (selectedOnly &&
                  !isSelected &&
                  childSensorData.length === 0 &&
                  successorData.length === 0)
              )
                return null

              if (
                name.toLowerCase().includes(search.toLowerCase()) ||
                (meter_number &&
                  meter_number.toLowerCase().includes(search.toLowerCase())) ||
                childSensorData.length > 0 ||
                successorData.length > 0
              )
                return (
                  <ListItem
                    key={key}
                    sx={{
                      p: 0,
                      width: fullWidth ? '25%' : 'auto',
                      display: fullWidth ? 'inline-block' : 'block',
                    }}
                  >
                    <ListItemButton
                      onClick={() => toggle(entity_id, successorData)}
                      selected={isSelected}
                    >
                      <ListItemIcon sx={{ color: chartColor }}>
                        <Star />
                      </ListItemIcon>
                      <ListItemText
                        primary={name}
                        secondary={showSensorInfo ? sensorInfo : null}
                      />
                      {successorData.length > 0 &&
                        (openIds.includes(key) ? (
                          <ArrowDropUpTwoTone
                            onClick={event => {
                              event.stopPropagation()
                              handleOpenIDs(key)
                            }}
                          />
                        ) : (
                          <ArrowDropDownTwoTone
                            onClick={event => {
                              event.stopPropagation()
                              handleOpenIDs(key)
                            }}
                          />
                        ))}
                    </ListItemButton>
                    {successorData.length > 0 ? (
                      <Collapse
                        in={openIds.includes(key)}
                        timeout={'auto'}
                        unmountOnExit
                      >
                        {successorData.map((successorSensor, successorKey) => {
                          const successorDate = new Date(
                            successorSensor.data_sampling_date
                          ).toLocaleDateString(userLocales, DDMMYYYY)

                          const successorSensorInfo = (
                            <Box>
                              <Box>
                                Zählernummer:{' '}
                                {successorSensor.meter_number
                                  ? successorSensor.meter_number
                                  : 'n/a'}
                              </Box>
                              <Box>Datenbeginn: {successorDate}</Box>
                              <Box>
                                Messverfahren{' '}
                                {successorSensor.sampling_mode === 1
                                  ? 'Zählerstand'
                                  : successorSensor.sampling_mode === 2
                                    ? 'Verbrauch'
                                    : '-'}
                              </Box>

                              {operatorData?.isAdmin && (
                                <Box>
                                  Sensor ID: {successorSensor.entity_id}
                                </Box>
                              )}
                            </Box>
                          )
                          const isSuccessorSelected = selected.includes(
                            successorSensor.entity_id
                          )
                          const successorToggle = entity_id => {
                            const newValue = isSuccessorSelected
                              ? selected.filter(f => f !== entity_id)
                              : [...selected, entity_id]

                            setSelected(
                              data
                                .sort(SORT_HANDLER)
                                .map(sensor => sensor.entity_id)
                                .filter(f => newValue.includes(f))
                            )
                          }
                          const childSuccessorSensorData = getAllWitParentId(
                            successorSensor.entity_id
                          )
                          return (
                            <>
                              <List
                                component={'div'}
                                disablePadding
                                key={successorKey}
                                onClick={() =>
                                  successorToggle(successorSensor.entity_id)
                                }
                              >
                                <ListItemButton selected={isSuccessorSelected}>
                                  <ListItemIcon
                                    sx={{ color: successorSensor.chartColor }}
                                  >
                                    <Star />
                                  </ListItemIcon>

                                  <ListItemText
                                    primary={successorSensor.name}
                                    secondary={
                                      showSensorInfo
                                        ? successorSensorInfo
                                        : null
                                    }
                                    primaryTypographyProps={{
                                      fontSize: '0.9rem',
                                    }}
                                  />
                                </ListItemButton>
                              </List>
                              <ChildSensorSelector
                                childSensorData={childSuccessorSensorData}
                                selected={selected}
                                setSelected={setSelected}
                                showSensorInfo={showSensorInfo}
                                data={data}
                                SORT_HANDLER={SORT_HANDLER}
                                successorChild={true}
                              />
                            </>
                          )
                        })}
                      </Collapse>
                    ) : (
                      <> </>
                    )}
                    {childSensorData.length > 0 ? (
                      <ChildSensorSelector
                        childSensorData={childSensorData}
                        selected={selected}
                        setSelected={setSelected}
                        showSensorInfo={showSensorInfo}
                        data={data}
                        SORT_HANDLER={SORT_HANDLER}
                        successorChild={false}
                      />
                    ) : (
                      <> </>
                    )}
                  </ListItem>
                )

              return null
            })}
        </List>
      </Stack>
    </>
  )
}

SensorSelector.propTypes = {
  border: PropTypes.string,
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  medium: PropTypes.string,
  order: PropTypes.oneOf([ASC, DESC]).isRequired,
  selected: PropTypes.array.isRequired,
  toolbarColor: PropTypes.string,
  width: PropTypes.number,
  exportModal: PropTypes.bool,
  fullWidth: PropTypes.bool,
  folderId: PropTypes.number,
}
