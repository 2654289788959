import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Paper,
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { useAppConfig } from '../../hooks'
import { Preloader } from '../dialog/Preloader'

export const KPage = ({ isLoading, children, ...rest }) => {
  const { appConfig } = useAppConfig()
  const isWideScreen = appConfig?.wideScreen ?? false

  return isLoading === true ? (
    <Preloader isLoading={true} />
  ) : (
    <Collapse in={true}>
      <Paper elevation={0} sx={{ borderBottom: 'solid 1px #999' }}>
        <Card>
          <CardHeader {...rest} />

          <CardContent sx={{ minHeight: 'calc(100vh - 200px)' }}>
            <Container maxWidth={isWideScreen === true ? 'false' : 'xl'}>
              {children}
            </Container>
          </CardContent>
        </Card>
      </Paper>
    </Collapse>
  )
}

KPage.propTypes = {
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
}
