import { TrendingUpTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  useFolderAddress,
  useFolderCTime,
  useFolderImage,
  useFolderMTime,
  useFolderName,
  useFolderParent,
} from '../../hooks'

export const FolderList = ({ folderId }) => {
  const history = useHistory()

  const folderName = useFolderName(folderId)
  const folderImage = useFolderImage(folderId)
  const folderCTime = useFolderCTime(folderId)
  const folderMTime = useFolderMTime(folderId)
  const parentId = useFolderParent(folderId)
  const address = useFolderAddress(folderId)

  const onEditFolder = () => history.push(`/folder/${folderId}/edit`)

  const onGotoPerformance = () =>
    history.push(`/folder/${folderId}/home/performance`)

  return (
    <ListItemButton onClick={onEditFolder}>
      <ListItemAvatar>
        <Avatar src={folderImage} />
      </ListItemAvatar>

      <ListItemText
        primary={folderName}
        secondary={address}
        sx={{ width: '55%' }}
      />

      <Divider flexItem orientation={'vertical'} />

      <ListItemText
        primary={!parentId ? 'Gebäude' : 'Untergebäude'}
        secondary={`ID: ${folderId}`}
        sx={{ pl: 1, width: '10%' }}
      />

      <Divider flexItem orientation={'vertical'} />

      <ListItemText
        primary={`aktualisiert: ${folderMTime}`}
        secondary={`angelegt: ${folderCTime}`}
        sx={{ pl: 1, width: '25%' }}
      />

      <Tooltip title={'Leistungsübersicht'}>
        <IconButton edge={'end'} onClick={onGotoPerformance}>
          <TrendingUpTwoTone />
        </IconButton>
      </Tooltip>
    </ListItemButton>
  )
}

FolderList.propTypes = {
  folderId: PropTypes.number.isRequired,
}
