import {
  ArrowDropDownTwoTone,
  ArrowDropUpTwoTone,
  Star,
} from '@mui/icons-material'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { DDMMYYYY, userLocales } from '../../api'
import { useOperator } from '../../hooks'

export const ChildSensorSelector = ({
  childSensorData,
  selected,
  setSelected,
  showSensorInfo,
  data,
  SORT_HANDLER,
  successorChild,
}) => {
  const { data: operatorData } = useOperator()
  const isAdmin = operatorData?.isAdmin

  const [openIds, setOpenIds] = useState([])

  const handleOpenIDs = id =>
    setOpenIds(prev =>
      prev.includes(id) ? prev.filter(f => f !== id) : [...prev, id]
    )

  const getAllWithSuccessorId = id => data.filter(f => f.successor_id === id)

  return (
    <Box sx={{ position: 'relative' }}>
      {childSensorData.map((childSensor, childKey) => {
        const {
          chartColor: color,
          data_sampling_date: dsd,
          entity_id,
          meter_number,
          name,
          sampling_mode,
        } = childSensor

        const childDate = new Date(dsd).toLocaleDateString(
          userLocales,
          DDMMYYYY
        )

        const isLastItem = childKey === childSensorData.length - 1

        const childSensorInfo = (
          <Box>
            <Box>Zählernummer: {meter_number ? meter_number : 'n/a'}</Box>
            <Box>Datenbeginn: {childDate}</Box>
            <Box>
              Messverfahren:{' '}
              {sampling_mode === 1
                ? 'Zählerstand'
                : sampling_mode === 2
                  ? 'Verbrauch'
                  : '-'}
            </Box>
            {isAdmin && (
              <>
                <Box>Sensor ID: {entity_id}</Box>
              </>
            )}
          </Box>
        )

        const successorData = getAllWithSuccessorId(entity_id)
        const isChildSelected = selected.includes(entity_id)

        const childToggle = (entity_id, successorData) => {
          let newValue

          newValue = isChildSelected
            ? selected.filter(f => f !== entity_id)
            : [...selected, entity_id]

          successorData.forEach(sensor => {
            newValue = isChildSelected
              ? newValue.filter(f => f !== sensor.entity_id)
              : [...newValue, sensor.entity_id]
          })

          setSelected(
            data
              .sort(SORT_HANDLER)
              .map(sensor => sensor.entity_id)
              .filter(f => newValue.includes(f))
          )
        }

        const onClick = () => childToggle(entity_id, successorData)

        return (
          <Box key={childKey} sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                left: successorChild ? '50px' : '28px',
                top: '0',
                bottom: '0',
                borderLeft: '1px solid rgb(0, 0, 0)',
                height:
                  isLastItem && openIds.includes(childKey)
                    ? '27%'
                    : isLastItem
                      ? '51%'
                      : '100%',
              }}
            />
            <List component={'div'} disablePadding onClick={onClick}>
              <ListItemButton
                sx={{ pl: successorChild ? 6 : 4 }}
                selected={isChildSelected}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    left: successorChild ? '50px' : '28px',
                    top: '50%',
                    width: '10px',
                    borderBottom: '1px solid #000',
                    transform: 'translateY(-50%)',
                  }}
                />
                <ListItemIcon sx={{ color }}>
                  <Star />
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  secondary={showSensorInfo ? childSensorInfo : null}
                />

                {successorData.length > 0 &&
                  (openIds.includes(childKey) ? (
                    <ArrowDropUpTwoTone
                      onClick={event => {
                        event.stopPropagation()
                        handleOpenIDs(childKey)
                      }}
                    />
                  ) : (
                    <ArrowDropDownTwoTone
                      onClick={event => {
                        event.stopPropagation()
                        handleOpenIDs(childKey)
                      }}
                    />
                  ))}
              </ListItemButton>
            </List>

            {successorData.length > 0 ? (
              <Collapse
                in={openIds.includes(childKey)}
                timeout={'auto'}
                unmountOnExit
              >
                {successorData.map((successorSensor, successorKey) => {
                  const {
                    chartColor: color,
                    data_sampling_date: dsd,
                    entity_id,
                    meter_number,
                    name,
                    sampling_mode,
                  } = successorSensor

                  const successorDate = new Date(dsd).toLocaleDateString(
                    userLocales,
                    DDMMYYYY
                  )

                  const successorSensorInfo = (
                    <Box>
                      <Box>
                        Zählernummer: {meter_number ? meter_number : 'n/a'}
                      </Box>
                      <Box>Datenbeginn: {successorDate}</Box>
                      <Box>
                        Messverfahren{' '}
                        {sampling_mode === 1
                          ? 'Zählerstand'
                          : sampling_mode === 2
                            ? 'Verbrauch'
                            : '-'}
                      </Box>
                      {isAdmin && (
                        <>
                          <Box>Sensor ID: {entity_id}</Box>
                        </>
                      )}
                    </Box>
                  )

                  const isSuccessorSelected = selected.includes(entity_id)

                  const successorToggle = entity_id => {
                    const newValue = isSuccessorSelected
                      ? selected.filter(f => f !== entity_id)
                      : [...selected, entity_id]

                    setSelected(
                      data
                        .sort(SORT_HANDLER)
                        .map(sensor => sensor.entity_id)
                        .filter(f => newValue.includes(f))
                    )
                  }

                  return (
                    <List
                      component={'div'}
                      disablePadding
                      key={successorKey}
                      onClick={() => successorToggle(entity_id)}
                    >
                      <ListItemButton selected={isSuccessorSelected}>
                        <ListItemIcon sx={{ color, pl: 2.5 }}>
                          <Star />
                        </ListItemIcon>

                        <ListItemText
                          primary={name}
                          secondary={
                            showSensorInfo ? successorSensorInfo : null
                          }
                          primaryTypographyProps={{ fontSize: '0.9rem' }}
                        />
                      </ListItemButton>
                    </List>
                  )
                })}
              </Collapse>
            ) : (
              <> </>
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export default ChildSensorSelector

ChildSensorSelector.propTypes = {
  childSensorData: PropTypes.array.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelected: PropTypes.func.isRequired,
  showSensorInfo: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  SORT_HANDLER: PropTypes.func.isRequired,
  successorChild: PropTypes.bool,
}
