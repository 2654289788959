import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const DlgRegistrationConfirmation = ({
  open,
  title = 'Registrierung erfolgreich abgeschlossen!',
}) => {
  const history = useHistory()
  const gotoLogin = () => history.push('/pages/login-page')

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Alert severity={'info'}>
          <Typography sx={{ mb: 2 }}>
            Wir haben Ihnen eine E-Mail mit einem Bestätigungslink geschickt.
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Bitte beachten Sie, dass Sie Ihren KARMA Account erst nach der
            Bestätigung Ihrer E-Mail Adresse nutzen können.
          </Typography>
        </Alert>

        <Box sx={{ textAlign: 'center' }}>
          <Button onClick={gotoLogin} sx={{ my: 1 }}>
            Zum Login
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

DlgRegistrationConfirmation.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
}
