import { HomeTwoTone, MemoryTwoTone } from '@mui/icons-material'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { colorSchemeConsumption } from '../../api'

export const PanelDisplayNames = ({ data, type }) => (
  <List>
    {data.map((name, key) => {
      const color = colorSchemeConsumption[key % colorSchemeConsumption.length]
      const icon = type === 'sensor' ? <MemoryTwoTone /> : <HomeTwoTone />

      return (
        <ListItem key={key}>
          <ListItemIcon sx={{ color }}>{icon}</ListItemIcon>

          <ListItemText primary={name} />
        </ListItem>
      )
    })}
  </List>
)

PanelDisplayNames.propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
}
