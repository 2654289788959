import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useFolderContract } from '../../hooks'

export const ToDateSelector = ({
  folderId,
  label = 'bis',
  onChange,
  type = 'date',
  variant = 'standard',
  ...rest
}) => {
  const contract = useFolderContract(folderId)
  if (!contract) return null

  const { date_begin, date_expire } = contract

  // disable selection for future dates
  const limitNowAsMax = date =>
    date > new Date(Date.now()).toISOString().split('T')[0]
      ? new Date(Date.now()).toISOString().split('T')[0]
      : date

  // limit date selection to contract lifetime
  const boundaries = date =>
    date < date_begin ? date_begin : date > date_expire ? date_expire : date

  const sanitize = date => limitNowAsMax(boundaries(date))

  const onChangeDate = event => {
    const result = event
    result.target.value = sanitize(event.target.value)

    onChange(result)
  }

  return (
    <TextField
      inputProps={{
        onChange: onChangeDate,
        min: sanitize(date_begin),
        max: sanitize(date_expire),
      }}
      label={label}
      type={type}
      variant={variant}
      {...rest}
    />
  )
}

ToDateSelector.propTypes = {
  folderId: PropTypes.number.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
}
