import { Box, List, ListItem, ListItemText, ListSubheader } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { SORT_WEEKDAY, userLocales } from '../../api'

const OHItem = ({ disabled, status, title }) => (
  <ListItem disabled={disabled}>
    <ListItemText sx={{ width: 0.5 }}>{status}</ListItemText>
    <ListItemText sx={{ width: 0.5 }}>{title}</ListItemText>
  </ListItem>
)
OHItem.propTypes = {
  disabled: PropTypes.bool,
  status: PropTypes.string,
  title: PropTypes.string,
}

export const OfficeHours = ({ data }) => {
  if (!data) return <Box />

  const FILTER_IS_REGULAR = item => item.exception !== 1
  const FILTER_IS_HOLIDAY = item => item.exception === 1

  const hasHoliday = data.filter(FILTER_IS_HOLIDAY).length > 0

  return (
    <List>
      <ListSubheader>Öffnungszeiten</ListSubheader>

      {data
        .filter(FILTER_IS_REGULAR)
        .sort(SORT_WEEKDAY)
        .map((item, key) => {
          const { closed, day_of_week } = item
          let { end_time, start_time } = item

          const title = [
            'Montag',
            'Dienstag',
            'Mittwoch',
            'Donnerstag',
            'Freitag',
            'Samstag',
            'Sonntag',
          ][day_of_week - 1]

          if (!start_time) start_time = '00:00:00'
          if (!end_time) end_time = '00:00:00'
          start_time = start_time.slice(0, -3)
          end_time = end_time.slice(0, -3)

          const status =
            closed === 1 ? 'Geschlossen' : `${start_time} - ${end_time}`

          return (
            <OHItem
              disabled={closed === 1}
              key={key}
              status={status}
              title={title}
            />
          )
        })}

      {hasHoliday && <ListSubheader>Feiertage</ListSubheader>}

      {data.filter(FILTER_IS_HOLIDAY).map((item, key) => {
        const { closed, date_exception, name } = item
        let { end_time, start_time } = item

        const dateFormat = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }

        const dateStr = new Date(date_exception).toLocaleString(
          userLocales,
          dateFormat
        )

        const title = `${dateStr} - ${name}`

        if (!start_time) start_time = '00:00:00'
        if (!end_time) end_time = '00:00:00'

        start_time = start_time.slice(0, -3)
        end_time = end_time.slice(0, -3)

        const status =
          closed === 1 ? 'Geschlossen' : `${start_time} - ${end_time}`

        return <OHItem key={key} status={status} title={title} />
      })}
    </List>
  )
}

OfficeHours.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      closed: PropTypes.number,
      date_exception: PropTypes.string,
      day_of_week: PropTypes.number,
      end_time: PropTypes.string,
      entity_id: PropTypes.number,
      exception: PropTypes.number,
      folder_id: PropTypes.number,
      name: PropTypes.string,
      start_time: PropTypes.string,
    })
  ),
}
