import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'

export const useFolderAddress = folderId => {
  const [data, setData] = useState('')

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.city) return

    const city = folderData?.attributes?.city
    const street = folderData?.attributes?.street
    const zip = folderData?.attributes?.zip

    setData(`${street}, ${zip} ${city}`)
  }, [folderPool, folderId])

  return data
}

useFolderAddress.propTypes = {
  folderId: PropTypes.number,
}
