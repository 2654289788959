import {
  CalendarTodayTwoTone,
  InfoTwoTone,
  TroubleshootTwoTone,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { useHistory } from 'react-router-dom'
import { currentMonth, getStorage, sensorUnit, setStorage } from '../../api'
import { ChartTypeSelector, HStack } from '..'

const GranularitySelector = ({
  data = [
    { label: 'Jahr', value: 'yearly' },
    { label: 'Monat', value: 'monthly' },
    { label: 'Tag', value: 'daily' },
  ],
  ...rest
}) => (
  <ToggleButtonGroup {...rest}>
    {data.map((item, key) => (
      <ToggleButton key={key} value={item.value}>
        <CalendarTodayTwoTone sx={{ mr: 1 }} /> {item.label}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)

GranularitySelector.propTypes = {
  data: PropTypes.array,
}

const MediumIcon = ({ ...rest }) => (
  <Avatar>
    <Box alt={''} component={'img'} {...rest} />
  </Avatar>
)

export const ConsumptionChart = ({
  data,
  sensors,
  folderId,
  icon,
  name,
  type,
  PAGE_CONFIG = 'settings_performance',
}) => {
  const theme = useTheme()

  const history = useHistory()

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))

  const noData = 'Keine Werte vorhanden'
  const periods = ['year', 'month', 'day']
  const [anchorEl, setAnchorEl] = useState(null)

  periods.map(period => {
    if (data[period]) {
      if (data[period].length < 2) data[period].push([noData, 0, ''])
    } else {
      // package not booked
      data[period] = []
      data[period].push([currentMonth, sensorUnit(type), ''])
      data[period].push([noData, 0, ''])
    }

    return null
  })

  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  if (!config.chartType)
    setConfig(prev => ({ ...prev, chartType: 'ColumnChart' }))

  if (!config.view) setConfig(prev => ({ ...prev, view: 'daily' }))

  // handler

  const changeView = (event, view) => {
    if (view !== null) setConfig(prev => ({ ...prev, view }))
  }

  const changeChartType = (event, chartType) => {
    if (chartType !== null) setConfig(prev => ({ ...prev, chartType }))
  }

  const gotoDetailPage = () =>
    history.push(`/folder/${folderId}/detail/${type}`)

  return (
    <Card variant={'performance'}>
      <CardHeader
        action={
          <HStack>
            <GranularitySelector
              exclusive
              onChange={changeView}
              sx={{ mx: 1 }}
              value={config.view}
            />

            <ChartTypeSelector
              exclusive
              onChange={changeChartType}
              sx={{ mx: 1 }}
              value={config.chartType}
            />

            <Tooltip title={`Details ${name}`}>
              <IconButton onClick={gotoDetailPage}>
                <TroubleshootTwoTone />
              </IconButton>
            </Tooltip>
          </HStack>
        }
        avatar={<MediumIcon src={icon} />}
        title={name}
        titleTypographyProps={{ variant: 'h6' }}
        variant={'feeling'}
      />

      <CardContent sx={{ background: '#EEE', position: 'relative' }}>
        {[
          {
            data: data['year'],
            format: 'MM/YYYY',
            granularity: 'yearly',
            title: 'Verbrauch in den letzten 12 Monaten',
          },
          {
            data: data['month'],
            format: 'dd.MM.',
            granularity: 'monthly',
            title: 'Verbrauch in den letzten 30 Tagen',
          },
          {
            data: data['day'],
            format: 'HH:mm',
            granularity: 'daily',
            title: 'Verbrauch in den letzten 24 Stunden',
          },
        ].map((item, key) => {
          const correlation = theme.palette.correlation.main

          return (
            <Collapse key={key} in={config.view === item.granularity}>
              <Chart
                chartLanguage={'de_DE'}
                chartType={config.chartType}
                data={item.data}
                height={300}
                loader={<LinearProgress />}
                options={{
                  backgroundColor: '#EEEEEE',
                  chartArea: { height: '80%', width: '85%' },
                  colors: [correlation],
                  curveType: 'function',
                  hAxis: { format: item.format, textStyle: { fontSize: 10 } },
                  legend: 'none',
                  title: item.title,
                  vAxis: {
                    format: `#,###.## ${sensorUnit(type)}`,
                    viewWindow: { min: 0 },
                    0: { baseline: 0 },
                  },
                }}
              />
            </Collapse>
          )
        })}
        <Tooltip title={'Messpunkte in Übersicht'}>
          <IconButton
            onClick={event =>
              setAnchorEl(anchorEl ? null : event.currentTarget)
            }
            sx={{ position: 'absolute', top: 20, right: 20 }}
          >
            <InfoTwoTone />
          </IconButton>
        </Tooltip>
      </CardContent>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setAnchorEl(false)}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box>
          <Typography sx={{ fontWeight: 700, px: 2, py: 1 }}>
            Messpunkte in Übersicht
          </Typography>

          <Divider />

          <List sx={{ p: 1 }}>
            {sensors.map((sensor, key) => (
              <ListItem key={key}>
                <ListItemText sx={{ fontWeight: 10 }} primary={sensor} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </Card>
  )
}

ConsumptionChart.propTypes = {
  data: PropTypes.array,
  sensors: PropTypes.array,
  folderId: PropTypes.number,
  icon: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  PAGE_CONFIG: PropTypes.string,
}
