import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { consumptionActions } from '../../actions'
import { colorSchemeConsumption } from '../../api/colorSchemes'

export const useCompareConsumptionByFolderId = (
  folderId,
  period,
  fromDate,
  toDate
) => {
  const dispatch = useDispatch()
  const { compareConsumption } = useSelector(state => state.consumption)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (
      Number(folderId) > 0 &&
      fromDate !== undefined &&
      toDate !== undefined
    ) {
      let newPeriod = 'h'
      switch (period) {
        case 'y':
          newPeriod = 'm'
          break
        case 'm':
          newPeriod = 'd'
          break
        case 'd':
          newPeriod = 'h'
          break
        default:
          newPeriod = 'h'
      }
      dispatch(
        consumptionActions.getCompareConsumption(
          folderId,
          newPeriod,
          fromDate,
          toDate
        )
      )
      setLoading(true)
    }
  }, [dispatch, folderId, fromDate, toDate, period])

  useEffect(() => {
    if (compareConsumption) {
      const { error, item } = compareConsumption
      if (item) {
        const { data, factorError } = item
        if (factorError) {
          setLoading(false)
        }
        if (!data) return
        setData(
          data.map((item, key) => ({
            ...item.attributes,
            entity_id: Number(item.id),
            consumption: item.attributes.consumptions,
            chartColor:
              colorSchemeConsumption[key % colorSchemeConsumption.length],
          }))
        )
        setLoading(false)
      }

      if (error) {
        setError(error)
        setLoading(false)
      }
    }
  }, [compareConsumption])

  return { data, isError, isLoading }
}
