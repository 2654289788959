import {
  AddCircleTwoTone,
  ExpandMoreTwoTone,
  FilterListTwoTone,
  RemoveCircleTwoTone,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Collapse,
  IconButton,
  List,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { FolderListItem, HStack } from '../../../components'
import { useFolderPool } from '../../../hooks'

export const UserFolderAssignments = ({ config, setConfig, user, setUser }) => {
  const theme = useTheme()
  const color = theme.palette.primary.main

  const { data: folderPool, isLoading: isFolderPoolLoading } = useFolderPool()

  if (!user || !config || !folderPool) return null

  const { assignedFoldersOnly, assignmentSection } = config
  const { folders } = user

  // filter

  const FILTER_ASSIGNED_FOLDERS_ONLY = f =>
    assignedFoldersOnly ? folders.find(f2 => f2.entity_id === Number(f.id)) : f

  // handler

  const handleRemoveAll = () => setUser(prev => ({ ...prev, folders: [] }))

  const handleAssignAll = () => {
    const folders = folderPool.map(folder => folder.attributes)

    setUser(prev => ({ ...prev, folders }))
  }

  const handleToggleAssigned = () => {
    setConfig(prev => ({
      ...prev,
      assignedFoldersOnly: !prev.assignedFoldersOnly,
    }))
  }

  return (
    <Collapse in={!isFolderPoolLoading} sx={{ mb: 2 }}>
      <Accordion
        expanded={assignmentSection === 0}
        onChange={() => setConfig(prev => ({ ...prev, assignmentSection: 0 }))}
      >
        <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
          <Typography>
            Gebäude {`(${folders.length}/${folderPool.length})`}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <HStack justifyContent={'space-between'}>
            <HStack>
              <ButtonGroup>
                <Tooltip title={'Alle Gebäude entziehen'}>
                  <IconButton onClick={handleRemoveAll}>
                    <RemoveCircleTwoTone />
                  </IconButton>
                </Tooltip>

                <Tooltip title={'Alle Gebäude zuweisen'}>
                  <IconButton onClick={handleAssignAll}>
                    <AddCircleTwoTone />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            </HStack>

            <HStack>
              <Tooltip
                title={`Nur zugewiesene Gebäude: ${
                  assignedFoldersOnly ? '' : 'in'
                }aktiv`}
              >
                <IconButton
                  sx={{ bgcolor: assignedFoldersOnly ? color : 'none' }}
                  onClick={handleToggleAssigned}
                >
                  <FilterListTwoTone />
                </IconButton>
              </Tooltip>
            </HStack>
          </HStack>

          <List
            sx={{
              cursor: 'pointer',
              maxHeight: 400,
              overflow: 'auto',
              overflowX: 'hidden',
              width: '100%',
            }}
          >
            {folderPool
              .filter(FILTER_ASSIGNED_FOLDERS_ONLY)
              .map((folder, key) => {
                const { attributes, id } = folder
                const selected = Boolean(
                  folders.find(f => f.entity_id === Number(id))
                )
                const onClick = () =>
                  setUser(prev => ({
                    ...prev,
                    folders: !selected
                      ? [...folders, attributes]
                      : folders.filter(f => f.entity_id !== Number(id)),
                  }))

                return (
                  <FolderListItem
                    folderId={Number(id)}
                    key={key}
                    onClick={onClick}
                    selected={selected}
                  />
                )
              })}
          </List>
        </AccordionDetails>
      </Accordion>
    </Collapse>
  )
}

UserFolderAssignments.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  config: PropTypes.object,
  setConfig: PropTypes.func,
}
