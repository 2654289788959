import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'

export const useFolderSensorIds = folderId => {
  const [data, setData] = useState([])

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.folder_sensors) return

    const fsData = folderData.attributes.folder_sensors
    const folderSensors = fsData.map(f => f.sensor_id).sort((a, b) => a - b)

    if (folderData) setData(folderSensors)
  }, [folderPool, folderId])

  return data
}

useFolderSensorIds.propTypes = {
  folderId: PropTypes.number,
}
