import {
  AppBar,
  Box,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useTheme,
} from '@mui/material'
import React, { useEffect } from 'react'
import { NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { CopyrightNotice } from '../components'
import { LoginPage, UserRegister } from '../pages'
import bgImage from '../assets/img/background.jpg'

const routes = [
  {
    component: UserRegister,
    hidden: true,
    path: '/pages/register-page/:status',
  },
  {
    component: UserRegister,
    name: 'Registrieren',
    path: '/pages/register-page',
  },
  { component: LoginPage, name: 'Anmeldung', path: '/pages/login-page' },
  { path: '/pages', pathTo: '/pages/register-page', redirect: true },
]

export const Page = () => {
  const location = useLocation()
  const theme = useTheme()
  const { white: color } = theme.palette

  const isActive = route => location.pathname.indexOf(route) > -1

  useEffect(() => {
    document.body.style.overflow = 'unset'
  }, [])

  return (
    <>
      <AppBar sx={{ backgroundColor: 'transparent' }}>
        <Toolbar>
          <List sx={{ display: 'flex', flexDirection: 'row' }}>
            {routes
              .filter(f => f.hidden !== true)
              .map((route, key) => {
                const { name, path, redirect } = route
                const backgroundColor = isActive(path)
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'none'

                return redirect ? null : (
                  <NavLink key={key} to={path}>
                    <ListItem
                      sx={{
                        backgroundColor,
                        color,
                        textTransform: 'uppercase',
                      }}
                    >
                      <ListItemText disableTypography={true} primary={name} />
                    </ListItem>
                  </NavLink>
                )
              })}
          </List>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          display: 'flex!important',
          minHeight: '100vh',
        }}
      >
        <Switch>
          {routes.map((item, key) => {
            const { component, path, pathTo, redirect } = item

            if (redirect) return <Redirect from={path} to={pathTo} key={key} />

            return <Route path={path} component={component} key={key} />
          })}
        </Switch>

        <CopyrightNotice
          sx={{
            bottom: 0,
            color,
            position: 'absolute',
            textAlign: 'center',
            width: '100%',
          }}
        />
      </Box>
    </>
  )
}
