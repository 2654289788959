import {
  Avatar,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SensorIcon } from '../../api'
import {
  useOperator,
  useSensorName,
  useSensorStatus,
  useTypeInFolder,
} from '../../hooks'

export const CorrelationConsumptionItem = ({
  color = 'primary',
  selected,
  sensorId,
  ...rest
}) => {
  const { data: operatorData } = useOperator()
  const sensorName = useSensorName(sensorId)
  const sensorStatus = useSensorStatus(sensorId)
  const typeInFolder = useTypeInFolder(sensorId)

  const src = SensorIcon(typeInFolder, sensorStatus)
  const title = operatorData?.isAdmin ? `Sensor ID: ${sensorId}` : ''

  return (
    <ListItem sx={{ p: 0, width: 1 }}>
      <ListItemButton selected={selected} {...rest}>
        <ListItemAvatar>
          <Tooltip title={title}>
            <Avatar src={src} />
          </Tooltip>
        </ListItemAvatar>

        <ListItemText primary={sensorName} />

        <Checkbox color={color} checked={selected} />
      </ListItemButton>
    </ListItem>
  )
}

CorrelationConsumptionItem.propTypes = {
  color: PropTypes.string,
  selected: PropTypes.bool,
  sensorId: PropTypes.number,
}
