import { newsConstants as NC } from '../constants'

const initial = {
  allAlerts: { loading: true },
  allNews: { loading: true },
  newNews: { loading: true },
  newsTypes: { loading: true },
  oneNews: { loading: true },
}

export const news = (state = initial, action) => {
  const { alerts, error, news, newsTypes, type } = action

  switch (type) {
    case NC.NEWS_DELETE_ERROR:
      return { ...state, newNews: { error, loading: true } }

    case NC.NEWS_DELETE_REQUEST:
      return { ...state, newNews: { loading: true } }

    case NC.NEWS_DELETE_SUCCESS:
      return { ...state, newNews: { loading: true } }

    case NC.NEWS_GET_ALL_ERROR:
      return {
        ...state,
        allNews: {
          error,
          fetchDate: Date.now(),
          loading: false,
        },
      }

    case NC.NEWS_GET_ALL_REQUEST:
      return {
        ...state,
        allNews: {
          loading: true,
        },
      }

    case NC.NEWS_GET_ALL_SUCCESS:
      return {
        ...state,
        allNews: {
          fetchDate: Date.now(),
          items: news,
          loading: false,
        },
      }

    case NC.NEWS_GET_ALL_ALERTS_ERROR:
      return { ...state, allAlerts: { error, loading: false } }

    case NC.NEWS_GET_ALL_ALERTS_REQUEST:
      return { ...state, allAlerts: { loading: true } }

    case NC.NEWS_GET_ALL_ALERTS_SUCCESS:
      return { ...state, allAlerts: { items: alerts, loading: false } }

    case NC.NEWS_GET_ALL_TYPES_ERROR:
      return { ...state, newsTypes: { error, loading: true } }

    case NC.NEWS_GET_ALL_TYPES_REQUEST:
      return { ...state, newsTypes: { loading: true } }

    case NC.NEWS_GET_ALL_TYPES_SUCCESS:
      return { ...state, newsTypes: { item: newsTypes, loading: false } }

    case NC.NEWS_GET_BY_ID_ERROR:
      return { ...state, oneNews: { error, loading: true } }

    case NC.NEWS_GET_BY_ID_REQUEST:
      return { ...state, oneNews: { loading: true } }

    case NC.NEWS_GET_BY_ID_SUCCESS:
      return { ...state, oneNews: { item: news, loading: false } }

    case NC.NEWS_SAVE_ERROR:
      return { ...state, newNews: { error, loading: true } }

    case NC.NEWS_SAVE_REQUEST:
      return { ...state, newNews: { loading: true } }

    case NC.NEWS_SAVE_SUCCESS:
      return { ...state, newNews: { item: news, loading: false } }

    case NC.NEWS_UPDATE_ERROR:
      return { ...state, newNews: { error, loading: true } }

    case NC.NEWS_UPDATE_REQUEST:
      return { ...state, newNews: { loading: true } }

    case NC.NEWS_UPDATE_SUCCESS:
      return { ...state, newNews: { loading: true } }

    default:
      return state
  }
}
