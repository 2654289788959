import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolder } from '..'
import { categoryByType } from '../../api'

export const useConsumptionSensors = folderId => {
  const [data, setData] = useState([])

  const { data: folderData } = useFolder(folderId)

  useEffect(() => {
    if (!folderId || !folderData?.sensors) return

    const FILTER_CONSUMPTION = f =>
      f.sensor_target === 'consumption' ||
      f.sensor_target === 'performance' ||
      categoryByType(f.sensor_type, f.type_in_folder) === 'consumption' ||
      categoryByType(f.sensor_type, f.type_in_folder) === 'performance'

    setData(folderData?.sensors?.filter(FILTER_CONSUMPTION))
  }, [folderData, folderId])

  return data
}

useConsumptionSensors.propTypes = {
  folderId: PropTypes.number,
}
