import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolder } from '..'

export const useFolderContract = folderId => {
  const [data, setData] = useState(null)

  const { data: folderData } = useFolder(folderId)

  useEffect(() => {
    if (!folderId || !folderData?.contract) return

    setData(folderData?.contract)
  }, [folderData, folderId])

  return data
}

useFolderContract.propTypes = {
  folderId: PropTypes.number,
}
