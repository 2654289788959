import { history } from '../api'
import { sensorBenchmarkConstants as SBC } from '../constants'
import { sensorBenchmarkService as SBS, tokenService as TS } from '../services'
import { alertActions as AA } from '.'
const benchmarkData = data => {
  const {
    entity_id,
    sensor_id,
    benchmark_name,
    value,
    unit,
    period_start,
    period_end,
  } = data
  return {
    data: {
      id: entity_id,
      type: 'benchmark',
      attributes: {
        sensor_id: parseFloat(sensor_id),
        period_start: period_start,
        period_end: period_end,
        benchmark_name: benchmark_name,
        value: parseFloat(value),
        unit: unit,
      },
    },
  }
}
const getById = (id, includes = []) => {
  const failure = error => ({ type: SBC.GET_BY_ID_ERROR, error })
  const request = benchmark => ({ type: SBC.GET_BY_ID_REQUEST, benchmark })
  const success = benchmark => ({ type: SBC.GET_BY_ID_SUCCESS, benchmark })
  return dispatch => {
    dispatch(request({ id }))
    TS.refreshToken().then(() =>
      SBS.getById(id, includes).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message
          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}
const remove = id => {
  const failure = error => ({ type: SBC.REMOVE_BENCHMARK_ERROR, error })
  const request = () => ({ type: SBC.REMOVE_BENCHMARK_REQUEST })
  const success = benchmark => ({
    type: SBC.REMOVE_BENCHMARK_SUCCESS,
    benchmark,
  })
  return dispatch => {
    if (!id) return dispatch(AA.error(SBC.BENCHMARK_ID_REQUIRED))
    dispatch(request())
    SBS.remove(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SBC.BENCHMARK_DELETED))
        history.go('/sensors')
      })
      .catch(error => {
        const { message } = error
        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}
const save = data => {
  const failure = error => ({ type: SBC.SAVE_BENCHMARK_ERROR, error })
  const request = () => ({ type: SBC.SAVE_BENCHMARK_REQUEST })
  const success = benchmark => ({ type: SBC.SAVE_BENCHMARK_SUCCESS, benchmark })
  const { benchmark_name } = data
  return dispatch => {
    if (!benchmark_name) return dispatch(AA.error(SBC.BENCHMARK_NAME_REQUIRED))
    dispatch(request())
    SBS.save(benchmarkData(data))
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SBC.BENCHMARK_SAVED))
        history.go('/sensors')
      })
      .catch(error => {
        const { message } = error
        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}
const update = (data, id) => {
  const failure = error => ({ type: SBC.UPDATE_BENCHMARK_ERROR, error })
  const request = () => ({ type: SBC.UPDATE_BENCHMARK_REQUEST })
  const success = benchmark => ({
    type: SBC.UPDATE_BENCHMARK_SUCCESS,
    benchmark,
  })
  return dispatch => {
    dispatch(request())
    SBS.update(benchmarkData(data), id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SBC.BENCHMARK_UPDATED))
        history.go('/sensors')
      })
      .catch(error => {
        const { message } = error
        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}
const getAll = id => {
  const failure = error => ({
    type: SBC.GET_BENCHMARKS_BY_SENSOR_ID_ERROR,
    error,
  })
  const request = benchmark => ({
    type: SBC.GET_BENCHMARKS_BY_SENSOR_ID_REQUEST,
    benchmark,
  })
  const success = benchmark => ({
    type: SBC.GET_BENCHMARKS_BY_SENSOR_ID_SUCCESS,
    benchmark,
  })
  return dispatch => {
    dispatch(request({ id }))
    TS.refreshToken().then(() =>
      SBS.getAll(id).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message
          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}
export const sensorBenchmarkActions = {
  getById,
  remove,
  save,
  update,
  getAll,
}
