import {
  Alert,
  Avatar,
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AccountBalanceTwoTone,
  AddTwoTone,
  AppsTwoTone,
  EditTwoTone,
  HighlightOffTwoTone,
  InfoTwoTone,
  ListTwoTone as ListIconTwoTone,
  MapTwoTone,
  MoreVertTwoTone,
  SearchTwoTone,
  VerifiedUserTwoTone,
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ASC, DESC, getStorage, isFilterMatch, setStorage } from '../../api'
import {
  AM,
  AMItem,
  DlgFolderMap,
  DlgFolderPoolInfo,
  DlgIntegrityCheck,
  FolderList,
  FolderSortSelector,
  HStack,
  KPage,
  KTextField,
  Preloader,
} from '../../components'
import { folderConstants as FC } from '../../constants'
import { useFolderPool } from '../../hooks'

export const FolderAll = ({ PAGE_CONFIG = 'settings_folders_all' }) => {
  const history = useHistory()
  const { data, isLoading, isError } = useFolderPool()

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const [actionMenu, setActionMenu] = useState(null)

  const inputFilter = folder =>
    config.filter
      ? isFilterMatch(config.filter, folder.attributes.name) ||
        isFilterMatch(config.filter, folder.attributes.street) ||
        isFilterMatch(config.filter, folder.attributes.zip) ||
        isFilterMatch(config.filter, folder.attributes.city) ||
        Number(config.filter) === Number(folder.id)
      : folder

  const typeFilter = f =>
    config.type === FC.TYPE_DEFAULT
      ? f.attributes.parent_id === null
      : config.type === FC.TYPE_SUB
        ? f.attributes.parent_id !== null
        : f

  const sortFilter = (a, b) => {
    const { order, orderby } = config

    if (
      orderby === 'name' &&
      a.attributes[orderby].toLowerCase() < b.attributes[orderby].toLowerCase()
    )
      return order === DESC ? 1 : -1

    if (orderby === 'entity_id' && Number(a.id) < Number(b.id))
      return order === DESC ? 1 : -1

    return order === DESC ? -1 : 1
  }

  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  const actionButton = (
    <IconButton onClick={event => setActionMenu(event.currentTarget)}>
      <MoreVertTwoTone />
    </IconButton>
  )

  const avatar = (
    <Avatar>
      <AccountBalanceTwoTone />
    </Avatar>
  )

  const changeOrderByFilter = (event, orderby) => {
    const { order } = config
    if (orderby !== null) setConfig(prev => ({ ...prev, orderby }))
    else {
      const newOrder = order === DESC ? ASC : DESC
      setConfig(prev => ({ ...prev, order: newOrder }))
    }
  }

  return (
    <>
      {/* preloader */}
      <Preloader error={isError} isLoading={isLoading} />
      {/* preloader end */}

      {/* map */}
      <DlgFolderMap
        onClose={() => setConfig(prev => ({ ...prev, map: false }))}
        open={config.map || false}
      />
      {/* map end */}

      {/* integrity check */}
      <DlgIntegrityCheck
        onClose={() => setConfig(prev => ({ ...prev, integrityCheck: false }))}
        open={config.integrityCheck || false}
      />
      {/* integrity check end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Gebäude'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'dashboard'}
      >
        <AMItem
          caption={'Neu'}
          icon={<AddTwoTone />}
          onClick={() => history.push('/folder/new')}
        />

        <AMItem
          caption={'Info'}
          icon={<InfoTwoTone />}
          onClick={() => setConfig(prev => ({ ...prev, showStats: true }))}
        />
      </AM>
      {/* action menu end */}

      {/* pool stat */}
      <DlgFolderPoolInfo
        open={config.showStats || false}
        onClose={() => setConfig(prev => ({ ...prev, showStats: false }))}
      />
      {/* pool stat end */}

      {!isLoading && (
        <KPage
          action={actionButton}
          avatar={avatar}
          isLoading={isLoading}
          title={`Gebäude`}
        >
          <HStack justifyContent={'space-between'} sx={{ mx: 1, mb: 1 }}>
            <HStack>
              <Stack
                alignItems={'center'}
                direction={{ lg: 'column', xl: 'row' }}
                spacing={1}
              >
                <Typography>Typ</Typography>
                <ToggleButtonGroup
                  exclusive
                  onChange={(event, type) => {
                    if (type !== null) setConfig(prev => ({ ...prev, type }))
                  }}
                  value={config.type}
                >
                  {[
                    { label: 'Alle', value: FC.TYPE_ANY },
                    { label: FC.TYPE_DEFAULT, value: FC.TYPE_DEFAULT },
                    { label: FC.TYPE_SUB, value: FC.TYPE_SUB },
                  ].map((item, key) => (
                    <ToggleButton key={key} value={item.value}>
                      {item.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>

              <Stack
                alignItems={'center'}
                direction={{ lg: 'column', xl: 'row' }}
                spacing={1}
              >
                <Typography>Ansicht</Typography>
                <ToggleButtonGroup
                  exclusive
                  onChange={(event, itemView) => {
                    if (itemView !== null)
                      setConfig(prev => ({ ...prev, itemView }))
                  }}
                  value={config.itemView}
                >
                  {[
                    { icon: <ListIconTwoTone />, value: 'list' },
                    { icon: <AppsTwoTone />, value: 'tile' },
                  ].map((item, key) => (
                    <ToggleButton key={key} value={item.value}>
                      {item.icon}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>

              <Stack
                alignItems={'center'}
                direction={{ lg: 'column', xl: 'row' }}
                spacing={1}
              >
                <Typography>Tools</Typography>
                <ToggleButtonGroup
                  onChange={(event, target) => {
                    if (target[0] === 'map')
                      setConfig(prev => ({ ...prev, map: true }))
                    if (target[0] === 'integrity')
                      setConfig(prev => ({ ...prev, integrityCheck: true }))
                  }}
                >
                  <Tooltip title={'Kartenübersicht Standorte'}>
                    <ToggleButton value={'map'}>
                      <MapTwoTone />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title={'Integritätsprüfung'}>
                    <ToggleButton value={'integrity'}>
                      <VerifiedUserTwoTone />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Stack>
            </HStack>

            <FolderSortSelector
              onChange={changeOrderByFilter}
              order={config.order}
              orderby={config.orderby}
            />
          </HStack>

          {/* filter */}
          <KTextField
            icon={<SearchTwoTone />}
            onChange={event => {
              const filter = event.target.value

              setConfig(prev => ({ ...prev, filter }))
            }}
            unit={
              config.filter && (
                <Tooltip title={'Filter löschen'}>
                  <IconButton
                    onClick={() => setConfig(prev => ({ ...prev, filter: '' }))}
                  >
                    <HighlightOffTwoTone />
                  </IconButton>
                </Tooltip>
              )
            }
            value={config.filter}
          />

          {/* hit hint */}
          <Alert severity={'info'} sx={{ mx: 1 }}>
            <HStack>
              <Typography>
                <strong>
                  {data.filter(inputFilter).filter(typeFilter).length}
                </strong>{' '}
                Gebäude entsprechen dem Suchfilter{' '}
                <strong>
                  &quot;
                  {config.filter}
                  &quot;
                </strong>
              </Typography>
            </HStack>
          </Alert>

          {/* list view */}
          {config.itemView === 'list' && (
            <List
              sx={{
                m: 1,
                maxHeight: 200,
                minHeight: 'calc(100vh - 560px)',
                overflow: 'auto',
              }}
            >
              {data
                .filter(inputFilter)
                .filter(typeFilter)
                .sort(sortFilter)
                .map((folder, key) => (
                  <FolderList folderId={Number(folder.id)} key={key} />
                ))}
            </List>
          )}

          {/* tile view */}
          {config.itemView === 'tile' && (
            <ImageList
              sx={{
                m: 1,
                maxHeight: 200,
                minHeight: 'calc(100vh - 560px)',
                overflow: 'auto',
              }}
              cols={6}
            >
              {data.filter(inputFilter).map((item, key) => {
                const { image_url, name, name_short } = item.attributes

                return (
                  <ImageListItem cols={2} key={key} sx={{ cursor: 'pointer' }}>
                    <Box
                      alt={''}
                      component={'img'}
                      onClick={() => history.push(`/folder/${item.id}`)}
                      src={image_url}
                    />

                    <ImageListItemBar
                      actionIcon={
                        <IconButton
                          onClick={() =>
                            history.push(`/folder/${item.id}/edit`)
                          }
                        >
                          <EditTwoTone />
                        </IconButton>
                      }
                      subtitle={name_short}
                      title={name}
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>
          )}
        </KPage>
      )}
    </>
  )
}

FolderAll.propTypes = {
  PAGE_CONFIG: PropTypes.string,
}
