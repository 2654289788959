import { Dialog, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const DlgInstallationExport = ({
  open,
  onClose,
  title = 'Daten exportieren',
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
  </Dialog>
)

DlgInstallationExport.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
}
