import { MemoryTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import matchSorter from 'match-sorter'
import Papa from 'papaparse'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useFilters, usePagination, useSortBy, useTable } from 'react-table'
import { sensorActions } from '../../actions'
import { getStorage } from '../../api'
import { HStack, KPage } from '../../components'

const columns = [
  {
    accessor: 'entity_id',
    filterAll: true,
    Header: 'Entity ID',
    sortMethod: (a, b) => Number(a) - Number(b),
  },
  {
    accessor: 'sensor_id',
    filterAll: true,
    Header: 'Sensor ID',
  },
  {
    accessor: 'value',
    filterAll: true,
    Header: 'Wert',
  },
  {
    accessor: 'time',
    filterAll: true,
    Header: 'Time',
  },
  {
    accessor: 'timestamp',
    filterAll: true,
    Header: 'Timestamp',
  },
  {
    accessor: 'timestamp_sensor',
    filterAll: true,
    Header: 'Timestamp Sensor',
  },
]

export const ImportSensorData = ({ PAGE_CONFIG = 'settings_sensors_all' }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const [importData, setImportData] = useState([])
  const [formatedData, setFormatedData] = useState([])

  const [config] = useState(getStorage(PAGE_CONFIG))
  const [rows] = useState(Number(config.maxResults))

  const onClickTableRow = (state, rowInfo) => {
    if (rowInfo) {
      const { original } = rowInfo
      const { id } = original

      return {
        onClick: () => history.push(`/virtualsensors/${id}/edit`),
        style: { cursor: 'pointer' },
      }
    }
  }

  const updateData = result => {
    const data = result.data
    data.pop()
    setImportData(data)
    setFormatedData(data)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
  } = useTable(
    {
      columns: useMemo(() => columns, []),
      data: useMemo(() => formatedData, [formatedData]),
      defaultColumn: { Filter: () => null },
      filterTypes: useMemo(
        () => ({
          text: (rows, id, filterValue) =>
            matchSorter(rows, filterValue, { keys: [id] }),
        }),
        []
      ),
      initialState: {
        sortBy: [{ id: config.orderby, desc: config.order !== 'ASC' }],
        pageSize: rows,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  )

  return (
    <KPage
      avatar={
        <Avatar>
          <MemoryTwoTone />
        </Avatar>
      }
      title={`Sensor ID: ${id} Import`}
    >
      <Button component={'label'} sx={{ mr: 2 }}>
        Datei auswählen
        <input
          hidden
          onChange={event => setImportData({ csvfile: event.target.files[0] })}
          placeholder={null}
          type={'file'}
        />
      </Button>

      <Button
        onClick={event => {
          event.preventDefault()
          const { csvfile } = importData

          csvfile &&
            Papa.parse(csvfile, {
              complete: updateData,
              dynamicTyping: true,
              header: true,
            })
        }}
        sx={{ mr: 2 }}
      >
        Hochladen
      </Button>

      <Button
        onClick={() => dispatch(sensorActions.importData(formatedData, id))}
        sx={{ mr: 2 }}
      >
        Importieren
      </Button>

      <>
        <TableContainer>
          <Table sx={{ width: '100%' }} {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, key) => (
                <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, innerKey) => (
                    <TableCell
                      key={innerKey}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* Sorting indicator */}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {page.map((row, key) => {
                prepareRow(row)
                return (
                  <TableRow
                    key={key}
                    {...row.getRowProps()}
                    onClick={() => onClickTableRow(row)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell, innerKey) => (
                      <TableCell key={innerKey} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider sx={{ my: 2 }} />

        {/* Pagination Controls */}
        <HStack>
          <Button disabled={pageIndex === 0} onClick={() => previousPage()}>
            Zurück
          </Button>
          <Button
            disabled={pageIndex >= pageCount - 1}
            onClick={() => nextPage()}
          >
            Weiter
          </Button>

          <TablePagination
            component={'div'}
            count={pageCount}
            onPageChange={(event, newPage) => gotoPage(newPage)}
            onRowsPerPageChange={event =>
              setPageSize(Number(event.target.value))
            }
            page={pageIndex}
            rowsPerPage={Number(pageSize)}
            rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
          />
        </HStack>
      </>
    </KPage>
  )
}

ImportSensorData.propTypes = {
  PAGE_CONFIG: PropTypes.string,
}
