import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contractActions } from '../../actions'

const contractContext = createContext({})

export const ProvideContractPool = ({ children }) => {
  const value = useContracts()

  return (
    <contractContext.Provider value={value}>
      {children}
    </contractContext.Provider>
  )
}
ProvideContractPool.propTypes = { children: PropTypes.any }

export const useContractPool = () => useContext(contractContext)

const useContracts = () => {
  const dispatch = useDispatch()
  const { allContracts } = useSelector(state => state.contract)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(() => {
    dispatch(contractActions.getAll())
    setLoading(true)
  }, [dispatch])

  useEffect(() => {
    if (allContracts) {
      const { error, fetchDate, items } = allContracts

      if (items) {
        const { data } = items

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    }
  }, [allContracts])

  return { data, isError, isLoading, fetchDate }
}
