import { createTheme, darken, lighten } from '@mui/material/styles'

export const theme = createTheme({
  spacing: 10,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiAccordion: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        root: {
          border: 'solid 1px #DDD',
          '&:before': { backgroundColor: '#707070' },
          '&:after': { backgroundColor: '#707070' },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          // borderRadius: ({ shape }) => shape.borderRadius,
          borderRadius: 3,
          border: '1px solid',
        },
        standardSuccess: {
          borderColor: theme => theme.palette.success.main,
        },
        standardWarning: {
          borderColor: theme => theme.palette.warning.main,
        },
        standardError: {
          borderColor: theme => theme.palette.error.main,
        },
        standardInfo: {
          borderColor: theme => theme.palette.info.main,
        },
      },
    },

    MuiButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: { root: { borderRadius: 0 } },
    },

    MuiCard: {
      defaultProps: { elevation: 0 },
      styleOverrides: {},
      variants: [
        {
          props: { variant: 'performance' },
          style: ({ theme }) => ({
            backgroundColor: '#EEEEEE',
            border: 'solid 1px #DDD',
            //minHeight: '100%',
            marginBottom: theme.spacing(5),
          }),
        },
        {
          props: { variant: 'feeling' },
          style: () => ({
            backgroundColor: '#EEEEEE',
            border: 'solid 1px #DDD',
            minHeight: '100%',
          }),
        },
        {
          props: { variant: 'security' },
          style: () => ({
            backgroundColor: '#EEEEEE',
            border: 'solid 1px #DDD',
            minHeight: '100%',
          }),
        },
        {
          props: { variant: 'installation' },
          style: ({ theme }) => ({
            backgroundColor: '#EEEEEE',
            border: 'solid 1px #DDD',
            minHeight: '100%',
            marginBottom: theme.spacing(5),
          }),
        },
        {
          props: { variant: 'correlation' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            border: 'solid 1px #DDD',
            minHeight: '100%',
            marginBottom: theme.spacing(2),
          }),
        },
        {
          props: { variant: 'office_hours' },
          style: ({ theme }) => ({
            backgroundColor: '#D3D3D3',
            border: 'solid 1px #DDD',
            minHeight: '100%',
            marginBottom: theme.spacing(2),
          }),
        },
      ],
    },

    MuiCardHeader: {
      variants: [
        {
          props: { variant: 'dialog' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white,
          }),
        },
        {
          props: { variant: 'performance' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'feeling' },
          style: ({ theme }) => ({
            backgroundColor: '#D3D3D3',
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'security' },
          style: ({ theme }) => ({
            backgroundColor: '#D3D3D3',
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'correlation' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'installation_overview' },
          style: ({ theme }) => ({
            backgroundColor: '#D3D3D3',
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'installation' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'karma' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.color,
          }),
        },
      ],
    },

    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
        body: {
          backgroundColor: '#EEEEEE',
          color: '#3C4858',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontSize: '14px',
          fontWeight: 300,
          margin: 0,
          overflow: 'auto',
        },
        a: {
          color: '#9c27b0',
          textDecoration: 'none',
        },
        img: {
          border: 0,
          verticalAlign: 'middle',
        },
        'input[type="file"]': {
          display: 'none',
        },
        code: {
          backgroundColor: 'lightgray',
          padding: '3px',
          borderRadius: '5px',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.white,

          '& .MuiButton-root': {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 3,
          },

          '& .MuiButton-root .MuiSvgIcon-root': {
            marginRight: theme.spacing(1),
          },
        }),
      },
      variants: [
        {
          props: { variant: 'preloader' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
          }),
        },
      ],
    },

    MuiDialogContent: {
      variants: [
        {
          props: { variant: 'profilePicture' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.black,
            padding: 0,
          }),
        },
        {
          props: { variant: 'inline' },
          style: () => ({ margin: 1, padding: 1 }),
        },
      ],
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.white,
        }),
      },
      variants: [
        {
          props: { variant: 'preloader' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
          }),
        },
      ],
    },

    MuiDrawer: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiDrawer-paper': {
            background: `linear-gradient(45deg, #0E28BB 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
            bottom: 0,
            left: 0,
            overflow: 'hidden',
            position: 'fixed',
            top: 0,
            height: '100vh',
            width: 260,
          },
        }),
      },
    },

    MuiIconButton: {
      variants: [
        {
          props: { variant: 'close' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.white,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.error.main,
              color: theme.palette.white,
            },
          }),
        },
      ],
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          /*
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.16)',
            },
          },
          */
        },
      },
    },

    MuiPaper: {
      defaultProps: { elevation: 5 },
    },

    MuiSelect: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: { root: { margin: 10 } },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid #DDD',
          borderRadius: '8px', // entspricht 2, da 1 unit = 8px
        },
      },
    },

    MuiTextField: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: { root: { margin: 10 } },
    },
  },

  palette: {
    // dark mode
    mode: 'light',

    // CI: KARMA Purpur
    primary: {
      main: '#6B00BD',
      light: lighten('#6B00BD', 0.2),
      dark: darken('#6B00BD', 0.2),
    },

    // CI: KARMA Fuchsia
    secondary: {
      main: '#DA16F9',
      light: lighten('#DA16F9', 0.2),
      dark: darken('#DA16F9', 0.2),
    },

    success: {
      main: '#9ECF67',
      light: lighten('#9ECF67', 0.2),
      dark: darken('#9ECF67', 0.2),
    },

    warning: {
      main: '#F8E71C',
      light: lighten('#F8E71C', 0.2),
      dark: darken('#F8E71C', 0.2),
    },

    error: {
      main: '#D0021B',
      light: lighten('#D0021B', 0.2),
      dark: darken('#D0021B', 0.2),
    },

    info: {
      main: '#8F8F8F',
      light: lighten('#8F8F8F', 0.2),
      dark: darken('#8F8F8F', 0.2),
    },

    correlation: {
      main: '#00AEF6',
      light: lighten('#00AEF6', 0.2),
      dark: darken('#00AEF6', 0.2),
    },

    lightgray: { main: '#DDDDDE', light: '#EEEEEE', dark: '#CBCBCC' },

    black: '#000',
    white: '#FFF',
  },

  props: {
    MuiButton: { color: 'primary' },

    MuiCheckbox: { color: 'primary' },

    MuiRadio: { color: 'primary' },

    MuiSelect: { color: 'primary' },

    MuiSwitch: { color: 'primary' },

    MuiTextField: { color: 'primary' },
  },

  /*
  shape: {
    borderRadius: 10,
  },
  */

  typography: {
    button: { textTransform: 'uppercase' },
  },
})
