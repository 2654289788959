import { combineReducers } from 'redux'

import { alert } from './alerts.reducer'
import { authentication } from './authentication.reducer'
import { config } from './config.reducer'
import { consumption } from './consumption.reducer'
import { contract } from './contracts.reducer'
import { dashboard } from './dashboards.reducer'
import { notifications } from './notifications.reducer'
import { folder } from './folders.reducer'
import { missingData } from './missing_data.reducer'
import { news } from './news.reducer'
import { registration } from './registration.reducer'
import { report } from './reports.reducer'
import { sensor } from './sensors.reducer'
import { sensorBenchmark } from './sensorBenchmark.reducer'
import { sensorFactor } from './sensorFactor.reducer'
import { sensorPrice } from './sensorPrice.reducer'
import { system } from './system.reducer'
import { user } from './users.reducer'
import { virtualSensor } from './virtualSensor.reducer'

export const rootReducer = combineReducers({
  alert,
  authentication,
  consumption,
  config,
  contract,
  dashboard,
  notifications,
  folder,
  missingData,
  news,
  registration,
  report,
  sensor,
  sensorBenchmark,
  sensorFactor,
  sensorPrice,
  system,
  user,
  virtualSensor,
})
