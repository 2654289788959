import { CancelTwoTone, SaveTwoTone } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDashboardPool } from '../../hooks'

export const DlgDashboardCreate = ({
  initialTitle = 'Neues Dashboard',
  onCancel,
  onConfirm,
  ...rest
}) => {
  const { data: dashboardPool } = useDashboardPool()

  const [title, setTitle] = useState('')

  // handle multiple instances of same title
  useEffect(() => {
    const instances = Number(
      dashboardPool.filter(f => f.attributes.name.includes(initialTitle)).length
    )

    setTitle(instances > 0 ? `${initialTitle} (${instances})` : initialTitle)
  }, [dashboardPool, initialTitle])

  const changeTitle = event => setTitle(event.target.value)

  const NO_TITLE = title === ''

  return (
    <Dialog fullWidth {...rest}>
      <DialogTitle>{initialTitle}</DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          label={'Titel'}
          onChange={changeTitle}
          sx={{ mx: 0, mb: 0, mt: 2 }}
          value={title}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={NO_TITLE} onClick={() => onConfirm(title)}>
          <SaveTwoTone />
          Erstellen
        </Button>

        <Button onClick={onCancel}>
          <CancelTwoTone />
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgDashboardCreate.propTypes = {
  initialTitle: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}
