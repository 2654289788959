import PropTypes from 'prop-types'
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

export const RouteSwitcher = ({ routes }) => (
  <Switch>
    {routes.map((route, key) => {
      const { component, path, pathTo, redirect } = route

      return redirect ? (
        <Redirect from={path} key={key} to={pathTo} />
      ) : (
        <Route component={component} key={key} path={path} />
      )
    })}
  </Switch>
)

RouteSwitcher.propTypes = {
  routes: PropTypes.array,
}
