import { Alert } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { DMMMMYYYY, userLocales } from '../../api'
import { useFolderContract } from '../../hooks'

export const ContractStatus = ({
  folderId,
  locales = userLocales,
  options = DMMMMYYYY,
}) => {
  const contract = useFolderContract(folderId)
  if (!contract) return null
  const { date_begin: begin, date_expire: expire, severity } = contract

  const fmtDate = date => new Date(date).toLocaleDateString(locales, options)
  const isValid = date => date instanceof Date && !isNaN(date)

  const BEGIN = new Date(begin)
  const EXPIRE = new Date(expire)
  const NOW = Date.now()

  const status =
    !isValid(BEGIN) || !isValid(EXPIRE) || BEGIN > EXPIRE
      ? 'ungültig'
      : NOW > EXPIRE
        ? 'abgelaufen'
        : NOW < BEGIN
          ? 'geplant'
          : NOW > BEGIN && NOW < EXPIRE
            ? 'aktiv'
            : `unvorhergesehen: folderId: ${folderId} | ${fmtDate(begin)} - ${fmtDate(expire)}`

  const runtime = `${fmtDate(begin)} - ${fmtDate(expire)}`

  return (
    <Alert severity={severity}>
      Vertragslizenz: <strong>{status}</strong> {runtime}
    </Alert>
  )
}

ContractStatus.propTypes = {
  folderId: PropTypes.number.isRequired,
  locales: PropTypes.string,
  options: PropTypes.string,
}
