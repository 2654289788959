import preval from 'preval.macro'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify'
import { store, setStorage } from './api'
import { App } from './App'
//import * as serviceWorker from './service-worker'
import generatedGitInfo from './generatedGitInfo.json'

const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_API_REGION,
  REACT_APP_S3_REGION,
  REACT_APP_S3_BUCKET,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_APP_CLIENT_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
} = process.env

const { branch, commit_hash } = generatedGitInfo

console.log(
  `Build: ${branch}::${commit_hash}`,
  `pool::${REACT_APP_ENV}`,
  'compiled:',
  preval`module.exports = new Date().toLocaleString('en-US', {hour12:false});`
)
const info = preval`module.exports = new Date().toLocaleString('en-US', {hour12:false});`
setStorage('buildInfo', {
  environment: REACT_APP_ENV,
  build: info,
  branch,
  commit_hash,
})

// setup aws-amplify
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  Storage: {
    region: REACT_APP_S3_REGION,
    bucket: REACT_APP_S3_BUCKET,
    identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'karmaApi',
        endpoint: REACT_APP_API_URL,
        region: REACT_APP_API_REGION,
      },
    ],
  },
})

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register()
