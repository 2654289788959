import { MemoryTwoTone, TroubleshootTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { sensorType } from '../../api'
import { useFeelingSensors, useSensorIcon } from '../../hooks'
import { ToggleDateType } from '..'

export const FeelingRoomSensor = ({ folderId, onClick, sensorId }) => {
  const fSensors = useFeelingSensors(folderId)
  const record = fSensors.find(f => f.entity_id === sensorId)

  const {
    sensor_type,
    status,
    sub_sensor_target: sst,
    time,
    type_in_folder,
    value,
    unit,
  } = record ?? {}

  const image_url = useSensorIcon(sensorId, status)
  const hrType = sensorType(sensor_type, type_in_folder, sst)
  const hrUnit = unit ?? ''
  const timestamp = new Date(Date.parse(time))

  return fSensors.length === 0 ? null : (
    <Collapse in={true}>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={image_url} width={80}>
            {!image_url && <MemoryTwoTone />}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={hrType}
          secondary={<ToggleDateType date={timestamp} />}
          sx={{ width: 0.7 }}
        />

        <ListItemText
          disableTypography
          primary={`${value} ${hrUnit}`}
          sx={{
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'flex-end',
            mr: 2,
            width: 0.3,
          }}
        />

        <Divider flexItem orientation={'vertical'} sx={{ mr: 2 }} />

        <Tooltip title={`Details ${hrType}`}>
          <IconButton onClick={onClick}>
            <TroubleshootTwoTone />
          </IconButton>
        </Tooltip>
      </ListItem>
    </Collapse>
  )
}

FeelingRoomSensor.propTypes = {
  folderId: PropTypes.number,
  onClick: PropTypes.func,
  sensorId: PropTypes.number,
}
