import { Checkbox, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useFolderPool, useSensorPool } from '../../hooks'

export const PanelDisplaySensors = ({
  data,
  selectedFolders,
  setSelectedFolders,
  selectedSensors,
  setSelectedSensors,
  presentationLevel,
}) => {
  const { data: folderPool } = useFolderPool()
  const { data: sensorPool } = useSensorPool()

  return data.map(sensor => {
    const { entity_id, folder_id, name: sensorName } = sensor.attributes

    const folderId = Number(folder_id)
    const record = folderPool.find(f => Number(f.id) === folderId)
    const folderName = record?.attributes?.name ?? ''

    const label = `${sensorName} | Gebäude ${folderName}`
    const checked = selectedSensors.includes(entity_id)

    const onChange = () => {
      if (!selectedFolders.includes(folderId))
        setSelectedFolders(prev => [...prev, folderId])

      const remainingSensors = sensorPool.some(
        sensor =>
          sensor.attributes.folder_id === folderId &&
          selectedSensors.includes(entity_id)
      )

      if (!remainingSensors && presentationLevel === 2)
        setSelectedFolders(prev => prev.filter(f => f !== folderId))

      setSelectedSensors(prev =>
        prev.includes(entity_id)
          ? prev.filter(f => f !== entity_id)
          : [...prev, entity_id]
      )
    }

    const control = (
      <Checkbox checked={checked} name={label} onChange={onChange} />
    )

    return <FormControlLabel control={control} key={entity_id} label={label} />
  })
}

PanelDisplaySensors.propTypes = {
  data: PropTypes.array.isRequired,
  selectedFolders: PropTypes.array.isRequired,
  setSelectedFolders: PropTypes.func.isRequired,
  selectedSensors: PropTypes.array.isRequired,
  setSelectedSensors: PropTypes.func.isRequired,
  presentationLevel: PropTypes.number,
}
