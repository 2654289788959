import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useSensorName = sensorId => {
  const [data, setData] = useState('')

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const sensorData = sensorPool.find(f => Number(f.id) === sensorId)
    if (!sensorData?.attributes?.name) return

    const sensorName = sensorData?.attributes?.name
    if (sensorName) setData(sensorName)
  }, [sensorPool, sensorId])

  return data
}

useSensorName.propTypes = {
  sensorId: PropTypes.number,
}
