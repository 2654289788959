import { BugReportTwoTone, CloseTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { getRelativeDate } from '../../api'
import { HStack } from '..'

const isValidJson = payload => {
  let result = false

  try {
    JSON.parse(
      payload
        .replace(/'/g, '"')
        .replace(/None/g, '"undefined"')
        .replace(/True/g, '"True"')
        .replace(/False/g, '"False"')
    )
    result = true
  } catch (error) {
    result = false
  }

  return result
}

export const DlgPayloadIssues = ({
  title = 'Ungültige Payloads',
  data,
  open,
  onClose,
  issues,
}) => {
  const theme = useTheme()
  const { main: bgcolor } = theme.palette.secondary
  const { white } = theme.palette
  const color = issues === 0 ? 'success' : 'error'

  return (
    <Dialog maxWidth={'xl'} onClose={onClose} open={open}>
      <DialogTitle
        onClose={onClose}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <HStack>
          <Avatar sx={{ bgcolor, color: white, mr: 1 }}>
            <BugReportTwoTone />
          </Avatar>

          <Typography sx={{ mr: 1, fontSize: 20 }}>
            {title} <Chip color={color} label={issues} sx={{ ml: 1 }} />
          </Typography>
        </HStack>

        <IconButton onClick={onClose} sx={{ bgcolor, color: white }}>
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <List>
          {data
            ?.filter(item => !isValidJson(item.payload))
            .map((item, key) => {
              const primary = item.device_id
              const secondary = `${item.timestamp} | ${getRelativeDate(
                item.timestamp
              )}`

              return (
                <ListItem key={key}>
                  <Stack>
                    <ListItemButton>
                      <ListItemText primary={primary} secondary={secondary} />
                    </ListItemButton>

                    <Collapse in={true}>
                      <Typography>
                        <code>{item.payload}</code>
                      </Typography>
                    </Collapse>
                  </Stack>
                </ListItem>
              )
            })}
        </List>
      </DialogContent>
    </Dialog>
  )
}

DlgPayloadIssues.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  issues: PropTypes.number,
}
