import { Alert, AlertTitle, Box, LinearProgress } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { folderActions, sensorActions } from '../../actions'
import { useContractPool, useFolderPool } from '../../hooks'

export const IntegritySensorCount = ({
  title = 'BrückenSensor zwischen Gebäuden & Verträgen',
}) => {
  const dispatch = useDispatch()

  const { oneFolder } = useSelector(state => state.folder)
  const { oneSensor } = useSelector(state => state.sensor)

  const { data: contractPool, isLoading: cLoading } = useContractPool()
  const { data: folderPool, isLoading: fLoading } = useFolderPool()

  const [severity, setSeverity] = useState('info')
  const [log, setLog] = useState([])

  const isLoading = cLoading || fLoading

  useEffect(() => {
    if (folderPool.length === 0) return

    const folderIds = folderPool
      .map(folder => Number(folder.id))
      .sort((a, b) => a - b)

    folderIds.forEach(id => dispatch(folderActions.getById(id, ['sensors'])))
  }, [dispatch, folderPool])

  useEffect(() => {
    const { item } = oneFolder
    if (!item?.data || !item?.included) return

    const { id: folderId } = item.data

    const folderSensors = item.included
      .map(item => Number(item.id))
      .sort((a, b) => a - b)
    const count = folderSensors.length

    const name = folderPool.find(f => f.id === folderId).attributes.name

    if (count === 0) {
      setSeverity('error')
      setLog(log => [
        ...log,
        `ACHTUNG: Gebäude ${name} hat 0 zugewiesene Sensoren. Keine Vertragsinformationen verfügbar.`,
      ])
    }

    if (count > 0) {
      const sensorId = Number(folderSensors[0])

      dispatch(sensorActions.getById(sensorId, ['contracts']))
    }
  }, [dispatch, folderPool, oneFolder])

  useEffect(() => {
    const { item } = oneSensor
    const { items } = contractPool
    if (!item?.included || items?.length === 0) return

    const { id: sensorId } = item.data
    const referenceSensor = item.included.map(item => Number(item.id))
    const count = referenceSensor.length

    if (count === 0)
      setLog(log => [
        ...log,
        `ACHTUNG: Sensor mit ID ${sensorId} ist keinem Vertrag zugeordnet. Es kann keine Beziehung zwischen Gebäude und Vertrag hergestellt werden.`,
      ])

    if (count > 0) {
      const record = contractPool.find(
        f => Number(f.id) === Number(referenceSensor[0])
      )

      if (!record)
        setLog(log => [
          ...log,
          `ACHTUNG: Vertrags ID ${
            referenceSensor[0]
          } ungültig (Vertrag nicht gefunden)`,
        ])
    }
  }, [dispatch, oneSensor, contractPool])

  return (
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>

      {isLoading && <LinearProgress />}

      {!isLoading &&
        log.map((item, key) => (
          <Box key={key}>
            {item}
            <br />
          </Box>
        ))}
    </Alert>
  )
}

IntegritySensorCount.propTypes = {
  title: PropTypes.string,
}
