import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sensorActions } from '../../actions'
import { FILTER_TYPE_CONTRACT } from '../../api'
import { useUserPool } from './useUserPool'

export const useCoachData = bridgeId => {
  const dispatch = useDispatch()
  const { data: userPool } = useUserPool()

  const [data, setData] = useState({})

  const { oneSensor } = useSelector(state => state.sensor)

  useEffect(() => {
    if (isNaN(bridgeId) || Number(bridgeId) === 0) return

    dispatch(sensorActions.getById(bridgeId, ['contracts', 'folders']))
  }, [dispatch, bridgeId])

  useEffect(() => {
    if (!oneSensor?.item || !userPool) return
    const { included } = oneSensor.item

    if (!included.filter(FILTER_TYPE_CONTRACT)[0]) return
    const { primary_coach_id } =
      included.filter(FILTER_TYPE_CONTRACT)[0].attributes

    const FILTER_COACH = f => Number(f.id) === primary_coach_id

    if (primary_coach_id) setData(userPool.find(FILTER_COACH))
  }, [oneSensor, userPool])

  return data
}
