import { ExpandMoreTwoTone } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Input,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { HStack } from '../../components'
import { useFolderPool, useSensorPool } from '../../hooks'

export const PanelDisplayFolders = ({
  disabled,
  selectedFolders,
  setSelectedSensors,
  setSelectedFolders,
}) => {
  const { data: folderPool } = useFolderPool()
  const { data: sensorPool } = useSensorPool()

  const [foldersSearch, setFoldersSearch] = useState('')
  const [showSelectedFolders, setShowSelectedFolders] = useState(false)

  const FILTER_SEARCH = f =>
    f.attributes.name.toLowerCase().includes(foldersSearch.toLowerCase())

  const FILTER_SELECTED_FOLDERS = f =>
    showSelectedFolders
      ? selectedFolders.includes(f.attributes.entity_id)
      : true

  return (
    <Accordion disabled={disabled}>
      <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
        <HStack sx={{ width: 1 }}>
          <Chip label={`${selectedFolders.length}/${folderPool.length}`} />

          <Typography>Gebäude</Typography>
        </HStack>
      </AccordionSummary>

      <AccordionDetails>
        <HStack sx={{ width: 1 }}>
          <FormGroup>
            <FormControlLabel
              label={'Nur ausgewählte Gebäude anzeigen'}
              control={
                <Checkbox
                  checked={showSelectedFolders}
                  onClick={() => setShowSelectedFolders(!showSelectedFolders)}
                />
              }
            />
          </FormGroup>
        </HStack>

        <HStack sx={{ width: 1 }}>
          <Stack sx={{ width: 1 }}>
            <Input
              onChange={event => setFoldersSearch(event.target.value)}
              placeholder={'Suchen...'}
              sx={{ m: 0, minHeight: 23, pl: 1 }}
              value={foldersSearch}
              variant={'standard'}
            />
            <FormGroup>
              {folderPool
                .filter(FILTER_SEARCH)
                .filter(FILTER_SELECTED_FOLDERS)
                .map((folder, key) => {
                  const { name, entity_id } = folder.attributes
                  const checked = selectedFolders.includes(entity_id)

                  const onChange = () => {
                    if (!selectedFolders.includes(entity_id)) {
                      const entityIds = sensorPool
                        .filter(f => f.attributes.folder_id === entity_id)
                        .map(item => item.attributes.entity_id)

                      setSelectedSensors(prev =>
                        prev.filter(f => !entityIds.includes(f))
                      )
                    }

                    setSelectedFolders(prev =>
                      prev.includes(entity_id)
                        ? prev.filter(f => f !== entity_id)
                        : [...prev, entity_id]
                    )
                  }

                  const control = (
                    <Checkbox
                      checked={checked}
                      name={name}
                      onChange={onChange}
                    />
                  )

                  return (
                    <FormControlLabel
                      control={control}
                      key={key}
                      label={name}
                    />
                  )
                })}
            </FormGroup>
          </Stack>
        </HStack>
      </AccordionDetails>
    </Accordion>
  )
}

PanelDisplayFolders.propTypes = {
  disabled: PropTypes.bool.isRequired,
  selectedFolders: PropTypes.array.isRequired,
  setSelectedSensors: PropTypes.func.isRequired,
  setSelectedFolders: PropTypes.func.isRequired,
}
