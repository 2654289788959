import { API, Auth } from 'aws-amplify'
import axios from 'axios'
import S3FileUpload from 'react-s3'
import { authHeader, getStorage, removeStorage } from '../api'
import { folderService } from './'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const ERR_MISSING_USER = new Error('Kein Benutzer gewählt')
const ERR_INVALID_USER = new Error('Ungültiger Benutzer')
const ERR_MISSING_FOLDER_IDS = new Error('Keine Gebäude')

const addUser = async body => {
  const endpoint = '/user/admin/users'
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

export const addUser2 = async body => {
  const config = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
    url: `${REACT_APP_API_URL}/user/admin/users`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('usersService.addUser', error)
  }
}

const deleteUser = async id => {
  const config = {
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/user/admin/users/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('usersService.deleteUser', error)
  }
}

const getOperator = async () => {
  const currentUser = Auth.userPool ? Auth.userPool.getCurrentUser() : null
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/user`,
  }

  try {
    if (currentUser) {
      const response = await axios(config)
      const { data } = response

      return data
    }
  } catch (error) {
    console.error('usersService.getOperator', error)
  }
}

const getRoles = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/user/admin/user-roles`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('usersService.getRoles', error)
  }
}

const getS3Config = async () => {
  const currentUser = Auth.userPool ? Auth.userPool.getCurrentUser() : null
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/user/admin/users/config`,
  }

  try {
    if (currentUser) {
      const response = await axios(config)
      const { data } = response

      return data
    }
  } catch (error) {
    console.error('usersService.getS3Config', error)
  }
}

const getUser = async id => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_USER)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_USER)

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/user/admin/users/${id}?include=folders,contracts,sensors`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('usersService.getUser', error)
  }
}

const getUsers = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/user/admin/users`,
  }

  try {
    const response = await axios(config)

    return response.data
  } catch (error) {
    const { config, message, request, response } = error

    // request was made; server responded with status code other than 2xx
    if (response) {
      const { data, status } = response
      const { message } = data

      console.log(data)
      console.log(status)

      return Promise.reject({ status, message })
    }
    // request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in browser and an instance of http.ClientRequest in node.js
    else if (request) {
      console.log(request)
    }
    // something happened in setting up the request that triggered an Error
    else {
      console.log('Error', message)
    }

    console.log(config)
  }
}

const login = async (email, password) => {
  return await Auth.signIn(email, password)
}

const logout = () => {
  // remove user from local storage to log user out
  removeStorage('user')
  removeStorage('config')
  removeStorage('errorNotConfirmed')

  const currentUser = Auth.userPool ? Auth.userPool.getCurrentUser() : null

  if (currentUser !== null) currentUser.signOut()
}

const register = async data => {
  return await Auth.signUp({
    username: data.email,
    password: data.password,
    attributes: {
      email: data.email,
      name: data.firstName,
      family_name: data.lastName,
    },
  })
}

const resetPassword = async (email, password, code) => {
  return await Auth.forgotPasswordSubmit(email, code, password)
}

const sendResetCode = async email => {
  return await Auth.forgotPassword(email)
}

const sendSignup = async email => {
  return await Auth.sendSignUp(email)
}

const updateOperator = async (id, body) => {
  const endpoint = `/user/${id}`
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const updateOperator2 = async (id, body) => {
  const config = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'PATCH',
    url: `${REACT_APP_API_URL}/user/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('usersService.updateOperator', error)
  }
}

const updatePassword = async (fromPassword, toPassword) => {
  const currentUser = Auth.userPool ? Auth.userPool.getCurrentUser() : null

  return await Auth.changePassword(currentUser, fromPassword, toPassword)
}

const updateUser = async (id, body) => {
  const endpoint = `/user/admin/users/${id}`
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const updateUser2 = async (id, body) => {
  const config = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'PATCH',
    url: `${REACT_APP_API_URL}/user/admin/users/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('usersService.updateUser', error)
  }
}

const uploadImage = async (file, dirName) => {
  const config = getStorage('config')
  const handle = new File(
    [file],
    (
      Math.random().toString(36).substring(2, 16) +
      Math.random().toString(36).substring(2, 16)
    ).toUpperCase() +
      new Date().getTime() +
      file.name.substr(file.name.lastIndexOf('.'))
  )
  const options = {
    bucketName: config.s3.bucketName,
    dirName: dirName,
    region: config.s3.region,
    accessKeyId: config.s3.accessKeyId,
    secretAccessKey: config.s3.secrectAccessKey,
  }

  return await S3FileUpload.uploadFile(handle, options)
}

const getFoldersSensors = async folderIds => {
  if (!folderIds) return Promise.reject(ERR_MISSING_FOLDER_IDS)

  const result = []

  for (const folderId of folderIds) {
    try {
      const sensors = await folderService.sensorsByFolderId(folderId)
      if (sensors && sensors.data)
        result.push(sensors.data.map(item => item.id))
    } catch (error) {
      console.error('usersService.getFoldersSensors', error)
    }
  }

  return String(result).split(',')
}

const setUsersBySensorId = async (sensorId, users) => {
  const endpoint = `/sensors/admin/sensors/${sensorId}/users/`
  const options = {
    body: { data: { users } },
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const userService = {
  addUser,
  deleteUser,
  getOperator,
  getRoles,
  getS3Config,
  getUser,
  getUsers,
  login,
  logout,
  register,
  resetPassword,
  sendResetCode,
  sendSignup,
  updateOperator,
  updatePassword,
  updateUser,
  uploadImage,
  getFoldersSensors,
  setUsersBySensorId,
}
