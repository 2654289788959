import {
  AccountBalanceTwoTone,
  DownloadTwoTone,
  MoreVertTwoTone,
  NotificationImportantTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  categoryByType,
  DDMMYYYYHHMM,
  DMMMHHMM,
  getStatusColor,
  getStorage,
  normalizedSensorType,
  SensorIcon,
  sensorType,
  sensorUnit,
  setStorage,
  SORT_NAME,
  SVGStatus,
  userLocales,
} from '../../api'
import {
  AM,
  AMItem,
  ChartTypeSelector,
  DlgFeelingExport,
  DlgFeelingLimits,
  HStack,
  KPage,
  Preloader,
  Statistics,
} from '../../components'
import { useFolderSensors, useFolderStatus, useTimeSeries } from '../../hooks'
import { DataSampling, TimeSeriesChart } from './components'

const formatPeriod = (from, to, locales, options, showTZO = true) => {
  const TZO = new Date().getTimezoneOffset() * 60000
  const fromObj = new Date(from)
  const toObj = new Date(to)

  if (showTZO) {
    fromObj.setTime(fromObj.getTime() + TZO)
    toObj.setTime(toObj.getTime() + TZO)
  }

  const fromDate = new Date(fromObj).toLocaleDateString(locales, options)
  const toDate = new Date(toObj).toLocaleDateString(locales, options)

  return String(`${fromDate} - ${toDate}`)
}

const getAverage = data =>
  data.reduce((prev, cur) => prev + cur, 0) / data.length

const removeDuplicates = data => {
  const results = new Set()

  return data.filter(f => {
    const key = new Date(f.datetime).toLocaleDateString(userLocales, DMMMHHMM)

    return results.has(key) ? false : results.add(key)
  })
}

const DropDownFeelingItem = ({ label, icon, size = 25 }) => (
  <ListItem sx={{ p: 0 }}>
    <ListItemIcon>
      <Box alt={''} component={'img'} src={icon} sx={{ width: size }} />
    </ListItemIcon>

    <ListItemText>{label}</ListItemText>
  </ListItem>
)

DropDownFeelingItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.number,
}

export const FeelingHistory = ({
  PAGE_CONFIG = 'settings_feeling_history',
  title = 'Details Wohlbefinden',
}) => {
  const history = useHistory()

  const { id, sensorid, medium: initialMedium } = useParams()
  const folderId = Number(id)
  const folderStatus = useFolderStatus(folderId)
  const folderSensors = useFolderSensors(folderId)

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  const exportDialog = config?.exportDialog
  const limitsDialog = config?.limitsDialog
  const chartType = config?.chartType
  const lineColor = config?.lineColor

  const [sensorId, setSensorId] = useState(sensorid)
  const [dataSamplingDate, setDataSamplingDate] = useState(false)
  const [medium, setMedium] = useState(initialMedium)

  const [sensorTypes, setSensorTypes] = useState([])
  const [mediumSensors, setMediumSensors] = useState([])
  const [timeSeriesData, setTimeSeriesData] = useState([])
  const [actionMenu, setActionMenu] = useState(null)
  const [showPeriodWarning, setShowPeriodWarning] = useState(false)

  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split('T')[0]
  )
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0])

  const [kpiData, setKpiData] = useState({ min: 0, max: 0, average: 0 })

  const [chartData, setChartData] = useState([])

  const period = formatPeriod(fromDate, toDate, userLocales, DDMMYYYYHHMM)

  const [minLimit, setMinLimit] = useState(null)
  const [maxLimit, setMaxLimit] = useState(null)

  const {
    data: tsData,
    isLoading: isTsLoading,
    isError,
  } = useTimeSeries(sensorId, fromDate, toDate)

  const FILTER_IS_TYPE_FEELING = f =>
    f.sensor_target === 'feeling' || categoryByType(f.sensor_type) === 'feeling'

  const FILTER_IS_TYPE_MEDIUM = f =>
    f.sensor_target === 'feeling' ||
    (categoryByType(f.sensor_type) === 'feeling' &&
      f.sensor_target !== 'security')

  useEffect(() => {
    const data = folderSensors
      ?.filter(FILTER_IS_TYPE_FEELING)
      .map(sensor => normalizedSensorType(sensor.sensor_type))
      .filter(f => f !== 'motion')

    setSensorTypes([...new Set(data)])
  }, [folderSensors])

  useEffect(
    () =>
      setMediumSensors(
        folderSensors
          ?.filter(
            sensor => normalizedSensorType(sensor.sensor_type) === medium
          )
          .filter(FILTER_IS_TYPE_MEDIUM)
      ),
    [folderSensors, medium]
  )

  useEffect(
    () => history.push(`/folder/${folderId}/feeling/${medium}/${sensorId}`),
    [history, folderId, medium, sensorId]
  )

  useEffect(() => {
    if (!tsData) return

    const sensor = folderSensors?.find(
      sensor => Number(sensor.entity_id) === Number(sensorId)
    )
    setTimeSeriesData(removeDuplicates(tsData))

    setKpiData({
      min: Math.min(...tsData.map(i => i.value)),
      max: Math.max(...tsData.map(i => i.value)),
      average: getAverage(tsData.map(i => i.value)),
    })

    const data = tsData
      .sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
      .map(item => {
        const timestamp = new Date(item.datetime)
        const value = Number(item.value)
        const min = Number(minLimit)
        const max = Number(maxLimit)

        if (minLimit && maxLimit) return [timestamp, value, min, max]
        else if (maxLimit) return [timestamp, value, max]
        else if (minLimit) return [timestamp, value, min]
        else return [timestamp, value]
      })

    // add chart caption
    if (minLimit && maxLimit) {
      data.unshift(['Datum', sensorUnit(medium), 'GMin', 'GMax'])
    } else if (maxLimit) {
      data.unshift(['Datum', sensorUnit(medium), 'GMin'])
    } else if (minLimit) {
      data.unshift(['Datum', sensorUnit(medium), 'GMax'])
    } else {
      data.unshift(['Datum', sensorUnit(medium)])
    }

    if (data.length === 1) data.push([new Date(), 0])
    setChartData(data)

    if (sensor) setDataSamplingDate(sensor.data_sampling_date)
  }, [folderSensors, maxLimit, medium, minLimit, sensorId, tsData])

  const onChangeMedium = event => {
    const newMedium = event.target.value

    const newMediumSensors = folderSensors.filter(
      f => f.sensor_type === newMedium
    )

    if (newMediumSensors && newMediumSensors?.[0]) {
      const sensor = folderSensors.find(
        f => Number(f.entity_id) === Number(sensorId)
      )

      const newSensor = newMediumSensors.find(f => f.name === sensor?.name)

      setSensorId(
        newSensor ? newSensor.entity_id : newMediumSensors[0].entity_id
      )

      setDataSamplingDate(newMediumSensors[0].data_sampling_date)
    }

    setMedium(newMedium)

    setMinLimit(null)
    setMaxLimit(null)
  }

  const onChangeSensor = event => {
    setSensorId(event.target.value.entity_id)
    setDataSamplingDate(event.target.value.data_sampling_date)

    setMinLimit(null)
    setMaxLimit(null)
  }

  const onChangeDate = event => {
    const fromDate = event.target.value

    if (new Date(fromDate) > Date.now()) setShowPeriodWarning(true)
    else {
      const toDate = new Date(fromDate)
      toDate.setDate(toDate.getDate() + 2)
      setFromDate(fromDate)
      setToDate(toDate.toISOString().substring(0, 10))
      setShowPeriodWarning(false)
    }
  }

  const exportData = timeSeriesData.map(item => [item.datetime, item.value])

  const isPageLoading = isTsLoading
  const isPageError = isError

  return (
    <>
      <Preloader error={isPageError} isLoading={isPageLoading} />

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={title}
        open={Boolean(actionMenu)}
        onClose={() => setActionMenu(null)}
        historyUrlTarget={'feeling'}
        historyUrlId={Number(folderId)}
      >
        {[
          {
            caption: 'Details zum Gebäude',
            icon: <AccountBalanceTwoTone />,
            fn: () => history.push(`/folder/${folderId}`),
          },
          null,
          {
            caption: 'Leistung',
            icon: (
              <SVGStatus
                type={'performance'}
                status={folderStatus.performance}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/performance`),
          },
          {
            caption: 'Anlagen',
            icon: (
              <SVGStatus
                type={'installation'}
                status={folderStatus.installation}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/installation`),
          },
          {
            caption: 'Korrelation',
            icon: (
              <SVGStatus
                type={'correlation'}
                status={folderStatus.correlation}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/correlation`),
          },
          {
            caption: 'Sicherheit',
            icon: (
              <SVGStatus type={'security'} status={folderStatus.security} />
            ),
            fn: () => history.push(`/folder/${folderId}/home/security`),
          },
          {
            caption: 'Wohlbefinden',
            icon: <SVGStatus type={'feeling'} status={folderStatus.feeling} />,
            fn: () => history.push(`/folder/${folderId}/home/feeling`),
          },
        ].map((item, key) =>
          item ? (
            <AMItem
              caption={item.caption}
              icon={
                <Stack alignItems={'center'} sx={{ width: 25 }}>
                  {item.icon}
                </Stack>
              }
              key={key}
              onClick={item.fn}
            />
          ) : (
            <Divider key={key} />
          )
        )}
      </AM>
      {/* action menu end */}

      <DlgFeelingExport
        exportData={exportData}
        folderId={folderId}
        medium={medium}
        onClose={() => setConfig(prev => ({ ...prev, exportDialog: false }))}
        open={exportDialog}
        period={period}
        sensorId={Number(sensorId)}
      />

      <DlgFeelingLimits
        onClose={() => setConfig(prev => ({ ...prev, limitsDialog: false }))}
        open={limitsDialog}
        medium={medium}
        mediumSensors={mediumSensors}
        sensorId={Number(sensorId)}
        minLimit={minLimit}
        maxLimit={maxLimit}
        onChangeMinLimit={limit => setMinLimit(limit)}
        onChangeMaxLimit={limit => setMaxLimit(limit)}
        onChangeLineColor={color =>
          setConfig(prev => ({ ...prev, lineColor: color }))
        }
      />

      <KPage
        action={
          <IconButton onClick={event => setActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        avatar={
          <Avatar>
            <SVGStatus
              type={'feeling'}
              status={getStatusColor(
                folderSensors?.filter(FILTER_IS_TYPE_MEDIUM)
              )}
            />
          </Avatar>
        }
        title={title}
      >
        {/* Sensor Time Series */}
        {!isPageLoading && (
          <Card variant={'feeling'}>
            <CardHeader
              title={
                <HStack
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <HStack>
                    <TextField
                      label={'Raumwert'}
                      onChange={onChangeMedium}
                      select
                      type={'text'}
                      value={medium || ''}
                      variant={'filled'}
                    >
                      {sensorTypes.map((medium, key) => (
                        <MenuItem key={key} value={medium}>
                          <DropDownFeelingItem
                            label={sensorType(medium)}
                            icon={SensorIcon(
                              medium,
                              getStatusColor(
                                folderSensors
                                  .filter(FILTER_IS_TYPE_FEELING)
                                  .filter(FILTER_IS_TYPE_MEDIUM)
                                  .filter(
                                    f =>
                                      normalizedSensorType(f.sensor_type) ===
                                      medium
                                  )
                              )
                            )}
                          />
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label={'Messpunkt'}
                      onChange={onChangeSensor}
                      select
                      type={'text'}
                      value={
                        mediumSensors?.find(
                          f => Number(f.entity_id) === Number(sensorId)
                        ) || ''
                      }
                      variant={'filled'}
                    >
                      {mediumSensors
                        ?.filter(
                          f => normalizedSensorType(f.sensor_type) === medium
                        )
                        .sort(SORT_NAME)
                        .map((sensor, key) => (
                          <MenuItem key={key} value={sensor}>
                            <HStack>
                              <ListItemIcon>
                                <Box
                                  alt={''}
                                  component={'img'}
                                  src={SensorIcon(medium, sensor.status)}
                                  sx={{ width: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText>{sensor.name}</ListItemText>
                            </HStack>
                          </MenuItem>
                        ))}
                    </TextField>

                    <Tooltip title={'Grenzwerte'}>
                      <IconButton
                        onClick={() =>
                          setConfig(prev => ({ ...prev, limitsDialog: true }))
                        }
                      >
                        <NotificationImportantTwoTone />
                      </IconButton>
                    </Tooltip>
                  </HStack>

                  <HStack>
                    <ChartTypeSelector
                      exclusive
                      onChange={(event, chartType) => {
                        if (chartType !== null)
                          setConfig(prev => ({ ...prev, chartType }))
                      }}
                      sx={{ mx: 1 }}
                      value={chartType}
                    />

                    <Tooltip title={'Exportieren'}>
                      <IconButton
                        onClick={() =>
                          setConfig(prev => ({ ...prev, exportDialog: true }))
                        }
                      >
                        <DownloadTwoTone />
                      </IconButton>
                    </Tooltip>

                    <TextField
                      label={'Datum'}
                      onChange={onChangeDate}
                      sx={{ ml: 1 }}
                      type={'date'}
                      value={fromDate}
                      variant={'filled'}
                    />
                  </HStack>
                </HStack>
              }
              variant={'feeling'}
            />

            <CardContent sx={{ p: '0px !important' }}>
              <Collapse in={tsData?.length === 0}>
                <Alert severity={'warning'}>
                  <AlertTitle>Achtung!</AlertTitle>
                  Für diesen Sensor gibt es im gewähltem Zeitraum keine Daten.
                </Alert>
              </Collapse>

              <Collapse in={showPeriodWarning}>
                <Alert severity={'warning'}>
                  <AlertTitle>Achtung!</AlertTitle>
                  Bitte wählen Sie ein Startdatum, für welches bereits Daten
                  erhoben wurden und welches nicht in der Zukunft liegt.
                </Alert>
              </Collapse>

              <Collapse in={tsData?.length > 0 && !showPeriodWarning}>
                <DataSampling date={dataSamplingDate} />

                <TimeSeriesChart
                  backgroundColor={'white'}
                  chartType={chartType}
                  data={chartData}
                  medium={medium}
                  unit={sensorUnit(medium)}
                  series={{
                    1: { type: 'line', color: lineColor },
                    2: { type: 'line', color: lineColor },
                  }}
                />

                <Statistics
                  average={kpiData.average}
                  minimum={kpiData.min}
                  maximum={kpiData.max}
                  unit={sensorUnit(medium)}
                />
              </Collapse>
            </CardContent>
          </Card>
        )}
      </KPage>
    </>
  )
}

FeelingHistory.propTypes = {
  PAGE_CONFIG: PropTypes.string,
  title: PropTypes.string,
}
