import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { IntegrityOC, IntegritySensorCount } from '..'

export const DlgIntegrityCheck = ({
  onClose,
  open,
  title = 'Integritätsprüfung',
}) => (
  <Dialog fullWidth={true} maxWidth={'md'} onClose={onClose} open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent variant={'inline'}>
      <IntegrityOC sx={{ mb: 1 }} />
      <br />
      <br />
      <IntegritySensorCount />
    </DialogContent>
  </Dialog>
)

DlgIntegrityCheck.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}
