import {
  AccountBalanceTwoTone,
  DownloadTwoTone,
  MoreVertTwoTone,
  NotificationImportantTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  categoryByType,
  DDMMYYYYHHMM,
  DMMMHHMM,
  getStatusColor,
  getStorage,
  normalizedSensorType,
  SensorIcon,
  sensorType,
  sensorUnit,
  setStorage,
  SORT_NAME,
  SVGStatus,
  userLocales,
} from '../../api'
import {
  AM,
  AMItem,
  ChartTypeSelector,
  DlgSecurityExport,
  DlgSecurityLimits,
  HStack,
  KPage,
  Preloader,
  Statistics,
} from '../../components'
import { useFolderSensors, useFolderStatus, useTimeSeries } from '../../hooks'
import { DataSampling, TimeSeriesChart } from './components'

const formatPeriod = (from, to, locales, options, showTZO = true) => {
  const TZO = new Date().getTimezoneOffset() * 60000
  const fromObj = new Date(from)
  const toObj = new Date(to)

  if (showTZO) {
    fromObj.setTime(fromObj.getTime() + TZO)
    toObj.setTime(toObj.getTime() + TZO)
  }

  const fromDate = new Date(fromObj).toLocaleDateString(locales, options)
  const toDate = new Date(toObj).toLocaleDateString(locales, options)

  return String(`${fromDate} - ${toDate}`)
}

const getAverage = data =>
  data.reduce((prev, cur) => prev + cur, 0) / data.length

const removeDuplicates = data => {
  const results = new Set()

  return data.filter(f => {
    const key = new Date(f.datetime).toLocaleDateString(userLocales, DMMMHHMM)

    return results.has(key) ? false : results.add(key)
  })
}

export const SecurityHistory = ({
  PAGE_CONFIG = 'settings_security_history',
  title = 'Details Sicherheit',
}) => {
  const history = useHistory()

  const { id, sensorid, medium: initialMedium } = useParams()
  const folderId = Number(id)
  const folderStatus = useFolderStatus(folderId)
  const folderSensors = useFolderSensors(folderId)

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  const exportDialog = config?.exportDialog
  const limitsDialog = config?.limitsDialog
  const chartType = config?.chartType
  const lineColor = config?.lineColor

  const [sensorId, setSensorId] = useState(sensorid)
  const [dataSamplingDate, setDataSampingDate] = useState(false)
  const [medium, setMedium] = useState(normalizedSensorType(initialMedium))

  const [sensorTypes, setSensorTypes] = useState([])
  const [mediumSensors, setMediumSensors] = useState([])
  const [timeSeriesData, setTimeSeriesData] = useState([])
  const [actionMenu, setActionMenu] = useState(null)
  const [showPeriodWarning, setShowPeriodWarning] = useState(false)

  // range
  // YYYY-MM-DD
  const _YESTERDAY = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split('T')[0]
  const _TODAY = new Date().toISOString().split('T')[0]

  const [fromDate, setFromDate] = useState(_YESTERDAY)
  const [toDate, setToDate] = useState(_TODAY)

  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(0)
  const [averageValue, setAverageValue] = useState(0)
  const [chartData, setChartData] = useState([])

  const period = formatPeriod(fromDate, toDate, userLocales, DDMMYYYYHHMM)

  const [minLimit, setMinLimit] = useState(null)
  const [maxLimit, setMaxLimit] = useState(null)

  const FILTER_IS_TYPE_SECURITY = f =>
    f.sensor_target === 'security' ||
    categoryByType(f.sensor_type) === 'security'

  const FILTER_IS_TYPE_MEDIUM = f =>
    f.sensor_target === 'security' ||
    (categoryByType(f.sensor_type) === 'security' &&
      f.sensor_target !== 'feeling')

  const {
    data: tsData,
    isLoading: isTsLoading,
    isError,
  } = useTimeSeries(sensorId, fromDate, toDate)

  useEffect(() => {
    const data = folderSensors
      ?.filter(FILTER_IS_TYPE_SECURITY)
      .map(sensor => normalizedSensorType(sensor.sensor_type))
      .filter(f => f === 'temperature')
    // remove "water detector"s from sensor type selector,
    // as its detail page is undesired

    setSensorTypes([...new Set(data)])
  }, [folderSensors])

  useEffect(
    () =>
      setMediumSensors(
        folderSensors
          ?.filter(
            sensor => normalizedSensorType(sensor.sensor_type) === medium
          )
          .filter(FILTER_IS_TYPE_MEDIUM)
      ),
    [folderSensors, medium]
  )

  useEffect(
    () => history.push(`/folder/${folderId}/security/${medium}/${sensorId}`),
    [history, folderId, medium, sensorId]
  )

  useEffect(() => {
    if (!tsData) return

    const sensor = folderSensors?.find(
      f => Number(f.entity_id) === Number(sensorId)
    )
    setTimeSeriesData(removeDuplicates(tsData))

    setAverageValue(getAverage(tsData.map(i => i.value)))
    setMinValue(Math.min(...tsData.map(i => i.value)))
    setMaxValue(Math.max(...tsData.map(i => i.value)))

    const data = tsData
      .sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
      .map(item => {
        const timestamp = new Date(item.datetime)
        const value = Number(item.value)
        const min = Number(minLimit)
        const max = Number(maxLimit)

        if (minLimit && maxLimit) return [timestamp, value, min, max]
        else if (maxLimit) return [timestamp, value, max]
        else if (minLimit) return [timestamp, value, min]
        else return [timestamp, value]
      })

    // add chart caption
    if (minLimit && maxLimit) {
      data.unshift(['Datum', sensorUnit(medium), 'GMin', 'GMax'])
    } else if (maxLimit) {
      data.unshift(['Datum', sensorUnit(medium), 'GMin'])
    } else if (minLimit) {
      data.unshift(['Datum', sensorUnit(medium), 'GMax'])
    } else {
      data.unshift(['Datum', sensorUnit(medium)])
    }

    if (data.length === 1) data.push([new Date(), 0])
    setChartData(data)

    if (sensor) setDataSampingDate(sensor.data_sampling_date)
  }, [maxLimit, medium, minLimit, tsData, sensorId, folderSensors])

  const exportData = timeSeriesData.map(item => [item.datetime, item.value])

  const isPageLoading = isTsLoading
  const isPageError = isError

  const onChangeMedium = event => {
    const newMedium = event.target.value
    const newSensorId = folderSensors.filter(f => f.sensor_type === newMedium)

    if (newSensorId && newSensorId[0]) {
      setSensorId(newSensorId[0].entity_id)
      setDataSampingDate(newSensorId[0].data_sampling_date)
    }

    setMedium(newMedium)
    setMinLimit(null)
    setMaxLimit(null)
  }

  const onChangeSensor = event => {
    setSensorId(event.target.value.entity_id)
    setDataSampingDate(event.target.value.data_sampling_date)
    setMinLimit(null)
    setMaxLimit(null)
  }

  const onChangeDate = event => {
    const fromDate = event.target.value
    let toDate

    if (new Date(fromDate) > Date.now()) setShowPeriodWarning(true)
    else {
      toDate = new Date(fromDate)
      toDate.setDate(toDate.getDate() + 2)
      setFromDate(fromDate)
      setToDate(toDate.toISOString().substring(0, 10))
      setShowPeriodWarning(false)
    }
  }

  return (
    <>
      <Preloader error={isPageError} isLoading={isPageLoading} />

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={title}
        open={Boolean(actionMenu)}
        onClose={() => setActionMenu(null)}
        historyUrlTarget={'security'}
        historyUrlId={Number(folderId)}
      >
        {[
          {
            caption: 'Details zum Gebäude',
            icon: <AccountBalanceTwoTone />,
            fn: () => history.push(`/folder/${folderId}`),
          },
          null,
          {
            caption: 'Leistung',
            icon: (
              <SVGStatus
                type={'performance'}
                status={folderStatus.performance}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/performance`),
          },
          {
            caption: 'Anlagen',
            icon: (
              <SVGStatus
                type={'installation'}
                status={folderStatus.installation}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/installation`),
          },
          {
            caption: 'Korrelation',
            icon: (
              <SVGStatus
                type={'correlation'}
                status={folderStatus.correlation}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/correlation`),
          },
          {
            caption: 'Sicherheit',
            icon: (
              <SVGStatus type={'security'} status={folderStatus.security} />
            ),
            fn: () => history.push(`/folder/${folderId}/home/security`),
          },
          {
            caption: 'Wohlbefinden',
            icon: <SVGStatus type={'feeling'} status={folderStatus.feeling} />,
            fn: () => history.push(`/folder/${folderId}/home/feeling`),
          },
        ].map((item, key) =>
          item ? (
            <AMItem
              caption={item.caption}
              icon={
                <Stack alignItems={'center'} sx={{ width: 25 }}>
                  {item.icon}
                </Stack>
              }
              key={key}
              onClick={item.fn}
            />
          ) : (
            <Divider key={key} />
          )
        )}
      </AM>
      {/* action menu end */}

      <DlgSecurityExport
        exportData={exportData}
        medium={medium}
        onClose={() => setConfig(prev => ({ ...prev, exportDialog: false }))}
        open={exportDialog}
        period={period}
        sensorId={Number(sensorId)}
      />

      <DlgSecurityLimits
        onClose={() => setConfig(prev => ({ ...prev, limitsDialog: false }))}
        open={limitsDialog}
        medium={medium}
        mediumSensors={mediumSensors}
        sensorId={Number(sensorId)}
        minLimit={minLimit}
        maxLimit={maxLimit}
        onChangeMinLimit={limit => setMinLimit(limit)}
        onChangeMaxLimit={limit => setMaxLimit(limit)}
        onChangeLineColor={color =>
          setConfig(prev => ({ ...prev, lineColor: color }))
        }
      />

      <KPage
        action={
          <IconButton onClick={event => setActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        avatar={
          <Avatar>
            <SVGStatus
              type={'security'}
              status={getStatusColor(
                folderSensors?.filter(FILTER_IS_TYPE_MEDIUM)
              )}
            />
          </Avatar>
        }
        title={title}
      >
        {/* Sensor Time Series */}
        {!isPageLoading && (
          <Card sx={{ minHeight: '100%' }} variant={'security'}>
            <CardHeader
              title={
                <HStack
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <HStack alignItems={'center'}>
                    <TextField
                      label={'Raumwert'}
                      onChange={onChangeMedium}
                      select
                      type={'text'}
                      value={medium || ''}
                      variant={'filled'}
                    >
                      {sensorTypes.map((medium, key) => (
                        <MenuItem key={key} value={medium}>
                          <HStack>
                            <ListItemIcon>
                              <Box
                                alt={''}
                                component={'img'}
                                src={SensorIcon(
                                  medium,
                                  getStatusColor(
                                    folderSensors
                                      .filter(FILTER_IS_TYPE_SECURITY)
                                      .filter(FILTER_IS_TYPE_MEDIUM)
                                      .filter(
                                        f =>
                                          normalizedSensorType(
                                            f.sensor_type
                                          ) === medium
                                      )
                                  )
                                )}
                                sx={{ width: 25 }}
                              />
                            </ListItemIcon>
                            <ListItemText>{sensorType(medium)}</ListItemText>
                          </HStack>
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label={'Messpunkt'}
                      onChange={onChangeSensor}
                      select
                      type={'text'}
                      value={
                        mediumSensors?.find(
                          f => Number(f.entity_id) === Number(sensorId)
                        ) || ''
                      }
                      variant={'filled'}
                    >
                      {mediumSensors
                        ?.filter(
                          f => normalizedSensorType(f.sensor_type) === medium
                        )
                        .sort(SORT_NAME)
                        .map((sensor, key) => (
                          <MenuItem key={key} value={sensor}>
                            <HStack>
                              <ListItemIcon>
                                <Box
                                  alt={''}
                                  component={'img'}
                                  src={SensorIcon(medium, sensor.status)}
                                  sx={{ width: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText>{sensor.name}</ListItemText>
                            </HStack>
                          </MenuItem>
                        ))}
                    </TextField>

                    <Tooltip title={'Grenzwerte'}>
                      <ToggleButton
                        onClick={() =>
                          setConfig(prev => ({ ...prev, limitsDialog: true }))
                        }
                        value={limitsDialog}
                      >
                        <NotificationImportantTwoTone />
                      </ToggleButton>
                    </Tooltip>
                  </HStack>

                  <HStack alignItems={'center'}>
                    <ChartTypeSelector
                      exclusive
                      onChange={(event, chartType) => {
                        if (chartType !== null)
                          setConfig(prev => ({ ...prev, chartType }))
                      }}
                      sx={{ mx: 1 }}
                      value={chartType}
                    />

                    <Tooltip title={'Exportieren'}>
                      <IconButton
                        onClick={() =>
                          setConfig(prev => ({ ...prev, exportDialog: true }))
                        }
                      >
                        <DownloadTwoTone />
                      </IconButton>
                    </Tooltip>

                    <TextField
                      label={'Datum'}
                      onChange={onChangeDate}
                      sx={{ ml: 1 }}
                      type={'date'}
                      value={fromDate}
                      variant={'filled'}
                    />
                  </HStack>
                </HStack>
              }
              variant={'security'}
            />

            <CardContent sx={{ p: '0px !important' }}>
              <Collapse in={showPeriodWarning}>
                <Alert severity={'warning'}>
                  <AlertTitle>Achtung!</AlertTitle>
                  Bitte wählen Sie ein Startdatum, für welches bereits Daten
                  erhoben wurden und nicht in der Zukunft liegt.
                </Alert>
              </Collapse>

              <Collapse in={tsData?.length > 0 && !showPeriodWarning}>
                <DataSampling date={dataSamplingDate} />

                <TimeSeriesChart
                  backgroundColor={'white'}
                  chartType={chartType}
                  data={chartData}
                  medium={medium}
                  unit={sensorUnit(medium)}
                  series={{
                    1: { type: 'line', color: lineColor },
                    2: { type: 'line', color: lineColor },
                  }}
                />

                <Statistics
                  average={averageValue}
                  minimum={minValue}
                  maximum={maxValue}
                  unit={sensorUnit(medium)}
                />
              </Collapse>
            </CardContent>
          </Card>
        )}
      </KPage>
    </>
  )
}

SecurityHistory.propTypes = {
  PAGE_CONFIG: PropTypes.string,
  title: PropTypes.string,
}
