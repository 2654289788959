import { PropTypes } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { AddTwoTone } from '@mui/icons-material'
// scripts
import { useSensorBenchmark } from '../../../hooks'
import { sensorBenchmarkActions } from '../../../actions'
import { HStack, KTextField } from '../../../components'
import { useContractPool } from '../../../hooks'
import { DDMMYYYY, userLocales } from '../../../api'

const GermanDate = data => {
  return new Date(data).toLocaleDateString(userLocales, DDMMYYYY)
}

export const SensorBenchmarks = sensor => {
  const dispatch = useDispatch()
  const { data: contractPool } = useContractPool()

  const { entity_id } = sensor.sensor

  const { contractId } = sensor

  const contractData = contractPool.find(
    contract => Number(contract.id) === Number(contractId)
  )

  let date_begin = '2018-01-01'
  let date_expire = new Date(Date.now()).toISOString().split('T')[0]
  if (contractData && contractData.attributes) {
    date_begin = contractData.attributes.date_begin
    date_expire = contractData.attributes.date_expire
  }
  // limit date selection to contract lifetime
  const boundaries = date =>
    date < date_begin ? date_begin : date > date_expire ? date_expire : date
  const sanitize = date => boundaries(date)
  const { data: sensorBenchmarks, isLoading } = useSensorBenchmark(entity_id)
  const [editMode, setEditMode] = useState(false)
  const [newSensorBenchmark, setNewSensorBenchmark] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [warningText, setWarningText] = useState(false)
  // default sensor benchmark object shape
  const basicSensorBenchmark = {
    period_start: sanitize(date_begin),
    period_end: sanitize(date_expire),
    benchmark_name: null,
    value: null,
    unit: null,
    sensor_id: entity_id,
  }
  const [sensorBenchmark, setSensorBenchmark] = useState(basicSensorBenchmark)
  const germanNotation = value => value && value.toString().replace(',', '.')
  function removeEntityId(obj) {
    const newObj = { ...obj }
    if ('entity_id' in newObj) {
      delete newObj.entity_id
    }
    return newObj
  }
  useEffect(() => {
    if (!isLoading) {
      if (sensorBenchmarks.length >= 1) {
        const lastSensorBenchmark =
          sensorBenchmarks[sensorBenchmarks.length - 1]
        lastSensorBenchmark.attributes.entity_id =
          sensorBenchmarks[sensorBenchmarks.length - 1].id
        setSensorBenchmark(lastSensorBenchmark.attributes)
      }
    }
  }, [isLoading, sensorBenchmarks])
  useEffect(() => {
    if (!isLoading) {
      if (sensorBenchmarks.length > 1) {
        const checkSensorFactorsPeriodsOverlap = periods => {
          periods.sort(function (a, b) {
            return new Date(a.period_start) - new Date(b.period_start)
          })
          for (let i = 1; i < periods.length; i++) {
            if (
              new Date(periods[i].period_start) <
              new Date(periods[i - 1].period_end)
            ) {
              return true
            }
          }
          return false
        }
        const checkForPeriodOverlap =
          checkSensorFactorsPeriodsOverlap(sensorBenchmarks)
        if (checkForPeriodOverlap) {
          setShowWarning(true)
          setWarningText(
            'Es gibt Überschneidungen zwischen den gesetzten Zeitäumen'
          )
        } else {
          setShowWarning(false)
        }
      }
    }
  }, [isLoading, sensorBenchmarks])

  useEffect(() => {
    if (sensor.sensor && sensor.sensor.entity_id) {
      setSensorBenchmark(prev => ({
        ...prev,
        sensor_id: sensor.sensor.entity_id,
      }))
    }
  }, [sensor.sensor])

  const saveSensorBenchmark = sensorBenchmark => {
    newSensorBenchmark === true
      ? dispatch(sensorBenchmarkActions.save(removeEntityId(sensorBenchmark)))
      : dispatch(
          sensorBenchmarkActions.update(
            sensorBenchmark,
            sensorBenchmark.entity_id
          )
        )
  }
  const deleteSensorBenchmark = sensorBenchmark => {
    dispatch(
      sensorBenchmarkActions.remove(sensorBenchmark.entity_id, entity_id)
    )
  }
  const handleRowClick = rowInfo => {
    setEditMode(true)
    setNewSensorBenchmark(false)
    console.log(rowInfo)
    setSensorBenchmark(rowInfo.attributes)
  }

  return (
    <>
      <Dialog
        maxWidth={'lg'}
        open={editMode}
        onClose={() => {
          setEditMode(false)
        }}
      >
        <DialogTitle>
          {newSensorBenchmark === true
            ? 'Hier können Sie neue Benchmarks für einen gewünschten Zeitraum erfassen'
            : 'Benchmarks für Zeitraum bearbeiten'}
        </DialogTitle>
        <DialogContent>
          {' '}
          <HStack>
            <TextField
              label={'Beginn Zeitraum'}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: sanitize(date_begin),
                max: sanitize(date_expire),
              }}
              onChange={e =>
                setSensorBenchmark(prev => ({
                  ...prev,
                  period_start: e.target.value,
                }))
              }
              type={'date'}
              value={sensorBenchmark.period_start}
              fullWidth
            />
            <TextField
              label={'Ende Zeitraum'}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: sanitize(date_begin),
                max: sanitize(date_expire),
              }}
              onChange={e =>
                setSensorBenchmark(prev => ({
                  ...prev,
                  period_end: e.target.value,
                }))
              }
              type={'date'}
              value={sensorBenchmark.period_end}
              fullWidth
            />
            <KTextField
              error={!sensorBenchmark.benchmark_name}
              inputProps={{ min: 0 }}
              label={'Name'}
              onChange={e =>
                setSensorBenchmark(prev => ({
                  ...prev,
                  benchmark_name: e.target.value,
                }))
              }
              type={'string'}
              value={germanNotation(sensorBenchmark.benchmark_name)}
            />
            <KTextField
              label={'Wert'}
              onChange={e => {
                setSensorBenchmark(prev => ({
                  ...prev,
                  value: e.target.value,
                }))
              }}
              type={'number'}
              value={germanNotation(sensorBenchmark.value)}
            />
            <KTextField
              error={!sensorBenchmark.unit}
              label={'Einheit'}
              onChange={e =>
                setSensorBenchmark(prev => ({
                  ...prev,
                  unit: e.target.value,
                }))
              }
              type={'string'}
              value={germanNotation(sensorBenchmark.unit)}
            />
          </HStack>{' '}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditMode(false)
            }}
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              setEditMode(false)
              deleteSensorBenchmark(sensorBenchmark)
            }}
          >
            Löschen
          </Button>
          <Button
            onClick={() => {
              setEditMode(false)
              saveSensorBenchmark(sensorBenchmark)
            }}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      <Collapse in={showWarning}>
        <Alert severity={'warning'}>{warningText}</Alert>
      </Collapse>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Beginn Zeitraum</TableCell>
              <TableCell>Ende Zeitraum</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Wert</TableCell>
              <TableCell>Einheit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sensorBenchmarks
              .filter(f => f.attributes.sensor_id === entity_id)
              .map(benchmark => (
                <TableRow
                  key={benchmark.id}
                  hover
                  onClick={() => handleRowClick(benchmark)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    {GermanDate(benchmark.attributes.period_start)}
                  </TableCell>
                  <TableCell>
                    {GermanDate(benchmark.attributes.period_end)}
                  </TableCell>
                  <TableCell>{benchmark.attributes.benchmark_name}</TableCell>
                  <TableCell>{benchmark.attributes.value}</TableCell>
                  <TableCell>{benchmark.attributes.unit}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <IconButton
        onClick={() => {
          setEditMode(true)
          setNewSensorBenchmark(true)
        }}
      >
        <AddTwoTone />
      </IconButton>
    </>
  )
}

SensorBenchmarks.propTypes = {
  sensor: PropTypes.object,
}
