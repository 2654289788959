import axios from 'axios'
import { authHeader } from '../api'

const { REACT_APP_API_URL } = process.env

const getAll = async (folderId, fromDate, toDate, period) => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/consumption/folder/${folderId}?from=${fromDate}&to=${toDate}&period=${period}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    const { response } = error
    if (response) {
      const { data, status } = response
      const { message } = data
      if (status === 400 && message.includes('Bad Request')) {
        const sensor = message.split('for')[1].replace(/.$/, '')
        return {
          factorError: true,
          sensor: sensor,
        }
      }
    }

    return { error: error.message }
  }
}

const getAllMeterReading = async (folderId, fromDate, toDate) => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/consumption/meter-reading/folder/${folderId}?from=${fromDate}&to=${toDate}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('consumptionService.getAllMeterReading', error)
    const { response } = error
    const { data, status } = response
    const { message } = data
    if (status === 400 && message.includes('Bad Request')) {
      const sensor = message.split('for')[1].replace(/.$/, '')
      return {
        factorError: true,
        sensor: sensor,
      }
    }
  }
}

const getCompareConsumption = async (folderId, fromDate, toDate, period) => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/consumption/folder/${folderId}?from=${fromDate}&to=${toDate}&period=${period}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('consumptionService.getCompareConsumption', error)
    const { response } = error
    const { data, status } = response
    const { message } = data
    if (status === 400 && message.includes('Bad Request')) {
      const sensor = message.split('for')[1].replace(/.$/, '')
      return {
        factorError: true,
        sensor: sensor,
      }
    }
  }
}

export const consumptionService = {
  getAll,
  getAllMeterReading,
  getCompareConsumption,
}
