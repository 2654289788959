import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { authHeader, normalizedSensorType } from '../../api'

export const useTimeSeriesByFeelingTypes = (folderData, fromDate, toDate) => {
  const dispatch = useDispatch()
  const [isError, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [timeSeries, setTimeSeries] = useState({ items: [] })

  useEffect(() => {
    if (folderData?.sensors?.length > 0) {
      setIsLoading(true)
      setTimeSeries({ items: [] })
      let filteredSensors

      const API_NAME = 'karmaApi'
      const options = { method: 'GET', headers: authHeader() }

      const fetchTimeSeries = async (id, fromDate, toDate) => {
        try {
          const endpoint = `/sensors/admin/history/${id}?from=${fromDate}&to=${toDate}`
          const response = await API.get(API_NAME, endpoint, options)
          const result = response.data.attributes

          if (result) {
            setTimeSeries(prevTimeSeries => ({
              items: {
                ...prevTimeSeries.items,
                [id]: result.time_series,
              },
            }))
          }
        } catch (error) {
          setError(error)
        }
      }

      const { sensors } = folderData
      if (sensors) {
        const allowedSensorTypes = ['temperature', 'co2', 'light', 'humidity']
        filteredSensors = sensors.filter(f =>
          allowedSensorTypes.includes(normalizedSensorType(f.sensor_type))
        )

        Promise.all(
          filteredSensors.map(sensor => {
            return fetchTimeSeries(
              sensor.entity_id,
              fromDate + ' 00:00:00',
              toDate + ' 23:59:59'
            )
          })
        )
          .then(() => {
            setIsLoading(false)
          })
          .catch(err => {
            setError(err)
            setIsLoading(false)
          })
      } else {
        setIsLoading(false)
      }
    }
  }, [dispatch, folderData, fromDate, toDate])

  return { isLoading, isError, timeSeries }
}
