import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useTypeInFolder = sensorId => {
  const [data, setData] = useState('')

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const sensorData = sensorPool.find(f => Number(f.id) === sensorId)
    if (!sensorData?.attributes?.type_in_folder) return

    const typeInFolder = sensorData?.attributes?.type_in_folder
    if (typeInFolder) setData(typeInFolder)
  }, [sensorPool, sensorId])

  return data
}

useTypeInFolder.propTypes = {
  sensorId: PropTypes.number,
}
