import {
  AccountBalanceTwoTone,
  ArrowBackTwoTone,
  ArrowForwardTwoTone,
  InfoTwoTone,
  MemoryTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../actions'
import {
  colorSchemeConsumption,
  DDMMYY,
  DDMMYYYY,
  DDMMYYYYHHMM,
  germanDecimals,
  getHoursArray,
  getDaysArray,
  getMonthsArray,
  MMMYY,
  userLocales,
  MMMMYYYY,
  WDDDMMYYYY,
  WDDDMMYYHHMM,
} from '../../api'
import {
  DlgPanelDelete,
  DlgPanelEdit,
  DlgPanelEditSummary,
  HStack,
  PanelDisplayNames,
  StackSelector,
} from '..'
import {
  useConsumptionByDashboardId,
  useDataPointsByPanelIds,
  useFolderPool,
  usePanels,
  useSensorPool,
  useSensorPricePool,
} from '../../hooks'
import { BtnEditPanel } from '../button/BtnEditPanel'

const chartTypes = {
  pie_chart: 1,
  column_chart: 2,
  table: 3,
}

const indicatorType = {
  consumption: 1,
  consumption_per_sqm: 2,
  cost: 3,
  cost_per_sqm: 4,
  co2: 5,
  co2_per_sqm: 6,
}

const indicatorAttributes = {
  [indicatorType.consumption]: 'total_consumption',
  [indicatorType.consumption_per_sqm]: 'total_consumption_per_sqm',
  [indicatorType.cost]: 'total_cost',
  [indicatorType.cost_per_sqm]: 'total_cost_per_sqm',
  [indicatorType.co2]: 'total_co2',
  [indicatorType.co2_per_sqm]: 'total_co2_per_sqm',
}

const indicatorWarnings = {
  [indicatorType.consumption]:
    'Es gibt Sensoren ohne passenden Indikator: Verbrauch',
  [indicatorType.consumption_per_sqm]:
    'Es gibt Sensoren ohne passende Indikatoren: Verbrauch & Fläche',
  [indicatorType.cost]: 'Es gibt Sensoren ohne passenden Indikator: Kosten',
  [indicatorType.cost_per_sqm]:
    'Es gibt Sensoren ohne passende Indikatoren: Kosten & Fläche',
  [indicatorType.co2]: 'Es gibt Sensoren ohne passenden Indikator: CO2',
  [indicatorType.co2_per_sqm]:
    'Es gibt Sensoren ohne passende Indikatoren: CO2 & Fläche',
}

const digitGrouping = digit =>
  Number(digit).toLocaleString(userLocales, germanDecimals)

const granularityTitle = granularity =>
  granularity === 'h'
    ? 'Stündlich'
    : granularity === 'd'
      ? 'Täglich'
      : granularity === 'm'
        ? 'Monatlich'
        : granularity

const calculateAmount = (data, indicator) => {
  const {
    total_consumption,
    total_consumption_per_sqm,
    total_co2,
    total_co2_per_sqm,
    total_cost,
    total_cost_per_sqm,
  } = data.attributes.consumptions

  return indicator === indicatorType.cost
    ? total_cost
    : indicator === indicatorType.cost_per_sqm
      ? total_cost_per_sqm
      : indicator === indicatorType.co2
        ? total_co2
        : indicator === indicatorType.co2_per_sqm
          ? total_co2_per_sqm
          : indicator === indicatorType.consumption
            ? total_consumption
            : indicator === indicatorType.consumption_per_sqm
              ? total_consumption_per_sqm
              : 0
}

const createConsumptionValuesMap = (values, userLocales, format) =>
  new Map(
    values.map(value => [
      new Date(value.timepoint).toLocaleString(userLocales, format),
      value,
    ])
  )

const formatDateToLocaleString = (date, userLocales, format) =>
  typeof date === 'string'
    ? date
    : new Date(date).toLocaleString(userLocales, format)

const createBaseDataColumnChart = (
  consumptionData,
  granularity,
  fromDate,
  toDate,
  indicator
) => {
  let columns = [granularityTitle(granularity)]

  const format =
    granularity === 'h'
      ? DDMMYYYYHHMM
      : granularity === 'd'
        ? DDMMYY
        : granularity === 'm'
          ? MMMYY
          : DDMMYY

  let data =
    granularity === 'h'
      ? getHoursArray(new Date(fromDate), new Date(toDate))
      : granularity === 'd'
        ? getDaysArray(new Date(fromDate), new Date(toDate))
        : granularity === 'm'
          ? getMonthsArray(fromDate, toDate)
          : []

  if (granularity === 'd') data = data.map(day => [day])
  if (granularity === 'm') data = data.map(day => [day])

  consumptionData.forEach(sensor => {
    const { consumptions: consumption, name } = sensor.attributes

    const addConsumption = record => {
      const consumptionValuesMap = createConsumptionValuesMap(
        consumption.values,
        userLocales,
        format
      )
      const timepoint = formatDateToLocaleString(record[0], userLocales, format)

      const data = consumptionValuesMap.get(timepoint)
      const aggregated = data
        ? indicator === indicatorType.cost
          ? data.cost === null
            ? 0
            : data.cost
          : indicator === indicatorType.cost_per_sqm
            ? data.cost_per_sqm === null
              ? 0
              : data.cost_per_sqm
            : indicator === indicatorType.co2
              ? data.co2 === null
                ? 0
                : data.co2
              : indicator === indicatorType.co2_per_sqm
                ? data.co2_per_sqm === null
                  ? 0
                  : data.co2_per_sqm
                : indicator === indicatorType.consumption
                  ? data.value === null
                    ? 0
                    : data.value
                  : indicator === indicatorType.consumption_per_sqm
                    ? data.value_per_sqm === null
                      ? 0
                      : data.value_per_sqm
                    : 0
        : 0
      const roundedValue =
        aggregated !== null ? parseFloat(aggregated.toFixed(2)) : 0
      return [...record, roundedValue || 0]
    }

    columns = [...columns, name]

    data = data.map(record => addConsumption(record))
  })

  data.sort((a, b) =>
    new Date(a[0]).getTime() > new Date(b[0]).getTime() ? 1 : -1
  )

  // fill columns data to minimum if no data
  if (data.length === 0) data = [granularity, granularity]

  // add columns header (sensor names) on top of data
  data = [columns, ...data]

  // add dummy data if no data to render
  if (data.length === 1) data.push(columns.map(() => 0))

  return data
}

const createBaseDataFolderColumnChart = (
  granularity,
  consumptions,
  fromDate,
  toDate,
  indicator
) => {
  let columns = [granularityTitle(granularity)]

  const format =
    granularity === 'h'
      ? DDMMYYYYHHMM
      : granularity === 'd'
        ? DDMMYY
        : granularity === 'm'
          ? MMMYY
          : DDMMYY

  let data =
    granularity === 'h'
      ? getHoursArray(new Date(fromDate), new Date(toDate))
      : granularity === 'd'
        ? getDaysArray(new Date(fromDate), new Date(toDate))
        : granularity === 'm'
          ? getMonthsArray(fromDate, toDate)
          : []

  if (granularity === 'd') data = data.map(day => [day])
  if (granularity === 'm') data = data.map(day => [day])

  consumptions.forEach(sensor => {
    const { consumptions: consumption, name } = sensor.attributes

    const addConsumption = record => {
      const consumptionValuesMap = createConsumptionValuesMap(
        consumption.values,
        userLocales,
        format
      )

      const timepoint = formatDateToLocaleString(record[0], userLocales, format)

      const data = consumptionValuesMap.get(timepoint)

      const aggregated = data
        ? indicator === indicatorType.cost
          ? data.cost === null
            ? 0
            : data.cost
          : indicator === indicatorType.cost_per_sqm
            ? data.cost_per_sqm === null
              ? 0
              : data.cost_per_sqm
            : indicator === indicatorType.co2
              ? data.co2 === null
                ? 0
                : data.co2
              : indicator === indicatorType.co2_per_sqm
                ? data.co2_per_sqm === null
                  ? 0
                  : data.co2_per_sqm
                : indicator === indicatorType.consumption
                  ? data.value === null
                    ? 0
                    : data.value
                  : indicator === indicatorType.consumption_per_sqm
                    ? data.value_per_sqm === null
                      ? 0
                      : data.value_per_sqm
                    : 0
        : 0

      let roundedValue =
        aggregated !== null ? parseFloat(aggregated.toFixed(2)) : 0

      if (indicator === 2 || indicator === 4 || indicator === 6)
        roundedValue = roundedValue / consumptions.length

      return [...record, roundedValue || 0]
    }

    columns = [...columns, name]

    data = data.map(record => addConsumption(record))
  })

  data.sort((a, b) =>
    new Date(a[0]).getTime() > new Date(b[0]).getTime() ? 1 : -1
  )

  // fill columns data to minimum if no data
  if (data.length === 0) data = [granularity, granularity]

  // add columns header (sensor names) on top of data
  data = [columns, ...data]

  // add dummy data if no data to render
  if (data.length === 1) data.push(columns.map(() => 0))

  return data
}

const addTooltipColumnChart = (data, granularity, unit) => {
  const combined = []
  const dateFormat =
    granularity === 'h'
      ? WDDDMMYYHHMM
      : granularity === 'd'
        ? WDDDMMYYYY
        : granularity === 'm'
          ? MMMMYYYY
          : DDMMYYYY

  data.forEach((row, key) => {
    if (key === 0) {
      combined.push([row[key]])

      row.forEach((col, j) => {
        if (j > 0)
          combined[key].push(col, {
            type: 'string',
            role: 'tooltip',
            p: { html: true },
          })
      })
    } else {
      combined[key] = [row[0]]

      row.forEach((col, j) => {
        if (j > 0) {
          const name = data[0][j]
          const timestamp = combined[key][0].toLocaleString(
            userLocales,
            dateFormat
          )

          combined[key][0] = timestamp
          combined[key].push(
            col,
            `<div style="padding:5px 10px; min-width:220px;"><p><strong>${timestamp}</strong></p><p>${name}: <strong>${digitGrouping(
              col
            )} ${unit}</strong></p></div>`
          )
        }
      })
    }
  })

  return combined
}

export const SinglePanel = ({ dashboardId, panelId }) => {
  const { data: panelsData, isLoading: isPanelsLoading } =
    usePanels(dashboardId)

  useEffect(() => {
    if (panelsData?.length === 0) return

    const handle = panelsData.find(f => Number(f.id) === panelId)
    if (!handle) return

    const {
      end_date,
      granularity,
      graphic_type: graphicType,
      indicator,
      name,
      position,
      presentation_level: presentationLevel,
      start_date,
    } = handle.attributes

    setTile({
      chartLegend: true,
      end_date,
      graphicType,
      granularity,
      indicator,
      isStacked: true,
      presentationLevel,
      name,
      position,
      start_date,
      unit: 'kWh',
    })
  }, [panelsData, panelId])

  const { data: consumptionData, isLoading: isDashboardConsumptionLoading } =
    useConsumptionByDashboardId(dashboardId)

  const { dataPoints, isLoading: isDataPointsLoading } =
    useDataPointsByPanelIds(dashboardId, panelsData)

  useEffect(() => {
    if (
      consumptionData.length === 0 ||
      Object.keys(dataPoints.items || {}).length === 0
    )
      return

    const sensorIds = dataPoints.items[panelId] ?? []
    const cData = consumptionData.filter(f => sensorIds.includes(Number(f.id)))

    setExtractedSensorIDs(cData.map(item => Number(item.id)))
  }, [consumptionData, dataPoints, panelId])

  /* */

  const theme = useTheme()

  const dispatch = useDispatch()

  const [showDlg, setShowDlg] = useState(false)

  const { data: folderPool, isLoading: isFolderPoolLoading } = useFolderPool()
  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()
  const { isLoading: isSensorPricePoolLoading } = useSensorPricePool()

  const isPageLoading =
    isFolderPoolLoading ||
    isSensorPoolLoading ||
    isSensorPricePoolLoading ||
    isPanelsLoading ||
    isDashboardConsumptionLoading ||
    isDataPointsLoading

  const [extractedSensorIDs, setExtractedSensorIDs] = useState([])
  const [extractedSensorNames, setExtractedSensorNames] = useState([])

  const [extractedFolderIDs, setExtractedFolderIDs] = useState([])
  const [extractedFolderNames, setExtractedFolderNames] = useState([])

  useEffect(() => {
    extractedSensorIDs.forEach(sensorId => {
      const folder = folderPool.find(f =>
        f.attributes.folder_sensors.some(s => Number(s.sensor_id) === sensorId)
      )
      if (folder) {
        const { name: folderName } = folder.attributes

        setExtractedFolderIDs(prev => [...prev, Number(folder.id)])
        setExtractedFolderNames(prev => [...prev, folderName])
      }

      const sensor = sensorPool.find(f => f.attributes.entity_id === sensorId)
      if (sensor) {
        const { name: folderName } = folder.attributes
        const { name: sensorName, unit } = sensor.attributes

        setExtractedSensorNames(prev => [
          ...prev,
          `${sensorName} | Gebäude ${folderName}`,
        ])

        setTile(prev => ({ ...prev, unit }))
      }
    })
  }, [extractedSensorIDs, folderPool, sensorPool])

  const [tile, setTile] = useState({})

  const [showWarning, setShowWarning] = useState(false)
  const [warningText, setWarningText] = useState(false)
  const [warningSensors, setWarningSensors] = useState([])
  const [warningSensorsIds, setWarningSensorsIds] = useState([])
  const [warningAnchorEl, setWarningAnchorEl] = useState(false)

  const [total, setTotal] = useState(0)
  const [chartData, setChartData] = useState([])
  const [noPieChartData, setNoPieChartData] = useState(false)

  useEffect(
    () => setSelectedFolders([...new Set(extractedFolderIDs)]),
    [extractedFolderIDs]
  )

  useEffect(
    () => setSelectedSensors([...new Set(extractedSensorIDs)]),
    [extractedSensorIDs]
  )

  const [selectedFolders, setSelectedFolders] = useState([])
  const [selectedSensors, setSelectedSensors] = useState(extractedSensorIDs)

  const chartType =
    tile.graphicType === chartTypes.pie_chart
      ? 'PieChart'
      : tile.graphicType === chartTypes.column_chart
        ? 'ColumnChart'
        : tile.graphicType === chartTypes.table
          ? 'Table'
          : 'LineChart'

  const chartIndicator =
    tile.indicator === indicatorType.consumption
      ? 'Verbrauch'
      : tile.indicator === indicatorType.consumption_per_sqm
        ? 'Verbrauch/m²'
        : tile.indicator === indicatorType.cost
          ? 'Kosten'
          : tile.indicator === indicatorType.cost_per_sqm
            ? 'Kosten/m²'
            : tile.indicator === indicatorType.co2
              ? 'CO₂-Ausstoß'
              : 'CO₂-Ausstoß/m²'

  const chartUnit =
    tile.indicator === indicatorType.consumption ||
    tile.indicator === indicatorType.consumption_per_sqm
      ? tile.unit
      : tile.indicator === indicatorType.cost ||
          tile.indicator === indicatorType.cost_per_sqm
        ? '€'
        : tile.indicator === indicatorType.co2 ||
            tile.indicator === indicatorType.co2_per_sqm
          ? 'kg'
          : 'kWh'

  const LVL_FOLDER = 1
  const LVL_SENSOR = 2

  const listSource =
    tile.presentationLevel === LVL_SENSOR
      ? [...new Set(extractedSensorNames)]
      : [...new Set(extractedFolderNames)]

  const listLabel = tile.presentationLevel === LVL_SENSOR ? 'sensor' : 'folder'

  const chartAreaTop =
    chartType === 'PieChart' ? 32 : chartType === 'ColumnChart' ? 32 : 0
  const chartAreaLeft =
    chartType === 'PieChart' ? 32 : chartType === 'ColumnChart' ? 64 : 0
  const chartAreaBottom =
    chartType === 'PieChart' ? 32 : chartType === 'ColumnChart' ? 56 : 0
  const chartAreaRight =
    chartType === 'PieChart' ? 32 : chartType === 'ColumnChart' ? 0 : 0

  const deletePanel = () =>
    dispatch(dashboardActions.removePanel(dashboardId, panelId))
  const handleStackMode = (event, isStacked) => {
    if (isStacked !== null) setTile(prev => ({ ...prev, isStacked }))
  }
  const onChangeGraphicType = graphicType =>
    setTile(prev => ({ ...prev, graphicType }))
  const onChangeIndicator = indicator =>
    setTile(prev => ({ ...prev, indicator }))
  const onChangeName = name => setTile(prev => ({ ...prev, name }))
  const onChangePanelUnit = unit => setTile(prev => ({ ...prev, unit }))
  const onChangePresentationLevel = presentationLevel =>
    setTile(prev => ({ ...prev, presentationLevel }))
  const onCloseDelete = () => setShowDlg(false)
  const onCloseSettings = () => {
    setShowDlg(false)
    setSelectedSensors(extractedSensorIDs)
    setSelectedFolders([...new Set(extractedFolderIDs)])
  }
  const onCloseSummary = () => setShowDlg(false)
  const onEditPanel = () => setShowDlg('Edit')
  const onGotoSummary = () => setShowDlg('Summary')
  const onToggleLegend = () =>
    setTile(prev => ({
      ...prev,
      chartLegend: !prev.chartLegend,
    }))
  const onToggleWarning = event =>
    setWarningAnchorEl(warningAnchorEl ? null : event.currentTarget)
  const updatePanel = () => {
    const editPanel = {
      entity_id: panelId,
      dashboard_id: dashboardId,
      name: tile.name,
      position: tile.positon,
      indicator: tile.indicator,
      graphic_type: tile.graphicType,
      start_date: new Date(tile.start_date).toISOString().split('T')[0],
      end_date: new Date(tile.end_date).toISOString().split('T')[0],
      presentaton_level: tile.presentationLevel,
      granularity: tile.granularity,
    }

    const editDataPoint = {
      panel_id: panelId,
      sensor_ids: selectedSensors.filter(f => !warningSensorsIds.includes(f)),
    }

    dispatch(dashboardActions.updatePanel(editPanel))
    dispatch(dashboardActions.updateDataPoints(editDataPoint, dashboardId))
  }

  useEffect(() => {
    if (!consumptionData) return

    const cData = consumptionData.filter(f =>
      extractedSensorIDs.includes(Number(f.id))
    )

    let total = 0
    let totalObject = 0

    const getSensorById = id =>
      sensorPool.find(s => Number(s.id) === Number(id))

    if (tile.presentationLevel === LVL_SENSOR) {
      let numberOfSensors = 0

      cData.map(item => {
        if (item) {
          total += calculateAmount(item, tile.indicator)
          numberOfSensors++
        }

        return null
      })

      let data

      if (chartType === 'ColumnChart') {
        data = addTooltipColumnChart(
          createBaseDataColumnChart(
            cData,
            tile.granularity,
            tile.start_date,
            tile.end_date,
            tile.indicator
          ),
          tile.granularity,
          chartUnit
        )
      } else if (chartType === 'PieChart') {
        data = cData.map(item => {
          const sensor = getSensorById(item.id)
          if (!sensor) return null

          const { name } = sensor.attributes
          const amount = calculateAmount(item, tile.indicator)
          const tooltipContent = `<div style="padding:5px 10px; min-width:220px;"><p>${name}: <strong>${digitGrouping(amount)} ${chartUnit}</strong></p></div>`

          return [name, amount, tooltipContent]
        })

        data.unshift([
          'Messpunkt',
          'Verbrauch',
          { role: 'tooltip', type: 'string', p: { html: true } },
        ])
      } else {
        data = cData.map(item => {
          const sensor = getSensorById(item.id)

          if (!sensor) return null

          const { name, entity_id } = sensor.attributes
          const amount = calculateAmount(item, tile.indicator)
          const folder = folderPool.find(f =>
            f.attributes.folder_sensors.some(
              sensor => Number(sensor.sensor_id) === Number(entity_id)
            )
          )

          return [
            `${name}  |  ${folder ? folder.attributes.name : ''}`,
            {
              v: amount,
              f: ` ${digitGrouping(amount)} ${chartUnit}`,
            },
          ]
        })

        data.sort((a, b) => {
          if (!a?.[1] || !b?.[1]) return 0

          const valueA = parseFloat(
            a[1].f.replace(/[^\d.,]/g, '').replace(',', '.')
          )
          const valueB = parseFloat(
            b[1].f.replace(/[^\d.,]/g, '').replace(',', '.')
          )

          return valueB - valueA
        })

        data.unshift(['Messpunkt', `${chartIndicator}`])
      }

      if (
        tile.indicator === 2 ||
        tile.indicator === 4 ||
        tile.indicator === 6
      ) {
        if (numberOfSensors > 0) total /= numberOfSensors
      }

      setNoPieChartData(total === 0)
      setChartData(data)
      setTotal(total)
    } else if (tile.presentationLevel === LVL_FOLDER) {
      let _data = []
      let numberOfFolders = 0

      selectedFolders.forEach(folderId => {
        const folder = folderPool.find(f => Number(f.id) === Number(folderId))
        if (!folder) return

        const { name, folder_sensors } = folder.attributes

        const targetSensorIds = folder_sensors.map(item =>
          item.sensor_id.toString()
        )

        const filteredConsumptions = consumptionData.filter(f =>
          targetSensorIds.includes(f.id.toString())
        )

        if (!filteredConsumptions) return
        const numberOfSensors = filteredConsumptions.length
        numberOfFolders++

        filteredConsumptions.map(item => {
          if (!item) return null

          const amount = calculateAmount(item, tile.indicator)

          totalObject +=
            tile.indicator === 2 || tile.indicator === 4 || tile.indicator === 6
              ? amount / numberOfSensors
              : amount
        })

        let sensorsTotal = 0

        if (chartType === 'ColumnChart') {
          const chartData = createBaseDataFolderColumnChart(
            tile.granularity,
            filteredConsumptions,
            tile.start_date,
            tile.end_date,
            tile.indicator
          )

          chartData.map((item, key) => {
            if (key === 0) return null

            const date = item[0]

            let sum = item.slice(1)
            sum = sum.reduce((partialSum, a) => partialSum + a, 0)

            chartData[key] = [date, sum]

            return null
          })

          chartData[0] = ['Zeit', name]

          _data.push(chartData)
        } else if (chartType === 'PieChart') {
          filteredConsumptions.map(item => {
            const sensor = getSensorById(item.id)

            if (sensor) sensorsTotal += calculateAmount(item, tile.indicator)

            return null
          })

          if (
            tile.indicator === 2 ||
            tile.indicator === 4 ||
            tile.indicator === 6
          )
            sensorsTotal /= numberOfSensors

          const tooltipContent = `<div style="padding:5px 10px; min-width:220px;"><p>${name}: <strong>${digitGrouping(sensorsTotal)} ${chartUnit}</strong></p></div>`

          _data.push([name, sensorsTotal, tooltipContent])
        } else {
          const data = filteredConsumptions.map(item => {
            const sensor = getSensorById(item.id)

            if (sensor) {
              const { entity_id } = sensor.attributes
              const amount = calculateAmount(item, tile.indicator)
              sensorsTotal += amount

              const folder = folderPool.find(f =>
                f.attributes.folder_sensors.some(
                  sensor => Number(sensor.sensor_id) === Number(entity_id)
                )
              )

              return [
                `${name}  |  ${folder ? folder.attributes.name : ''}`,
                ` ${digitGrouping(amount)} ${chartUnit}`,
              ]
            }

            return null
          })

          data.sort((a, b) => {
            if (!a?.[1] || !b?.[1]) return 0

            const valueA = parseFloat(
              a[1].replace(/[^\d.,]/g, '').replace(',', '.')
            )
            const valueB = parseFloat(
              b[1].replace(/[^\d.,]/g, '').replace(',', '.')
            )

            return valueB - valueA
          })

          if (
            tile.indicator === 2 ||
            tile.indicator === 4 ||
            tile.indicator === 6
          )
            sensorsTotal /= numberOfSensors

          _data.push([
            `${name}`,
            {
              v: sensorsTotal,
              f: ` ${digitGrouping(sensorsTotal)} ${chartUnit}`,
            },
          ])
        }
      })

      /* ColumnChart specific */
      if (chartType === 'ColumnChart') {
        let combinedData = []

        if (_data.length > 0) {
          combinedData = _data[0]

          _data.forEach((objects, key) => {
            if (key === 0) return

            objects.forEach((object, i) => combinedData[i].push(object[1]))
          })
        }

        _data = addTooltipColumnChart(combinedData, tile.granularity, chartUnit)
      }

      /* PieChart specific */
      if (chartType === 'PieChart') {
        _data.unshift([
          'Messpunkt',
          'Verbrauch',
          { role: 'tooltip', type: 'string', p: { html: true } },
        ])
      }

      /* ranking specific */
      if (chartType === 'Table')
        _data.unshift(['Gebäude ', `${chartIndicator}`])

      // set data
      setChartData(_data)

      // set total
      if (tile.indicator === 2 || tile.indicator === 4 || tile.indicator === 6)
        if (numberOfFolders > 0) totalObject /= numberOfFolders
      setTotal(totalObject)
    }
  }, [
    chartIndicator,
    chartType,
    chartUnit,
    consumptionData,
    folderPool,
    tile.end_date,
    tile.granularity,
    tile.indicator,
    tile.presentationLevel,
    tile.start_date,
    sensorPool,
    selectedFolders,
    extractedSensorIDs,
  ])

  useEffect(() => {
    if (selectedSensors.length === 0) return

    // reset warning
    setShowWarning(false)
    setWarningText('')

    selectedSensors.forEach(sensorId => {
      const sensor = sensorPool.find(f => Number(f.id) === sensorId)
      if (!sensor) return
      const { entity_id, name: sensorName } = sensor.attributes

      const sensorConsumption = consumptionData.find(
        f => Number(f.id) === sensorId
      )

      const attributes = indicatorAttributes[tile.indicator]

      if (sensorConsumption?.attributes.consumptions[attributes] === null) {
        setShowWarning(true)
        setWarningText(indicatorWarnings[tile.indicator])

        setWarningSensors(prev => [...prev, sensorName])
        setWarningSensorsIds(prev => [...prev, entity_id])
      }
    })
  }, [selectedSensors, consumptionData, tile.indicator, sensorPool])

  return (
    <Card
      sx={{
        minWidth: 275,
        position: 'relative',
        border: 'solid 1px #ccc',
        borderRadius: 5,
        m: 1,
      }}
    >
      <CardHeader
        action={
          <>
            {chartType === 'ColumnChart' && (
              <StackSelector
                onChange={handleStackMode}
                value={tile.isStacked}
              />
            )}

            {!isPageLoading && <BtnEditPanel onClick={onEditPanel} />}
          </>
        }
        avatar={
          <Avatar>
            {tile.presentationLevel === LVL_SENSOR ? (
              <MemoryTwoTone />
            ) : (
              <AccountBalanceTwoTone />
            )}
          </Avatar>
        }
        subheader={
          isPageLoading
            ? 'wird aggregiert ...'
            : `${chartIndicator}: ${digitGrouping(total)} ${chartUnit}`
        }
        title={tile.name}
      />

      <Divider />

      {isPageLoading ? (
        <LinearProgress />
      ) : (
        <CardContent sx={{ p: '0 !important' }}>
          <Collapse in={true}>
            {/* warning */}
            <Collapse in={showWarning} sx={{ position: 'relative' }}>
              <Alert severity={'warning'}>{warningText}</Alert>
              <Tooltip title={'Messpunkte in Übersicht'}>
                <IconButton
                  onClick={onToggleWarning}
                  sx={{ position: 'absolute', top: 5, right: 20 }}
                >
                  <InfoTwoTone />
                </IconButton>
              </Tooltip>

              <Popover
                anchorEl={warningAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                onClose={() => setWarningAnchorEl(false)}
                open={Boolean(warningAnchorEl)}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 700, px: 2, py: 1 }}>
                    Messpunkte in Übersicht
                  </Typography>

                  <Divider />

                  <List sx={{ p: 1 }}>
                    {warningSensors.map((sensor, key) => (
                      <ListItem key={key}>
                        <ListItemText
                          primary={sensor}
                          sx={{ fontWeight: 10 }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Popover>
            </Collapse>

            {noPieChartData && chartType === 'PieChart' ? (
              <Collapse in={noPieChartData}>
                <Alert
                  severity={'warning'}
                  sx={{
                    borderBottomLeftRadius: 20,
                    borderBottomRightRadius: 20,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderColor: theme.palette.lightgray.light,
                  }}
                >
                  Keine Daten zum Darstellen für diesen Zeitraum
                </Alert>
              </Collapse>
            ) : (
              <HStack sx={{ alignItems: 'top' }}>
                <Chart
                  chartLanguage={'de_DE'}
                  chartType={chartType}
                  data={chartData}
                  options={{
                    chartArea: {
                      height: '500px',
                      width: '600px',
                      top: chartAreaTop,
                      left: chartAreaLeft,
                      bottom: chartAreaBottom,
                      right: chartAreaRight,
                    },
                    height: '500px',
                    width: '500px',
                    backgroundColor: 'white',
                    tooltip: { isHtml: true },
                    legend: 'none',
                    vAxis: { format: `#,###.## ${tile.unit}` },
                    hAxis: {
                      viewWindow: {
                        min:
                          selectedSensors.length === 0
                            ? new Date(tile.start_date)
                            : undefined,
                        max:
                          selectedSensors.length === 0
                            ? new Date(tile.end_date)
                            : undefined,
                      },
                      ticks: [
                        ...new Set(
                          chartData
                            .map((item, key) => {
                              if (!item?.[0] || key === 0) return null
                              return { v: item[0], f: item[0] }
                            })
                            .filter(f => f !== null)
                        ),
                      ],
                      textStyle: { fontSize: 10 },
                    },
                    colors: colorSchemeConsumption,
                    sliceVisibilityThreshold: 0,
                    isStacked: tile.isStacked,
                    pieStartAngle: 100,
                    sortColumn: 1,
                    sortAscending: false,
                    sort: 'enable',
                  }}
                />

                {chartType !== 'Table' && (
                  <Stack>
                    <Tooltip
                      title={
                        tile.chartLegend === true
                          ? 'Weniger anzeigen'
                          : 'Mehr anzeigen'
                      }
                    >
                      <IconButton onClick={onToggleLegend}>
                        {tile.chartLegend === true ? (
                          <ArrowBackTwoTone />
                        ) : (
                          <ArrowForwardTwoTone />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

                {tile.chartLegend === true && (
                  <Divider orientation={'vertical'} flexItem />
                )}

                {(chartType === 'PieChart' || chartType === 'ColumnChart') &&
                  tile.chartLegend === true && (
                    <PanelDisplayNames data={listSource} type={listLabel} />
                  )}
              </HStack>
            )}
          </Collapse>
        </CardContent>
      )}

      <DlgPanelEdit
        onClose={onCloseSettings}
        open={showDlg === 'Edit'}
        selectedFolders={selectedFolders}
        setSelectedFolders={setSelectedFolders}
        selectedSensors={selectedSensors}
        setSelectedSensors={setSelectedSensors}
        panelName={tile.name}
        onChangeName={onChangeName}
        presentationLevel={tile.presentationLevel}
        onChangePresentationLevel={onChangePresentationLevel}
        indicator={tile.indicator}
        onChangeIndicator={onChangeIndicator}
        graphicType={tile.graphicType}
        onChangeGraphicType={onChangeGraphicType}
        onChangePanelUnit={onChangePanelUnit}
        onDeletePanel={() => deletePanel(panelId)}
        onGotoSummary={onGotoSummary}
      />

      <DlgPanelEditSummary
        onClose={onCloseSummary}
        onUpdate={() => updatePanel(panelId)}
        open={showDlg === 'Summary'}
        selectedFolders={selectedFolders}
        selectedSensors={selectedSensors}
        warningSensors={warningSensors}
      />

      <DlgPanelDelete
        name={tile.name}
        onCancel={onCloseDelete}
        onConfirm={() => deletePanel(panelId)}
        open={showDlg === 'Delete'}
      />
    </Card>
  )
}

SinglePanel.propTypes = {
  dashboardId: PropTypes.number,
  panelId: PropTypes.number,
}
