import { history } from '../api'
import { virtualSensorConstants as VSC } from '../constants'
import { virtualSensorService as VSS, tokenService as TS } from '../services'
import { alertActions as AA } from '.'

const remove = id => {
  const failure = error => ({ type: VSC.DELETE_VIRTUAL_SENSOR_ERROR, error })
  const request = () => ({ type: VSC.DELETE_VIRTUAL_SENSOR_REQUEST })
  const success = virtualsensor => ({
    type: VSC.DELETE_VIRTUAL_SENSOR_SUCCESS,
    virtualsensor,
  })

  return dispatch => {
    if (!id) return dispatch(AA.error(VSC.VIRTUAL_SENSOR_ID_REQUIRED))

    dispatch(request())

    VSS.deleteVirtualSensor(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(VSC.VIRTUAL_SENSOR_DELETED))

        history.push('/virtualsensors')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const deleteVirtualSensor = id => {
  const failure = error => ({ type: VSC.VIRTUAL_SENSOR_ID_REQUIRED, error })
  const request = () => ({ type: VSC.DELETE_VIRTUAL_SENSOR_REQUEST })
  const success = virtualsensor => ({
    type: VSC.DELETE_VIRTUAL_SENSOR_SUCCESS,
    virtualsensor,
  })

  return dispatch => {
    if (!id) return dispatch(AA.error(VSC.VIRTUAL_SENSOR_ID_REQUIRED))

    dispatch(request())

    VSS.deleteSensorPrice(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(VSC.VIRTUAL_SENSOR_ID_REQUIRED))

        history.push('/virtualsensors')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getAll = () => {
  const failure = error => ({ type: VSC.GET_ALL_ERROR, error })
  const request = () => ({ type: VSC.GET_ALL_REQUEST })
  const success = virtualSensor => ({
    type: VSC.GET_ALL_SUCCESS,
    virtualSensor,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      VSS.getAll()
        .then(result => {
          const { error, items } = result

          if (error) {
            const message = `VirtualSensors Endpoint: ${error}`

            dispatch(failure(message))
            dispatch(AA.error(message))
          }

          if (items) {
            dispatch(success(result))
          }
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

const save = data => {
  const failure = error => ({ type: VSC.SAVE_VIRTUAL_SENSOR_ERROR, error })
  const request = () => ({ type: VSC.SAVE_VIRTUAL_SENSOR_REQUEST })
  const success = virtualsensor => ({
    type: VSC.SAVE_VIRTUAL_SENSOR_SUCCESS,
    virtualsensor,
  })

  const saveData = data => {
    const recordData = data => {
      const { name, originalSensorId } = data

      return {
        data: {
          attributes: {
            virtual_sensor_name: name,
            sensors_to_be_combined: originalSensorId,
          },
        },
      }
    }

    return dispatch => {
      dispatch(request())

      VSS.save(recordData(data))
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(VSC.VIRTUAL_SENSOR_SAVED))

          history.push('/virtualsensors')
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  const { originalSensorId } = data

  return dispatch => {
    if (!originalSensorId)
      return dispatch(AA.error(VSC.VIRTUAL_SENSOR_ID_REQUIRED))

    dispatch(saveData(data))
  }
}

const update = data => {
  const failure = error => ({ type: VSC.UPDATE_VIRTUAL_SENSOR_ERROR, error })
  const request = () => ({ type: VSC.UPDATE_VIRTUAL_SENSOR_REQUEST })
  const success = virtualsensor => ({
    type: VSC.UPDATE_VIRTUAL_SENSOR_SUCCESS,
    virtualsensor,
  })

  const updateData = data => {
    const recordData = data => {
      const {
        combine_id,
        combined_virtual_sensor_id,
        combined_virtual_sensor_device_id,
        name,
        originalSensorId,
        sensor_type,
      } = data

      return {
        data: [
          {
            attributes: {
              combine_id,
              combined_virtual_sensor_id,
              combined_virtual_sensor_device_id,
              original_sensors_id: originalSensorId,
              virtual_sensor_type: sensor_type,
              name,
            },
            id: combined_virtual_sensor_id.toString(),
            links: {
              self: '/virtualsensor/combined',
            },
            type: 'virtual_sensor',
          },
        ],
      }
    }

    return dispatch => {
      dispatch(request())

      VSS.update(recordData(data))
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(VSC.VIRTUAL_SENSOR_UPDATED))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  const { originalSensorId } = data

  return dispatch => {
    if (!originalSensorId)
      return dispatch(AA.error(VSC.VIRTUAL_SENSOR_ID_REQUIRED))

    dispatch(updateData(data))
  }
}

export const virtualSensorsActions = {
  remove,
  deleteVirtualSensor,
  getAll,
  save,
  update,
}
