import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'

export const useFolderCity = folderId => {
  const [data, setData] = useState('')

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.city) return

    const city = folderData?.attributes?.city

    setData(city)
  }, [folderPool, folderId])

  return data
}

useFolderCity.propTypes = {
  folderId: PropTypes.number,
}
