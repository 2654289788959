import { FilterListTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

export const ToggleSelectedOnly = ({ onChange, value = false, width = 45 }) => {
  const theme = useTheme()
  const { main: color } = theme.palette.secondary

  const [selectedOnly, setSelectedOnly] = useState(value)
  const toggle = () => setSelectedOnly(prev => !prev)

  const icon =
    selectedOnly === true ? (
      <FilterListTwoTone sx={{ color }} />
    ) : (
      <FilterListTwoTone />
    )

  const tooltip =
    selectedOnly === true
      ? 'Nicht ausgewählte Sensoren ausblenden'
      : 'Nicht ausgewählte Sensoren einblenden'

  useEffect(() => onChange(selectedOnly), [onChange, selectedOnly])

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={toggle} sx={{ m: 0, width }}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

ToggleSelectedOnly.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  width: PropTypes.number,
}
