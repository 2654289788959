import {
  AdminPanelSettingsTwoTone,
  EmojiPeopleTwoTone,
  PersonTwoTone,
  SchoolTwoTone,
} from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { userConstants as UC } from '../../constants'

const {
  USER_ROLE_ADMIN: ADMIN,
  USER_ROLE_COACH: COACH,
  USER_ROLE_GUEST: GUEST,
  USER_ROLE_USER: USER,
} = UC

export const UserRoleSelector = ({
  data = [
    { icon: EmojiPeopleTwoTone, title: GUEST, value: GUEST },
    { icon: PersonTwoTone, title: USER, value: USER },
    { icon: SchoolTwoTone, title: COACH, value: COACH },
    { icon: AdminPanelSettingsTwoTone, title: ADMIN, value: ADMIN },
  ],
  onChange,
  value,
  ...rest
}) => (
  <ToggleButtonGroup onChange={onChange} value={value} {...rest}>
    {data.map((role, key) => (
      <Tooltip title={role.title} key={key}>
        <ToggleButton key={key} value={role.value}>
          <role.icon sx={{ mr: 1 }} /> {role.title}
        </ToggleButton>
      </Tooltip>
    ))}
  </ToggleButtonGroup>
)

UserRoleSelector.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}
