import { CheckTwoTone, CloseTwoTone, PublicTwoTone } from '@mui/icons-material'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFolderPool, useOperator, useSensorPool } from '../hooks'
import JsonView from 'react18-json-view'

export const Diagnostics = () => {
  const { data: operatorData } = useOperator()
  const history = useHistory()

  const { data: fPool } = useFolderPool()
  const { data: sPool } = useSensorPool()

  const theme = useTheme()

  const isLink = key =>
    String(Object.values(process.env)[key]).includes('http://')

  const isBool = key =>
    String(Object.values(process.env)[key]) === 'true' ||
    String(Object.values(process.env)[key]) === 'false'

  return operatorData?.isAdmin ? (
    <Paper variant={'page'} sx={{ p: 2 }}>
      <Card>
        <CardHeader
          title={'process environment'}
          action={
            <IconButton onClick={() => history.goBack()}>
              <CloseTwoTone />
            </IconButton>
          }
        />
        <CardContent>
          <Stack spacing={1}>
            {[...Object.keys(process.env)].map((item, key) => (
              <TextField
                key={key}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position={'start'}>{item}</InputAdornment>
                  ),
                  endAdornment: isLink(key) ? (
                    <InputAdornment position={'end'}>
                      <IconButton
                        onClick={() =>
                          window.open(Object.values(process.env)[key], '_blank')
                        }
                      >
                        <PublicTwoTone />
                      </IconButton>
                    </InputAdornment>
                  ) : isBool(key) ? (
                    <InputAdornment position={'end'}>
                      {String(Object.values(process.env)[key]) === 'true' ? (
                        <IconButton sx={{ color: theme.palette.success.main }}>
                          <CheckTwoTone />
                        </IconButton>
                      ) : (
                        <IconButton sx={{ color: theme.palette.error.main }}>
                          <CloseTwoTone />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ) : null,
                }}
                value={Object.values(process.env)[key] || ''}
              />
            ))}
          </Stack>
        </CardContent>
      </Card>

      <Divider />

      <Card>
        <CardHeader title={'fPool'} />
        <CardContent>
          <JsonView collapsed={true} src={fPool.map(i => i.attributes)} />
        </CardContent>
      </Card>

      <Card>
        <CardHeader title={'sPool'} />
        <CardContent>
          <JsonView collapsed={true} src={sPool.map(i => i.attributes)} />
        </CardContent>
      </Card>
    </Paper>
  ) : null
}
