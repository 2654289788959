import { Collapse, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../actions'
import { setStorage, getStorage } from '../../api'
import {
  BtnAddDashboard,
  DlgDashboardCreate,
  Preloader,
} from '../../components'
import { useAppConfig, useDashboardPool } from '../../hooks'
import { SingleDashboard } from './SingleDashboard'

export const Dashboards = ({ PAGE_CONFIG = 'settings_dashboards' }) => {
  const dispatch = useDispatch()
  const { data, isLoading, isError } = useDashboardPool()

  const { appConfig } = useAppConfig()
  const { user_id } = appConfig

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  const [selectedId, setSelectedId] = useState(config?.selectedId ?? null)
  const showDlgAddDashboard = config?.showDlgAddDashboard ?? false

  const onOpenDlgAdd = () =>
    setConfig(prev => ({ ...prev, showDlgAddDashboard: true }))

  const onCloseDlgAdd = () =>
    setConfig(prev => ({ ...prev, showDlgAddDashboard: false }))

  const onSelectDashboard = selectedId => (_, isExpanded) => {
    setConfig(prev => ({
      ...prev,
      selectedId: isExpanded ? selectedId : null,
    }))
    setSelectedId(isExpanded ? selectedId : null)
  }

  const onCreate = name => {
    onCloseDlgAdd()
    dispatch(dashboardActions.save({ name, user_id }))
  }

  return (
    <>
      <Preloader error={isError} isLoading={isLoading} />

      <DlgDashboardCreate
        open={showDlgAddDashboard}
        onCancel={onCloseDlgAdd}
        onConfirm={onCreate}
      />

      {!isLoading && (
        <Collapse in={!isLoading}>
          {data.map((item, key) => (
            <SingleDashboard
              dashboardId={Number(item.id)}
              expanded={Number(item.id) === selectedId}
              key={key}
              onChange={onSelectDashboard(Number(item.id))}
            />
          ))}

          <Collapse in={data.length === 0}>
            <Typography sx={{ textAlign: 'center', py: 5 }} variant={'h6'}>
              Mit Klick auf &apos;+&apos; können Sie ein neues Dashboard
              erstellen.
            </Typography>
          </Collapse>
        </Collapse>
      )}

      <BtnAddDashboard onClick={onOpenDlgAdd} />
    </>
  )
}

Dashboards.propTypes = {
  PAGE_CONFIG: PropTypes.string,
}
