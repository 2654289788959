import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sensorBenchmarkActions } from '../../actions'

export const useSensorBenchmark = sensorId => {
  const dispatch = useDispatch()
  const { allSensorBenchmark } = useSelector(state => state.sensorBenchmark)
  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    dispatch(sensorBenchmarkActions.getAll(sensorId))
    setLoading(true)
  }, [dispatch, sensorId])
  useEffect(() => {
    if (allSensorBenchmark) {
      const { error, item } = allSensorBenchmark
      if (item) {
        const { data } = item
        if (data) {
          setData(data)
          setLoading(false)
        }
      }
      if (error) {
        setError(error)
        setLoading(false)
      }
    }
  }, [allSensorBenchmark])
  return { data, isError, isLoading }
}
