import {
  BarChartTwoTone,
  ShowChartTwoTone,
  TimelineTwoTone,
} from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const ChartTypeSelector = ({
  data = [
    { icon: BarChartTwoTone, title: 'Säulendiagramm', type: 'ColumnChart' },
    { icon: ShowChartTwoTone, title: 'Bereichsdiagramm', type: 'AreaChart' },
    { icon: TimelineTwoTone, title: 'Liniendiagramm', type: 'LineChart' },
  ],
  ...rest
}) => (
  <ToggleButtonGroup exclusive {...rest}>
    {data.map((item, key) => (
      <ToggleButton key={key} value={item.type}>
        <Tooltip title={item.title}>
          <item.icon />
        </Tooltip>
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)

ChartTypeSelector.propTypes = {
  data: PropTypes.array,
}
