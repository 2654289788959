import { CloseTwoTone, DeleteTwoTone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { BtnCancel, BtnConfirm, HStack } from '..'

export const DlgUserDelete = ({
  id,
  onCancel,
  onConfirm,
  open,
  serviceName,
  title = 'Bestätigung erforderlich',
  ...rest
}) => {
  const theme = useTheme()
  const bgcolor = theme.palette.secondary.main
  const color = theme.palette.white

  return (
    <Dialog onClose={onCancel} open={open} {...rest}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <HStack>
          <Avatar sx={{ bgcolor, color, mr: 1 }}>
            <DeleteTwoTone />
          </Avatar>

          <Typography sx={{ mr: 1, fontSize: 20 }}>{title}</Typography>
        </HStack>

        <IconButton onClick={onCancel} sx={{ bgcolor, color }}>
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Alert severity={'warning'}>
          <AlertTitle>ACHTUNG!</AlertTitle>

          <Typography>
            {serviceName} ID: {id} wirklich löschen?
          </Typography>
        </Alert>
      </DialogContent>

      <DialogActions>
        <BtnConfirm onClick={onConfirm} id={Number(id)} />

        <BtnCancel onClick={onCancel} />
      </DialogActions>
    </Dialog>
  )
}

DlgUserDelete.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  serviceName: PropTypes.string,
  title: PropTypes.string,
}
