import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export const exportToExcel = async (data, sheetname, filename) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet(sheetname)

  worksheet.columns = [
    { header: 'Sensor ID', key: 'id', width: 10 },
    { header: 'aktiviert', key: 'is_active', width: 10 },
    { header: 'Sensor Name', key: 'name', width: 40 },
    { header: 'Device ID', key: 'device_id', width: 20 },
    { header: 'Sensor Typ', key: 'sensor_type', width: 20 },
    { header: 'Provider', key: 'provider', width: 20 },
    { header: 'Wert', key: 'value', width: 10 },
    { header: 'Gebäude', key: 'folder_name', width: 40 },
    { header: 'Status', key: 'status', width: 10 },
    { header: 'letzte Aktivität', key: 'time', width: 20 },
  ]

  for (const row of data) {
    worksheet.addRow(Object.values(row))
  }

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, filename)
}
