import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { MoreHorizTwoTone, MoreVertTwoTone } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React from 'react'

export const StackSelector = ({
  data = [
    { icon: MoreHorizTwoTone, title: 'horizontal stapeln' },
    { icon: MoreVertTwoTone, title: 'vertikal stapeln' },
  ],
  ...rest
}) => (
  <ToggleButtonGroup exclusive {...rest}>
    {data.map((item, key) => (
      <ToggleButton key={key} value={Boolean(key)}>
        <Tooltip title={item.title}>
          <item.icon />
        </Tooltip>
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)

StackSelector.propTypes = {
  data: PropTypes.array,
}
