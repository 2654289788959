import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { sensorUnit } from '../../api'

export const exportToExcel = async (
  name,
  medium,
  data,
  sheetname,
  filename
) => {
  const workbook = new ExcelJS.Workbook()

  // Sheet1: Summary
  const sheetSummary = `${sheetname}_${name}`
  const summary = workbook.addWorksheet(sheetSummary)

  // Sheet2: record data
  const sheetData = `Messwerte`
  const worksheet = workbook.addWorksheet(sheetData)

  let rowIndex = 2 // start index for data, bcz 1st row is header

  worksheet.addRow(Object.values(data[0]))

  for (const row of data) {
    worksheet.addRow(Object.values(row))
    rowIndex++
  }
  const numRows = rowIndex - 1

  const kpiBorder = { style: 'medium' }
  const fromDate = worksheet.getCell('A2').value
  const toDate = worksheet.getCell(`A${numRows - 1}`).value

  // B2
  summary.getCell('B2').border = { top: kpiBorder, left: kpiBorder }
  summary.getCell('B2').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('B2').font = { size: 20, bold: true, underline: true }
  summary.getCell('B2').value = `${sheetname} ${name}`

  // C2
  summary.getCell('C2').border = { top: kpiBorder, right: kpiBorder }
  summary.getCell('C2').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }

  // B3
  summary.getCell('B3').border = { left: kpiBorder }
  summary.getCell('B3').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }

  // C3
  summary.getCell('C3').border = { right: kpiBorder }
  summary.getCell('C3').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }

  // B4
  summary.getCell('B4').border = { left: kpiBorder }
  summary.getCell('B4').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('B4').value = 'Zeitraum'

  // C4
  summary.getCell('C4').border = { right: kpiBorder }
  summary.getCell('C4').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('C4').value = `${fromDate} - ${toDate}`

  // B5
  summary.getCell('B5').border = { left: kpiBorder, bottom: kpiBorder }
  summary.getCell('B5').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('B5').value = 'Anzahl Messwerte:'

  // C5
  summary.getCell('C5').border = { right: kpiBorder, bottom: kpiBorder }
  summary.getCell('C5').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('C5').value = numRows - 1

  // B6
  summary.getCell('B6').border = { left: kpiBorder }
  summary.getCell('B6').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('B6').value = `Minimum (${sensorUnit(medium)})`

  // C6
  summary.getCell('C6').border = { right: kpiBorder }
  summary.getCell('C6').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('C6').value = {
    formula: `MIN(${sheetData}!B2:B${numRows})`,
    result: undefined,
  }

  // B7
  summary.getCell('B7').border = { left: kpiBorder }
  summary.getCell('B7').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('B7').value = `Maximum (${sensorUnit(medium)})`

  // C7
  summary.getCell('C7').border = { right: kpiBorder }
  summary.getCell('C7').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('C7').value = {
    formula: `MAX(${sheetData}!B2:B${numRows})`,
    result: undefined,
  }

  // B8
  summary.getCell('B8').border = { left: kpiBorder, bottom: kpiBorder }
  summary.getCell('B8').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('B8').value = `Durchschnitt (${sensorUnit(medium)})`

  // C8
  summary.getCell('C8').border = { right: kpiBorder, bottom: kpiBorder }
  summary.getCell('C8').fill = {
    fgColor: { argb: 'FFC0C0C0' },
    pattern: 'solid',
    type: 'pattern',
  }
  summary.getCell('C8').value = {
    formula: `ROUND(AVERAGE(${sheetData}!B2:B${numRows}), 2)`,
    result: undefined,
  }

  summary.getCell('C10').value = {
    text: 'Zu den Messwerten >',
    hyperlink: '#Messwerte!A1', // Link zum Arbeitsblatt "Messwerte" in Zelle A1
  }

  summary.columns = [{}, { width: 20 }, { width: 40 }]

  worksheet.columns = [
    { header: 'Datum', key: 'date', width: 20 },
    { header: `Wert (${sensorUnit(medium)})`, key: 'value', width: 20 },
  ]

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, filename)
}
