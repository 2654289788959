import { Container, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import { PropTypes } from 'prop-types'
import React from 'react'

export const FolderNews = ({ news }) =>
  !news ? null : (
    <Container>
      {news.map((item, key) => {
        const { content_text, headline, title } = item.attributes

        return (
          <Container key={key}>
            <Typography>{headline}</Typography>

            <Typography>{title}</Typography>

            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content_text),
              }}
            />
          </Container>
        )
      })}
    </Container>
  )

FolderNews.propTypes = {
  news: PropTypes.array,
}
