import { DeleteTwoTone } from '@mui/icons-material'
import { Button, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const BtnConfirm = ({ color = 'error', id, onClick }) => (
  <Button
    color={color}
    onClick={onClick}
    sx={{ color: useTheme().palette.white, borderRadius: 1 }}
  >
    <DeleteTwoTone sx={{ mr: 1 }} /> ID: {id} löschen
  </Button>
)

BtnConfirm.propTypes = {
  color: PropTypes.string,
  id: PropTypes.number,
  onClick: PropTypes.func,
}
