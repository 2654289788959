import { CloseTwoTone, DownloadTwoTone } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { HStack } from '../HStack'
import { CSVLink } from 'react-csv'
import { DDMMYYYYHHMM, sensorType, sensorUnit, userLocales } from '../../api'
import {
  useFolderName,
  useSensorIcon,
  useSensorName,
  useSensorStatus,
} from '../../hooks'
import { exportToExcel } from '../../pages/Folder/FeelingHistory.export'

export const DlgFeelingExport = ({
  exportData,
  folderId,
  medium,
  onClose,
  open,
  period,
  sensorId,
}) => {
  const theme = useTheme()
  const { main: bgcolor } = theme.palette.secondary
  const { white: color } = theme.palette

  const folderName = useFolderName(folderId)

  const sensorName = useSensorName(sensorId)
  const sensorStatus = useSensorStatus(sensorId)

  const onExportExcel = () => {
    exportToExcel(
      sensorName,
      medium,
      exportData.map(([timestamp, value]) => ({
        Datum: new Date(timestamp).toLocaleDateString(
          userLocales,
          DDMMYYYYHHMM
        ),
        [`Wert (${sensorUnit(medium)})`]: value,
      })),
      sensorType(medium),
      `${folderName}_${sensorName}.xlsx`
    )
  }

  const src = useSensorIcon(sensorId, sensorStatus)

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle
        onClose={onClose}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ mr: 1, fontSize: 20 }}>
          Exportieren: {sensorName}
        </Typography>

        <IconButton onClick={onClose} sx={{ bgcolor, color }} variant={'close'}>
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <List>
          <ListItemText primary={'Gebäude'} secondary={folderName} />

          <HStack>
            <ListItemText primary={'Messpunkt'} secondary={sensorName} />

            <ListItemIcon>
              <Box component={'img'} src={src} sx={{ width: 50 }} />
            </ListItemIcon>
          </HStack>

          <ListItemText primary={'Zeitraum'} secondary={period} />
        </List>
      </DialogContent>

      <DialogActions>
        <CSVLink
          headers={['Datum', `Wert (${sensorUnit(medium)})`]}
          data={exportData}
          filename={`${folderName}_${sensorName}.csv`}
          target={'_blank'}
        >
          <Button>
            <DownloadTwoTone /> CSV
          </Button>
        </CSVLink>

        <Button onClick={onExportExcel}>
          <DownloadTwoTone />
          Excel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgFeelingExport.propTypes = {
  exportData: PropTypes.array.isRequired,
  folderId: PropTypes.number.isRequired,
  medium: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  period: PropTypes.string.isRequired,
  sensorId: PropTypes.number.isRequired,
}
