import { Alert, AlertTitle, Box, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

export const PackageUpgrade = ({ coach }) => {
  const theme = useTheme()
  const color = theme.palette.primary.main

  if (!coach || !coach.attributes) return <Box />

  const { firstname, lastname, email_contact, telephone, telephone_mobile } =
    coach.attributes

  return (
    <Alert severity={'info'} sx={{ mb: 5 }}>
      <AlertTitle>Paket nicht gebucht</AlertTitle>
      Bei Interesse nehmen Sie bitte Kontakt zu Ihrem KARMA Coach auf:
      <Typography
        sx={{
          textTransform: 'uppercase',
          letterSpacing: 2,
          fontWeight: 'bold',
          mt: 5,
          mb: 2,
        }}
      >
        {firstname} {lastname}
      </Typography>
      <Typography>
        Telefon:{' '}
        <Link style={{ color }} to={`tel:${telephone}`}>
          {telephone}
        </Link>
      </Typography>
      <Typography>
        Mobil:{' '}
        <Link style={{ color }} to={`tel:${telephone_mobile}`}>
          {telephone_mobile}
        </Link>
      </Typography>
      <Typography>
        Mail:{' '}
        <Link style={{ color }} to={`mailto:${email_contact}`}>
          {email_contact}
        </Link>
      </Typography>
    </Alert>
  )
}

PackageUpgrade.propTypes = {
  coach: PropTypes.object.isRequired,
}
