import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { consumptionActions } from '../../actions'

export const useYearlyConsumptionToDate = folderId => {
  const dispatch = useDispatch()

  const fromDateYear = new Date()
  fromDateYear.setFullYear(new Date().getFullYear() - 1)
  fromDateYear.setMonth(new Date().getMonth() + 1)
  fromDateYear.setDate(1)

  const toDateYear = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  )

  const fromDate = fromDateYear.toISOString().split('T')[0]
  const toDate = toDateYear.toISOString().split('T')[0]

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const { consumptionYearly } = useSelector(state => state.consumption)

  useEffect(() => {
    if (Number(folderId) > 0) {
      setLoading(true)
      dispatch(consumptionActions.getAll(folderId, 'm', fromDate, toDate, true))
    }
  }, [dispatch, folderId, fromDate, toDate])

  useEffect(() => {
    if (!consumptionYearly) return

    const { error, item } = consumptionYearly

    if (item) {
      const { data } = item
      if (!data) return
      setData(
        data.map(item => ({
          ...item.attributes,
          entity_id: Number(item.id),
        }))
      )
      setLoading(false)
    }

    if (error) {
      const { message } = error

      setError(message)
      setLoading(false)
    }
  }, [consumptionYearly])

  return { isLoading, isError, data }
}
