import { API } from 'aws-amplify'
import axios from 'axios'
import S3FileUpload from 'react-s3'
import { authHeader, getStorage } from '../api'
// KAR-1980 bypass: Buffer is not defined
import { Buffer } from 'buffer'

// KAR-1980
window.Buffer = window.Buffer || Buffer

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL, REACT_APP_VER_HISTORY_AGGREGATED } = process.env

const ERR_MISSING_FOLDER = new Error('Kein Gebäude gewählt')
const ERR_INVALID_FOLDER = new Error('Ungültiges Gebäude')
const ERR_MISSING_RESOLUTION = new Error('Keine Granularität gewählt')
const ERR_MISSING_START_DATE = new Error('Kein Startdatum gewählt')
const ERR_MISSING_END_DATE = new Error('Kein Enddatum gewählt')

const getAll = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/admin/folders`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.getAll', error)
  }
}

const sensorsByFolderId = async id => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/${id}/sensors`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.sensorsByFolderId', error)
  }
}

const statusByFolderId = async id => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/${id}/status`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.statusByFolderId', error)
  }
}

const subFoldersByFolderId = async id => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/${id}/folders`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.subFoldersByFolderId', error)
  }
}

const coachesByFolderId = async id => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/${id}/coaches`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.coachesByFolderId', error)
  }
}

const newsByFolderId = async id => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/${id}/news`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.newsByFolderId', error)
  }
}

const officeHoursByFolderId = async id => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/${id}/officehours`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.officeHoursByFolderId', error)
  }
}

const exceptionsByFolderId = async id => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/${id}/exceptions`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.exceptionsByFolderId', error)
  }
}

const getById = async (id, includes = []) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_FOLDER)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_FOLDER)

  let request = id
  if (includes.length > 0) {
    request += '?include='
    includes.forEach(val => {
      request += encodeURIComponent(val)
      request += ','
    })
  }

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/admin/folders/${request}`,
    //url: `${REACT_APP_API_URL}/folders/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    /*
    console.log('byid data', data)

    const sensors = await sensorsByFolderId(id)
    console.log('sensors', sensors)

    const status = await statusByFolderId(id)
    console.log('status', status)

    const subFolders = await subFoldersByFolderId(id)
    console.log('subFolders (fId 15)', subFolders)

    const eEquipment = await emergencyEquipmentByFolderId(id)
    console.log('emergency-equipment (fId 4)', eEquipment)

    const coaches = await coachesByFolderId(id)
    console.log('coaches', coaches)

    const news = await newsByFolderId(id)
    console.log('news (fId 2)', news)

    const officehours = await officeHoursByFolderId(id)
    console.log('officehours', officehours)

    const exceptions = await exceptionsByFolderId(id)
    console.log('exceptions (fId: 3,23,90,105)', exceptions)

    console.log('users: clarify')
    console.log('installations: clarify')
    */

    return data
  } catch (error) {
    console.error('folderService.getById', error)
  }
}

const getCustomEnergyCompareConsumption = async (id, period, start, end) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_FOLDER)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_FOLDER)
  if (!period || period === '') return Promise.reject(ERR_MISSING_RESOLUTION)
  if (!start || start === '') return Promise.reject(ERR_MISSING_START_DATE)
  if (!end || end === '') return Promise.reject(ERR_MISSING_END_DATE)

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/admin/folders/${id}/custom-consumption/${period}?from=${start}&to=${end}&${REACT_APP_VER_HISTORY_AGGREGATED}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.getAll', error)
  }
}

const getCustomEnergyConsumption = async (id, period, start, end) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_FOLDER)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_FOLDER)
  if (!period || period === '') return Promise.reject(ERR_MISSING_RESOLUTION)
  if (!start || start === '') return Promise.reject(ERR_MISSING_START_DATE)
  if (!end || end === '') return Promise.reject(ERR_MISSING_END_DATE)

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/admin/folders/${id}/custom-consumption/${period}?from=${start}&to=${end}&${REACT_APP_VER_HISTORY_AGGREGATED}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.getCustomEnergyConsumption', error)
  }
}

const getCounterReading = async (id, period, start, end) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_FOLDER)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_FOLDER)
  if (!period || period === '') return Promise.reject(ERR_MISSING_RESOLUTION)
  if (!start || start === '') return Promise.reject(ERR_MISSING_START_DATE)
  if (!end || end === '') return Promise.reject(ERR_MISSING_END_DATE)

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/admin/folders/${id}/counter-reading/${period}?from=${start}&to=${end}&${REACT_APP_VER_HISTORY_AGGREGATED}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.getCounterReading', error)
  }
}

const getEnergyConsumption = async id => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_FOLDER)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_FOLDER)

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/folders/admin/folders/${id}/consumption?${REACT_APP_VER_HISTORY_AGGREGATED}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.getEnergyConsumption', error)
  }
}

const remove = async id => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/folders/admin/folders/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.remove', error)
  }
}

const save = async body => {
  const endpoint = '/folders/admin/folders'
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

const saveOfficeHours = async body => {
  const endpoint = '/officehours/admin/officehours'
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

export const saveOfficeHours2 = async body => {
  const config = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: `${REACT_APP_API_URL}/officehours/admin/officehours`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('folderService.saveOfficeHours2', error)
  }
}

const update = async (body, id) => {
  const endpoint = `/folders/admin/folders/${id}`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

const uploadAws = async (file, dirName) => {
  const config = getStorage('config')

  const handle = new File(
    [file],
    (
      Math.random().toString(36).substring(2, 16) +
      Math.random().toString(36).substring(2, 16)
    ).toUpperCase() +
      new Date().getTime() +
      file.name.substr(file.name.lastIndexOf('.'))
  )

  const options = {
    bucketName: config.s3.bucketName,
    dirName: dirName,
    region: config.s3.region,
    accessKeyId: config.s3.accessKeyId,
    secretAccessKey: config.s3.secrectAccessKey,
  }

  return await S3FileUpload.uploadFile(handle, options)
}

export const folderService = {
  getAll,
  getById,
  getCustomEnergyCompareConsumption,
  getCustomEnergyConsumption,
  getCounterReading,
  getEnergyConsumption,
  remove,
  save,
  saveOfficeHours,
  update,
  uploadAws,
  //
  coachesByFolderId,
  exceptionsByFolderId,
  newsByFolderId,
  officeHoursByFolderId,
  sensorsByFolderId,
  statusByFolderId,
  subFoldersByFolderId,
}
