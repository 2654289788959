import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { systemActions } from '../../actions'

export const useMaintenanceMode = () => {
  const dispatch = useDispatch()

  const [data, setData] = useState(null)

  const { isInMaintenance } = useSelector(state => state.system)

  useEffect(() => dispatch(systemActions.getIsInMaintenance()), [dispatch])

  useEffect(() => {
    const mode = isInMaintenance?.status?.data?.is_in_maintenance
    const loading = isInMaintenance?.loading

    if (!loading) setData(mode)
  }, [isInMaintenance])

  return data
}
