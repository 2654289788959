import { history } from '../api'
import { dashboardConstants as DC } from '../constants'
import { dashboardService as DS, tokenService as TS } from '../services'
import { alertActions as AA } from './'

const dashboardData = data => {
  const { user_id, name } = data

  return { data: { attributes: { user_id, name } } }
}

const panelData = data => {
  const {
    dashboard_id,
    name,
    position,
    indicator,
    graphic_type,
    start_date,
    end_date,
    granularity,
    presentation_level,
    sensor_ids,
  } = data

  return {
    data: {
      attributes: {
        dashboard_id,
        name,
        position,
        indicator,
        graphic_type,
        start_date,
        end_date,
        granularity,
        presentation_level,
      },
      sensor_ids: sensor_ids || [],
    },
  }
}

const movePanelData = data => {
  const { old_position, new_position } = data

  return { data: { attributes: { old_position, new_position } } }
}

const dataPointsData = data => {
  const { panel_id, sensor_ids } = data

  return {
    data: {
      attributes: { panel_id },
      sensor_ids: sensor_ids || [],
    },
  }
}

const updatePanelDataByDashboardId = data => {
  const { start_date, end_date, granularity } = data
  return {
    data: {
      attributes: {
        start_date: start_date,
        end_date: end_date,
        granularity: granularity,
      },
    },
  }
}

const getAll = () => {
  const failure = error => ({ type: DC.GET_ALL_DASHBOARDS_FAILURE, error })
  const request = () => ({ type: DC.GET_ALL_DASHBOARDS_REQUEST })
  const success = dashboards => ({
    type: DC.GET_ALL_DASHBOARDS_SUCCESS,
    dashboards,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      DS.getAll()
        .then(result => {
          const { error, data } = result

          if (error) {
            const message = `Dashboards Endpoint: ${error}`

            dispatch(failure(message))
            dispatch(AA.error(message))
          }

          if (data) {
            dispatch(success(result))
          }
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getById = (id, includes = []) => {
  const failure = error => ({ type: DC.GET_BY_ID_ERROR, error })
  const request = dashboard => ({ type: DC.GET_BY_ID_REQUEST, dashboard })
  const success = dashboard => ({ type: DC.GET_BY_ID_SUCCESS, dashboard })

  return dispatch => {
    dispatch(request({ id }))

    TS.refreshToken().then(() =>
      DS.getById(id, includes).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const remove = id => {
  const failure = error => ({ type: DC.REMOVE_DASHBOARD_ERROR, error })
  const request = () => ({ type: DC.REMOVE_DASHBOARD_REQUEST })
  const success = dashboard => ({
    type: DC.REMOVE_DASHBOARD_SUCCESS,
    dashboard,
  })

  return dispatch => {
    if (!id) return dispatch(AA.error(DC.DASHBOARD_ID_REQUIRED))

    dispatch(request())

    DS.remove(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.DASHBOARD_DELETED))
        history.go('/dashboards')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const save = data => {
  const failure = error => ({ type: DC.SAVE_DASHBOARD_ERROR, error })
  const request = () => ({ type: DC.SAVE_DASHBOARD_REQUEST })
  const success = dashboard => ({ type: DC.SAVE_DASHBOARD_SUCCESS, dashboard })

  const { name } = data

  return dispatch => {
    if (!name) return dispatch(AA.error(DC.DASHBOARD_NAME_REQUIRED))

    dispatch(request())

    DS.save(dashboardData(data))
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.DASHBOARD_SAVED))
        history.go('/dashboards')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const update = (id, data) => {
  const failure = error => ({ type: DC.UPDATE_DASHBOARD_ERROR, error })
  const request = () => ({ type: DC.UPDATE_DASHBOARD_REQUEST })
  const success = dashboard => ({
    type: DC.UPDATE_DASHBOARD_SUCCESS,
    dashboard,
  })

  const { name } = data

  return dispatch => {
    if (!name) return dispatch(AA.error(DC.DASHBOARD_NAME_REQUIRED))

    dispatch(request())

    DS.update(id, dashboardData(data))
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.DASHBOARD_UPDATED))
        history.go('/dashboards')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getAllPanels = dashboardId => {
  const failure = error => ({ type: DC.GET_ALL_PANELS_FAILURE, error })
  const request = () => ({ type: DC.GET_ALL_PANELS_REQUEST })
  const success = panels => ({ type: DC.GET_ALL_PANELS_SUCCESS, panels })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      DS.getAllPanels(dashboardId)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getPanelById = id => {
  const failure = error => ({ type: DC.GET_PANEL_BY_ID_ERROR, error })
  const request = panel => ({ type: DC.GET_PANEL_BY_ID_REQUEST, panel })
  const success = panel => ({ type: DC.GET_PANEL_BY_ID_SUCCESS, panel })

  return dispatch => {
    dispatch(request({ id }))

    TS.refreshToken().then(() =>
      DS.getPanelById(id).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const removePanel = (dashboard_id, panel_id) => {
  const failure = error => ({ type: DC.REMOVE_PANEL_ERROR, error })
  const request = () => ({ type: DC.REMOVE_PANEL_REQUEST })
  const success = panel => ({
    type: DC.REMOVE_PANEL_SUCCESS,
    panel,
  })

  return dispatch => {
    if (!panel_id) return dispatch(AA.error(DC.PANEL_ID_REQUIRED))

    dispatch(request())

    DS.removePanel(dashboard_id, panel_id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.PANEL_DELETED))
        history.go('/dashboards')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const movePanel = (data, dashboardId, panelId) => {
  const failure = error => ({ type: DC.MOVE_PANEL_ERROR, error })
  const request = () => ({ type: DC.MOVE_PANEL_REQUEST })
  const success = panel => ({
    type: DC.MOVE_PANEL_SUCCESS,
    panel,
  })

  return dispatch => {
    dispatch(request())

    DS.movePanel(movePanelData(data), dashboardId, panelId)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.PANEL_MOVED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const savePanel = data => {
  const failure = error => ({ type: DC.SAVE_PANEL_ERROR, error })
  const request = () => ({ type: DC.SAVE_PANEL_REQUEST })
  const success = dashboard => ({ type: DC.SAVE_PANEL_SUCCESS, dashboard })

  const { name, dashboard_id, sensor_ids } = data
  return dispatch => {
    if (!name) return dispatch(AA.error(DC.PANEL_NAME_REQUIRED))
    if (sensor_ids.length === 0)
      return dispatch(AA.error(DC.PANEL_SENSORS_REQUIRED))

    dispatch(request())

    DS.savePanel(panelData(data), dashboard_id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.PANEL_SAVED))
        history.go('/dashboards')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const updatePanel = data => {
  const failure = error => ({ type: DC.UPDATE_PANEL_ERROR, error })
  const request = () => ({ type: DC.UPDATE_PANEL_REQUEST })
  const success = dashboard => ({
    type: DC.UPDATE_PANEL_SUCCESS,
    dashboard,
  })

  const { name, dashboard_id, entity_id } = data

  return dispatch => {
    if (!name) return dispatch(AA.error(DC.PANEL_NAME_REQUIRED))

    dispatch(request())
    DS.updatePanel(panelData(data), dashboard_id, entity_id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.PANEL_UPDATED))
        history.go('/dashboards')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getAllDataPoints = () => {
  const failure = error => ({ type: DC.GET_ALL_DATA_POINT_FAILURE, error })
  const request = () => ({ type: DC.GET_ALL_DATA_POINT_REQUEST })
  const success = dataPoints => ({
    type: DC.GET_ALL_DATA_POINT_SUCCESS,
    dataPoints,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      DS.getAllDataPoints()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getDataPointById = id => {
  const failure = error => ({ type: DC.GET_DATA_POINT_BY_ID_ERROR, error })
  const request = dataPoint => ({
    type: DC.GET_DATA_POINT_BY_ID_REQUEST,
    dataPoint,
  })
  const success = dataPoint => ({
    type: DC.GET_DATA_POINT_BY_ID_SUCCESS,
    dataPoint,
  })

  return dispatch => {
    dispatch(request({ id }))

    TS.refreshToken().then(() =>
      DS.getDataPointById(id).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const removeDataPoint = (dashboardId, panelId, dataPointId) => {
  const failure = error => ({ type: DC.REMOVE_DATA_POINT_ERROR, error })
  const request = () => ({ type: DC.REMOVE_DATA_POINT_REQUEST })
  const success = dataPoint => ({
    type: DC.REMOVE_DATA_POINT_SUCCESS,
    dataPoint,
  })

  return dispatch => {
    if (!dashboardId) return dispatch(AA.error(DC.DATA_POINT_ID_REQUIRED))

    dispatch(request())

    DS.removeDataPoint(dashboardId, panelId, dataPointId)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.DATA_POINT_DELETED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const saveDataPoint = data => {
  const failure = error => ({ type: DC.SAVE_DATA_POINT_ERROR, error })
  const request = () => ({ type: DC.SAVE_DATA_POINT_REQUEST })
  const success = dataPoint => ({ type: DC.SAVE_DATA_POINT_SUCCESS, dataPoint })

  const { name, panel_id, dashboard_id } = data
  return dispatch => {
    if (!name) return dispatch(AA.error(DC.DATA_POINT_NAME_REQUIRED))

    dispatch(request())

    DS.saveDataPoint(dataPointsData(data), dashboard_id, panel_id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.DATA_POINT_SAVED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const updateDataPoints = (data, dashboardId) => {
  const failure = error => ({ type: DC.UPDATE_DATA_POINT_ERROR, error })
  const request = () => ({ type: DC.UPDATE_DATA_POINT_REQUEST })
  const success = dataPoint => ({
    type: DC.UPDATE_DATA_POINT_SUCCESS,
    dataPoint,
  })

  const { panel_id, entity_id } = data
  return dispatch => {
    dispatch(request())

    DS.updateDataPoints(dataPointsData(data), dashboardId, panel_id, entity_id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.DATA_POINT_UPDATED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const updatePanelsByDashboardId = (dashboardId, data) => {
  const failure = error => ({
    type: DC.UPDATE_PANEL_BY_DASHBOARD_ID_ERROR,
    error,
  })
  const request = () => ({ type: DC.UPDATE_PANEL_BY_DASHBOARD_ID_REQUEST })
  const success = dataPoint => ({
    type: DC.UPDATE_PANEL_BY_DASHBOARD_ID_SUCCESS,
    dataPoint,
  })

  return dispatch => {
    dispatch(request())

    DS.updatePanelsByDashboardId(
      dashboardId,
      updatePanelDataByDashboardId(data)
    )
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(DC.UPDATE_PANEL_BY_DASHBOARD_ID_SUCCESS))
        history.go('/dashboards')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

export const dashboardActions = {
  getAll,
  getById,
  remove,
  save,
  update,
  getAllPanels,
  getPanelById,
  removePanel,
  movePanel,
  savePanel,
  updatePanel,
  getAllDataPoints,
  getDataPointById,
  removeDataPoint,
  saveDataPoint,
  updateDataPoints,
  updatePanelsByDashboardId,
}
