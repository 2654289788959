import { Button } from '@mui/material'
import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { sensorPriceActions } from '../../../actions'
import { HStack, KTextField } from '../../../components'
import { useSensorPricePool } from '../../../hooks'

export const EditPrice = ({ sensorId, unit = '€' }) => {
  const { data: sensorPricePool } = useSensorPricePool()

  const [priceForYear, setPriceForYear] = useState(0)
  const [price, setPrice] = useState(0)

  const [newPrice, setNewPrice] = useState('')
  const [entityId, setEntityID] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    const priceData = sensorPricePool.filter(
      s => s.sensor_id === Number(sensorId)
    )
    if (priceData[0]) {
      setEntityID(priceData[0].entity_id)
      setPrice(priceData[0].price)
      setPriceForYear(priceData[0].price_for_year)
      setNewPrice(false)
    } else setNewPrice(true)
  }, [sensorPricePool, sensorId])

  const handleSubmit = actions => {
    switch (actions) {
      case 'save':
        dispatch(
          sensorPriceActions.save({
            entity_id: Number(entityId),
            sensor_id: Number(sensorId),
            price_for_year: parseFloat(priceForYear),
            price: parseFloat(price),
          })
        )
        break
      case 'update':
        dispatch(
          sensorPriceActions.update({
            entity_id: Number(entityId),
            sensor_id: Number(sensorId),
            price_for_year: parseFloat(priceForYear),
            price: parseFloat(price),
          })
        )
        break

      default:
        break
    }
  }

  return (
    <HStack sx={{ width: 1 }}>
      <KTextField
        label={'Kosten'}
        onChange={event => setPrice(event.target.value)}
        unit={unit}
        value={price}
      />

      <KTextField
        label={'Fixkosten pro Jahr'}
        onChange={event => setPriceForYear(event.target.value)}
        unit={unit}
        value={priceForYear}
      />

      <Button onClick={() => handleSubmit(newPrice ? 'save' : 'update')}>
        Aktualisieren
      </Button>
    </HStack>
  )
}

EditPrice.propTypes = {
  sensorId: PropTypes.string,
  unit: PropTypes.string,
}
