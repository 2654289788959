import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'
import { DDMMYYYYHHMM, userLocales } from '../../api'

export const useFolderCTime = (
  folderId,
  locales = userLocales,
  options = DDMMYYYYHHMM
) => {
  const [data, setData] = useState(null)

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.created_at) return

    const created_at = folderData?.attributes?.created_at

    if (created_at)
      setData(new Date(created_at).toLocaleString(locales, options))
  }, [folderPool, folderId, locales, options])

  return data
}

useFolderCTime.propTypes = {
  folderId: PropTypes.number,
}
