import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  List,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getStatusColor, SVGStatus } from '../../api'
import { useSecuritySensors } from '../../hooks'
import { IntervalAlert, SecurityRoomSensor } from '..'

export const SecurityRoom = ({ folderId, title }) => {
  const history = useHistory()
  const sSensors = useSecuritySensors(folderId)
  const roomSensors = [
    ...new Set(sSensors.filter(f => f.name === title)),
  ].sort()
  const roomStatus = getStatusColor(roomSensors)

  const avatar = (
    <Avatar>
      <SVGStatus type={'security'} status={roomStatus} />
    </Avatar>
  )

  return (
    <Card variant={'security'}>
      <CardHeader
        avatar={avatar}
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        variant={'security'}
      />

      {sSensors.length === 0 ? (
        <LinearProgress />
      ) : (
        <CardContent sx={{ p: '0 !important' }}>
          {/* display interval alert, if applicable  */}
          <IntervalAlert title={title} sensors={sSensors} />

          <List>
            {roomSensors.map((item, key) => {
              const { entity_id, sensor_type } = item
              const hasDetailPage =
                sensor_type === 'pressure' ||
                sensor_type === 'temperature' ||
                sensor_type === 'temperature_0' ||
                sensor_type === 'temperature_1' ||
                sensor_type === 'Ni1000' ||
                sensor_type === 'degrees-celsius'

              const target = `/folder/${folderId}/security/${sensor_type}/${entity_id}`
              const onClick = hasDetailPage ? () => history.push(target) : null

              return (
                <SecurityRoomSensor
                  folderId={folderId}
                  key={key}
                  onClick={onClick}
                  sensorId={entity_id}
                />
              )
            })}
          </List>
        </CardContent>
      )}
    </Card>
  )
}

SecurityRoom.propTypes = {
  folderId: PropTypes.number,
  title: PropTypes.string,
}
