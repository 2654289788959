import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useSensorPoolIds = () => {
  const [data, setData] = useState([])

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const ids = sensorPool
      .map(sensor => Number(sensor.attributes.entity_id))
      .sort((a, b) => a - b)

    setData(ids)
  }, [sensorPool])

  return data
}
