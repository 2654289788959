import { ExpandMoreTwoTone } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useFolderPool } from '../../hooks'
import { HStack } from '../HStack'

export const DashboardDisplayFolders = ({
  disabled,
  maxHeight = 300,
  onChange,
  selected,
  title = 'Gebäude',
}) => {
  const { data: folderPool, isLoading } = useFolderPool()

  const [filter, setFilter] = useState('')

  const FILTER_FOLDER = f =>
    f.attributes.name.toLowerCase().includes(filter.toLowerCase())

  const label = `${selected.length}/${folderPool.length}`

  const onBulkToggle = event => {
    if (event.target.checked === false) onChange([])
    else onChange(folderPool.map(f => Number(f.attributes.entity_id)))

    // reset filter because we performed a bulk operation (select all/none)
    setFilter('')
  }

  const onChangeFilter = event => setFilter(event.target.value)

  const BULK_CHECKED = folderPool.length === selected.length

  return isLoading ? null : (
    <Accordion disabled={disabled}>
      <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
        <HStack sx={{ width: 1 }}>
          <Typography>{title}</Typography>

          <Chip label={label} />
        </HStack>
      </AccordionSummary>

      <Divider />

      <AccordionDetails sx={{ maxHeight, overflow: 'auto', p: 0 }}>
        <ListSubheader sx={{ py: 0 }}>
          <HStack>
            <Input
              fullWidth
              onChange={onChangeFilter}
              placeholder={'Filter'}
              sx={{ py: 1 }}
              value={filter}
            />

            <Divider orientation={'vertical'} sx={{ height: 30 }} />
            <Tooltip title={'Massen Auswahl'}>
              <Switch checked={BULK_CHECKED} onClick={onBulkToggle} />
            </Tooltip>
          </HStack>
        </ListSubheader>

        <List>
          {folderPool.filter(FILTER_FOLDER).map((folder, key) => {
            const { entity_id: folderId, name: folderName } = folder.attributes

            const checked = selected.includes(folderId)

            const toggleSelected = () =>
              onChange(prev =>
                prev.includes(folderId)
                  ? prev.filter(f => f !== folderId)
                  : [...prev, folderId]
              )

            return (
              <ListItem key={key} sx={{ py: 0 }}>
                <ListItemButton onClick={toggleSelected} sx={{ px: 0 }}>
                  <ListItemText primary={folderName} sx={{ width: 0.5 }} />

                  <Switch checked={checked} />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

DashboardDisplayFolders.propTypes = {
  disabled: PropTypes.bool,
  maxHeight: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  title: PropTypes.string,
}
