import { Alert, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { HStack } from '../../components'

export const HitsInfo = ({ count, filter, serviceName, severity = 'info' }) => (
  <Alert severity={severity} sx={{ mx: 1 }}>
    <HStack justifyContent={'space-between'}>
      <Typography>
        <Box component={'span'} fontWeight={'bold'}>
          {count}
        </Box>{' '}
        {serviceName} entsprechen dem Suchfilter &quot;
        <Box component={'span'} fontWeight={'bold'}>
          {filter}
        </Box>
        &quot;{' '}
      </Typography>
    </HStack>
  </Alert>
)

HitsInfo.propTypes = {
  count: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  severity: PropTypes.string,
}
