import { ExpandLessTwoTone, ExpandMoreTwoTone } from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { ASC, DESC } from '../../api'

export const UserSortSelector = ({
  data = [
    { title: 'Vorname', value: 'firstname' },
    { title: 'Nachname', value: 'lastname' },
    { title: 'ID', value: 'entity_id' },
  ],
  onChange,
  order,
  orderby,
  ...rest
}) => (
  <ToggleButtonGroup exclusive onChange={onChange} value={orderby} {...rest}>
    {data.map((item, key) => (
      <ToggleButton key={key} value={item.value}>
        {item.title}
        {item.value === orderby && order === ASC && <ExpandLessTwoTone />}
        {item.value === orderby && order === DESC && <ExpandMoreTwoTone />}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)

UserSortSelector.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  order: PropTypes.string,
  orderby: PropTypes.string,
}
