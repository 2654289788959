import { FormControl, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { KTextField } from '../../components'

export const DashboardGranularitySelector = ({
  data = [
    { label: 'letzter voller Monat', value: 'lastFullMonth' },
    { label: 'letzten 30 Tage', value: 'lastThirtyDays' },
    { label: 'letzten 2 Monate', value: 'lastTwoMonths' },
    { label: 'letzten 6 Monate', value: 'lastSixMonths' },
    { label: 'dieses Jahr (Januar bis heute)', value: 'thisYear' },
    { label: 'letztes Jahr', value: 'lastYear' },
  ],
  granularity,
  onChange,
}) => {
  return (
    <FormControl>
      <KTextField onChange={onChange} select value={granularity}>
        {data.map((item, key) => (
          <MenuItem key={key} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </KTextField>
    </FormControl>
  )
}

DashboardGranularitySelector.propTypes = {
  data: PropTypes.array,
  granularity: PropTypes.string,
  onChange: PropTypes.func,
}
