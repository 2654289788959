import { authHeader } from '../api'
import { API } from 'aws-amplify'
import axios from 'axios'
const API_NAME = 'karmaApi'
const { REACT_APP_API_URL } = process.env
const ERR_MISSING_BENCHMARK = new Error('Kein Benchmark gewählt')
const ERR_INVALID_BENCHMARK = new Error('Ungültiges Benchmark')
const getById = async id => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_BENCHMARK)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_BENCHMARK)
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/benchmark/v1/${id}`,
  }
  try {
    const response = await axios(config)
    const { data } = response
    return data
  } catch (error) {
    console.error('benchmarkService.getById', error)
  }
}
const remove = async id => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/benchmark/v1/${id}`,
  }
  try {
    const response = await axios(config)
    const { data } = response
    return data
  } catch (error) {
    console.error('benchmarkService.remove', error)
  }
}
const save = async body => {
  const endpoint = `/benchmark/v1/`
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }
  return await API.post(API_NAME, endpoint, options)
}
const update = async (body, id) => {
  const endpoint = `/benchmark/v1/${id}`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }
  return await API.patch(API_NAME, endpoint, options)
}
const getAll = async sensorId => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/benchmark/v1/all/${sensorId}`,
  }
  try {
    const response = await axios(config)
    const { data, status } = response
    if (status === 204) {
      console.log('status 204')
      return {
        item: {
          data: [],
        },
      }
    }
    return data
  } catch (error) {
    console.error('sensorBenchmarkService.getAll', error)
  }
}
const removeBySensorId = async sensorId => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/benchmark/v1/all/${sensorId}`,
  }
  try {
    const response = await axios(config)
    const { data } = response
    return data
  } catch (error) {
    console.error('benchmarkService.removeBySensorId', error)
  }
}
export const sensorBenchmarkService = {
  getById,
  remove,
  save,
  update,
  getAll,
  removeBySensorId,
}
