import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool, useSensorPool } from '..'
import { categoryByType } from '../../api'

export const useFeelingSensors = folderId => {
  const { data: folderPool } = useFolderPool()
  const { data: sensorPool } = useSensorPool()

  const [data, setData] = useState([])

  useEffect(() => {
    if (!folderId || folderPool.length === 0 || sensorPool.length === 0) return

    const FILTER_FEELING = f =>
      (categoryByType(f.sensor_type) === 'feeling' &&
        f.sensor_target !== 'security') ||
      f.sensor_target === 'feeling'

    const sensorIds = folderPool
      .find(i => Number(i.attributes.entity_id) === Number(folderId))
      .attributes.folder_sensors.map(i => i.sensor_id)
      .sort()

    const feelingSensors = sensorPool
      .map(data => data.attributes)
      .filter(sensor => sensorIds.includes(sensor.entity_id))
      .filter(FILTER_FEELING)

    setData(feelingSensors)
  }, [folderId, folderPool, sensorPool])

  return data
}

useFeelingSensors.propTypes = {
  folderId: PropTypes.number,
}
