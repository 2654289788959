import { SaveTwoTone } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useFolderPool, useSensorPool } from '../../hooks'

export const DlgPanelAddSummary = ({
  disabled,
  onClose,
  onConfirm,
  open,
  selectedFolders,
  selectedSensors,
  title = 'Neue Kachel - Zusammenfassung',
}) => {
  const { data: folderPool } = useFolderPool()
  const { data: sensorPool } = useSensorPool()

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {selectedFolders.length > 0 && (
          <Stack sx={{ border: 'solid 1px #eee', borderRadius: 3, my: 2 }}>
            <ListSubheader>Ausgewählte Gebäude</ListSubheader>

            <Divider />

            <List sx={{ maxHeight: 400, overflow: 'auto' }}>
              {folderPool
                .filter(folder => selectedFolders.includes(Number(folder.id)))
                .map(folder => folder.attributes.name)
                .map((fName, key) => (
                  <ListItem key={key}>
                    <ListItemText primary={fName} />
                  </ListItem>
                ))}
            </List>
          </Stack>
        )}

        <Stack sx={{ border: 'solid 1px #eee', borderRadius: 3 }}>
          <ListSubheader>Ausgewählte Messpunkte</ListSubheader>

          <Divider />

          <List sx={{ maxHeight: 400, overflow: 'auto' }}>
            {sensorPool
              .filter(sensor => selectedSensors.includes(Number(sensor.id)))
              .map(sensor => sensor.attributes.name)
              .map((sName, key) => (
                <ListItem key={key}>
                  <ListItemText primary={sName} />
                </ListItem>
              ))}
          </List>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Zurück</Button>

        <Button disabled={disabled} onClick={onConfirm}>
          <SaveTwoTone />
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgPanelAddSummary.propTypes = {
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedFolders: PropTypes.array.isRequired,
  selectedSensors: PropTypes.array.isRequired,
  title: PropTypes.string,
}
