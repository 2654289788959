import { MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const KPISelector = ({
  data = [
    { label: 'Verbrauch', value: 'consumption' },
    { label: 'CO₂', value: 'co2' },
    { label: 'Kosten', value: 'price' },
  ],
  label = 'Einheit',
  variant = 'standard',
  ...rest
}) => (
  <TextField label={label} select variant={variant} {...rest}>
    {data.map((item, key) => (
      <MenuItem key={key} value={item.value}>
        {item.label}
      </MenuItem>
    ))}
  </TextField>
)

KPISelector.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  variant: PropTypes.string,
}
