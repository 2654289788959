import { useCallback, useEffect, useMemo, useState } from 'react'
import { categoryByType } from '../../api'
import { useFolderPool, useSensorPool } from '..'

export const useFolderStatus = folderId => {
  const [data, setData] = useState({
    correlation: 'neutral',
    feeling: 'neutral',
    installation: 'neutral',
    performance: 'neutral',
    security: 'neutral',
    canCorrelate: null,
  })

  const { data: folderPool } = useFolderPool()
  const { data: sensorPool } = useSensorPool()

  const filters = useMemo(
    () => ({
      isCurrentFolder: f => Number(f.attributes.folder_id) === folderId,
      isConsumptionSensor: f =>
        ['consumption', 'performance'].includes(
          categoryByType(f.attributes.sensor_type)
        ),
      isSecuritySensor: f =>
        f.attributes.sensor_target === 'security' ||
        categoryByType(f.attributes.sensor_type) === 'security',
      isFeelingSensor: f =>
        f.attributes.sensor_target === 'feeling' ||
        categoryByType(f.attributes.sensor_type) === 'feeling',
      canCorrelate: f => {
        const blacklist = [
          'door',
          'smokeDetektor',
          'Removal_detection',
          'Battery_end_of_life',
          'Smoke_alarm',
          'Acoustic_alarm_failure',
          'waterDetected',
          'external_digital_button',
          'external_digital_button_2',
        ]

        return !blacklist.includes(f.attributes.sensor_type)
      },
    }),
    [folderId]
  )

  const filterData = useCallback(
    fn => sensorPool.filter(filters.isCurrentFolder).filter(fn),
    [sensorPool, filters.isCurrentFolder]
  )

  useEffect(() => {
    if (!folderPool || !folderId || !sensorPool) return

    const folderData = folderPool.find(folder => Number(folder.id) === folderId)
    if (!folderData) return

    const { sensor_group_status: sgs } = folderData.attributes

    const getStatus = category =>
      sgs.find(f => f.type === category)?.status || 'neutral'

    const P_SENSORS = filterData(filters.isConsumptionSensor)
    const S_SENSORS = filterData(filters.isSecuritySensor)
    const W_SENSORS = filterData(filters.isFeelingSensor)

    const performance =
      P_SENSORS.length > 0 ? getStatus('status_performance') : 'neutral'

    const security =
      S_SENSORS.length > 0 ? getStatus('status_security') : 'neutral'

    const feeling =
      W_SENSORS.length > 0 ? getStatus('status_well_being') : 'neutral'

    const PSW_SENSORS = [...P_SENSORS, ...S_SENSORS, ...W_SENSORS]

    const correlation =
      PSW_SENSORS.filter(filters.canCorrelate).length > 1 ? 'green' : 'neutral'

    const installation = getStatus('status_installation')

    const canCorrelate =
      PSW_SENSORS.length > 0
        ? PSW_SENSORS.filter(filters.canCorrelate).length > 1
        : null

    setData({
      performance,
      security,
      feeling,
      correlation,
      installation,
      canCorrelate,
    })
  }, [folderPool, folderId, filterData, filters, sensorPool])

  return data
}
