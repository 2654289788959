import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'
import defaultImage from '../../assets/img/image_placeholder.jpg'

export const useFolderImage = folderId => {
  const [data, setData] = useState(defaultImage)

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.image_url) return

    const image = folderData?.attributes?.image_url
    if (image) setData(image)
  }, [folderPool, folderId])

  return data
}

useFolderImage.propTypes = {
  folderId: PropTypes.number,
}
