import { Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { HStack } from '../../../components'
import { DDMMYYYY, userLocales } from '../../../api'

export const DataSampling = ({
  date,
  locales = userLocales,
  options = DDMMYYYY,
}) => {
  const theme = useTheme()
  const { white: bgcolor } = theme.palette

  const firstRecord = new Date(date).toLocaleDateString(locales, options)

  return !date ? null : (
    <HStack sx={{ bgcolor, pt: 2, pl: 15 }}>
      <Typography>Messwerte seit {firstRecord}</Typography>
    </HStack>
  )
}

DataSampling.propTypes = {
  date: PropTypes.instanceOf(Date),
  locales: PropTypes.string,
  options: PropTypes.object,
}
