import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  CheckTwoTone,
  DeleteTwoTone,
  PublishTwoTone,
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useFolderImage, useFolderName } from '../../hooks'
import defaultImage from '../../assets/img/image_placeholder.jpg'

export const DlgFolderImage = ({ folderId, onClose, onUpdate, open }) => {
  const fName = useFolderName(folderId)
  const fImg = useFolderImage(folderId)

  const [preview, setPreview] = useState()

  const ref = useRef()

  const NO_IMG = !fImg || fImg === ''

  useEffect(() => setPreview(fImg ?? defaultImage), [fImg])

  const onChange = event => {
    event.preventDefault()

    const image_url = event.target.files[0]

    const reader = new FileReader()
    reader.readAsDataURL(image_url)
    reader.onloadend = () => {
      setPreview(reader.result)
      onUpdate(image_url)
    }
  }

  const onClear = () => {
    setPreview(defaultImage)
    onUpdate(defaultImage)

    ref.current = null
  }

  const onUpload = () => ref.current.click()

  return (
    <Dialog maxWidth={'xl'} open={open} onClose={onClose}>
      <DialogTitle>Profilbild von {fName}</DialogTitle>

      <DialogContent
        sx={{ width: 1, height: 'auto' }}
        variant={'profilePicture'}
      >
        <Box alt={''} component={'img'} src={preview} />
      </DialogContent>

      <DialogActions>
        <Button disabled={NO_IMG} onClick={onClear}>
          <DeleteTwoTone /> Löschen
        </Button>

        <input
          accept={'image/*'}
          hidden
          onChange={onChange}
          ref={r => (ref.current = r)}
          type={'file'}
        />

        <Button onClick={onUpload}>
          <PublishTwoTone /> Upload
        </Button>

        <Button onClick={onClose}>
          <CheckTwoTone /> Fertig
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgFolderImage.propTypes = {
  folderId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
