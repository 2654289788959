import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sensorActions } from '../../actions'

export const useTimeSeries = (id, fromDate, toDate, granularity = null) => {
  const dispatch = useDispatch()

  const [data, setData] = useState(null)
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const { timeSeries } = useSelector(state => state.sensor)

  useEffect(() => {
    if (Number(id) > 0) {
      setLoading(true)
      dispatch(
        sensorActions.getTimeSeries(
          id,
          fromDate + ' 00:00:00',
          toDate + ' 23:59:59',
          granularity
        )
      )
    }
  }, [dispatch, id, fromDate, toDate, granularity])

  useEffect(() => {
    const { error, items, loading } = timeSeries

    if (items) {
      const { data } = items

      if (data) {
        const { time_series } = data.attributes

        setData(time_series)
        setLoading(false)
      }
    }

    if (loading) setLoading(loading)

    if (error) {
      const { message } = error

      setError(message)
      setLoading(false)
    }
  }, [timeSeries])
  return { isLoading, isError, data }
}
