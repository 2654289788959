import {
  EmailTwoTone,
  VisibilityOffTwoTone,
  VisibilityTwoTone,
  VpnKeyTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
} from '@mui/material'
import React, { forwardRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userActions, alertActions } from '../../actions'
import {
  getStorage,
  isValidEmail,
  isValidLength,
  isValidPassword,
} from '../../api'
import { KTextField } from '../../components'
import { userConstants } from '../../constants'

const { USER_PASSWORD_CHANGED } = userConstants

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction={'down'} ref={ref} {...props} />
})

export const LoginPage = () => {
  const dispatch = useDispatch()

  /* states */

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [showModalReset1, setShowModalReset1] = useState(true)
  const [showModalReset2, setShowModalReset2] = useState(false)

  /* functions */

  const onSubmit = event => {
    event.preventDefault()

    dispatch(userActions.login(email, password))
  }

  const closeModal = () => {
    setOpenModal(false)
    setShowModalReset1(true)
    setShowModalReset2(false)
  }

  const onChangeConfirmationCode = event =>
    setVerificationCode(event.target.value)
  const onChangeEmail = event => setEmail(event.target.value.toLowerCase())
  const onChangeNewPassword = event => setNewPassword(event.target.value)
  const onChangePassword = event => setPassword(event.target.value)
  const onCodeRequest = () => {
    dispatch(userActions.sendResetCode(email))

    setShowModalReset1(false)
    setShowModalReset2(true)
  }
  const onDoNothing = event => event.preventDefault()
  const onOpenModal = () => setOpenModal(true)
  const onResetPassword = () => {
    dispatch(userActions.resetPassword(email, newPassword, verificationCode))

    closeModal()
    dispatch(alertActions.success(USER_PASSWORD_CHANGED))
  }
  const onSendConfirmation = () => dispatch(userActions.sendSignup(email))
  const onTogglePasswordMode = () => setShowPassword(!showPassword)

  /* constants */

  const CODE_REQUEST_DISABLED = !isValidEmail(email) || email === ''

  const RESET_PASSWORD_DISABLED =
    !isValidEmail(email) ||
    email === '' ||
    !isValidLength(verificationCode, 6) ||
    verificationCode === '' ||
    !isValidPassword(newPassword) ||
    newPassword === ''

  const SEND_CONFIRMATION_DISABLED = !isValidEmail(email)

  const LOGIN_BUTTON_DISABLED =
    !isValidEmail(email) || !isValidPassword(password)

  /* component */

  return (
    <>
      {/* password reset */}
      <Dialog
        keepMounted
        onClose={closeModal}
        open={openModal}
        TransitionComponent={Transition}
      >
        <DialogTitle>Passwort vergessen?</DialogTitle>

        <DialogContent>
          {showModalReset1 && (
            <Alert severity={'info'}>
              Bitte geben Sie die E-Mail Adresse von Ihrem Karma Konto an. Wir
              schicken Ihnen einen Code zum Zurücksetzen des Passwortes an diese
              Adresse. Bitte überprüfen Sie den Erhalt ggfs. auch in Ihrem Spam
              Ordner.
            </Alert>
          )}

          <KTextField
            autoComplete={'email'}
            error={email !== '' && !isValidEmail(email)}
            icon={<EmailTwoTone />}
            label={'E-Mail'}
            onChange={onChangeEmail}
            type={'email'}
            value={email}
          />

          {showModalReset2 && (
            <>
              <KTextField
                error={
                  verificationCode !== '' && !isValidLength(verificationCode, 6)
                }
                icon={<VpnKeyTwoTone />}
                label={'Verifizierungscode'}
                onChange={onChangeConfirmationCode}
                value={verificationCode}
              />

              <FormControl fullWidth>
                <TextField
                  autoComplete={'new-password'}
                  error={newPassword !== '' && !isValidPassword(newPassword)}
                  label={'Neues Passwort'}
                  helperText={
                    newPassword !== '' &&
                    !isValidPassword(newPassword) &&
                    'Passwort muss mindestens 8 Zeichen lang sein, 1 Ziffer, Groß- und Kleinbuchstaben enthalten.'
                  }
                  onChange={onChangeNewPassword}
                  value={newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <IconButton
                          tabIndex={-1}
                          onClick={onTogglePasswordMode}
                          onMouseDown={onDoNothing}
                        >
                          {showPassword ? (
                            <VisibilityTwoTone />
                          ) : (
                            <VisibilityOffTwoTone />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showPassword ? 'text' : 'password'}
                />
              </FormControl>
            </>
          )}
        </DialogContent>

        <DialogActions>
          {showModalReset1 && (
            <Button disabled={CODE_REQUEST_DISABLED} onClick={onCodeRequest}>
              Code anfordern
            </Button>
          )}

          {showModalReset2 && (
            <Button
              disabled={RESET_PASSWORD_DISABLED}
              onClick={onResetPassword}
            >
              Zurücksetzen
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Container maxWidth={'sm'} sx={{ m: 'auto', zIndex: 4 }}>
        <Card>
          <form name={'form'} onSubmit={onSubmit}>
            <CardHeader title={'Anmeldung'} variant={'dialog'} />

            <CardContent>
              <KTextField
                autoComplete={'email'}
                icon={<EmailTwoTone />}
                label={'E-Mail'}
                onChange={onChangeEmail}
                type={'email'}
                value={email}
              />

              <FormControl sx={{ mb: 2 }} fullWidth>
                <TextField
                  autoComplete={'current-password'}
                  error={!isValidPassword(password) && password !== ''}
                  helperText={
                    !isValidPassword(password) &&
                    password !== '' &&
                    'Passwort muss mindestens 8 Zeichen lang sein, 1 Ziffer, Groß- und Kleinbuchstaben enthalten.'
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <IconButton
                          onClick={onTogglePasswordMode}
                          onMouseDown={onDoNothing}
                          tabIndex={-1}
                        >
                          {showPassword ? (
                            <VisibilityTwoTone />
                          ) : (
                            <VisibilityOffTwoTone />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={'Passwort'}
                  onChange={onChangePassword}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                />
              </FormControl>

              {getStorage('errorNotConfirmed') && (
                <Button
                  disabled={SEND_CONFIRMATION_DISABLED}
                  onClick={onSendConfirmation}
                >
                  Konto Bestätigungslink senden
                </Button>
              )}

              <Button variant={'text'} onClick={onOpenModal}>
                Passwort vergessen?
              </Button>

              <Box sx={{ textAlign: 'center' }}>
                <Button
                  autoFocus
                  disabled={LOGIN_BUTTON_DISABLED}
                  type={'submit'}
                >
                  Login
                </Button>
              </Box>
            </CardContent>
          </form>
        </Card>
      </Container>
    </>
  )
}
