import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import PropTypes from 'prop-types'
import React from 'react'

export const DlgFolderDelete = ({ id, onClose, onConfirm, open }) => (
  <Dialog maxWidth={'md'} onClose={onClose} open={open}>
    <DialogTitle>
      Löschen von Gebäude ID:
      {id} bestätigen
    </DialogTitle>
    <DialogContent />
    <DialogActions>
      <Button autoFocus onClick={onClose}>
        Abbrechen
      </Button>
      <Button onClick={onConfirm}>Löschen</Button>
    </DialogActions>
  </Dialog>
)

DlgFolderDelete.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
}
