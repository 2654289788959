import {
  DashboardCustomizeTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExpandMoreTwoTone,
  SettingsTwoTone,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Chip,
  Collapse,
  LinearProgress,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ListManager } from 'react-beautiful-dnd-grid'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../actions'
import { userLocales, DDMMYYYY, getStorage, setStorage } from '../../api'
import {
  AddFirstPanel,
  DashboardGranularitySelector,
  DlgPanelAddSummary,
  DlgDashboardDelete,
  DlgDashboardEdit,
  DlgPanelAdd,
  SinglePanel,
} from '../../components'
import {
  useConsumptionByDashboardId,
  useDashboardPool,
  usePanels,
} from '../../hooks'

const germanDate = date =>
  new Date(date).toLocaleDateString(userLocales, DDMMYYYY)

const getStartAndEndDate = () => {
  const y = new Date().getFullYear()
  const m = new Date().getMonth()
  const startDate = new Date(y, m - 1, 1)
  const endDate = new Date(y, m, 0)

  return { startDate, endDate }
}

export const SingleDashboard = ({
  dashboardId,
  expanded,
  onChange,
  PAGE_CONFIG = 'settings_dashboard_id',
}) => {
  const dispatch = useDispatch()

  // hooks

  const { data: dashboardPool } = useDashboardPool()

  const {
    isLoading: isDashboardConsumptionLoading,
    isFactorError,
    factorErrorSensor,
  } = useConsumptionByDashboardId(dashboardId)

  const { data: panelsData, isLoading: isPanelsLoading } =
    usePanels(dashboardId)

  const [panelData, setPanelData] = useState({})

  useEffect(() => {
    setPanelData({
      granularity: 'd',
      graphic_type: 1,
      indicator: 1,
      name: 'Neue Kachel',
      position: 0,
      presentation_level: null,
      startDate: getStartAndEndDate().startDate,
      endDate: getStartAndEndDate().endDate,
    })
  }, [])

  // handle multiple instances of same title
  useEffect(() => {
    if (panelsData.length === 0) return

    const instances = Number(
      panelsData.filter(f => f.attributes.name.includes(panelData?.name).length)
    )

    const name =
      instances > 0 ? `${panelData?.name} (${instances})` : panelData?.name
    setPanelData(prev => ({ ...prev, name }))
  }, [dashboardPool, panelData?.name, panelsData])

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  const [showDlg, setShowDlg] = useState(false)

  const dashboardGranularity = config?.granularity ?? 'lastFullMonth'

  const [panelsAndConsumptions, setPanelsAndConsumptions] = useState([])

  const maxColItems = config?.maxColItems ?? 2

  const [selectedFolders, setSelectedFolders] = useState([])
  const [selectedSensors, setSelectedSensors] = useState([])

  const [dashboardData, setDashboardData] = useState({
    name: null,
    startDate: null,
    endDate: null,
    granularity: 'lastFullMonth',
  })

  const dateRangeLabel = `${germanDate(dashboardData.startDate)} - ${germanDate(dashboardData.endDate)}`

  const actions = [
    {
      fn: () => setShowDlg('DlgAddTile'),
      icon: <DashboardCustomizeTwoTone />,
      name: 'Neue Kachel',
    },
    {
      fn: () => setShowDlg('DlgEditDashboard'),
      icon: <EditTwoTone />,
      name: `${dashboardData.name} bearbeiten`,
    },
    {
      fn: () => setShowDlg('DlgDeleteDashboard'),
      icon: <DeleteTwoTone />,
      name: `${dashboardData.name} löschen`,
    },
  ]

  // fn's

  const onChangeGranularity = event => {
    const mode = event.target.value

    const y = new Date().getFullYear()
    const m = new Date().getMonth()
    const d = new Date().getDate()

    let fromDate
    let toDate
    let granularity

    switch (mode) {
      case 'lastFullMonth':
        fromDate = new Date(y, m - 1, 1)
        toDate = new Date(y, m, 0)
        granularity = 'd'
        break
      case 'lastThirtyDays':
        fromDate = new Date(y, m, d - 30)
        toDate = new Date(y, m, d)
        granularity = 'd'
        break
      case 'lastTwoMonths':
        fromDate = new Date(y, m - 2, 1)
        toDate = new Date(y, m, 0)
        granularity = 'm'
        break
      case 'lastSixMonths':
        fromDate = new Date(y, m - 6, 1)
        toDate = new Date(y, m, 0)
        granularity = 'm'
        break
      case 'thisYear':
        fromDate = new Date(y, 0, 1)
        toDate = new Date(y, m, d)
        granularity = 'm'
        break
      case 'lastYear':
        fromDate = new Date(y - 1, 0, 1)
        toDate = new Date(y - 1, 12, 0)
        granularity = 'm'
        break
      default:
        break
    }

    setConfig(prev => ({ ...prev, granularity: mode }))

    // en-CA (!) as user locales; formats to YYYY-MM-DD
    const start_date = fromDate.toLocaleDateString('en-CA', DDMMYYYY)
    const end_date = toDate.toLocaleDateString('en-CA', DDMMYYYY)
    const options = { start_date, end_date, granularity }

    dispatch(dashboardActions.updatePanelsByDashboardId(dashboardId, options))
  }

  const onCloseAdd = () => {
    setShowDlg(false)
    setPanelData(prev => ({ ...prev, presentation_level: null }))
    setSelectedFolders([])
    setSelectedSensors([])
  }

  const onClose = () => setShowDlg(false)

  const onDragEnd = (startIndex, endIndex) => {
    // dragging operation did not changed panel position
    if (startIndex === endIndex) return

    const list = Array.from(panelsAndConsumptions)
    const [removed] = list.splice(startIndex, 1)
    list.splice(endIndex, 0, removed)

    const dashboardId = Number(list[endIndex].attributes.dashboard_id)
    const panelId = Number(list[endIndex].id)

    const movePanel = {
      old_position: Number(startIndex),
      new_position: Number(endIndex),
    }

    dispatch(dashboardActions.movePanel(movePanel, dashboardId, panelId))

    setPanelsAndConsumptions(list)
  }

  const onGotoSummary = () => setShowDlg('DlgPanelAddSummary')

  const onSavePanel = () => {
    const {
      endDate,
      granularity,
      graphic_type,
      indicator,
      name,
      position,
      presentation_level,
      startDate,
    } = panelData

    const startYear = startDate.getFullYear()
    const startMonth = ('0' + (startDate.getMonth() + 1)).slice(-2)
    const startDay = ('0' + startDate.getDate()).slice(-2)

    const endYear = endDate.getFullYear()
    const endMonth = ('0' + (endDate.getMonth() + 1)).slice(-2)
    const endDay = ('0' + endDate.getDate()).slice(-2)

    dispatch(
      dashboardActions.savePanel({
        dashboard_id: dashboardId,
        name,
        position: Number(position),
        indicator: Number(indicator),
        graphic_type: Number(graphic_type),
        start_date: startYear + '-' + startMonth + '-' + startDay,
        end_date: endYear + '-' + endMonth + '-' + endDay,
        granularity,
        presentation_level,
        sensor_ids: selectedSensors,
      })
    )
  }

  // useEffects

  useEffect(() => {
    if (dashboardPool.length === 0) return

    const record = dashboardPool.find(f => Number(f.id) === dashboardId)
    if (!record) console.error('dashboard not found!', dashboardId)

    const name = record?.attributes?.name ?? 'Neues Dashboard'
    setDashboardData(prev => ({ ...prev, name }))
  }, [dashboardId, dashboardPool])

  useEffect(() => {
    if (panelsData.length === 0) return

    const { start_date, end_date } = panelsData[0].attributes

    const today = new Date()
    const y = today.getFullYear()
    const m = today.getMonth()
    const d = today.getDate()

    today.setHours(0, 0, 0, 0)

    const startDate = new Date(start_date)
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date(end_date)
    endDate.setHours(0, 0, 0, 0)

    let granularity = 'lastFullMonth'

    if (startDate.getTime() === new Date(y, m - 1, 1).getTime()) {
      granularity = 'lastFullMonth'
    } else if (startDate.getTime() === new Date(y, m, d - 30).getTime()) {
      granularity = 'lastThirtyDays'
    } else if (startDate.getTime() === new Date(y, m - 2, 1).getTime()) {
      granularity = 'lastTwoMonths'
    } else if (startDate.getTime() === new Date(y, m - 6, 1).getTime()) {
      granularity = 'lastSixMonths'
    } else if (startDate.getTime() === new Date(y, 0, 1).getTime()) {
      granularity = 'thisYear'
    } else if (
      startDate.getTime() === new Date(y - 1, 0, 1).getTime() &&
      endDate.getTime() === new Date(y - 1, 12, 0).getTime()
    )
      granularity = 'lastYear'

    setConfig(prev => ({ ...prev, granularity }))

    const lastPanel = panelsData[panelsData.length - 1]
    const { indicator, graphic_type } = lastPanel.attributes

    setPanelData(prev => ({
      ...prev,
      indicator,
      graphic_type,
      position: prev?.position + 1,
    }))

    setPanelsAndConsumptions(panelsData)
  }, [panelsData])

  useEffect(() => {
    const y = new Date().getFullYear()
    const m = new Date().getMonth()
    const d = new Date().getDate()

    let startDate
    let endDate
    let granularity

    switch (dashboardGranularity) {
      case 'lastFullMonth':
        startDate = new Date(y, m - 1, 1)
        endDate = new Date(y, m, 0)
        granularity = 'd'
        break

      case 'lastThirtyDays':
        startDate = new Date(y, m, d - 30)
        endDate = new Date(y, m, d)
        granularity = 'd'
        break

      case 'lastTwoMonths':
        startDate = new Date(y, m - 2, 1)
        endDate = new Date(y, m, 0)
        granularity = 'm'
        break

      case 'lastSixMonths':
        startDate = new Date(y, m - 6, 1)
        endDate = new Date(y, m, 0)
        granularity = 'm'
        break

      case 'thisYear':
        startDate = new Date(y, 0, 1)
        endDate = new Date(y, m, d)
        granularity = 'm'
        break

      case 'lastYear':
        startDate = new Date(y - 1, 0, 1)
        endDate = new Date(y - 1, 12, 0)
        granularity = 'm'
        break

      default:
        break
    }

    startDate = startDate.toLocaleDateString('en-CA', DDMMYYYY)
    endDate = endDate.toLocaleDateString('en-CA', DDMMYYYY)

    setDashboardData(prev => ({ ...prev, startDate, endDate, granularity }))
  }, [dashboardGranularity])

  //

  const isPageLoading = isPanelsLoading || isDashboardConsumptionLoading

  const disabled = !panelData?.presentation_level || !panelData?.name

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      sx={{ '&.Mui-expanded': { m: 0 }, p: 1 }}
    >
      <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
        <Typography variant={'h6'}>{dashboardData.name}</Typography>

        <Chip label={dateRangeLabel} sx={{ ml: 2 }} />
      </AccordionSummary>

      {isPageLoading === true ? (
        <LinearProgress />
      ) : (
        <Stack>
          {expanded === true && (
            <AccordionDetails>
              {/* dashboard granularity */}
              <DashboardGranularitySelector
                granularity={dashboardGranularity}
                onChange={onChangeGranularity}
              />

              {/* display warning in case of factor issues */}
              <Collapse in={isFactorError}>
                <Alert severity={'warning'}>
                  Bitte prüfen Sie die Faktoren für Messpunkt{' '}
                  {factorErrorSensor}.
                </Alert>
              </Collapse>

              {/* drag-n-drop wrapper for panels  */}
              <ListManager
                direction={'horizontal'}
                items={panelsAndConsumptions}
                maxItems={maxColItems}
                onDragEnd={onDragEnd}
                render={item => (
                  <SinglePanel
                    dashboardId={dashboardId}
                    panelId={Number(item.id)}
                  />
                )}
                style={{ display: 'flex' }}
              />

              {/* if dashboard is empty -> no panel added yet, then display a hint to create one */}
              {!isPanelsLoading && panelsData.length === 0 && (
                <AddFirstPanel onAdd={() => setShowDlg('DlgAddTile')} />
              )}

              {/* display speed dial only if accordion is expanded */}
              {expanded && (
                <SpeedDial
                  ariaLabel={'SpeedDial'}
                  icon={<SettingsTwoTone />}
                  sx={{ position: 'absolute', bottom: 16, right: 16 }}
                >
                  {actions.map((action, key) => (
                    <SpeedDialAction
                      icon={action.icon}
                      key={key}
                      onClick={action.fn}
                      tooltipTitle={action.name}
                    />
                  ))}
                </SpeedDial>
              )}
            </AccordionDetails>
          )}
        </Stack>
      )}

      {/* add */}
      <DlgPanelAdd
        panelData={panelData}
        onClose={onCloseAdd}
        onGotoSummary={onGotoSummary}
        open={showDlg === 'DlgAddTile'}
        selectedFolders={selectedFolders}
        selectedSensors={selectedSensors}
        setSelectedFolders={setSelectedFolders}
        setSelectedSensors={setSelectedSensors}
        setPanelData={setPanelData}
      />

      {/* edit */}
      <DlgDashboardEdit
        dashboardId={dashboardId}
        granularity={dashboardGranularity}
        name={dashboardData.name}
        onChangeGranularity={onChangeGranularity}
        onChangeName={name => setDashboardData(prev => ({ ...prev, name }))}
        onClose={onClose}
        open={showDlg === 'DlgEditDashboard'}
      />

      {/* delete */}
      <DlgDashboardDelete
        dashboardId={dashboardId}
        name={dashboardData.name}
        onClose={onClose}
        open={showDlg === 'DlgDeleteDashboard'}
      />

      {/* summary */}
      <DlgPanelAddSummary
        disabled={disabled}
        onClose={() => setShowDlg('DlgAddTile')}
        onConfirm={onSavePanel}
        open={showDlg === 'DlgPanelAddSummary'}
        selectedFolders={selectedFolders}
        selectedSensors={selectedSensors}
      />
    </Accordion>
  )
}

SingleDashboard.propTypes = {
  dashboardId: PropTypes.number,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  PAGE_CONFIG: PropTypes.string,
}
