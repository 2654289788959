import { API } from 'aws-amplify'
import axios from 'axios'
import { authHeader } from '../api'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const deleteSensorPrice = async id => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/sensor_price/admin/sensor_price/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorPriceService.deleteSensorPrice', error)
  }
}

const getAll = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/sensor_price/admin/sensor_price`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorPriceService.getAll', error)
  }
}

const save = async body => {
  const endpoint = '/sensor_price/admin/sensor_price'
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

const update = async body => {
  const endpoint = '/sensor_price/admin/sensor_price'
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const sensorPriceService = {
  deleteSensorPrice,
  getAll,
  save,
  update,
}
