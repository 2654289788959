import { AddTwoTone, EmailTwoTone } from '@mui/icons-material'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { isValidEmail } from '../../api'
import { HStack } from '..'

export const AddAboRecipient = ({
  avatar = <EmailTwoTone />,
  onAdd,
  title = 'Empfänger hinzufügen',
}) => {
  const theme = useTheme()
  const { dark: borderColor, light: bgcolor } = theme.palette.lightgray
  const border = `solid 1px ${borderColor}`

  const [email, setEmail] = useState('')

  const disabled = !isValidEmail(email)

  const onChange = event => setEmail(event.target.value)
  const onClick = () => {
    onAdd(email)
    setEmail('')
  }

  return (
    <Card sx={{ border, borderRadius: '10px' }}>
      <CardHeader avatar={avatar} title={title} />

      <Divider />

      <CardContent>
        <HStack alignItems={'center'} sx={{ width: 1 }}>
          <TextField
            label={'E-Mail'}
            onChange={onChange}
            sx={{ width: 1 }}
            value={email}
          />

          <Stack>
            <IconButton disabled={disabled} onClick={onClick} sx={{ bgcolor }}>
              <AddTwoTone />
            </IconButton>
          </Stack>
        </HStack>
      </CardContent>
    </Card>
  )
}

AddAboRecipient.propTypes = {
  avatar: PropTypes.element,
  onAdd: PropTypes.func.isRequired,
  title: PropTypes.string,
}
