import {
  DeleteTwoTone,
  ExpandMoreTwoTone,
  InfoTwoTone,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { HStack, PanelDisplayFolders, PanelDisplaySensors } from '..'
import { useFolderPool, useOperator } from '../../hooks'
import useDashboardPagination from '../../hooks/dashboard/useDashboardPagination'
import { useSensorPool, useSensorPricePool } from '../../hooks'

const graphicTypeMenuData = [
  { label: 'Kreisdiagramm', value: 1 },
  { label: 'Balkendiagramm', value: 2 },
  { label: 'Ranking', value: 3 },
]

const indicatorMenuData = [
  { label: 'Verbrauch', value: 1 },
  { label: 'Verbrauch/m²', value: 2 },
  { label: 'Kosten', value: 3 },
  { label: 'Kosten/m²', value: 4 },
  { label: 'CO₂-Ausstoß', value: 5 },
  { label: 'CO₂-Ausstoß/m²', value: 6 },
]

const indicatorType = {
  consumption: 1,
  consumption_per_sqm: 2,
  cost: 3,
  cost_per_sqm: 4,
  co2: 5,
  co2_per_sqm: 6,
}

export const DlgPanelEdit = ({
  selectedFolders,
  selectedSensors,
  graphicType,
  indicator,
  onChangeGraphicType,
  onChangeIndicator,
  onChangeName,
  onChangePanelUnit,
  onChangePresentationLevel,
  onClose,
  onDeletePanel,
  onGotoSummary,
  open,
  panelName,
  presentationLevel,
  setSelectedFolders,
  setSelectedSensors,
  title = 'Kachel Einstellungen',
}) => {
  const { data: folderPool } = useFolderPool()
  const { data: sensorPool } = useSensorPool()
  const { data: operatorData } = useOperator()
  const { data: sensorPricePool } = useSensorPricePool()

  const [energyData, setEnergyData] = useState([])
  const [waterData, setWaterData] = useState([])
  const [gasData, setGasData] = useState([])

  const PER_PAGE = 10

  const ENERGYDATA = useDashboardPagination(energyData, PER_PAGE)
  const WATERDATA = useDashboardPagination(waterData, PER_PAGE)
  const GASDATA = useDashboardPagination(gasData, PER_PAGE)

  const [energyChecked, setEnergyChecked] = useState(false)
  const [waterChecked, setWaterChecked] = useState(false)
  const [gasChecked, setGasChecked] = useState(false)

  const [showFolderSensors, setShowFolderSensors] = useState(false)
  const [showSelectedFolderSensors, setShowSelectedFolderSensors] =
    useState(false)

  const [page, setPage] = useState(1)
  const [sensorsSearch, setSensorsSearch] = useState('')

  const [count, setCount] = useState(0)

  const handlePageChange = (event, page) => {
    setPage(page)
    ENERGYDATA.jump(page)
    WATERDATA.jump(page)
    GASDATA.jump(page)
  }

  const handleToggleAllSensors = (event, type) => {
    const sensors =
      type === 'energy'
        ? energyData
        : type === 'water'
          ? waterData
          : type === 'gas'
            ? gasData
            : null

    const panelUnit = type === 'water' ? 'm³' : 'kWh'

    const updatedEntityIds = []
    const updatedFolderIds = []

    sensors.map(sensor => {
      updatedEntityIds.push(sensor.attributes.entity_id)
      const folderId = Number(sensor.attributes.folder_id)
      const folder = folderPool.find(f => Number(f.id) === folderId)
      updatedFolderIds.push(folderId)

      if (folder && !selectedFolders.includes(folder.attributes.entity_id))
        setSelectedFolders(prev => [...prev, folder.attributes.entity_id])

      return null
    })

    let updatedSelectedSensorIds = event.target.checked
      ? [...selectedSensors, ...updatedEntityIds]
      : selectedSensors.filter(entityId => !updatedEntityIds.includes(entityId))

    if (
      event.target.checked === false &&
      updatedSelectedSensorIds.length === 0
    ) {
      setSelectedFolders([])
      updatedSelectedSensorIds = []
    }

    setSelectedSensors(updatedSelectedSensorIds)

    onChangePanelUnit(panelUnit)
  }

  useEffect(() => {
    if (!sensorPool || !folderPool) return

    const FILTER_INDICATOR = sensor =>
      indicator === indicatorType.consumption
        ? true
        : indicator === indicatorType.consumption_per_sqm
          ? sensor.attributes.area_in_sqm !== null
          : indicator === indicatorType.cost
            ? sensorPricePool.find(
                f => f.sensor_id === sensor.attributes.entity_id
              )
            : indicator === indicatorType.cost_per_sqm
              ? sensorPricePool.find(
                  f => f.sensor_id === sensor.attributes.entity_id
                ) && sensor.attributes.area_in_sqm !== null
              : indicator === indicatorType.co2
                ? sensor.attributes.co2_factor !== null
                : sensor.attributes.co2_factor !== null &&
                  sensor.attributes.area_in_sqm !== null

    const FILTER_FOLDERS = f =>
      presentationLevel === 1
        ? selectedFolders?.includes(Number(f.attributes.folder_id))
        : true

    const FILTER_FOLDER_SENSORS = sensor =>
      showFolderSensors ? sensor.attributes.folder_id !== null : true

    const FILTER_SELECTED_FOLDER_SENSORS = sensor =>
      showSelectedFolderSensors
        ? selectedSensors.includes(sensor.attributes.entity_id)
        : true

    const FILTER_SEARCH = sensor => {
      const { name, folder_id } = sensor.attributes
      const folder = folderPool.find(f => Number(f.id) === Number(folder_id))
      const label = `${name}  |  ${folder ? folder.attributes.name : ''}`

      return label.toLowerCase().includes(sensorsSearch.toLowerCase())
    }

    const FILTER_BY_TYPE = type =>
      sensorPool
        .filter(
          sensor =>
            sensor.attributes.name !== null &&
            (sensor.attributes.type_in_folder === type ||
              sensor.attributes.type_in_folder === `${type}_detail`)
        )
        .filter(FILTER_INDICATOR)
        .filter(FILTER_SEARCH)
        .filter(FILTER_FOLDERS)
        .filter(FILTER_FOLDER_SENSORS)
        .filter(FILTER_SELECTED_FOLDER_SENSORS)

    const count = Math.ceil(FILTER_BY_TYPE('energy').length / PER_PAGE)
    setCount(count)

    setEnergyData(FILTER_BY_TYPE('energy'))
    setWaterData(FILTER_BY_TYPE('water'))
    setGasData(FILTER_BY_TYPE('gas'))
  }, [
    sensorsSearch,
    selectedFolders,
    sensorPool,
    presentationLevel,
    folderPool,
    indicator,
    sensorPricePool,
    showFolderSensors,
    showSelectedFolderSensors,
    selectedSensors,
  ])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      onClose={onClose}
      open={open}
      onMouseDown={event => event.stopPropagation()}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ my: 1 }}>
        <HStack sx={{ width: 1, my: 1 }}>
          {/* Name */}
          <TextField
            fullWidth
            label={'Titel'}
            onChange={event => onChangeName(event.target.value)}
            value={panelName || ''}
          />

          {/* Indikator */}
          <TextField
            fullWidth
            label={'Indikator'}
            onChange={event => onChangeIndicator(event.target.value)}
            select
            value={indicator || ''}
          >
            {indicatorMenuData.map((item, key) => (
              <MenuItem key={key} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </TextField>

          {/* graphic type */}
          <TextField
            fullWidth
            label={'Grafiktyp'}
            onChange={event => onChangeGraphicType(event.target.value)}
            select
            value={graphicType || ''}
          >
            {graphicTypeMenuData.map((item, key) => (
              <MenuItem key={key} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </TextField>
        </HStack>

        {/* presentation Level */}
        <FormControl>
          <FormLabel>
            Darstellungsebene{' '}
            <Tooltip
              title={
                'Eine Kachel kann auf Gebäude- oder Messpunktebene erstellt werden. In der Gebäudeebene werden alle ausgewählten Messpunkte unter dem Gebäudenamen zusammengefasst. In der Messpunktebene wird jeder Messpunkt einzeln aufgelistet.'
              }
            >
              <InfoTwoTone />
            </Tooltip>
          </FormLabel>

          <RadioGroup
            onChange={event => {
              onChangePresentationLevel(Number(event.target.value))

              setSelectedFolders([])
              setSelectedSensors([])
              setEnergyChecked(false)
              setWaterChecked(false)
              setGasChecked(false)
            }}
            row
            value={presentationLevel}
          >
            {[
              { label: 'Gebäude', value: '1' },
              { label: 'Messpunkt', value: '2' },
            ].map((item, key) => (
              <FormControlLabel
                control={<Radio />}
                key={key}
                label={item.label}
                labelPlacement={'start'}
                value={item.value}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <PanelDisplayFolders
          disabled={presentationLevel !== 1}
          selectedFolders={selectedFolders}
          setSelectedSensors={setSelectedSensors}
          setSelectedFolders={setSelectedFolders}
        />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
            <HStack sx={{ width: 1 }}>
              <Chip label={`${selectedSensors.length}/${sensorPool.length}`} />

              <Typography>Messpunkte</Typography>
            </HStack>
          </AccordionSummary>

          <AccordionDetails>
            {operatorData?.isAdmin && (
              <HStack sx={{ width: 1 }}>
                <FormGroup>
                  <FormControlLabel
                    label={'Nur zugeordnete Sensoren anzeigen'}
                    control={
                      <Checkbox
                        checked={showFolderSensors}
                        onClick={() => setShowFolderSensors(!showFolderSensors)}
                      />
                    }
                  />
                </FormGroup>
              </HStack>
            )}

            <HStack justifyContent={'space-between'} sx={{ display: 'flex' }}>
              <FormGroup>
                <FormControlLabel
                  label={'Nur ausgewählte Sensoren anzeigen'}
                  control={
                    <Checkbox
                      checked={showSelectedFolderSensors}
                      onClick={() =>
                        setShowSelectedFolderSensors(!showSelectedFolderSensors)
                      }
                    />
                  }
                />
              </FormGroup>
            </HStack>

            <HStack justifyContent={'space-between'} sx={{ display: 'flex' }}>
              {/* Strom */}
              <FormGroup>
                <FormControlLabel
                  label={'Strom'}
                  control={
                    <Checkbox
                      checked={energyChecked}
                      disabled={gasChecked || waterChecked}
                      onChange={event => {
                        handleToggleAllSensors(event, 'energy')
                        setEnergyChecked(prev => !prev)
                      }}
                    />
                  }
                />

                <PanelDisplaySensors
                  data={ENERGYDATA.currentData()}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                  presentationLevel={presentationLevel}
                />
              </FormGroup>

              {/* Water */}
              <FormGroup>
                <FormControlLabel
                  label={'Wasser'}
                  control={
                    <Checkbox
                      checked={waterChecked}
                      disabled={gasChecked || energyChecked}
                      onChange={event => {
                        handleToggleAllSensors(event, 'water')
                        setWaterChecked(prev => !prev)
                      }}
                    />
                  }
                />

                <PanelDisplaySensors
                  data={WATERDATA.currentData()}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                  presentationLevel={presentationLevel}
                />
              </FormGroup>

              {/* Gas */}
              <FormGroup>
                <FormControlLabel
                  label={'Wärme'}
                  control={
                    <Checkbox
                      checked={gasChecked}
                      disabled={energyChecked || waterChecked}
                      onChange={event => {
                        handleToggleAllSensors(event, 'gas')
                        setGasChecked(prev => !prev)
                      }}
                    />
                  }
                />

                <PanelDisplaySensors
                  data={GASDATA.currentData()}
                  selectedFolders={selectedFolders}
                  setSelectedFolders={setSelectedFolders}
                  selectedSensors={selectedSensors}
                  setSelectedSensors={setSelectedSensors}
                  presentationLevel={presentationLevel}
                />
              </FormGroup>
            </HStack>

            <Input
              onChange={event => setSensorsSearch(event.target.value)}
              placeholder={'Suchen...'}
              sx={{ m: 0, minHeight: 23, pl: 1 }}
              value={sensorsSearch}
              variant={'standard'}
            />

            <Pagination
              count={count}
              size={'large'}
              page={page}
              variant={'outlined'}
              shape={'rounded'}
              onChange={handlePageChange}
            />
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions>
        <Button onClick={onDeletePanel}>
          <DeleteTwoTone />
          Löschen
        </Button>

        <Button onClick={onGotoSummary}>Zusammenfassung</Button>
      </DialogActions>
    </Dialog>
  )
}

DlgPanelEdit.propTypes = {
  selectedFolders: PropTypes.array.isRequired,
  selectedSensors: PropTypes.array.isRequired,
  graphicType: PropTypes.number,
  indicator: PropTypes.number,
  onChangeGraphicType: PropTypes.func.isRequired,
  onChangeIndicator: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangePanelUnit: PropTypes.func.isRequired,
  onChangePresentationLevel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeletePanel: PropTypes.func.isRequired,
  onGotoSummary: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  panelName: PropTypes.string,
  presentationLevel: PropTypes.number,
  setSelectedFolders: PropTypes.func.isRequired,
  setSelectedSensors: PropTypes.func.isRequired,
  title: PropTypes.string,
}
