import { Collapse, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SORT } from '../../api'
import { FeelingRoom, PackageUpgrade } from '../../components'
import {
  useBridgeId,
  useCoachData,
  useFeelingSensors,
  useSensorPool,
} from '../../hooks'

export const Feeling = ({ folderId }) => {
  const { isLoading } = useSensorPool()
  const feelingSensors = useFeelingSensors(folderId)
  const bridgeSensor = useBridgeId(folderId)
  const coachData = useCoachData(bridgeSensor)

  const fSensorTypes = [
    ...new Set(feelingSensors.map(sensor => sensor.name)),
  ].sort(SORT)

  const upgradeAvailable =
    feelingSensors.length === 0 && bridgeSensor && coachData

  return (
    <Collapse in={true}>
      {!isLoading && upgradeAvailable && <PackageUpgrade coach={coachData} />}

      <Grid container spacing={5}>
        {fSensorTypes.map((title, key) => (
          <Grid item xs={12} md={6} lg={4} key={key}>
            <FeelingRoom folderId={Number(folderId)} title={title} />
          </Grid>
        ))}
      </Grid>
    </Collapse>
  )
}

Feeling.propTypes = {
  folderId: PropTypes.number.isRequired,
}
