import {
  ConstructionTwoTone,
  EmojiPeopleTwoTone,
  InfoTwoTone,
  MarkunreadMailboxTwoTone,
  NotificationsTwoTone,
  SaveTwoTone,
  UpdateTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions, systemActions } from '../../actions'
import {
  createStorageConfig,
  deleteStorageConfig,
  getStorage,
  userLocales,
} from '../../api'
import { useAppConfig, useAppVersion, useOperator } from '../../hooks'
import { HStack, KTextField } from '..'

export const DlgSettingsGlobal = ({ title = 'Einstellungen' }) => {
  const theme = useTheme()
  const { white: color } = theme.palette

  const dispatch = useDispatch()
  const { appConfig, setAppConfig } = useAppConfig()
  const { data: appVersion } = useAppVersion()
  const { data: operatorData } = useOperator()

  const { isInMaintenance, maintenanceText } = useSelector(
    state => state.system
  )

  const buildInfo = getStorage('buildInfo') || { environment: '', build: '' }

  const ALERTS_FEELING = 1
  const ALERTS_SECURITY = 2
  const ALERTS_PERFORMANCE = 4

  const IS_DASHBOARD_EXPIRED =
    Date.parse(buildInfo.build) < Date.parse(appVersion.actual_version)

  const [message, setMessage] = useState('')
  const [messageActive, setMessageActive] = useState(0)

  const versionOptions = {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }

  useEffect(() => {
    dispatch(systemActions.getIsInMaintenance())
    dispatch(systemActions.getMaintenanceText())
  }, [dispatch])

  useEffect(() => {
    const is_in_maintenance = isInMaintenance?.status?.data?.is_in_maintenance

    if (!is_in_maintenance) return
    setMessageActive(is_in_maintenance)
  }, [isInMaintenance])

  useEffect(() => {
    const maintenance_message = maintenanceText?.text?.data?.maintenance_message

    if (!maintenance_message) return
    setMessage(maintenance_message)
  }, [maintenanceText])

  useEffect(
    () => setAppConfig(prev => ({ ...prev, isMaintenance: messageActive })),
    [setAppConfig, messageActive]
  )

  useEffect(
    () => setAppConfig(prev => ({ ...prev, maintenanceMessage: message })),
    [setAppConfig, message]
  )

  const toggleDashboardAlerts = () =>
    setAppConfig(prev => ({ ...prev, alertsEnabled: !prev.alertsEnabled }))

  const toggleFeeling = () => {
    const old = Number(appConfig.mask)
    const mask = old % 2 === 0 ? old + ALERTS_FEELING : old - ALERTS_FEELING

    setAppConfig(prev => ({ ...prev, mask }))
  }

  const toggleSecurity = () => {
    const old = Number(appConfig.mask)
    const mask =
      old === 2 || old === 3 || old === 6 || old === 7
        ? old - ALERTS_SECURITY
        : old + ALERTS_SECURITY

    setAppConfig(prev => ({ ...prev, mask }))
  }

  const togglePerformance = () => {
    const old = Number(appConfig.mask)
    const mask = old >= 4 ? old - ALERTS_PERFORMANCE : old + ALERTS_PERFORMANCE

    setAppConfig(prev => ({ ...prev, mask }))
  }

  const onCloseSettings = () =>
    setAppConfig(prev => ({ ...prev, showSettings: !prev.showSettings }))

  const onSetTab = (event, setupTab) =>
    setAppConfig(prev => ({ ...prev, setupTab }))

  const onChangeSalutation = event => {
    const salutationMode = event.target.value

    setAppConfig(prev => ({ ...prev, salutationMode }))
  }

  const onMarkUnread = () => {
    const alertsAcknowledged = []

    setAppConfig(prev => ({ ...prev, alertsAcknowledged }))
  }

  const onResetAppStorage = () => {
    deleteStorageConfig()
    createStorageConfig()

    caches
      .keys()
      .then(keyList => Promise.all(keyList.map(key => caches.delete(key))))

    dispatch(alertActions.success('Lokale Einstellungen zurückgesetzt'))

    window.location.reload(true)
  }

  const onSetMaintenanceMessage = () => {
    const maintenanceMessage = message

    setAppConfig(prev => ({ ...prev, maintenanceMessage }))
    dispatch(systemActions.setMaintenanceText(maintenanceMessage))
  }

  const onToggleMaintenance = () => {
    const mode = messageActive === 0 ? 1 : 0

    setMessageActive(mode)
    setAppConfig(prev => ({ ...prev, isMaintenance: mode }))
    dispatch(systemActions.setIsInMaintenance(mode))
  }

  const onChangeAlertsMax = event => {
    const alertsMax = event.target.value

    setAppConfig(prev => ({ ...prev, alertsMax }))
  }

  const onToggleTimeMode = event => {
    const timeMode = event.target.value

    setAppConfig(prev => ({ ...prev, timeMode }))
  }

  const onSetMessage = event => setMessage(event.target.value)

  return (
    <Dialog open={appConfig?.showSettings ?? false} onClose={onCloseSettings}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Tabs
          centered
          indicatorColor={'primary'}
          onChange={onSetTab}
          textColor={'primary'}
          value={appConfig.setupTab}
        >
          <Tab icon={<EmojiPeopleTwoTone />} label={'Anrede'} value={0} />

          <Tab
            icon={<NotificationsTwoTone />}
            label={'Dashboard Alerts'}
            value={1}
          />

          <Tab
            icon={
              <Badge
                color={'error'}
                badgeContent={IS_DASHBOARD_EXPIRED ? 1 : 0}
                invisible={!IS_DASHBOARD_EXPIRED}
              >
                <InfoTwoTone />
              </Badge>
            }
            label={'Version'}
            value={2}
          />

          {operatorData?.isAdmin && (
            <Tab icon={<ConstructionTwoTone />} label={'Wartung'} value={3} />
          )}
        </Tabs>

        <Divider />

        {/* Anrede */}
        <Collapse in={appConfig.setupTab === 0} sx={{ mt: 3 }}>
          <RadioGroup
            onChange={onChangeSalutation}
            value={appConfig.salutationMode}
          >
            <FormControlLabel
              control={<Radio />}
              label={'keine Anrede'}
              value={'noSalutation'}
            />
            <FormControlLabel
              control={<Radio />}
              label={'Vor- und Nachname'}
              value={'default'}
            />
            <FormControlLabel
              control={<Radio />}
              label={'Vorname'}
              value={'short'}
            />
          </RadioGroup>
        </Collapse>

        {/* dashboard alerts */}
        <Collapse in={appConfig.setupTab === 1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={appConfig.alertsEnabled}
                  onChange={toggleDashboardAlerts}
                />
              }
              label={'Dashboard Alerts'}
              labelPlacement={'start'}
              sx={{ mb: 1 }}
            />
          </FormGroup>

          <Collapse in={appConfig.alertsEnabled}>
            <FormControl component={'fieldset'} fullWidth sx={{ mt: 2, mb: 2 }}>
              <FormLabel component={'legend'}>Modul</FormLabel>

              <FormGroup>
                <FormControlLabel
                  checked={Number(appConfig.mask) % 2 !== 0}
                  control={<Checkbox />}
                  onChange={toggleFeeling}
                  label={'Wohlbefinden'}
                  value={ALERTS_FEELING}
                />

                <FormControlLabel
                  checked={
                    Number(appConfig.mask) === 2 ||
                    Number(appConfig.mask) === 3 ||
                    Number(appConfig.mask) === 6 ||
                    Number(appConfig.mask) === 7
                  }
                  control={<Checkbox />}
                  onChange={toggleSecurity}
                  label={'Sicherheit'}
                  value={ALERTS_SECURITY}
                />

                <FormControlLabel
                  checked={Number(appConfig.mask) >= 4}
                  control={<Checkbox />}
                  onChange={togglePerformance}
                  label={'Leistung'}
                  value={ALERTS_PERFORMANCE}
                />
              </FormGroup>
            </FormControl>

            <Alert severity={'info'} sx={{ mb: 2 }}>
              <AlertTitle>Alerts konfigurieren</AlertTitle>
              Abhängig von Ihrem Anwendungsprofil können Sie Alerts Ihrer
              entsprechenden Module anzeigen oder ausblenden.
            </Alert>

            <Divider />

            <FormControl fullWidth>
              <KTextField
                label={'maximal anzeigen'}
                onChange={onChangeAlertsMax}
                sx={{ mb: 2, mt: 2, ml: 0, mr: 0 }}
                type={'number'}
                unit={'Alerts'}
                value={appConfig.alertsMax}
              />
            </FormControl>

            <Divider />

            <FormControl sx={{ mt: 2, mb: 2 }}>
              <FormLabel>Zeitangaben</FormLabel>
              <RadioGroup
                onChange={onToggleTimeMode}
                value={appConfig.timeMode}
              >
                <FormControlLabel
                  control={<Radio />}
                  label={'absolut'}
                  value={'absolute'}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={'relativ'}
                  value={'relative'}
                />
              </RadioGroup>
            </FormControl>

            <Divider />

            <Alert severity={'info'} sx={{ mt: 2 }}>
              <AlertTitle>Alerts als ungelesen markieren</AlertTitle>
              Bestätigte Alerts werden als ungelesen markiert und wieder
              angezeigt. (max. 100 Alerts)
            </Alert>
          </Collapse>
        </Collapse>

        {/* version */}
        <Collapse in={appConfig.setupTab === 2}>
          <Alert severity={IS_DASHBOARD_EXPIRED ? 'warning' : 'success'}>
            <AlertTitle>
              {IS_DASHBOARD_EXPIRED
                ? 'Aktualisierung verfügbar'
                : 'Sie verwenden die aktuelle Version'}
            </AlertTitle>
            {IS_DASHBOARD_EXPIRED &&
              'Um die neue Version zu erhalten, klicken Sie auf Aktualisieren und starten Sie danach Ihren Browser neu.'}
            <br />

            <small>
              Ihre Version:{' '}
              {new Date(Date.parse(buildInfo.build)).toLocaleDateString(
                userLocales,
                versionOptions
              )}
            </small>
            <br />
            <small>
              verfügbare Version:{' '}
              {new Date(
                Date.parse(appVersion.actual_version)
              ).toLocaleDateString(userLocales, versionOptions)}
            </small>
          </Alert>
        </Collapse>

        {/* wartung */}
        <Collapse in={appConfig.setupTab === 3}>
          <KTextField
            label={'Meldung'}
            onChange={onSetMessage}
            maxRows={30}
            multiline
            sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
            value={message}
          />

          <Alert severity={'info'} sx={{ m: 1 }}>
            <AlertTitle>Wartungsmeldung</AlertTitle>
            Wenn aktiviert, wird eine Wartungsmeldung eingeblendet. Der Text ist
            frei wählbar.
          </Alert>
        </Collapse>
      </DialogContent>

      <DialogActions>
        {appConfig.setupTab === 1 && appConfig.alertsEnabled && (
          <Tooltip title={'Als ungelesen markieren'}>
            <IconButton onClick={onMarkUnread} sx={{ color }}>
              <MarkunreadMailboxTwoTone />
            </IconButton>
          </Tooltip>
        )}
        {appConfig.setupTab === 2 && (
          <Button color={'success'} onClick={onResetAppStorage}>
            <UpdateTwoTone sx={{ mr: 1 }} /> Aktualisieren
          </Button>
        )}
        {appConfig.setupTab === 3 && (
          <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ ml: 3, width: '100%' }}
          >
            <Typography>
              Wartungsmeldung ist:{' '}
              {['deaktiviert', 'aktiviert'][Number(appConfig.isMaintenance)]}
            </Typography>

            <HStack>
              <Tooltip title={'Meldung speichern'}>
                <IconButton onClick={onSetMaintenanceMessage} sx={{ color }}>
                  <SaveTwoTone />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={`Wartungsmeldung ${
                  ['aktivieren', 'deaktivieren'][
                    Number(appConfig.isMaintenance)
                  ]
                }`}
              >
                <Switch
                  checked={Boolean(appConfig.isMaintenance)}
                  color={'secondary'}
                  onChange={onToggleMaintenance}
                />
              </Tooltip>
            </HStack>
          </HStack>
        )}
      </DialogActions>
    </Dialog>
  )
}

DlgSettingsGlobal.propTypes = {
  title: PropTypes.string,
}
