import {
  AccountBalanceTwoTone,
  AnnouncementTwoTone,
  AssignmentTwoTone,
  HomeTwoTone,
  MemoryTwoTone,
  NotificationsTwoTone,
  PeopleTwoTone,
  SettingsRemoteTwoTone,
  SpeedTwoTone,
  StorageTwoTone,
} from '@mui/icons-material'
import React from 'react'
import {
  AllVirtual,
  Calculon,
  Overview,
  Dashboards,
  Diagnostics,
  EditPrice,
  FolderAll,
  FolderEdit,
  FolderSingle,
  HomeFolder,
  InstallationDetail,
  LoginPage,
  ImportSensorData,
  MissingData,
  MissingDataQueryGenerator,
  NewsAll,
  NewsEdit,
  NewsSingle,
  NotificationAll,
  NotificationEdit,
  Payloads,
  PerformanceDetail,
  RecalculateSensorData,
  ReportGenerator,
  SensorAll,
  SensorEdit,
  SensorVirtualEdit,
  FeelingHistory,
  SecurityHistory,
  UserAll,
  UserEdit,
  UserProfile,
  UserRegister,
} from '../pages'

const routes = [
  /* Overview */
  {
    component: Overview,
    hidden: true,
    icon: <HomeTwoTone />,
    name: 'Gebäudeübersicht',
    path: '//',
  },

  /* Dashboard */
  {
    component: Dashboards,
    icon: <SpeedTwoTone />,
    name: 'Dashboard',
    path: '/dashboard',
  },

  /* special pages */
  { component: UserProfile, hidden: true, path: '/user-page/:tabId' },
  { component: UserProfile, hidden: true, path: '/user-page' },
  {
    component: UserRegister,
    hidden: true,
    path: '/pages/register-page',
    short: 'Register',
  },
  {
    component: LoginPage,
    hidden: true,
    path: '/pages/login-page',
    short: 'Login',
  },

  /* Reports */
  { component: ReportGenerator, hidden: true, path: '/reports/:folder_id' },
  {
    component: ReportGenerator,
    icon: <AssignmentTwoTone />,
    name: 'Berichte',
    path: '/reports',
  },

  /* Notifications */
  {
    component: NotificationEdit,
    hidden: true,
    isAdmin: true,
    path: '/notifications/:id/edit',
  },
  {
    component: NotificationEdit,
    hidden: true,
    isAdmin: true,
    path: '/notifications/new',
  },
  {
    component: NotificationAll,
    icon: <NotificationsTwoTone />,
    isAdmin: true,
    name: 'Benachrichtigungen',
    path: '/notifications',
  },

  /* Folder */
  {
    component: PerformanceDetail,
    hidden: true,
    path: '/folder/:id/detail/:type',
  },
  { component: HomeFolder, hidden: true, path: '/folder/:id/home/:category' },
  {
    component: FeelingHistory,
    hidden: true,
    path: '/folder/:id/feeling/:medium/:sensorid',
  },
  {
    component: SecurityHistory,
    hidden: true,
    path: '/folder/:id/security/:medium/:sensorid',
  },
  {
    component: InstallationDetail,
    hidden: true,
    path: '/folder/:id/installation/:installationid',
  },
  {
    component: FolderEdit,
    hidden: true,
    isAdmin: true,
    path: '/folder/:id/edit/:section',
  },
  {
    component: FolderEdit,
    hidden: true,
    isAdmin: true,
    path: '/folder/:id/edit',
  },
  {
    component: FolderSingle,
    hidden: true,
    isAdmin: true,
    path: '/folder/:id/:section',
  },
  { component: FolderSingle, hidden: true, isAdmin: true, path: '/folder/:id' },
  { component: FolderEdit, hidden: true, isAdmin: true, path: '/folder/new' },
  {
    component: FolderAll,
    icon: <AccountBalanceTwoTone />,
    isAdmin: true,
    name: 'Gebäude',
    path: '/folder',
  },

  /* Sensoren */
  {
    component: Calculon,
    hidden: true,
    isAdmin: true,
    path: '/sensors/calculon',
  },
  { component: EditPrice, hidden: true, isAdmin: true, path: '/sensors/price' },
  {
    component: MissingDataQueryGenerator,
    hidden: true,
    isAdmin: true,
    path: '/sensors/missing-data-query-generator',
  },
  {
    component: Payloads,
    hidden: true,
    isAdmin: true,
    path: '/sensors/payloads/:device_id',
  },
  {
    component: Payloads,
    hidden: true,
    isAdmin: true,
    path: '/sensors/payloads',
  },
  {
    component: MissingData,
    hidden: true,
    isAdmin: true,
    path: '/sensors/:id/missing',
  },
  {
    component: ImportSensorData,
    hidden: true,
    isAdmin: true,
    path: '/sensors/:id/import',
  },
  { component: SensorEdit, hidden: true, isAdmin: true, path: '/sensors/:id' },
  {
    component: RecalculateSensorData,
    hidden: true,
    icon: <StorageTwoTone />,
    isAdmin: true,
    path: '/data',
  },
  {
    component: SensorAll,
    icon: <MemoryTwoTone />,
    isAdmin: true,
    name: 'Sensoren',
    path: '/sensors',
  },
  {
    component: SensorVirtualEdit,
    hidden: true,
    isAdmin: true,
    path: '/virtualsensors/:id/edit',
  },
  {
    component: SensorVirtualEdit,
    hidden: true,
    isAdmin: true,
    path: '/virtualsensors/new',
  },
  {
    component: AllVirtual,
    hidden: true,
    icon: <SettingsRemoteTwoTone />,
    isAdmin: true,
    path: '/virtualsensors',
  },

  /* news */
  { component: NewsSingle, hidden: true, isAdmin: true, path: '/news/:id/:nc' },
  { component: NewsEdit, hidden: true, isAdmin: true, path: '/news/:id/edit' },
  { component: NewsEdit, hidden: true, isAdmin: true, path: '/news/new' },
  {
    component: NewsAll,
    icon: <AnnouncementTwoTone />,
    isAdmin: true,
    name: 'News',
    path: '/news',
  },

  /* users  */
  { component: UserEdit, hidden: true, isAdmin: true, path: '/users/:id/edit' },
  {
    exact: true,
    hidden: true,
    isAdmin: true,
    path: '/users/:id',
    pathTo: '/users/:id/edit',
    redirect: true,
  },
  { component: UserEdit, hidden: true, isAdmin: true, path: '/users/new' },
  {
    component: UserAll,
    icon: <PeopleTwoTone />,
    isAdmin: true,
    name: 'Benutzer',
    path: '/users',
  },

  /* env infos */
  {
    component: Diagnostics,
    hidden: true,
    icon: <AssignmentTwoTone />,
    isAdmin: true,
    path: '/about',
  },

  {
    path: '/',
    pathTo: '/',
    redirect: true,
    exact: true,
  },
]

export default routes
