import {
  AccountBalanceTwoTone,
  ArrowBackIosTwoTone,
  ArrowForwardIosTwoTone,
  AssignmentTwoTone,
  InfoTwoTone,
  MoreVertTwoTone,
  StarBorderTwoTone,
  StarTwoTone,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { TimeSeries } from 'pondjs'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  ChartContainer,
  ChartRow,
  Charts,
  Legend,
  LineChart,
  Resizable,
  styler,
  YAxis,
} from 'react-timeseries-charts'
import {
  addWeekdays,
  categoryByType,
  colorSchemeConsumption,
  colorSchemeFeeling,
  daysAgo,
  daysOnward,
  DDMMYY,
  DDMMYYHHMM,
  DDMMYYYY,
  germanDecimals,
  getDaysArray,
  getHoursArray,
  getMonthsArray,
  getSession,
  getStatusColor,
  installationStatus,
  MMMYY,
  noDecimals,
  normalizedSensorType,
  sensorType,
  sensorUnit,
  setSession,
  SVGStatus,
  userLocales,
  WDDDMMYY,
  WDDDMMYYYYHHMMSS,
} from '../../api'
import {
  AM,
  AMItem,
  DlgInstallationExport,
  HStack,
  KPage,
  Preloader,
} from '../../components'
import {
  useConsumptionByFolderId,
  useFolder,
  useFolderContract,
  useFolderName,
  useFolderSensors,
  useFolderShortName,
  useFolderStatus,
  useOperator,
  useSensorPoolIds,
  useTimeSeries,
  useTimeSeriesDataListReload,
} from '../../hooks'

const isMainSensor = sensor => !sensor.parent_id

// if given date is in future, return current date
const limitTodayMax = date =>
  date > new Date(Date.now()).toISOString().split('T')[0]
    ? new Date(Date.now()).toISOString().split('T')[0]
    : date

const axisStyle = {
  label: {
    strokeOpacity: 0.8,
    fontWeight: 400,
    'font-size': '16px',
  },
  values: {
    stroke: 'none',
    fill: '#000',
    'font-size': '14px',
  },
  ticks: {
    fill: 'none',
    stroke: '#000',
    opacity: 0.2,
  },
  axis: {
    fill: 'none',
    stroke: '#AAA',
    opacity: 1,
    fontWeight: 100,
    'font-size': '14px',
  },
}

const granularityCaption = granularity => {
  switch (granularity) {
    case 'd':
      return 'Stündlich'
    case 'm':
      return 'Täglich'
    case 'y':
      return 'Monatlich'
    default:
      console.error('unhandled granularityCaption', granularityCaption)
  }
}

const getTableRows = row => {
  const data = []

  data.push(
    <TableCell component={'th'} scope={'row'}>
      {row[0]}
    </TableCell>
  )

  for (let i = 1; i < row.length; i++)
    data.push(<TableCell align={'right'}>{row[i]}</TableCell>)

  return <>{data}</>
}

export const InstallationDetail = ({
  PAGE_CONFIG = 'settings_correlation',
}) => {
  const history = useHistory()

  const theme = useTheme()
  const { white: bgcolor } = theme.palette
  const { main: color } = theme.palette.secondary

  const { id, installationid } = useParams()
  const folderId = Number(id)
  const installationId = Number(installationid)

  const folderName = useFolderName(folderId)
  const folderShortName = useFolderShortName(folderId)
  const folderSensors = useFolderSensors(folderId)
  const folderStatus = useFolderStatus(folderId)
  const contractData = useFolderContract(folderId)

  const [installation, setInstallation] = useState(installationId)

  const [config, setConfig] = useState(getSession(PAGE_CONFIG))

  const [consumptionSensors, setConsumptionSensors] = useState([])
  const [feelingSensors, setFeelingSensors] = useState([])
  const { data: operatorData } = useOperator()

  const [energyType, setEnergyType] = useState('energy')
  const [feelingType, setFeelingType] = useState('temperature')
  const [installations, setInstallations] = useState([])
  const [activeInstallationStatus, setActiveInstallationStatus] =
    useState('green')
  const [showSensorInfo, setShowSensorInfo] = useState(false)

  const operatorSensorIds = useSensorPoolIds()

  const [activeSensors, setActiveSensors] = useState([])
  const [activeFeelingSensors, setActiveFeelingSensors] = useState([])

  const [feelingSensorId, setFeelingSensorId] = useState(null)

  const arrRemove = (arr, value) => arr.filter(f => f !== value)

  const period = 'day'

  const toDate =
    new Date(config?.toDate).setHours(0, 0, 0, 0) ===
    new Date().setHours(0, 0, 0, 0)
      ? new Date()
      : `${config?.toDate} 23:59:59`

  const dataTimeRange = {
    ui: {
      period: period,
      min_period: -10000,
      max_period: 10000,
    },
    widget: [
      {
        type: 'bid',
        data: [
          [Date.parse(`${config?.fromDate} 00:00:00`), -10000],
          [Date.parse(toDate), 10000],
        ],
        display: 'absolute',
      },
    ],
  }

  const pointsTimeRange = dataTimeRange.widget[0].data
  const requestsSeries = new TimeSeries({
    name: 'Temperature',
    columns: ['time', 'temp'],
    points: pointsTimeRange,
  })

  const [dataEnergy, setDataEnergy] = useState([])
  const [dataFeeling, setDataFeeling] = useState([])
  const [lineCharts, setLineCharts] = useState([])
  const [showSecondAxis, setShowSecondAxis] = useState(false)
  const [style, setStyle] = useState([])
  const [activeTableSensor, setActiveTableSensor] = useState({})
  const [legend, setLegend] = useState([])
  const [maxConsumption, setMaxConsumption] = useState(0)
  const [minFeeling, setMinFeeling] = useState()
  const [maxFeeling, setMaxFeeling] = useState(0)
  const [allSeries, setAllSeries] = useState([])
  const [tracker, setTracker] = useState()
  const [trackerX, setTrackerX] = useState()

  const mouseX = useRef(0)
  const mouseY = useRef(0)

  const [timeSeriesDataList, setTimeSeriesDataList] = useState({ items: [] })
  const [timeSeriesDataListLoading, setTimeSeriesDataListLoading] =
    useState(false)

  const [timeRange, setTimeRange] = useState(requestsSeries.range())
  const [reloadTrigger, setReloadTrigger] = useState(false)

  const [periodWarning, setPeriodWarning] = useState(null)
  const [periodInDays, setPeriodInDays] = useState()

  // export
  const [exportModal, setExportModal] = useState(false)
  const [tableDataExportExcel, setTableDataExportExcel] = useState({
    Korrelation: [],
  })

  const [actionMenu, setActionMenu] = useState(null)

  const [range, setRange] = useState({
    fromDate: config.fromDate,
    toDate: config.toDate,
    granularity: config.granularity,
  })

  const {
    data: folderData,
    isError: folderError,
    isLoading: folderLoading,
  } = useFolder(folderId)

  const { data: consumptionData, isLoading: consumptionLoading } =
    useConsumptionByFolderId(
      folderId,
      config.granularity,
      config.fromDate,
      config.toDate
    )

  const { data: timeSeriesData, isLoading: timeSeriesLoading } = useTimeSeries(
    feelingSensorId,
    config.fromDate + ' 00:00:00',
    config.toDate + ' 23:59:59',
    config.granularity
  )

  const {
    timeSeries: reloadedTimeSeriesData,
    isLoading: reloadedTimeSeriesLoading,
  } = useTimeSeriesDataListReload(
    reloadTrigger,
    timeSeriesDataList,
    config.fromDate + ' 00:00:00',
    config.toDate + ' 23:59:59'
  )

  useEffect(() => {
    const onMouseMove = event => {
      mouseX.current = event.pageX
      mouseY.current = event.pageY
    }

    document.addEventListener('mousemove', onMouseMove)

    return () => document.removeEventListener('mousemove', onMouseMove)
  }, [])

  useEffect(() => setSession(PAGE_CONFIG, config), [PAGE_CONFIG, config])

  useEffect(() => {
    if (!folderData) return
    const { installations, sensors } = folderData

    if (sensors?.[0]) {
      installations.forEach(installation => {
        const installationSensors = sensors
          .filter(f => operatorSensorIds.includes(Number(f.entity_id)))
          .filter(f => f.installation_id === installation.entity_id)

        installation['sensors'] = installationSensors
      })

      if (installations?.[0]) setInstallations(installations)
    }

    if (sensors)
      setFeelingSensors(
        sensors.filter(
          f =>
            f.installation_id === Number(installation) &&
            (categoryByType(f.sensor_type) === 'feeling' ||
              categoryByType(f.sensor_type) === 'security')
        )
      )

    const feelingSensor = sensors.find(f => f.sensor_type === feelingType)

    const status = installationStatus(
      sensors.filter(f => f.installation_id === Number(installation))
    )

    if (feelingSensor) {
      const { name, sensor_type } = feelingSensor

      setFeelingType(sensor_type)
      setActiveTableSensor(name)
      setActiveInstallationStatus(status)
    }
  }, [feelingType, folderData, folderId, installation, operatorSensorIds])

  useEffect(() => {
    if (feelingSensors.length === 0) return

    const FILTER_FEELING = f =>
      normalizedSensorType(f.sensor_type) === feelingType

    const record = feelingSensors.find(FILTER_FEELING)
    if (!record) return

    const { entity_id, name } = record

    setFeelingSensorId(entity_id)
    setActiveFeelingSensors([entity_id])
    setActiveTableSensor(name)
  }, [feelingSensors, feelingType])

  useEffect(() => {
    if (!reloadTrigger) return
    if (reloadedTimeSeriesLoading) return
    if (!reloadedTimeSeriesData) return

    setReloadTrigger(false)
    setTimeSeriesDataList(reloadedTimeSeriesData)
  }, [reloadedTimeSeriesData, reloadedTimeSeriesLoading, reloadTrigger])

  useEffect(() => {
    if (timeSeriesDataListLoading) return

    const style = []
    const legend = []
    const legendStyle = {}
    const labelStyle = {
      normal: {
        fontSize: 'normal',
        color: '#000',
        cursor: 'default',
        marginRight: 5,
      },
    }

    const valueStyle = {
      normal: { fontSize: 'normal', color: '#000', cursor: 'default' },
    }

    consumptionSensors.map((sensor, key) => {
      const { entity_id, name } = sensor
      const colorIndex = key % colorSchemeConsumption.length
      const color = colorSchemeConsumption[colorIndex]
      const isActive = activeSensors.includes(entity_id)

      if (isActive) {
        style.push({ key: entity_id, color })
        style.push({ key: name, color })
        legend.push({ key: name, label: name, disabled: !isActive })
        legendStyle[name] = {
          symbol: { normal: { stroke: color, fill: color, strokeWidth: 10 } },
          label: labelStyle,
          value: valueStyle,
        }
      }

      return false
    })

    feelingSensors.map((sensor, key) => {
      const { entity_id, name } = sensor
      const colorIndex = key % colorSchemeFeeling.length
      const color = colorSchemeFeeling[colorIndex]
      const isActive = activeFeelingSensors.includes(entity_id)

      if (isActive) {
        style.push({ key: entity_id, color })
        style.push({ key: name, color })
        legend.push({ key: name, label: name, disabled: false })
        legendStyle[name] = {
          symbol: {
            normal: { stroke: color, fill: color, strokeWidth: 10 },
          },
          label: labelStyle,
          value: valueStyle,
        }
      }

      return true
    })

    setStyle(styler(style))
    setLegend(legend)
  }, [
    activeFeelingSensors,
    activeSensors,
    consumptionSensors,
    feelingSensors,
    timeSeriesDataListLoading,
  ])

  useEffect(() => {
    if (timeSeriesData && !timeSeriesLoading && !timeSeriesDataListLoading)
      if (!(feelingSensorId in timeSeriesDataList.items)) {
        const newItems = timeSeriesDataList.items

        newItems[feelingSensorId] = timeSeriesData
        setTimeSeriesDataList({ items: newItems })
      }
  }, [
    feelingSensorId,
    timeSeriesData,
    timeSeriesDataList,
    timeSeriesDataListLoading,
    timeSeriesLoading,
  ])

  useEffect(() => {
    const toDate =
      new Date(config.toDate).setHours(0, 0, 0, 0) ===
      new Date().setHours(0, 0, 0, 0)
        ? new Date()
        : `${config.toDate} 23:59:59`

    const dataTimeRange = {
      ui: { period, min_period: -10000, max_period: 10000 },
      widget: [
        {
          type: 'bid',
          data: [
            [Date.parse(`${config.fromDate} 00:00:00`), -10000],
            [Date.parse(toDate), 10000],
          ],
          display: 'absolute',
        },
      ],
    }

    const pointsTimeRange = dataTimeRange.widget[0].data
    const requestsSeries = new TimeSeries({
      name: 'Temperature',
      columns: ['time', 'temp'],
      points: pointsTimeRange,
    })

    setTimeRange(requestsSeries.range())
  }, [config.fromDate, config.toDate, period])

  useEffect(() => {
    if (!timeSeriesDataListLoading) return
    if (!timeSeriesLoading) setTimeSeriesDataListLoading(false)
  }, [timeSeriesDataListLoading, timeSeriesLoading])

  useEffect(() => {
    const charts = []
    const seriesList = []
    let _showSecondAxis = false

    if (timeSeriesDataListLoading) return

    Object.keys(dataEnergy).forEach(key => {
      const seriesConsumption = new TimeSeries({
        name: key,
        columns: ['time', key],
        points: dataEnergy[key].widget[0].data,
      })

      seriesList.push(seriesConsumption)
      charts.push(
        <LineChart
          key={'consumption'}
          axis={'consumption'}
          series={seriesConsumption}
          columns={[key]}
          style={style}
          interpolation={'curveBasis'}
        />
      )

      _showSecondAxis = true
    })

    Object.keys(dataFeeling).forEach(key => {
      const seriesFeeling = new TimeSeries({
        name: key,
        columns: ['time', key],
        points: dataFeeling[key].widget[0].data,
      })

      seriesList.push(seriesFeeling)
      charts.push(
        <LineChart
          key={'feeling'}
          axis={'feeling'}
          series={seriesFeeling}
          columns={[key]}
          style={style}
          interpolation={'curveBasis'}
        />
      )
    })

    setLineCharts(charts)
    setAllSeries(seriesList)
    setShowSecondAxis(_showSecondAxis)
  }, [
    dataEnergy,
    dataFeeling,
    style,
    timeSeriesData,
    timeSeriesDataList,
    timeSeriesDataListLoading,
  ])

  useEffect(() => {
    const { fromDate, granularity, toDate } = config

    const _1H = 60 * 60 * 1000
    const _24H = 24 * _1H

    const _periodInDays =
      (new Date(toDate).getTime() - new Date(fromDate).getTime()) / _24H

    setPeriodInDays(_periodInDays)

    switch (granularity) {
      case 'd':
        // timespan max a week
        setPeriodWarning(
          _periodInDays > 6
            ? 'Diese Granularität ist bei einer Auswahl von bis zu 7 Tagen verfügbar.'
            : null
        )
        break
      case 'm':
        // timespan max. half a year
        setPeriodWarning(
          _periodInDays > 182.625
            ? 'Diese Granularität ist bei einer Auswahl von bis zu 6 Monaten verfügbar.'
            : null
        )
        break
      case 'y':
        // no timespan limit
        setPeriodWarning(null)
        break
      default:
        break
    }
  }, [config, config.fromDate, config.toDate, config.granularity])

  useEffect(() => {
    if (consumptionData.length === 0) return

    const _consumptionSensors = consumptionData

    const hasMainSensor = consumptionData
      .filter(f => operatorSensorIds.includes(f.entity_id))
      .filter(f => f.type_in_folder === energyType)
      .find(f => isMainSensor(f))
    if (!hasMainSensor) return

    const _data = []
    const columnsChart = []
    let maxConsumption = 0

    _consumptionSensors.forEach(sensor => {
      const { consumption, entity_id: sensorId } = sensor

      if (!activeSensors.includes(sensorId)) return

      consumption.values.forEach((item, key) => {
        const { timepoint, value } = item
        const val = Number(value.toFixed(2))

        if (_data[key]) _data[key] = _data[key].concat([val])
        else _data[key] = [timepoint, val]
      })

      if (!columnsChart[sensorId]) {
        columnsChart[sensorId] = {
          ui: { min_period: 0, period, max_period: 0 },
          widget: [{ period, type: 'bid', data: [], display: 'absolute' }],
        }
        let minPeriod = 0
        let maxPeriod = 0

        const { ui, widget } = columnsChart[sensorId]

        consumption.values.forEach(item => {
          const { timepoint, value } = item
          const time = new Date(timepoint).getTime()
          const val = Number(value.toFixed(2))

          if (val > maxPeriod) maxPeriod = val
          else if (val < minPeriod) minPeriod = val

          widget[0].data.push([time, val])
        })

        ui.min_period = minPeriod
        ui.max_period = maxPeriod

        if (maxPeriod > maxConsumption) maxConsumption = maxPeriod

        if (widget[0].data.length === 0)
          widget[0].data.push([new Date().getTime(), 0])
      }
    })

    setMaxConsumption(maxConsumption)
    setConsumptionSensors(_consumptionSensors)
    setDataEnergy(columnsChart)
  }, [
    activeSensors,
    config.granularity,
    consumptionData,
    energyType,
    operatorSensorIds,
    period,
    range.granularity,
  ])

  useEffect(() => {
    if (timeSeriesDataListLoading) return

    const columnsChart = []
    let _maxFeeling = 0
    let _minFeeling = 0
    if (activeFeelingSensors.length > 0) _minFeeling = 1000000
    let averageFeeling = 0

    Object.keys(timeSeriesDataList.items).forEach(key => {
      if (activeFeelingSensors.includes(Number(key)))
        if (!columnsChart[key]) {
          columnsChart[key] = {
            ui: { min_period: 0, period, max_period: 0 },
            widget: [{ period, type: 'bid', data: [], display: 'absolute' }],
          }

          let minPeriod = 10000
          let maxPeriod = -10000

          const { ui, widget } = columnsChart[key]

          const orderedResult = timeSeriesDataList.items[key].sort(
            (a, b) => a.timestamp - b.timestamp
          )

          orderedResult.forEach(item => {
            const { timestamp, value } = item
            const val = Number(value.toFixed(2))

            if (val > maxPeriod) maxPeriod = val
            else if (val < minPeriod) minPeriod = val

            averageFeeling = averageFeeling + val
            widget[0].data.push([timestamp * 1000, val])
          })

          ui.min_period = minPeriod
          ui.max_period = maxPeriod

          if (maxPeriod > _maxFeeling) _maxFeeling = maxPeriod
          if (minPeriod < _minFeeling) _minFeeling = minPeriod
        }
    })

    if (_minFeeling >= 0) _minFeeling = 0

    setMinFeeling(_minFeeling)
    setMaxFeeling(_maxFeeling)
    setDataFeeling(columnsChart)
  }, [
    activeFeelingSensors,
    config.granularity,
    period,
    timeSeriesData,
    timeSeriesDataList.items,
    timeSeriesDataListLoading,
  ])

  useEffect(() => {
    const result = []
    let format
    let daylist = []
    const sumArray = [['Summe']]
    const excelArray = []

    switch (config.granularity) {
      case 'd':
        format = DDMMYYHHMM
        daylist = getHoursArray(
          new Date(config.fromDate),
          new Date(config.toDate)
        )
        break
      case 'm':
        format = DDMMYY
        daylist = getDaysArray(
          new Date(config.fromDate),
          new Date(config.toDate)
        )
        break
      case 'y':
        format = MMMYY
        daylist = getMonthsArray(config.fromDate, config.toDate)
        break
      default:
        break
    }

    daylist = daylist.map(day => [day.toLocaleString(userLocales, format)])
    result['Korrelation'] = []

    consumptionSensors.forEach(sensor => {
      const { consumption, entity_id, name, unit } = sensor

      if (activeSensors.includes(entity_id)) {
        let sum = 0
        excelArray[name] = []
        daylist.forEach(day => {
          const value = consumption.values.find(
            f =>
              new Date(f.timepoint).toLocaleString(userLocales, format) ===
              day[0]
          )

          if (value) {
            day.push(`${value.value} ${unit ? unit : ''}`)
            sum += value.value
            excelArray[name].push([
              day[0],
              `${value.value.toLocaleString(userLocales, germanDecimals)} ${
                unit ? unit : ''
              }`,
            ])
          } else {
            day.push(0)
            excelArray[name].push([day[0], `0 ${unit ? unit : ''}`])
            sum += 0
          }
        })

        const record = `${sum.toLocaleString(userLocales, germanDecimals)} ${
          unit ? unit : ''
        }`

        sumArray[0].push(record)
        excelArray[name].push(['Summe', record])
      }
    })

    Object.keys(timeSeriesDataList.items).map(sensor => {
      if (activeFeelingSensors.includes(Number(sensor))) {
        let sensorName = ''

        if (folderSensors.length !== 0)
          sensorName = folderSensors.find(
            f => f.entity_id === Number(sensor)
          ).name

        excelArray[sensorName] = []
        daylist.forEach(day => {
          let dayDate

          if (config.granularity === 'd') {
            dayDate = day[0].split('.')
            dayDate = new Date(
              `20${dayDate[2].substring(2, 4)}`,
              dayDate[1] - 1,
              dayDate[0],
              dayDate[2].substring(6, 7)
            )
          }

          if (config.granularity === 'm') {
            dayDate = day[0].substring(0, 10).split('.')
            dayDate = new Date(`20${dayDate[2]}`, dayDate[1] - 1, dayDate[0])
          }

          if (config.granularity === 'y') {
            dayDate = day[0].substring(0, 10).split('.')
            dayDate = new Date(`20${dayDate[2]}`, dayDate[1] - 1, dayDate[0])
          }

          const timestamp = dayDate.getTime() / 1000
          const closest =
            timeSeriesDataList.items[sensor].length !== 0
              ? timeSeriesDataList.items[sensor].reduce((prev, curr) =>
                  Math.abs(curr.timestamp - timestamp) <
                  Math.abs(prev.timestamp - timestamp)
                    ? curr
                    : prev
                )
              : { value: 0, timestamp: timestamp }

          const record = `${closest.value.toLocaleString(
            userLocales,
            germanDecimals
          )} ${sensorUnit(feelingType)}`

          day.push(record)
          excelArray[sensorName].push([day[0], record])
        })

        sumArray[0].push('-')
        excelArray[sensorName].push(['Summe', '-'])
      }

      return true
    })

    // add weekday to dates
    daylist = addWeekdays(daylist, config.granularity)
    Object.keys(excelArray).map(data => {
      excelArray[data] = addWeekdays(excelArray[data], config.granularity)
      return true
    })

    daylist = daylist.concat(sumArray)
    result['Korrelation'] = daylist
    setTableDataExportExcel(excelArray)
  }, [
    activeSensors,
    activeFeelingSensors,
    config.fromDate,
    config.granularity,
    config.toDate,
    consumptionSensors,
    energyType,
    feelingType,
    folderSensors,
    timeSeriesDataList,
  ])

  const changeFeelingSensors = entity_id => {
    console.log('changeFeelingSensors')
    let _activeFeelingSensors = activeFeelingSensors
    const _timeSeriesDataList = timeSeriesDataList

    if (_activeFeelingSensors.includes(entity_id)) {
      _activeFeelingSensors = _activeFeelingSensors.filter(f => f !== entity_id)
      delete _timeSeriesDataList.items[entity_id]
    } else _activeFeelingSensors = [..._activeFeelingSensors, entity_id]

    const feelingSensor = folderSensors.find(f => f.entity_id === entity_id)

    setFeelingSensorId(entity_id)
    setActiveTableSensor(feelingSensor.name)
    setTimeSeriesDataListLoading(true)
    setTimeSeriesDataList(_timeSeriesDataList)
    setActiveFeelingSensors(_activeFeelingSensors)
  }

  const changeActiveSensors = (entity_id, sensorType) => {
    console.log('changeActiveSensors')
    setActiveSensors(
      activeSensors.includes(entity_id)
        ? arrRemove(activeSensors, entity_id)
        : [...activeSensors, entity_id]
    )

    const consumptionSensor = folderSensors?.find(
      f => f.entity_id === entity_id
    )

    setActiveTableSensor(consumptionSensor.name)
    setEnergyType(sensorType)
  }

  const itemRows = []

  if (tracker)
    allSeries.forEach((value, index) => {
      const id = value._data._root.entries[0][1]

      let name = id
      let unit

      const sensor = folderSensors?.find(f => f.entity_id === Number(id))

      if (sensor) {
        name = sensor.name
        unit = sensor.unit ? sensor.unit : ''
      }

      if (allSeries[index].bisect(tracker)) {
        const markerIndex =
          tracker &&
          allSeries[index].at(allSeries[index].bisect(tracker)).get(id)

        const row = (
          <>
            <br />
            {name}:{' '}
            <strong>
              {markerIndex.toLocaleString(userLocales, germanDecimals)} {unit}
            </strong>{' '}
            <br />
          </>
        )

        itemRows.push(row)
      }
    })

  const handleTrackerChanged = (t, scale) => {
    setTracker(t)
    setTrackerX(t && scale(t))
  }

  const contractBoundaries = date => {
    const date_begin = contractData?.date_begin
    const date_expire = contractData?.date_expire

    if (date < date_begin) return date_begin
    if (date > date_expire) return date_expire
    return date
  }

  const sanitize = date => limitTodayMax(contractBoundaries(date))

  const getSensorById = id =>
    folderSensors.find(f => Number(f.entity_id) === Number(id))

  const getTableHeads = () => {
    const data = []
    data.push(<TableCell>Datum</TableCell>)

    activeSensors.forEach(id => {
      const sensor = getSensorById(id)

      if (sensor.name === activeTableSensor)
        data.push(<TableCell align={'right'}>{sensor.name}</TableCell>)
    })

    activeFeelingSensors.forEach(id => {
      const sensor = getSensorById(id)

      if (sensor.name === activeTableSensor)
        data.push(<TableCell align={'right'}>{sensor.name}</TableCell>)
    })

    return <>{data}</>
  }

  const onChangeInstallation = event => {
    setInstallation(event.target.value)

    setActiveSensors([])
    setActiveFeelingSensors([])
    setReloadTrigger(true)
    setDataFeeling([])
    setDataEnergy([])
    setAllSeries([])
    setMinFeeling(0)
    setMaxFeeling(0)
    setLineCharts([])
    setLegend([])
  }

  const onGoBackwardInTime = () => {
    const fromDate = daysAgo(config.fromDate, periodInDays + 1)
    const toDate = daysAgo(config.toDate, periodInDays + 1)

    setConfig(prev => ({ ...prev, fromDate, toDate }))
    setRange(prev => ({ ...prev, fromDate, toDate }))
    setReloadTrigger(true)
  }

  const onGoOnwardInTime = () => {
    const fromDate = daysOnward(config.fromDate, periodInDays + 1)
    const toDate = daysOnward(config.toDate, periodInDays + 1)

    setConfig(prev => ({ ...prev, fromDate, toDate }))
    setRange(prev => ({ ...prev, fromDate, toDate }))
    setReloadTrigger(true)
  }

  const onChangeFromDate = event => {
    const newRange = {
      ...range,
      fromDate: sanitize(event.target.value),
      ...(sanitize(event.target.value) > range.toDate && {
        toDate: sanitize(event.target.value),
      }),
    }
    setRange(newRange)
  }

  const onChangeToDate = event => {
    const newRange = {
      ...range,
      ...(sanitize(event.target.value) < range.fromDate && {
        fromDate: sanitize(event.target.value),
      }),
      toDate: sanitize(event.target.value),
    }
    setRange(newRange)
  }

  const isPageLoading = folderLoading || consumptionLoading || reloadTrigger

  const isPageError = folderError

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={installation?.name}
        open={Boolean(actionMenu)}
        onClose={() => setActionMenu(null)}
        historyUrlTarget={'installation'}
        historyUrlId={Number(folderId)}
      >
        {[
          {
            caption: 'Details zum Gebäude',
            icon: <AccountBalanceTwoTone />,
            fn: () => history.push(`/folder/${folderId}`),
          },
          null,
          {
            caption: 'Leistung',
            icon: (
              <SVGStatus
                type={'performance'}
                status={folderStatus.performance}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/performance`),
          },
          {
            caption: 'Anlagen',
            icon: (
              <SVGStatus
                type={'installation'}
                status={folderStatus.installation}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/installation`),
          },
          {
            caption: 'Korrelation',
            icon: (
              <SVGStatus
                type={'correlation'}
                status={folderStatus.correlation}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/correlation`),
          },
          {
            caption: 'Sicherheit',
            icon: (
              <SVGStatus type={'security'} status={folderStatus.security} />
            ),
            fn: () => history.push(`/folder/${folderId}/home/security`),
          },
          {
            caption: 'Wohlbefinden',
            icon: <SVGStatus type={'feeling'} status={folderStatus.feeling} />,
            fn: () => history.push(`/folder/${folderId}/home/feeling`),
          },
        ].map((item, key) =>
          item ? (
            <AMItem
              caption={item.caption}
              icon={
                <Stack alignItems={'center'} sx={{ width: 25 }}>
                  {item.icon}
                </Stack>
              }
              key={key}
              onClick={item.fn}
            />
          ) : (
            <Divider key={key} />
          )
        )}
      </AM>
      {/* action menu end */}

      {/* export */}
      <DlgInstallationExport
        open={exportModal}
        onClose={() => setExportModal(false)}
      />
      {/* export end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <SVGStatus
                type={'installation'}
                status={activeInstallationStatus}
              />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={folderName + (folderShortName ? ` (${folderShortName})` : '')}
        >
          {consumptionData?.isLoading || folderData?.isLoading ? null : (
            <Card variant={'installation'}>
              <CardHeader
                sx={{ py: 0 }}
                title={
                  <HStack
                    justifyContent={'space-between'}
                    sx={{ display: 'flex' }}
                  >
                    <HStack alignItems={'center'}>
                      <TextField
                        label={'Anlagen'}
                        onChange={onChangeInstallation}
                        select
                        type={'text'}
                        value={installation}
                        variant={'standard'}
                      >
                        {installations.map((item, key) => (
                          <MenuItem key={key} value={item.entity_id}>
                            <HStack>
                              <ListItemIcon>
                                <Box sx={{ width: 25 }}>
                                  <SVGStatus
                                    type={'installation'}
                                    status={getStatusColor(item.sensors)}
                                  />
                                </Box>
                              </ListItemIcon>

                              <ListItemText>{item.name}</ListItemText>
                            </HStack>
                          </MenuItem>
                        ))}
                      </TextField>
                    </HStack>

                    <HStack alignItems={'center'}>
                      <IconButton
                        disabled={
                          periodWarning ||
                          contractData?.date_begin >= range.fromDate
                        }
                        onClick={onGoBackwardInTime}
                      >
                        <ArrowBackIosTwoTone />
                      </IconButton>

                      <IconButton
                        disabled={
                          periodWarning ||
                          contractData?.date_expire <= range.toDate ||
                          new Date(Date.now()).toISOString().split('T')[0] <=
                            config.toDate
                        }
                        onClick={onGoOnwardInTime}
                      >
                        <ArrowForwardIosTwoTone />
                      </IconButton>

                      <TextField
                        inputProps={{
                          onChange: onChangeFromDate,
                          min: contractData?.date_begin,
                          max: contractData?.date_expire,
                        }}
                        label={'von'}
                        type={'date'}
                        value={range.fromDate}
                        variant={'standard'}
                      />

                      <TextField
                        inputProps={{
                          onChange: onChangeToDate,
                          min: contractData?.date_begin,
                          max: contractData?.date_expire,
                        }}
                        label={'bis'}
                        type={'date'}
                        value={range.toDate}
                        variant={'standard'}
                      />

                      <Button
                        color={'primary'}
                        disabled={
                          config.fromDate === range.fromDate &&
                          config.toDate === range.toDate &&
                          config.granularity === range.granularity
                        }
                        onClick={() => {
                          setReloadTrigger(true)
                          setConfig({
                            ...config,
                            fromDate: range.fromDate,
                            toDate: range.toDate,
                            granularity: range.granularity,
                          })
                        }}
                      >
                        Anwenden
                      </Button>
                    </HStack>
                  </HStack>
                }
                variant={'installation'}
              />

              <CardContent sx={{ p: '0px !important' }}>
                <Grid container sx={{ my: 0 }}>
                  <Grid item xs={12} md={9} sx={{ bgcolor }}>
                    <Box sx={{ position: 'relative', top: 16, right: 20 }}>
                      <Legend
                        type={'line'}
                        style={style}
                        categories={legend}
                        align={'right'}
                      />
                    </Box>

                    {trackerX &&
                    trackerX > 80 &&
                    ((showSecondAxis && trackerX < 1350) ||
                      (!showSecondAxis && trackerX < 1403)) &&
                    allSeries.length > 0 ? (
                      <Box
                        sx={{
                          bgcolor,
                          border: 'solid 1px #C8C8C8',
                          color: 'black',
                          left: mouseX.current - 260,
                          padding: '20px 10px',
                          pointerEvents: 'None',
                          position: 'absolute',
                          top: mouseY.current,
                          zIndex: 10000,
                        }}
                      >
                        <strong>
                          {new Date(tracker).toLocaleDateString(
                            userLocales,
                            WDDDMMYYYYHHMMSS
                          )}
                        </strong>
                        <br />
                        {itemRows}
                      </Box>
                    ) : null}

                    <Resizable>
                      <ChartContainer
                        enableDragZoom
                        format={f => f.toLocaleString(userLocales, WDDDMMYY)}
                        maxTime={requestsSeries.range().end()}
                        minTime={requestsSeries.range().begin()}
                        onTimeRangeChanged={range => setTimeRange(range)}
                        onTrackerChanged={handleTrackerChanged}
                        padding={20}
                        paddingTop={5}
                        paddingBottom={0}
                        timeAxisStyle={axisStyle}
                        timeAxisTickCount={5}
                        timeRange={timeRange}
                        trackerPosition={tracker}
                        trackerStyle={{
                          line: {
                            cursor: 'crosshair',
                            pointerEvents: 'none',
                            stroke: '#a62011',
                          },
                        }}
                      >
                        <ChartRow height={'500'} width={'100'}>
                          <YAxis
                            format={f =>
                              f.toLocaleString(
                                userLocales,
                                maxFeeling <= 10 ? germanDecimals : noDecimals
                              )
                            }
                            hideAxisLine
                            id={'feeling'}
                            label={`${sensorType(feelingType)} in ${sensorUnit(
                              feelingType
                            )}`}
                            max={maxFeeling}
                            min={minFeeling}
                            style={axisStyle}
                            type={'linear'}
                            width={'80'}
                          />
                          <Charts>{lineCharts}</Charts>
                          <YAxis
                            format={f =>
                              f.toLocaleString(
                                userLocales,
                                maxConsumption <= 10
                                  ? germanDecimals
                                  : noDecimals
                              )
                            }
                            hideAxisLine
                            id={'consumption'}
                            label={`${sensorType(energyType)} in ${sensorUnit(
                              energyType
                            )}`}
                            labelOffset={20}
                            max={maxConsumption}
                            min={0}
                            style={axisStyle}
                            type={'linear'}
                            visible={showSecondAxis}
                            width={'100'}
                          />
                        </ChartRow>
                      </ChartContainer>
                    </Resizable>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} sx={{ bgcolor }}>
                    <HStack
                      sx={{
                        bgcolor,
                        borderBottom: 'solid 1px #DDD',
                        borderLeft: 'solid 1px #DDD',
                      }}
                    >
                      <Typography
                        sx={{
                          height: 42,
                          lineHeight: 2.5,
                          px: 2,
                          py: 1,
                          width: '75%',
                        }}
                      >
                        Messpunkte
                      </Typography>
                      <Tooltip
                        title={
                          showSensorInfo === true
                            ? 'Informationen zum Messpunkt ausblenden'
                            : 'Informationen zum Messpunkt einblenden'
                        }
                      >
                        <IconButton
                          onClick={() => setShowSensorInfo(!showSensorInfo)}
                        >
                          {showSensorInfo ? (
                            <InfoTwoTone sx={{ color }} />
                          ) : (
                            <InfoTwoTone />
                          )}
                        </IconButton>
                      </Tooltip>
                    </HStack>
                    <List
                      height={420}
                      sx={{
                        borderLeft: 'solid 1px #D3D3D3',
                        minHeight: 1,
                        maxHeight: 420,
                        overflow: 'auto',
                      }}
                      width={300}
                    >
                      {folderSensors
                        .filter(
                          f =>
                            categoryByType(f.sensor_type) === 'performance' &&
                            f.installation_id === Number(installation)
                        )
                        .map((item, key) => {
                          const {
                            name,
                            entity_id,
                            installation_id,
                            type_in_folder,
                            data_sampling_date,
                          } = item
                          const date = new Date(
                            data_sampling_date
                          ).toLocaleDateString(userLocales, DDMMYYYY)

                          const sensorInfo = (
                            <span>
                              {operatorData?.isAdmin && (
                                <>
                                  <span>Sensor ID: {entity_id}</span>
                                  <br />
                                </>
                              )}
                              <>
                                <span>Datenbeginn: {date}</span>
                              </>
                            </span>
                          )

                          const onClick = () =>
                            changeActiveSensors(entity_id, type_in_folder)

                          const selected = activeSensors.includes(entity_id)
                          const color = colorSchemeConsumption[key]
                          const secondary = showSensorInfo ? sensorInfo : null

                          return installation_id === Number(installation) ? (
                            <ListItem key={key} sx={{ p: 0 }}>
                              <ListItemButton
                                disabled={timeSeriesLoading}
                                onClick={onClick}
                                selected={selected}
                                sx={{ py: 1 }}
                              >
                                <ListItemIcon sx={{ color }}>
                                  {isMainSensor(item) ? (
                                    <StarTwoTone />
                                  ) : (
                                    <StarBorderTwoTone />
                                  )}
                                </ListItemIcon>

                                <ListItemText
                                  primary={name}
                                  secondary={secondary}
                                />
                              </ListItemButton>
                            </ListItem>
                          ) : (
                            true
                          )
                        })}

                      {folderSensors
                        .filter(
                          f =>
                            f.installation_id === Number(installation) &&
                            (categoryByType(f.sensor_type) === 'feeling' ||
                              categoryByType(f.sensor_type) === 'security')
                        )
                        .map((item, key) => {
                          const {
                            name,
                            entity_id,
                            installation_id,
                            sensor_type,
                            data_sampling_date: dsd,
                          } = item

                          const date = new Date(dsd).toLocaleDateString(
                            userLocales,
                            DDMMYYYY
                          )

                          const sensorInfo = (
                            <span>
                              {operatorData?.isAdmin && (
                                <>
                                  <span>Sensor ID: {entity_id}</span>
                                  <br />
                                </>
                              )}
                              <>
                                <span>Datenbeginn: {date}</span>
                              </>
                            </span>
                          )

                          const onClick = () =>
                            changeFeelingSensors(entity_id, sensor_type)

                          const selected =
                            activeFeelingSensors.includes(entity_id)
                          const color = colorSchemeFeeling[key]
                          const secondary = showSensorInfo ? sensorInfo : null

                          return installation_id === Number(installation) ? (
                            <ListItem key={key} sx={{ p: 0 }}>
                              <ListItemButton
                                disabled={timeSeriesLoading}
                                onClick={onClick}
                                selected={selected}
                                sx={{ py: 1 }}
                              >
                                <ListItemIcon sx={{ color }}>
                                  {isMainSensor(item) ? (
                                    <StarTwoTone />
                                  ) : (
                                    <StarBorderTwoTone />
                                  )}
                                </ListItemIcon>

                                <ListItemText
                                  primary={name}
                                  secondary={secondary}
                                />
                              </ListItemButton>
                            </ListItem>
                          ) : (
                            true
                          )
                        })}
                    </List>
                    Kennzahlen
                    <List
                      height={420}
                      sx={{ maxHeight: 420, overflow: 'auto' }}
                      width={300}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

          {/* table data   */}
          <Collapse in={true}>
            <HStack alignItems={'top'}>
              {Object.keys(tableDataExportExcel).map((item, key) =>
                item === activeTableSensor ? (
                  <TableContainer key={key} sx={{ mt: 2 }}>
                    <Table sx={{ width: 1 }}>
                      <TableHead>
                        <TableRow>{getTableHeads()}</TableRow>
                      </TableHead>

                      <TableBody>
                        {tableDataExportExcel[item].map((row, key) => (
                          <TableRow key={key}>{getTableRows(row)}</TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null
              )}

              <List sx={{ overflow: 'auto' }} width={300}>
                {Object.keys(tableDataExportExcel).map((item, key) => {
                  const onClick = () => setActiveTableSensor(item)
                  const selected = item === activeTableSensor
                  const secondary = granularityCaption(config.granularity)

                  return (
                    <ListItem key={key}>
                      <ListItemButton onClick={onClick} selected={selected}>
                        <ListItemIcon>
                          <AssignmentTwoTone />
                        </ListItemIcon>

                        <ListItemText primary={item} secondary={secondary} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </HStack>
          </Collapse>
          {/* table data end */}
        </KPage>
      )}
    </>
  )
}

InstallationDetail.propTypes = {
  PAGE_CONFIG: PropTypes.string,
}
