import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { svgConstants as SVG } from '../../constants'

export const Brand = ({ width = 35 }) => {
  return (
    <Box
      sx={{
        p: '20px 0px',
        position: 'relative',
        zIndex: 4,
        '& img': {
          cursor: 'pointer',
          float: 'left',
          mx: '20px',
          width,
        },
        '&:after': {
          backgroundColor: 'hsla(0,0%,100%,.3)',
          bottom: '0',
          content: '""',
          height: '1px',
          position: 'absolute',
          right: '15px',
          width: 'calc(100% - 30px)',
        },
      }}
    >
      <Link to={'/'}>
        <img src={SVG.KARMA_LOGO} alt={'KARMA Logo'} />
      </Link>
      <Link
        to={'/'}
        style={{
          color: 'white',
          display: 'block',
          fontSize: '18px',
          padding: '5px 0px',
        }}
      >
        KARMA
      </Link>
    </Box>
  )
}

Brand.propTypes = {
  width: PropTypes.number,
}
