/*
FiberManualRecord exception for not using *TwoTone:
used as colored Icon representing <sensor>.is_active */
import { FiberManualRecord } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import React from 'react'

export const CellIsActive = row => {
  const theme = useTheme()
  const success = theme.palette.success.main
  const error = theme.palette.error.main

  const color = row.value ? success : error

  return <FiberManualRecord sx={{ color, width: 100 }} />
}
