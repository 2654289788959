import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sensorActions } from '../../actions'

const sensorContext = createContext({})

export const ProvideSensorPool = ({ children }) => {
  const value = useSensors()

  return (
    <sensorContext.Provider value={value}>{children}</sensorContext.Provider>
  )
}
ProvideSensorPool.propTypes = { children: PropTypes.any }

export const useSensorPool = () => useContext(sensorContext)

const useSensors = () => {
  const dispatch = useDispatch()
  const { allSensors } = useSelector(state => state.sensor)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(() => {
    dispatch(sensorActions.getAllSensors())
    setLoading(true)
  }, [dispatch])

  useEffect(() => {
    if (allSensors) {
      const { error, fetchDate, items } = allSensors

      if (items) {
        const { data } = items

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    }
  }, [allSensors])

  return { data, isError, isLoading, fetchDate }
}
