import {
  ArrowBackIosTwoTone,
  ArrowForwardIosTwoTone,
  NotificationImportantTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { TimeSeries } from 'pondjs'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Baseline,
  ChartContainer,
  ChartRow,
  Charts,
  Legend,
  LineChart,
  Resizable,
  styler,
  YAxis,
} from 'react-timeseries-charts'
import {
  colorSchemeConsumption,
  colorSchemeFeeling,
  colorSchemeSecurity,
  daysAgo,
  daysOnward,
  FILTER_SUB_SENSOR,
  FILTER_TARGET_NOT_PERFORMANCE,
  FILTER_TARGET_NOT_SECURITY,
  FILTER_TARGET_SECURITY,
  germanDecimals,
  getSession,
  noDecimals,
  normalizedSensorType,
  sensorType,
  sensorUnit,
  setSession,
  SORT_NAME,
  SVGStatus,
  userLocales,
  WDDDMMYY,
  WDDDMMYYYYHHMMSS,
} from '../../api'
import {
  AccConsumption,
  AccFeeling,
  AccSecurity,
  HStack,
  Preloader,
} from '../../components'
import {
  useAppConfig,
  useConsumptionByFolderId,
  useFolder,
  useFolderContract,
  useFolderSensors,
  useFolderStatus,
  useSensorPool,
  useSensorPoolIds,
  useTimeSeries,
  useTimeSeriesByFeelingTypes,
} from '../../hooks'

// if given date is in future, return current date
const limitTodayMax = date =>
  date > new Date(Date.now()).toISOString().split('T')[0]
    ? new Date(Date.now()).toISOString().split('T')[0]
    : date

export const Correlation = ({
  isCorrelational,
  PAGE_CONFIG = 'settings_correlation',
}) => {
  const theme = useTheme()
  const { white: bgcolor } = theme.palette

  const { id } = useParams()
  const folderId = Number(id)
  const folderStatus = useFolderStatus(folderId)

  const [config, setConfig] = useState(getSession(PAGE_CONFIG))
  const { appConfig } = useAppConfig()
  const { salutationMode } = appConfig

  const [consumptionSensors, setConsumptionSensors] = useState([])
  const [feelingSensors, setFeelingSensors] = useState([])
  const [securitySensors, setSecuritySensors] = useState([])

  // data
  const [selectedSensors, setSelectedSensors] = useState([])
  const [selectedFeelingSensors, setSelectedFeelingSensors] = useState([])
  const [feelingSensorId, setFeelingSensorId] = useState(null)

  const period = 'day'

  const toDate =
    new Date(config.toDate).setHours(0, 0, 0, 0) ===
    new Date().setHours(0, 0, 0, 0)
      ? new Date()
      : `${config.toDate} 23:59:59`

  const dataTimeRange = {
    ui: { period, min_period: -10000, max_period: 10000 },
    widget: [
      {
        type: 'bid',
        data: [
          [Date.parse(`${config.fromDate} 00:00:00`), -10000],
          [Date.parse(toDate), 10000],
        ],
        display: 'absolute',
      },
    ],
  }

  const pointsTimeRange = dataTimeRange.widget[0].data
  const requestsSeries = new TimeSeries({
    name: 'Temperature',
    columns: ['time', 'temp'],
    points: pointsTimeRange,
  })

  const [dataEnergy, setDataEnergy] = useState([])
  const [dataFeeling, setDataFeeling] = useState([])
  const [lineCharts, setLineCharts] = useState([])
  const [showFirstAxis, setShowFirstAxis] = useState(false)
  const [showSecondAxis, setShowSecondAxis] = useState(false)
  const [style, setStyle] = useState([])
  const [legendStyle, setLegendStyle] = useState({})
  const [legend, setLegend] = useState([])
  const [maxConsumption, setMaxConsumption] = useState()
  const [minFeeling, setMinFeeling] = useState(0)
  const [maxFeeling, setMaxFeeling] = useState(100)
  const [allSeries, setAllSeries] = useState([])
  const [tracker, setTracker] = useState()
  const [trackerX, setTrackerX] = useState()

  const mouseX = useRef(0)
  const mouseY = useRef(0)

  const [consumptionLabel, setConsumptionLabel] = useState('')
  const [feelingLabel, setFeelingLabel] = useState('')

  const [tsDataList, setTsDataList] = useState({ items: [] })
  const [tsDataListLoading, setTsDataListLoading] = useState(false)
  const [tsDataSensorId, setTsDataSensorId] = useState()
  const [feelingTypeLoading, setFeelingTypeLoading] = useState(false)
  const [timeRange, setTimeRange] = useState(requestsSeries.range())

  // period warning
  const [periodWarning, setPeriodWarning] = useState(null)

  // KPIs
  const [periodInDays, setPeriodInDays] = useState()

  const [limitModal, setLimitModal] = useState(false)
  const [baselines, setBaselines] = useState([])

  const [baselineFeelingSensors, setBaselineFeelingSensors] = useState([])
  const [selectedBaselineFeelingSensors, setSelectedBaselineFeelingSensors] =
    useState([])
  const [disableBaselineSwitch, setDisableBaselineSwitch] = useState(false)

  const [operatorHasMainEnergySensor, setOperatorHasMainEnergySensor] =
    useState(true)
  const [operatorHasMainWaterSensor, setOperatorHasMainWaterSensor] =
    useState(true)
  const [operatorHasMainGasSensor, setOperatorHasMainGasSensor] = useState(true)

  const [range, setRange] = useState({
    fromDate: config.fromDate,
    toDate: config.toDate,
    granularity: config.granularity,
  })

  const granularityData = [
    { value: 'd', label: 'Stündlich' },
    { value: 'm', label: 'Täglich' },
  ]

  const {
    data: folderData,
    isError: folderError,
    isLoading: folderLoading,
  } = useFolder(folderId)

  useEffect(() => setSession(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  useEffect(() => {
    const onMouseMove = event => {
      mouseX.current = event.pageX
      mouseY.current = event.pageY
    }

    document.addEventListener('mousemove', onMouseMove)

    return () => document.removeEventListener('mousemove', onMouseMove)
  }, [])

  const folderSensors = useFolderSensors(folderId)

  useEffect(() => {
    setSecuritySensors(
      folderSensors
        .filter(FILTER_TARGET_NOT_PERFORMANCE)
        .filter(FILTER_TARGET_SECURITY)
        .sort(SORT_NAME)
    )

    setFeelingSensors(
      folderSensors
        .filter(FILTER_TARGET_NOT_PERFORMANCE)
        .filter(FILTER_TARGET_NOT_SECURITY)
        .sort(SORT_NAME)
    )
  }, [folderSensors])

  const { data: consumptionData, isLoading: consumptionLoading } =
    useConsumptionByFolderId(
      folderId,
      config.granularity,
      config.fromDate,
      config.toDate
    )

  const { data: tsData, isLoading: tsLoading } = useTimeSeries(
    feelingSensorId,
    config.fromDate + ' 00:00:00',
    config.toDate + ' 23:59:59',
    config.granularity
  )

  const {
    timeSeries: tsDataByFeelingTypes,
    isLoading: tsDataByFeelingTypesLoading,
  } = useTimeSeriesByFeelingTypes(
    folderData,
    config.fromDate + ' 00:00:00',
    config.toDate + ' 23:59:59'
  )

  useEffect(() => {
    if (tsDataByFeelingTypesLoading) return

    setTsDataList(tsDataByFeelingTypes)
  }, [tsDataByFeelingTypesLoading, tsDataByFeelingTypes])

  // KAR-1103
  useEffect(() => {
    if (tsDataListLoading) return

    const style = []
    const legend = []
    const legendStyle = {}
    const labelStyle = {
      normal: {
        fontSize: 'normal',
        color: '#000',
        cursor: 'default',
        marginRight: 5,
      },
    }
    const consumptionTypes = []
    const feelingTypes = []

    const valueStyle = {
      normal: { fontSize: 'normal', color: '#000', cursor: 'default' },
    }

    consumptionSensors.map((sensor, key) => {
      const { entity_id, name, type_in_folder } = sensor
      const isSelected = selectedSensors.includes(entity_id)
      const colorIndex = key % colorSchemeConsumption.length
      const color = colorSchemeConsumption[colorIndex]

      if (isSelected) {
        style.push({ key: entity_id, color })
        style.push({ key: name, color })
        legend.push({
          key: name + entity_id,
          label:
            name +
            ` (${sensor.unit}
              )`,
          disabled: !isSelected,
        })
        legendStyle[name + entity_id] = {
          symbol: {
            normal: { stroke: color, fill: color, strokeWidth: 10 },
          },
          label: labelStyle,
          value: valueStyle,
        }
        consumptionTypes.push(type_in_folder)
      }

      return false
    })

    feelingSensors.map((sensor, key) => {
      const { entity_id, name, sensor_type } = sensor
      const colorIndex = key % colorSchemeFeeling.length
      const color = colorSchemeFeeling[colorIndex]
      const isSelected = selectedFeelingSensors.includes(entity_id)

      if (isSelected) {
        style.push({ key: entity_id, color })
        style.push({ key: name, color })
        legend.push({
          key: name + entity_id,
          label:
            name +
            ` (${sensor.unit ? sensor.unit : ''}
              )`,
          disabled: !isSelected,
        })
        legendStyle[name + entity_id] = {
          symbol: {
            normal: { stroke: color, fill: color, strokeWidth: 10 },
          },
          label: labelStyle,
          value: valueStyle,
        }
        feelingTypes.push(sensor_type)
      }

      return true
    })

    securitySensors.map((sensor, key) => {
      const { entity_id, name, sensor_type, unit } = sensor
      const colorIndex = key % colorSchemeSecurity.length
      const color = colorSchemeSecurity[colorIndex]
      const isSelected = selectedFeelingSensors.includes(entity_id)

      if (isSelected) {
        style.push({ key: entity_id, color, strokeWidth: 2 })
        style.push({ key: name, color })
        legend.push({
          key: name + entity_id,
          label:
            name +
            ` (${unit ? unit : ''}
              )`,
          disabled: !isSelected,
        })
        legendStyle[name + entity_id] = {
          symbol: {
            normal: { stroke: color, fill: color, strokeWidth: 10 },
          },
          label: labelStyle,
          value: valueStyle,
        }
        feelingTypes.push(sensor_type)
      }

      return true
    })

    setConsumptionLabel(createAxisLabel(consumptionTypes))
    setFeelingLabel(createAxisLabel(feelingTypes))
    setStyle(styler(style))
    setLegend(legend)
    setLegendStyle(legendStyle)
  }, [
    selectedFeelingSensors,
    selectedSensors,
    consumptionSensors,
    feelingSensors,
    securitySensors,
    tsDataListLoading,
  ])

  useEffect(() => {
    if (tsData && !tsLoading && !tsDataListLoading && !feelingTypeLoading)
      if (!(feelingSensorId in tsDataList.items)) {
        const newItems = tsDataList.items

        newItems[feelingSensorId] = tsData
        setTsDataList({ items: newItems })
      }
  }, [
    feelingSensorId,
    feelingTypeLoading,
    tsData,
    tsDataList,
    tsDataListLoading,
    tsLoading,
  ])

  useEffect(() => {
    if (!feelingTypeLoading) return

    setTsDataSensorId(feelingSensorId)

    if (tsDataSensorId === feelingSensorId)
      if (!tsLoading) setFeelingTypeLoading(false)
  }, [feelingSensorId, feelingTypeLoading, tsDataSensorId, tsLoading])

  useEffect(() => {
    const toDate =
      new Date(config.toDate).setHours(0, 0, 0, 0) ===
      new Date().setHours(0, 0, 0, 0)
        ? new Date()
        : `${config.toDate} 23:59:59`

    const dataTimeRange = {
      ui: {
        period: period,
        min_period: -10000,
        max_period: 10000,
      },
      widget: [
        {
          type: 'bid',
          data: [
            [Date.parse(`${config.fromDate} 00:00:00`), -10000],
            [Date.parse(toDate), 10000],
          ],
          display: 'absolute',
        },
      ],
    }

    const pointsTimeRange = dataTimeRange.widget[0].data

    const requestsSeries = new TimeSeries({
      name: 'Temperature',
      columns: ['time', 'temp'],
      points: pointsTimeRange,
    })

    setTimeRange(requestsSeries.range())
  }, [config.fromDate, config.toDate, period])

  useEffect(() => {
    if (!tsDataListLoading) return
    if (!tsLoading) setTsDataListLoading(false)
  }, [tsLoading, tsDataListLoading])

  useEffect(() => {
    if (tsDataListLoading) return

    const _charts = []
    const seriesList = []
    let _showFirstAxis = false
    let _showSecondAxis = false

    Object.keys(dataEnergy).forEach(key => {
      const seriesConsumption = new TimeSeries({
        name: key,
        columns: ['time', key],
        points: dataEnergy[key].widget[0].data,
      })

      seriesList.push(seriesConsumption)

      _charts.push(
        <LineChart
          axis={'consumption'}
          key={'consumption'}
          series={seriesConsumption}
          columns={[key]}
          style={style}
          breakLine={true}
        />
      )

      _showSecondAxis = true
    })

    Object.keys(dataFeeling).forEach(key => {
      const seriesFeeling = new TimeSeries({
        name: key,
        columns: ['time', key],
        points: dataFeeling[key].widget[0].data,
      })

      seriesList.push(seriesFeeling)

      _charts.push(
        <LineChart
          key={'feeling'}
          axis={'feeling'}
          series={seriesFeeling}
          columns={[key]}
          style={style}
          interpolation={'curveBasis'}
          breakLine={true}
        />
      )
      _showFirstAxis = true
    })

    setLineCharts(_charts)
    setAllSeries(seriesList)
    setShowFirstAxis(_showFirstAxis)
    setShowSecondAxis(_showSecondAxis)
  }, [dataEnergy, dataFeeling, style, tsData, tsDataList, tsDataListLoading])

  //KAR-1103
  const changeFeelingSensors = entity_id => {
    let _selectedFeelingSensors = [...selectedFeelingSensors]
    let _baselineFeelingSensors = [...baselineFeelingSensors]
    let _selectedBaselineFeelingSensors = [...selectedBaselineFeelingSensors]

    if (_selectedFeelingSensors.includes(entity_id)) {
      _selectedFeelingSensors = _selectedFeelingSensors.filter(
        f => f !== entity_id
      )
      delete tsDataList.items[entity_id]

      const index = _selectedBaselineFeelingSensors.findIndex(
        s => s.entity_id === entity_id
      )

      if (index !== -1) {
        _selectedBaselineFeelingSensors =
          _selectedBaselineFeelingSensors.filter(f => f.entity_id !== entity_id)
      }
    } else _selectedFeelingSensors.push(entity_id)

    if (_baselineFeelingSensors.includes(entity_id)) {
      _baselineFeelingSensors = _baselineFeelingSensors.filter(
        f => f !== entity_id
      )
    } else _baselineFeelingSensors.push(entity_id)

    setTsDataList({ ...tsDataList })
    setSelectedFeelingSensors(_selectedFeelingSensors)
    setBaselineFeelingSensors(_baselineFeelingSensors)
    setSelectedBaselineFeelingSensors(_selectedBaselineFeelingSensors)
  }

  const handleTrackerChanged = (t, scale) => {
    setTracker(t)
    setTrackerX(t && scale(t))
  }

  const axisStyle = {
    label: {
      fontSize: '16px',
      fontWeight: 400,
      strokeOpacity: 0.8,
    },
    values: {
      fill: '#000',
      fontSize: '14px',
      stroke: 'none',
    },
    ticks: {
      fill: 'none',
      opacity: 0.2,
      stroke: '#000',
    },
    axis: {
      fill: 'none',
      fontSize: '14px',
      fontWeight: 100,
      opacity: 1,
      stroke: '#AAA',
    },
  }

  const itemRows = []

  if (tracker)
    allSeries.forEach(series => {
      const id = series._data._root.entries[0][1]
      const sensor = folderSensors.find(
        sensor => sensor.entity_id === Number(id)
      )

      const name = sensor ? sensor.name : id
      const unit = sensor.unit ? sensor.unit : ''

      if (series.bisect(tracker)) {
        const markerIndex = series.at(series.bisect(tracker)).get(id)
        const row = (
          <>
            <br />
            {name}:{' '}
            <strong>
              {markerIndex} {unit}
            </strong>
            <br />
          </>
        )

        itemRows.push(row)
      }
    })

  useEffect(() => {
    const { fromDate, granularity, toDate } = range

    const _1H = 60 * 60 * 1000
    const _24H = 24 * _1H

    const _periodInDays =
      (new Date(toDate).getTime() - new Date(fromDate).getTime()) / _24H

    setPeriodInDays(_periodInDays)

    switch (granularity) {
      case 'd':
        // timespan max a week
        setPeriodWarning(
          _periodInDays > 6
            ? 'Diese Granularität ist bei einer Auswahl von bis zu 7 Tagen verfügbar.'
            : selectedSensors.length > 4
              ? 'Diese Ansicht erlaubt maximal 4 Messpunkte aus dem Bereich Leistung.'
              : null
        )
        break
      case 'm':
        // timespan max. half a year
        setPeriodWarning(
          _periodInDays > 182.625
            ? 'Diese Granularität ist bei einer Auswahl von bis zu 6 Monaten verfügbar.'
            : null
        )
        break
      case 'y':
        // no timespan limit
        setPeriodWarning(null)
        break
      default:
        break
    }
  }, [range, range.fromDate, range.toDate, range.granularity, selectedSensors])

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const checkForMainSensor = type =>
      sensorPool.some(
        sensor =>
          sensor.attributes.parent_id === null &&
          sensor.attributes.type_in_folder === type &&
          Number(sensor.attributes.folder_id) === folderId
      )

    setOperatorHasMainEnergySensor(checkForMainSensor('energy'))
    setOperatorHasMainWaterSensor(checkForMainSensor('water'))
    setOperatorHasMainGasSensor(checkForMainSensor('gas'))
  }, [folderId, sensorPool])

  const operatorSensorIds = useSensorPoolIds()

  useEffect(() => {
    if (consumptionData.length === 0 || operatorSensorIds.length === 0) return

    const cSensors = consumptionData.filter(f =>
      operatorSensorIds.includes(Number(f.entity_id))
    )

    const FILTER_MAIN_SENSOR = f => f.parent_id === null

    const mainSensor = consumptionData
      .filter(f => operatorSensorIds.includes(Number(f.entity_id)))
      .find(
        operatorHasMainEnergySensor ||
          operatorHasMainGasSensor ||
          operatorHasMainWaterSensor
          ? FILTER_MAIN_SENSOR
          : FILTER_SUB_SENSOR
      )

    if (!mainSensor) return

    const _data = []
    const columnsChart = []
    let _maxConsumption = 0

    cSensors.forEach(sensor => {
      const { consumption, entity_id } = sensor

      if (selectedSensors.includes(entity_id)) {
        consumption.values.forEach((item, key) => {
          const { timepoint, value } = item
          const val = Number(value.toFixed(2))

          if (_data[key]) _data[key] = _data[key].concat([val])
          else _data[key] = [timepoint, val]
        })

        if (!columnsChart[entity_id]) {
          columnsChart[entity_id] = {
            ui: { min_period: 0, period, max_period: 0 },
            widget: [{ period, type: 'bid', data: [], display: 'absolute' }],
          }

          let _minPeriod = 0
          let _maxPeriod = 0
          const { ui, widget } = columnsChart[entity_id]

          consumption.values.forEach(item => {
            const { timepoint, value } = item
            const time = new Date(timepoint).getTime()
            const val = Number(value.toFixed(2))

            if (val > _maxPeriod) _maxPeriod = val
            else if (val < _minPeriod) _minPeriod = val

            widget[0].data.push([time, val])
          })

          ui.min_period = _minPeriod
          ui.max_period = _maxPeriod

          if (_maxPeriod > _maxConsumption) _maxConsumption = _maxPeriod

          if (widget[0].data.length > 0) {
            const firstEntry = widget[0].data

            widget[0].data.unshift([firstEntry[0] - 86400000, firstEntry[1]])
          }

          if (widget[0].data.length === 0) {
            const timestamp = new Date().getTime()

            widget[0].data.push([timestamp, 0])
          }
        }
      }
    })

    // sort sensors by its names (label)
    cSensors.sort(SORT_NAME)

    setMaxConsumption(_maxConsumption)
    setConsumptionSensors(cSensors)
    setDataEnergy(columnsChart)
  }, [
    selectedSensors,
    config.granularity,
    consumptionData,
    operatorHasMainEnergySensor,
    operatorHasMainGasSensor,
    operatorHasMainWaterSensor,
    operatorSensorIds,
    period,
    range.granularity,
  ])

  useEffect(() => {
    if (tsDataListLoading) return

    const columnsChart = []
    let _maxFeeling = 0
    let _minFeeling = 0
    Object.keys(tsDataList.items).forEach(key => {
      if (selectedFeelingSensors.includes(Number(key)))
        if (!columnsChart[key]) {
          columnsChart[key] = {
            ui: { min_period: 0, period, max_period: 0 },
            widget: [{ period, type: 'bid', data: [], display: 'absolute' }],
          }

          let minPeriod = 10000
          let maxPeriod = -10000
          const { ui, widget } = columnsChart[key]

          const orderedResult = tsDataList.items[key].sort(
            (a, b) => a.timestamp - b.timestamp
          )

          orderedResult.forEach(item => {
            const { timestamp, value } = item
            const val = Number(value.toFixed(2))

            if (val > maxPeriod) maxPeriod = val
            else if (val < minPeriod) minPeriod = val

            widget[0].data.push([timestamp * 1000, val])
          })

          ui.min_period = minPeriod
          ui.max_period = maxPeriod

          if (minPeriod < _minFeeling) _minFeeling = minPeriod
          if (maxPeriod > _maxFeeling) _maxFeeling = maxPeriod
        }
    })

    if (_minFeeling >= 0) _minFeeling = 0

    setMinFeeling(_minFeeling)
    setMaxFeeling(_maxFeeling)
    setDataFeeling(columnsChart)
  }, [
    selectedFeelingSensors,
    config.granularity,
    period,
    tsData,
    tsDataList,
    tsDataList.items,
    tsDataListLoading,
  ])

  useEffect(() => {
    const baselineStyle = {
      line: {
        stroke: 'red',
        strokeWidth: 1,
        opacity: 0.4,
        strokeDasharray: 'none',
      },
      label: {
        fill: 'red',
      },
    }
    const _baselines = []
    const combinationsMin = []
    const combinationsMax = []
    let maxValue = 0

    const _disabled = selectedBaselineFeelingSensors.length >= 6 ? true : false

    selectedBaselineFeelingSensors.forEach(sensor => {
      if (sensor.alarm_min) {
        if (combinationsMin[sensor.alarm_min] === undefined) {
          combinationsMin[sensor.alarm_min] = `${sensor.name}-${sensor.unit}`
        } else {
          combinationsMin[sensor.alarm_min] += `, ${sensor.name}-${sensor.unit}`
        }
      }

      if (sensor.alarm_max) {
        if (combinationsMax[sensor.alarm_max] === undefined) {
          combinationsMax[sensor.alarm_max] = `${sensor.name}-${sensor.unit}`
        } else {
          combinationsMax[sensor.alarm_max] += `, ${sensor.name}-${sensor.unit}`
        }
      }
    })

    for (const [key, value] of Object.entries(combinationsMin)) {
      _baselines.push(
        <Baseline
          axis={'feeling'}
          style={baselineStyle}
          value={key}
          label={`GMin (${value})`}
          position={'right'}
        />
      )
    }

    for (const [key, value] of Object.entries(combinationsMax)) {
      _baselines.push(
        <Baseline
          axis={'feeling'}
          style={baselineStyle}
          value={key}
          label={`GMax (${value})`}
          position={'right'}
        />
      )

      if (key > maxValue) maxValue = key
    }

    setDisableBaselineSwitch(_disabled)
    setBaselines(_baselines)

    if (maxValue > maxFeeling) setMaxFeeling(maxValue)
  }, [selectedBaselineFeelingSensors, maxFeeling])

  const contractData = useFolderContract(folderId)

  const contractBoundaries = date => {
    const date_begin = contractData?.date_begin
    const date_expire = contractData?.date_expire

    if (date < date_begin) return date_begin
    if (date > date_expire) return date_expire
    return date
  }

  const sanitize = date => limitTodayMax(contractBoundaries(date))

  const removeBaselineSensor = (array, entity_id, type, value) => {
    let index

    type === 'alarm_min'
      ? (index = array.findIndex(
          sensor => sensor.entity_id === entity_id && sensor.alarm_min === value
        ))
      : (index = array.findIndex(
          sensor => sensor.entity_id === entity_id && sensor.alarm_max === value
        ))
    return index >= 0
      ? [...array.slice(0, index), ...array.slice(index + 1)]
      : array
  }

  const LimitSwitches = ({ sensors }) =>
    sensors.map((sensor, key) => {
      const _disabled = disableBaselineSwitch
      const { entity_id, name, attributes_values: av, unit } = sensor

      const alarm_min =
        av?.find(f => f.attribute_code === 'alarm_min')?.value ??
        'Nicht hinterlegt'

      const alarm_max =
        av?.find(f => f.attribute_code === 'alarm_max')?.value ??
        'Nicht hinterlegt'

      const minObj = { entity_id, alarm_min, name, unit }
      const maxObj = { entity_id, alarm_max, name, unit }

      if (selectedFeelingSensors.includes(entity_id)) {
        let disabledMin = _disabled
        let disabledMax = _disabled

        if (
          selectedBaselineFeelingSensors.filter(
            s => s.entity_id === entity_id && s.alarm_min === alarm_min
          ).length > 0
        )
          disabledMin = false

        if (
          selectedBaselineFeelingSensors.filter(
            s => s.entity_id === entity_id && s.alarm_max === alarm_max
          ).length > 0
        )
          disabledMax = false

        const minChecked =
          selectedBaselineFeelingSensors.filter(
            s => s.entity_id === entity_id && s.alarm_min === alarm_min
          ).length > 0

        const maxChecked =
          selectedBaselineFeelingSensors.filter(
            s => s.entity_id === entity_id && s.alarm_max === alarm_max
          ).length > 0

        const onMinChange = () => {
          if (
            selectedBaselineFeelingSensors.filter(
              s => s.entity_id === entity_id && s.alarm_min === alarm_min
            ).length > 0
          ) {
            const newData = removeBaselineSensor(
              selectedBaselineFeelingSensors,
              entity_id,
              'alarm_min',
              alarm_min
            )
            setSelectedBaselineFeelingSensors(newData)
          } else {
            setSelectedBaselineFeelingSensors([
              ...selectedBaselineFeelingSensors,
              minObj,
            ])
          }
        }

        const onMaxChange = () => {
          if (
            selectedBaselineFeelingSensors.filter(
              s => s.entity_id === entity_id && s.alarm_max === alarm_max
            ).length > 0
          ) {
            const newData = removeBaselineSensor(
              selectedBaselineFeelingSensors,
              entity_id,
              'alarm_max',
              alarm_max
            )
            setSelectedBaselineFeelingSensors(newData)
          } else {
            setSelectedBaselineFeelingSensors([
              ...selectedBaselineFeelingSensors,
              maxObj,
            ])
          }
        }

        return (
          <HStack spacing={2} key={key} sx={{ px: 1, width: 1 }}>
            <List sx={{ width: 1 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemText primary={name} sx={{ width: 0.33 }} />

                <FormControlLabel
                  control={
                    <Switch checked={minChecked} onChange={onMinChange} />
                  }
                  disabled={disabledMin}
                  key={key}
                  label={`${alarm_min} ${unit}`}
                  labelPlacement={'start'}
                  sx={{ width: 0.33 }}
                />

                <FormControlLabel
                  control={
                    <Switch checked={maxChecked} onChange={onMaxChange} />
                  }
                  disabled={disabledMax}
                  key={key}
                  label={`${alarm_max} ${unit}`}
                  labelPlacement={'start'}
                  sx={{ width: 0.33 }}
                />
              </ListItem>
            </List>
          </HStack>
        )
      }

      return true
    })

  const createAxisLabel = types => {
    if (types.length === 0) return ''

    const typeNameWithUnit = [...new Set(types)].map(
      item => `${sensorType(item)} (${sensorUnit(item)})`
    )

    return String(typeNameWithUnit)
  }

  const isPageLoading =
    folderLoading || consumptionLoading || feelingTypeLoading

  const isPageError = folderError

  return (
    <Collapse in={!isPageLoading}>
      <Preloader error={isPageError} isLoading={isPageLoading} />

      {/* limits */}
      <Dialog
        maxWidth={'xl'}
        open={limitModal}
        onClose={() => setLimitModal(false)}
      >
        <DialogTitle onClose={() => setLimitModal(false)}>
          Grenzwerte der Messpunkte
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <Stack>
            <Alert severity={'info'}>
              Hier {salutationMode === 'default' ? 'können Sie' : 'kannst Du'}{' '}
              auswählen, ob für die ausgewählten Messpunkte Grenzwertlinien
              eingeblendetet werden sollen. (max. 6)
            </Alert>

            <HStack
              justifyContent={'space-between'}
              spacing={2}
              sx={{ px: 2, py: 1, width: 1 }}
            >
              <Typography sx={{ py: 1, textAlign: 'center' }}>
                Messpunkt
              </Typography>

              <Typography sx={{ py: 1, textAlign: 'center' }}>
                Minimum
              </Typography>

              <Typography sx={{ py: 1, textAlign: 'center' }}>
                Maximum
              </Typography>
            </HStack>

            <LimitSwitches sensors={feelingSensors} />
            <LimitSwitches sensors={securitySensors} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setLimitModal(false)}>Schliessen</Button>
        </DialogActions>
      </Dialog>
      {/* limits end */}

      {isCorrelational === true ? (
        <>
          <Collapse in={!isPageLoading}>
            <Card variant={'correlation'} sx={{ minHeight: 'auto' }}>
              <CardHeader
                title={
                  <HStack
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <HStack alignItems={'center'}>
                      <TextField
                        label={'Granularität'}
                        onChange={event => {
                          const newRange = {
                            ...range,
                            granularity: event.target.value,
                          }

                          setRange(newRange)
                        }}
                        select
                        type={'text'}
                        value={range.granularity}
                        variant={'standard'}
                      >
                        {granularityData.map((item, key) => (
                          <MenuItem key={key} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Tooltip title={'Grenzwerte'}>
                        <ToggleButton
                          onClick={() => setLimitModal(true)}
                          value={limitModal}
                        >
                          <NotificationImportantTwoTone />
                        </ToggleButton>
                      </Tooltip>
                    </HStack>

                    <HStack alignItems={'center'}>
                      <Tooltip title={`Um ${'todo'} Tage zurück blättern`}>
                        <span>
                          <IconButton
                            disabled={
                              periodWarning ||
                              contractData?.date_begin >= range.fromDate ||
                              range.fromDate !== config.fromDate ||
                              range.toDate !== config.toDate ||
                              range.granularity !== config.granularity
                            }
                            onClick={() => {
                              const newConfig = {
                                ...config,
                                fromDate: daysAgo(
                                  config.fromDate,
                                  periodInDays + 1
                                ),
                                toDate: daysAgo(
                                  config.toDate,
                                  periodInDays + 1
                                ),
                              }

                              setConfig(newConfig)
                              setRange({
                                ...range,
                                fromDate: newConfig.fromDate,
                                toDate: newConfig.toDate,
                              })
                              setDataFeeling([])
                              setAllSeries([])
                              setMinFeeling(0)
                              setMaxFeeling(0)
                              setLineCharts([])
                              setLegend([])
                            }}
                          >
                            <ArrowBackIosTwoTone />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={`Um ${'todo'} Tage weiter blättern`}>
                        <span>
                          <IconButton
                            disabled={
                              periodWarning ||
                              contractData?.date_expire <= range.toDate ||
                              new Date(Date.now())
                                .toISOString()
                                .split('T')[0] <= config.toDate ||
                              range.fromDate !== config.fromDate ||
                              range.toDate !== config.toDate ||
                              range.granularity !== config.granularity
                            }
                            onClick={() => {
                              const newConfig = {
                                ...config,
                                fromDate: daysOnward(
                                  config.fromDate,
                                  periodInDays + 1
                                ),
                                toDate: daysOnward(
                                  config.toDate,
                                  periodInDays + 1
                                ),
                              }
                              setConfig(newConfig)
                              setRange({
                                ...range,
                                fromDate: newConfig.fromDate,
                                toDate: newConfig.toDate,
                              })
                              setDataFeeling([])
                              setAllSeries([])
                              setMinFeeling(0)
                              setMaxFeeling(0)
                              setLineCharts([])
                              setLegend([])
                            }}
                          >
                            <ArrowForwardIosTwoTone />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <TextField
                        inputProps={{
                          onChange: event => {
                            const newRange = {
                              ...range,
                              fromDate: sanitize(event.target.value),
                              ...(sanitize(event.target.value) >
                                range.toDate && {
                                toDate: sanitize(event.target.value),
                              }),
                            }
                            setRange(newRange)
                          },
                          min: contractData?.date_begin,
                          max: contractData?.date_expire,
                        }}
                        label={'von'}
                        sx={{ width: 150 }}
                        type={'date'}
                        value={range.fromDate}
                        variant={'standard'}
                      />
                      <TextField
                        inputProps={{
                          onChange: event => {
                            const newRange = {
                              ...range,
                              ...(sanitize(event.target.value) <
                                range.fromDate && {
                                fromDate: sanitize(event.target.value),
                              }),
                              toDate: sanitize(event.target.value),
                            }

                            setRange(newRange)
                          },
                          min: contractData?.date_begin,
                          max: contractData?.date_expire,
                        }}
                        label={'bis'}
                        sx={{ width: 150 }}
                        type={'date'}
                        value={range.toDate}
                        variant={'standard'}
                      />
                      {/* aktuell */}

                      <Tooltip
                        title={`Zeitspanne ändern: ${
                          Number(
                            new Date(config.toDate) - new Date(config.fromDate)
                          ) /
                          1000 /
                          60 /
                          60 /
                          24
                        } > ${
                          Number(
                            new Date(range.toDate) - new Date(range.fromDate)
                          ) /
                          1000 /
                          60 /
                          60 /
                          24
                        } Tag(e)`}
                      >
                        <span>
                          <Button
                            color={'primary'}
                            disabled={
                              config.fromDate === range.fromDate &&
                              config.toDate === range.toDate &&
                              config.granularity === range.granularity
                            }
                            onClick={() => {
                              setConfig({
                                ...config,
                                fromDate: range.fromDate,
                                toDate: range.toDate,
                                granularity: range.granularity,
                              })
                            }}
                          >
                            Anwenden
                          </Button>
                        </span>
                      </Tooltip>
                    </HStack>
                  </HStack>
                }
                titleTypographyProps={{ variant: 'h6' }}
                variant={'correlation'}
              />

              <CardContent sx={{ p: '0px !important' }}>
                {periodWarning && (
                  <Collapse in={true}>
                    <Alert severity={'warning'}>
                      <AlertTitle>Achtung!</AlertTitle>
                      {periodWarning}
                    </Alert>
                  </Collapse>
                )}

                <Collapse in={!periodWarning}>
                  <Grid container sx={{ bgcolor }}>
                    {trackerX &&
                      trackerX > 80 &&
                      ((showSecondAxis && trackerX < 1350) ||
                        (!showSecondAxis && trackerX < 1403)) &&
                      allSeries.length > 0 &&
                      !periodWarning && (
                        <Box
                          sx={{
                            bgcolor,
                            border: 'solid 1px #C8C8C8',
                            color: 'black',
                            left: mouseX.current - 260,
                            p: '20px 10px',
                            pointerEvents: 'None',
                            position: 'absolute',
                            top: mouseY.current,
                            zIndex: 10000,
                          }}
                        >
                          <strong>
                            {new Date(tracker).toLocaleDateString(
                              userLocales,
                              WDDDMMYYYYHHMMSS
                            )}
                          </strong>
                          <br />
                          {itemRows}
                        </Box>
                      )}
                    <Grid item xs={12}>
                      <Box sx={{ position: 'relative', top: 20 }}>
                        <Legend
                          type={'line'}
                          style={legendStyle}
                          categories={legend}
                          align={'right'}
                        />
                      </Box>

                      <Resizable>
                        <ChartContainer
                          enableDragZoom
                          format={f => f.toLocaleString(userLocales, WDDDMMYY)}
                          padding={40}
                          paddingBottom={0}
                          paddingTop={5}
                          maxTime={requestsSeries.range().end()}
                          minTime={requestsSeries.range().begin()}
                          onTimeRangeChanged={range => setTimeRange(range)}
                          onTrackerChanged={handleTrackerChanged}
                          timeAxisStyle={axisStyle}
                          timeAxisTickCount={5}
                          timeRange={timeRange}
                          trackerPosition={tracker}
                          trackerStyle={{
                            line: {
                              cursor: 'crosshair',
                              pointerEvents: 'none',
                              stroke: '#a62011',
                            },
                          }}
                        >
                          <ChartRow key={timeRange} height={500} width={100}>
                            <YAxis
                              format={f =>
                                f.toLocaleString(
                                  userLocales,
                                  maxFeeling <= 10 ? germanDecimals : noDecimals
                                )
                              }
                              hideAxisLine
                              id={'feeling'}
                              label={feelingLabel}
                              min={minFeeling}
                              max={maxFeeling}
                              style={axisStyle}
                              type={'linear'}
                              visible={showFirstAxis}
                              width={'80'}
                            />

                            <Charts>
                              {lineCharts}
                              {baselines}
                            </Charts>

                            <YAxis
                              format={f =>
                                f.toLocaleString(
                                  userLocales,
                                  maxConsumption <= 10
                                    ? germanDecimals
                                    : noDecimals
                                )
                              }
                              hideAxisLine
                              id={'consumption'}
                              label={consumptionLabel}
                              labelOffset={20}
                              max={maxConsumption}
                              style={axisStyle}
                              type={'linear'}
                              visible={showSecondAxis}
                              width={'80'}
                            />
                          </ChartRow>
                        </ChartContainer>
                      </Resizable>
                    </Grid>
                  </Grid>
                </Collapse>
              </CardContent>
            </Card>

            <HStack alignItems={'top'} spacing={2}>
              <Card variant={'correlation'} sx={{ width: 1 }}>
                <CardHeader
                  avatar={
                    <Avatar>
                      <SVGStatus
                        type={'performance'}
                        status={folderStatus.performance}
                      />
                    </Avatar>
                  }
                  title={'Leistung'}
                  titleTypographyProps={{ variant: 'h6' }}
                  variant={'correlation'}
                />

                <CardContent sx={{ p: '0 !important' }}>
                  <AccConsumption
                    folderId={folderId}
                    consumptionSensors={consumptionSensors}
                    selectedSensors={selectedSensors}
                    setSelectedSensors={setSelectedSensors}
                    changeFeelingSensors={changeFeelingSensors}
                    setFeelingSensorId={setFeelingSensorId}
                    selectedFeelingSensors={selectedFeelingSensors}
                  />
                </CardContent>
              </Card>

              <Card variant={'correlation'} sx={{ width: 1 }}>
                <CardHeader
                  avatar={
                    <Avatar>
                      <SVGStatus
                        type={'feeling'}
                        status={folderStatus.feeling}
                      />
                    </Avatar>
                  }
                  title={'Wohlbefinden'}
                  titleTypographyProps={{ variant: 'h6' }}
                  variant={'correlation'}
                />

                <CardContent sx={{ p: '0 !important' }}>
                  <AccFeeling
                    folderId={folderId}
                    feelingSensors={feelingSensors}
                    selectedSensors={selectedSensors}
                    setSelectedSensors={setSelectedSensors}
                    selectedFeelingSensors={selectedFeelingSensors}
                    setFeelingSensorId={setFeelingSensorId}
                    changeFeelingSensors={changeFeelingSensors}
                    selectedBaselineFeelingSensors={
                      selectedBaselineFeelingSensors
                    }
                    setSelectedFeelingSensors={setSelectedFeelingSensors}
                    setSelectedBaselineFeelingSensors={
                      setSelectedBaselineFeelingSensors
                    }
                  />
                </CardContent>
              </Card>

              <Card variant={'correlation'} sx={{ width: 1 }}>
                <CardHeader
                  avatar={
                    <Avatar>
                      <SVGStatus
                        type={'security'}
                        status={folderStatus.security}
                      />
                    </Avatar>
                  }
                  title={'Sicherheit'}
                  titleTypographyProps={{ variant: 'h6' }}
                  variant={'correlation'}
                />

                {['temperature', 'co2', 'humidity', 'light', 'pressure'].map(
                  (sensor_type, key) =>
                    securitySensors.find(
                      f => normalizedSensorType(f.sensor_type) === sensor_type
                    ) && (
                      <AccSecurity
                        folderId={folderId}
                        key={key}
                        securitySensors={securitySensors}
                        sensor_type={sensor_type}
                        selectedSensors={selectedSensors}
                        setSelectedSensors={setSelectedSensors}
                        selectedFeelingSensors={selectedFeelingSensors}
                        setFeelingSensorId={setFeelingSensorId}
                        changeFeelingSensors={changeFeelingSensors}
                        selectedBaselineFeelingSensors={
                          selectedBaselineFeelingSensors
                        }
                        setSelectedFeelingSensors={setSelectedFeelingSensors}
                        setSelectedBaselineFeelingSensors={
                          setSelectedBaselineFeelingSensors
                        }
                      />
                    )
                )}
              </Card>
            </HStack>
          </Collapse>
        </>
      ) : (
        <Alert severity={'info'}>
          Diese Funktion ist verfügbar, sobald mindestens zwei Messpunkte
          gebucht sind.
        </Alert>
      )}
    </Collapse>
  )
}

Correlation.propTypes = {
  isCorrelational: PropTypes.bool,
  PAGE_CONFIG: PropTypes.object,
}
