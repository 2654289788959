import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'

export const useFolderName = folderId => {
  const [data, setData] = useState('')

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.name) return

    const fsName = folderData?.attributes?.name
    if (fsName) setData(fsName)
  }, [folderPool, folderId])

  return data
}

useFolderName.propTypes = {
  folderId: PropTypes.number,
}
