import { InfoTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

export const ToggleShowSensorInfo = ({ onChange }) => {
  const theme = useTheme()
  const { main: color } = theme.palette.secondary

  const [showSensorInfo, setShowSensorInfo] = useState(false)
  const toggle = () => setShowSensorInfo(prev => !prev)

  const icon =
    showSensorInfo === true ? <InfoTwoTone sx={{ color }} /> : <InfoTwoTone />

  const tooltip =
    showSensorInfo === true
      ? 'Informationen zum Messpunkt ausblenden'
      : 'Informationen zum Messpunkt einblenden'

  useEffect(() => onChange(showSensorInfo), [onChange, showSensorInfo])

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={toggle}>{icon}</IconButton>
    </Tooltip>
  )
}

ToggleShowSensorInfo.propTypes = {
  onChange: PropTypes.func.isRequired,
}
