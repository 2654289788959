import { FunctionsTwoTone, Star } from '@mui/icons-material'
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const SheetSelector = ({ colors, data, onChange, title }) => (
  <ToggleButtonGroup
    exclusive
    sx={{ ml: 2 }}
    value={title || 'Gesamtverbrauch'}
  >
    {data.map((item, key) => (
      <ToggleButton key={key} onClick={() => onChange(key)} value={item}>
        <Tooltip title={item}>
          {item === 'Gesamtverbrauch' ? (
            <FunctionsTwoTone />
          ) : (
            <Star sx={{ color: colors[item] }} />
          )}
        </Tooltip>

        {data.length <= 4 && (
          <Typography sx={{ fontSize: 14, ml: 1 }}>{item}</Typography>
        )}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)

SheetSelector.propTypes = {
  colors: PropTypes.array.isRequired,
  data: PropTypes.array,
  onChange: PropTypes.func,
  title: PropTypes.string,
}
