import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { consumptionActions } from '../../actions'

export const useDailyConsumptionToDate = folderId => {
  const dispatch = useDispatch()

  const _endDateDay = new Date()
  _endDateDay.setDate(new Date().getDate() - 1)

  const fromDate = _endDateDay.toISOString().split('T')[0]
  const toDate = new Date().toISOString().split('T')[0]

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const { consumption24 } = useSelector(state => state.consumption)

  useEffect(() => {
    if (Number(folderId) > 0) {
      setLoading(true)
      dispatch(consumptionActions.getAll(folderId, 'h', fromDate, toDate, true))
    }
  }, [dispatch, folderId, fromDate, toDate])

  useEffect(() => {
    if (!consumption24) return

    const { error, item } = consumption24

    if (item) {
      const { data } = item
      if (!data) return
      setData(
        data.map(item => ({
          ...item.attributes,
          entity_id: Number(item.id),
        }))
      )
      setLoading(false)
    }

    if (error) {
      const { message } = error

      setError(message)
      setLoading(false)
    }
  }, [consumption24])

  return { isLoading, isError, data }
}
