export const isValidEmail = email => {
  const regExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regExp.test(email) ? true : false
}

export const isValidLength = (val, len) => val && val.length >= len

export const isValidPassword = password => {
  const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/

  return regExp.test(password) ? true : false
}

export const sanitizeJSON = data =>
  data
    .replace(/'/g, '"')
    //.replace(/None/g, '"undefined"')
    .replace(/Infinity/g, '"Infinity"')
//.replace(/True/g, '"True"')
//.replace(/False/g, '"False"')

// get status color from list of sensors
export const getStatusColor = sensors => {
  if (!sensors) return

  const data = [...new Set(sensors.map(sensor => sensor.status))]

  const STATUS_RED = f => f === 'red'
  const STATUS_YELLOW = f => f === 'yellow'
  const STATUS_GREEN = f => f === 'green'

  return data.find(STATUS_RED)
    ? 'red'
    : data.find(STATUS_YELLOW)
      ? 'yellow'
      : data.find(STATUS_GREEN)
        ? 'green'
        : null
}

export const getPrevMonth = (date, delta = 1) => {
  const newDate = new Date(date)
  newDate.setMonth(date.getMonth() - delta)

  return newDate
}

export const getNextMonth = (date, delta = 1) => {
  const newDate = new Date(date)
  newDate.setMonth(date.getMonth() + delta)

  return newDate
}

export const removeUnits = data =>
  typeof data === 'string'
    ? data
        .replace(' %', '')
        .replace(' kWh', '')
        .replace(' m³', '')
        .replace(' kg', '')
        .replace(' €', '')
    : data
