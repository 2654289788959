import { CloseTwoTone } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import {
  FILTER_BUSINESS_NEWS_TYPE,
  FILTER_LEGAL_NEWS_TYPE,
  FILTER_TECH_NEWS_TYPE,
  FILTER_UNSPECIFIED_NEWS_TYPE,
} from '../../api'
import { HStack, ToggleDateType } from '../../components'
import { useNewsPool } from '../../hooks'

const StatRecord = ({ label, value, ...rest }) => (
  <HStack spacing={2} sx={{ width: 1 }} {...rest}>
    <Stack sx={{ flex: 1, textAlign: 'right' }}>
      <Typography>{label}</Typography>
    </Stack>

    <Stack sx={{ flex: 1, textAlign: 'left' }}>
      <Typography>{value}</Typography>
    </Stack>
  </HStack>
)

StatRecord.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
}

const NewsPoolStat = () => {
  const { data: newsPool, fetchDate } = useNewsPool()

  return (
    <Stack sx={{ mt: 3 }}>
      <StatRecord label={'Poolgröße'} value={newsPool.length} />

      <StatRecord
        label={<strong>Kategorie</strong>}
        sx={{ my: 1, pr: 1, textAlign: 'right' }}
      />

      {[
        {
          label: 'Vertrags News',
          value: newsPool.filter(f => f.attributes.contract_id !== null).length,
        },
        {
          label: 'Gebäude News',
          value: newsPool.filter(f => f.attributes.folder_id !== null).length,
        },
        {
          label: 'unspezifiziert',
          value: newsPool.filter(
            f =>
              f.attributes.contract_id === null &&
              f.attributes.folder_id === null
          ).length,
        },
      ].map((item, key) => (
        <StatRecord key={key} label={item.label} value={item.value} />
      ))}

      <StatRecord
        label={<strong>Rubrik</strong>}
        sx={{ my: 1, pr: 1, textAlign: 'right' }}
      />

      {[
        {
          label: 'technisch',
          value: newsPool.filter(FILTER_TECH_NEWS_TYPE).length,
        },
        {
          label: 'rechtlich',
          value: newsPool.filter(FILTER_LEGAL_NEWS_TYPE).length,
        },
        {
          label: 'kaufmännisch',
          value: newsPool.filter(FILTER_BUSINESS_NEWS_TYPE).length,
        },
        {
          label: 'unspezifiziert',
          value: newsPool.filter(FILTER_UNSPECIFIED_NEWS_TYPE).length,
        },
      ].map((item, key) => (
        <StatRecord key={key} label={item.label} value={item.value} />
      ))}

      <StatRecord
        label={'gefetcht'}
        sx={{ mb: 1, mt: 2, pr: 1, textAlign: 'right' }}
        value={<ToggleDateType date={fetchDate} />}
      />
    </Stack>
  )
}

export const DlgNewsPoolInfo = ({
  onClose,
  open,
  title = 'News Pool Info',
}) => (
  <Dialog fullWidth open={open}>
    <DialogTitle
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontSize: 20 }}>{title}</Typography>

      <IconButton
        onClick={onClose}
        sx={{
          '&:hover': { transform: 'rotate(90deg)' },
          transform: 'rotate(0deg)',
          transition: 'transform 0.2s',
        }}
        variant={'close'}
      >
        <CloseTwoTone />
      </IconButton>
    </DialogTitle>

    <DialogContent>
      <NewsPoolStat />
    </DialogContent>
  </Dialog>
)

DlgNewsPoolInfo.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}
