import { sensorBenchmarkConstants as SBC } from '../constants'
const initialState = { allSensorBenchmark: { loading: true } }
export const sensorBenchmark = (state = initialState, action) => {
  const { error, benchmark, type } = action
  switch (type) {
    case SBC.GET_BY_ID_ERROR:
      return { ...state, benchmark: { error, loading: true } }
    case SBC.GET_BY_ID_REQUEST:
      return { ...state, benchmark: { loading: true } }
    case SBC.GET_BY_ID_SUCCESS:
      return {
        ...state,
        oneBenchmark: { item: benchmark, loading: false },
      }
    case SBC.REMOVE_BENCHMARK_ERROR:
      return { ...state, oneBenchmark: { error, loading: true } }
    case SBC.REMOVE_BENCHMARK_REQUEST:
      return { ...state, oneBenchmark: { loading: true } }
    case SBC.REMOVE_BENCHMARK_SUCCESS:
      return { ...state, oneBenchmark: { loading: true } }
    case SBC.SAVE_BENCHMARK_ERROR:
      return { ...state, oneBenchmark: { error, loading: true } }
    case SBC.SAVE_BENCHMARK_REQUEST:
      return { ...state, oneBenchmark: { loading: true } }
    case SBC.SAVE_BENCHMARK_SUCCESS:
      return {
        ...state,
        oneBenchmark: { item: benchmark, loading: false },
      }
    case SBC.UPDATE_BENCHMARK_ERROR:
      return { ...state, oneBenchmark: { error, loading: true } }
    case SBC.UPDATE_BENCHMARK_REQUEST:
      return { ...state, oneBenchmark: { loading: true } }
    case SBC.UPDATE_BENCHMARK_SUCCESS:
      return { ...state, oneBenchmark: { loading: true } }
    case SBC.GET_BENCHMARKS_BY_SENSOR_ID_REQUEST:
      return { ...state, allSensorBenchmark: { loading: true } }
    case SBC.GET_BENCHMARKS_BY_SENSOR_ID_SUCCESS:
      return {
        ...state,
        allSensorBenchmark: { item: benchmark, loading: false },
      }
    case SBC.GET_BENCHMARKS_BY_SENSOR_ID_ERROR:
      return {
        ...state,
        allSensorBenchmark: { error, loading: true },
      }
    default:
      return state
  }
}
