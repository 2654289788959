import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import React, { useCallback } from 'react'
import { useFolderPool } from '../../../hooks'

const DEFAULT_CENTER = { lat: 51.312801, lng: 9.481544 } // Kassel, Hessen, Germany

const { REACT_APP_API_GOOGLEMAPS } = process.env

const containerStyle = { width: '100%', height: '800px' }

export const FolderMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_API_GOOGLEMAPS,
  })

  const { data: folderPool } = useFolderPool()

  const onLoad = useCallback(map => {
    const bounds = new window.google.maps.LatLngBounds(DEFAULT_CENTER)
    map.fitBounds(bounds)
  }, [])

  const onUnmount = useCallback(() => {}, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={DEFAULT_CENTER}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {folderPool.map((item, key) => {
        const { latitude, longitude, name, name_short } = item.attributes

        return (
          <Marker
            key={key}
            position={{ lat: Number(latitude), lng: Number(longitude) }}
            onClick={() => {
              console.log(name)
            }}
            title={name + (name_short ? ` (${name_short})` : '')}
          />
        )
      })}
    </GoogleMap>
  ) : (
    <></>
  )
}
