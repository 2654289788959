import { Alert, Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { HStack } from '../HStack'

export const AddFirstPanel = ({ onAdd }) => (
  <HStack justifyContent={'center'}>
    <Alert severity={'info'} sx={{ width: 0.5 }}>
      <Typography sx={{ fontWeight: 'bold' }}>
        Erstellen Sie Ihre erste Kachel
      </Typography>

      <Button onClick={onAdd} sx={{ mt: 2 }}>
        Neue Kachel
      </Button>
    </Alert>
  </HStack>
)

AddFirstPanel.propTypes = {
  onAdd: PropTypes.func,
}
