import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useSensorProvider = sensorId => {
  const [data, setData] = useState(null)

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const sensorData = sensorPool.find(f => Number(f.id) === sensorId)
    if (!sensorData?.attributes?.provider) return

    const provider = sensorData?.attributes?.provider

    if (provider) setData(provider)
  }, [sensorPool, sensorId])

  return data
}

useSensorProvider.propTypes = {
  sensorId: PropTypes.number,
}
