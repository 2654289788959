import {
  Alert,
  AlertTitle,
  Collapse,
  LinearProgress,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useContractPool, useFolderPool } from '../../hooks'

export const IntegrityOC = ({
  title = 'Synchronizität von Gebäuden & Verträgen',
}) => {
  const { data: contracts, isLoading: cLoading } = useContractPool()
  const { data: folders, isLoading: fLoading } = useFolderPool()

  const SEVERITY_SUCCESS = 'success'
  const SEVERITY_ERROR = 'error'
  const SEVERITY_LOADING = 'info'

  const [severity, setSeverity] = useState(SEVERITY_LOADING)
  const [fNames, setFNames] = useState([])
  const [cNames, setCNames] = useState([])

  const isLoading = cLoading || fLoading

  useEffect(() => {
    if (isLoading === true) {
      setSeverity(SEVERITY_LOADING)
      return
    }

    setFNames(folders.map(i => i.attributes.name).sort())
    setCNames(contracts.map(i => i.attributes.name).sort())

    if (contracts.length === folders.length) setSeverity(SEVERITY_SUCCESS)
    if (contracts.length !== folders.length) setSeverity(SEVERITY_ERROR)
  }, [contracts, folders, isLoading])

  return (
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>

      <Collapse in={isLoading}>
        <LinearProgress />
      </Collapse>

      <Collapse in={!isLoading && severity === SEVERITY_SUCCESS}>
        <Typography>
          Gleiche Anzahl von Gebäuden ({folders.length}) und Verträgen (
          {contracts.length}) erkannt.
        </Typography>
      </Collapse>

      <Collapse in={!isLoading && severity !== SEVERITY_SUCCESS}>
        <Typography>
          Ungleiche Anzahl von Gebäuden ({folders.length}) und Verträgen (
          {contracts.length}) erkannt.
        </Typography>
      </Collapse>

      <Collapse in={!isLoading}>
        <Typography sx={{ py: 5 }}>
          Ermittle Gebäude und Verträge, die kein Paar bilden:
        </Typography>

        <Typography>
          <strong>
            GEBÄUDE OHNE GLEICHNAMIGEN VERTRAG (
            {fNames.filter(f => !cNames.includes(f)).length})
          </strong>
        </Typography>
        <br />
        {fNames
          .filter(f => !cNames.includes(f))
          .join(', ')
          .split('<br/>')}
        <br />
        <br />
        <Typography>
          <strong>
            VERTRÄGE OHNE GLEICHNAMIGES GEBÄUDE (
            {cNames.filter(f => !fNames.includes(f)).length})
          </strong>
        </Typography>
        <br />
        {cNames
          .filter(f => !fNames.includes(f))
          .join(', ')
          .split('<br/>')}
      </Collapse>
    </Alert>
  )
}

IntegrityOC.propTypes = {
  title: PropTypes.string,
}
