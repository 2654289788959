import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { categoryByType, normalizedSensorType, SensorIcon } from '../../api'
import { svgConstants as SVG } from '../../constants'
import {
  useDeviceId,
  useMeterNumber,
  useSensorName,
  useSensorProvider,
  useSensorTarget,
  useSensorType,
  useSubSensorTarget,
  useTypeInFolder,
} from '../../hooks'
import { MemoryTwoTone } from '@mui/icons-material'

const CONSUMPTION_MEDIUM_ENERGY = 'energy'
const CONSUMPTION_MEDIUM_HEATING = 'gas'
const CONSUMPTION_MEDIUM_WATER = 'water'

export const SensorItem = ({ onClick, isActive, isAdmin, sensorId }) => {
  const sensorName = useSensorName(sensorId)
  const sensorType = useSensorType(sensorId)
  const sensorTarget = useSensorTarget(sensorId)
  const subSensorTarget = useSubSensorTarget(sensorId)
  const provider = useSensorProvider(sensorId)
  const deviceId = useDeviceId(sensorId)
  const typeInFolder = useTypeInFolder(sensorId)
  const meterNumber = useMeterNumber(sensorId)

  let image_url = SensorIcon(categoryByType(sensorType, typeInFolder))
  const normalized = normalizedSensorType(sensorType, typeInFolder)

  switch (normalized) {
    case CONSUMPTION_MEDIUM_ENERGY:
      image_url = SVG.MEDIUM_ENERGY
      break
    case CONSUMPTION_MEDIUM_WATER:
      image_url = SVG.MEDIUM_WATER
      break
    case CONSUMPTION_MEDIUM_HEATING:
      image_url = SVG.MEDIUM_HEATING
      break
    default:
      console.warn('unhandled | bestehend aus:', sensorType, typeInFolder)
      image_url && <MemoryTwoTone />
      break
  }

  const adminInfo = `Sensor ID: ${sensorId}`

  return (
    <ListItemButton onClick={onClick} value={sensorId}>
      <ListItemAvatar>
        <Avatar src={image_url}>{!image_url && <MemoryTwoTone />}</Avatar>
      </ListItemAvatar>

      <ListItemText
        sx={{ width: 1 / 2 }}
        primary={`Name: ${sensorName}`}
        secondary={isAdmin && adminInfo}
      />

      <ListItemText
        sx={{ width: 1 / 4 }}
        primary={'Zählernummer'}
        secondary={meterNumber}
      />

      {isAdmin && (
        <ListItemText
          sx={{ width: 1 / 4 }}
          primary={'Typ Casting'}
          secondary={typeInFolder}
        />
      )}

      {isAdmin && (
        <ListItemText
          sx={{ width: 1 / 4 }}
          primary={'Target'}
          secondary={`${sensorTarget}::${subSensorTarget}`}
        />
      )}

      {isAdmin && (
        <ListItemText
          sx={{ width: 1 / 2 }}
          primary={'Hardware Information'}
          secondary={`${provider}::${deviceId}::${sensorType}`}
        />
      )}

      <ListItemIcon>
        <Switch checked={isActive} />
      </ListItemIcon>
    </ListItemButton>
  )
}

SensorItem.propTypes = {
  isActive: PropTypes.bool,
  isAdmin: PropTypes.bool,
  onClick: PropTypes.func,
  sensorId: PropTypes.number.isRequired,
}
