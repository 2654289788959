import {
  EmailTwoTone,
  PhoneTwoTone,
  SmartphoneTwoTone,
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { useCoachData } from '../../hooks'

export const Coach = ({ bridgeId }) => {
  const coachData = useCoachData(bridgeId)

  if (!bridgeId || !coachData?.attributes) return <Box />

  const {
    email,
    firstname,
    image_url: image,
    lastname,
    telephone,
    telephone_mobile,
  } = coachData.attributes

  const fullName = `${firstname} ${lastname}`

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 600,
        m: 'auto',
      }}
    >
      <CardMedia component={'img'} image={image} alt={fullName} />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', pl: 0 }}>
          <Typography variant={'h4'} sx={{ textTransform: 'uppercase', mb: 3 }}>
            {fullName}
          </Typography>

          <List>
            {telephone && (
              <ListItem>
                <ListItemIcon>
                  <PhoneTwoTone />
                </ListItemIcon>
                <ListItemText primary={telephone} />
              </ListItem>
            )}

            {telephone_mobile && (
              <ListItem>
                <ListItemIcon>
                  <SmartphoneTwoTone />
                </ListItemIcon>
                <ListItemText primary={telephone_mobile} />
              </ListItem>
            )}

            {email && (
              <ListItem>
                <ListItemIcon>
                  <EmailTwoTone />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Link to={`mailto:${email}`} target={'_top'}>
                      {email}
                    </Link>
                  }
                />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Box>
    </Card>
  )
}

Coach.propTypes = {
  bridgeId: PropTypes.number,
}
