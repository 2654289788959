import { Button, Typography } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sensorActions } from '../../actions'
import { authHeader } from '../../api'
import {
  DlgRecalculateSensorData,
  KPage,
  KTextField,
  Preloader,
} from '../../components'

const { REACT_APP_API_URL } = process.env

export const RecalculateSensorData = () => {
  const dispatch = useDispatch()

  const defaultDataStructure = {
    data: {
      first_sensor_message: {},
      missing_data: [],
      second_sensor_message: {},
    },
  }

  const textFieldMaxWidth = '250px'

  const [sensorID, setSensorID] = useState('')
  const [entityID1, setEntityID1] = useState('')
  const [entityID2, setEntityID2] = useState('')
  const [timestep, setTimestep] = useState('')
  const [previewData, setPreviewData] = useState(defaultDataStructure)
  const [previewGenerated, setPreviewGenerated] = useState(false)
  const [tableModal, setTabeModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const renderPreview = () => {
    const fetchCalculation = async (
      sensorID,
      entityID1,
      entityID2,
      timestep
    ) => {
      const config = {
        headers: authHeader(),
        method: 'GET',
        url: `${REACT_APP_API_URL}/iot/missing-data`,
        params: {
          sensor: sensorID,
          entity1: entityID1,
          entity2: entityID2,
          timestep: timestep,
        },
      }

      try {
        const response = await axios(config)
        const { data } = response

        if (data) {
          setPreviewData(data)
          setIsLoading(false)
          setTabeModal(true)
          setPreviewGenerated(true)
        }
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }

    fetchCalculation(sensorID, entityID1, entityID2, timestep)
  }

  const enablePreviewButton = () =>
    sensorID !== '' && entityID1 !== '' && entityID2 !== '' && timestep !== ''

  const saveRecalculationStart = () =>
    dispatch(sensorActions.insertMissingSensorData(previewData.data))

  return (
    <>
      <Preloader isLoading={isLoading} />

      <KPage>
        <Typography component={'h4'} variant={'h4'} align={'center'}>
          Daten
        </Typography>

        <KTextField
          label={'Sensor ID'}
          onChange={event => {
            setSensorID(event.target.value)
            setPreviewGenerated(false)
          }}
          value={sensorID}
          type={'number'}
          style={{ maxWidth: textFieldMaxWidth }}
        />

        <KTextField
          label={'Entity ID 1'}
          onChange={event => {
            setEntityID1(event.target.value)
            setPreviewGenerated(false)
          }}
          value={entityID1}
          type={'number'}
          style={{ maxWidth: textFieldMaxWidth }}
        />

        <KTextField
          label={'Entity ID 2'}
          onChange={event => {
            setEntityID2(event.target.value)
            setPreviewGenerated(false)
          }}
          value={entityID2}
          type={'number'}
          style={{ maxWidth: textFieldMaxWidth }}
        />

        <KTextField
          label={'Zeitintervall'}
          onChange={event => {
            setTimestep(event.target.value)
            setPreviewGenerated(false)
          }}
          value={timestep}
          type={'number'}
          style={{ maxWidth: textFieldMaxWidth }}
        />

        <Button
          disabled={!enablePreviewButton()}
          onClick={() => {
            setPreviewData(defaultDataStructure)
            setIsLoading(true)
            renderPreview()
          }}
          sx={{ mr: '30px' }}
        >
          Vorschau
        </Button>

        <Button
          disabled={!previewGenerated}
          onClick={() => saveRecalculationStart()}
        >
          Start
        </Button>

        <DlgRecalculateSensorData
          open={tableModal}
          onClose={() => setTabeModal(false)}
          previewData={previewData}
        />
      </KPage>
    </>
  )
}
