import { CancelTwoTone, DeleteTwoTone } from '@mui/icons-material'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../actions'

export const DlgDashboardDelete = ({
  dashboardId,
  name,
  onClose,
  open,
  title = 'Bestätigung erforderlich',
}) => {
  const dispatch = useDispatch()

  const deleteDashboard = dashboardId =>
    dispatch(dashboardActions.remove(dashboardId))

  const onDelete = () => {
    deleteDashboard(dashboardId)
    onClose()
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Alert severity={'info'} sx={{ p: 2 }}>
          Möchten Sie Dashboard <strong>{name}</strong> wirklich löschen?
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button color={'error'} onClick={onDelete}>
          <DeleteTwoTone />
          Löschen
        </Button>

        <Button onClick={onClose}>
          <CancelTwoTone />
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgDashboardDelete.propTypes = {
  dashboardId: PropTypes.number.isRequired,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
}
