import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import {
  BusinessTwoTone,
  EditTwoTone,
  FaceTwoTone,
  MemoryTwoTone,
  MessageTwoTone,
  MoreVertTwoTone,
} from '@mui/icons-material'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  categoryByType,
  getRelativeDate,
  normalizedSensorType,
  SensorIcon,
  sensorType,
  SORT,
  SVGStatus,
} from '../../api'
import {
  AM,
  AMItem,
  Coach,
  ContractStatus,
  FolderNews,
  KPage,
  OfficeHours,
  Preloader,
} from '../../components'
import {
  useBridgeId,
  useFolder,
  useFolderImage,
  useFolderName,
  useFolderShortName,
  useFolderStatus,
  useOperator,
} from '../../hooks'

const { REACT_APP_API_GOOGLEMAPS } = process.env

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

export const FolderSingle = () => {
  const history = useHistory()

  const theme = useTheme()
  const success = theme.palette.success.main
  const error = theme.palette.error.main

  const { id, section } = useParams()
  const folderId = Number(id)
  const folderImage = useFolderImage(folderId)
  const folderName = useFolderName(folderId)
  const folderShortName = useFolderShortName(folderId)
  const folderStatus = useFolderStatus(folderId)

  const [tab, setTab] = useState(Number(section) || 0)

  const bridgeSensor = useBridgeId(folderId)

  const {
    data: operatorData,
    isError: operatorError,
    isLoading: operatorLoading,
  } = useOperator()

  const {
    data: folderData,
    isError: folderError,
    isLoading: folderLoading,
  } = useFolder(folderId)

  const renderFolder = () => {
    if (
      !folderId ||
      folderId === '' ||
      folderLoading ||
      !folderData ||
      folderError
    )
      return <Box />

    const { city, country, office_hours, news, sensors, street, zip } =
      folderData

    const RegularMap = ''

    if (!sensors) return <Box />

    return (
      <>
        <Tabs
          centered
          indicatorColor={'primary'}
          onChange={(event, tab) => {
            setTab(tab)
            history.push(`/folder/${folderId}/${tab}`)
          }}
          scrollButtons={'auto'}
          sx={{ mb: 10 }}
          textColor={'primary'}
          value={tab}
        >
          <Tab value={0} label={'Info'} icon={<BusinessTwoTone />} />
          <Tab value={1} label={'Status'} icon={<MemoryTwoTone />} />
          <Tab value={2} label={'News'} icon={<MessageTwoTone />} disabled />
          {/*<Tab value={3} label={'Notfall'} icon={<LocalHospitalTwoTone />} />*/}
          <Tab value={4} label={'Coach'} icon={<FaceTwoTone />} />
        </Tabs>

        {/* info */}
        <Collapse in={tab === 0}>
          {folderLoading && <LinearProgress color={'primary'} />}
          <Collapse in={!folderLoading}>
            <Typography component={'h4'} variant={'h4'}>
              {folderName}
            </Typography>
            <Typography component={'h6'} variant={'h6'}>
              {street}
            </Typography>
            <Typography component={'h6'} variant={'h6'}>
              {zip} {city}
              <br />
              {countries.getName(country, 'de')}
            </Typography>
            {RegularMap && (
              <RegularMap
                googleMapURL={REACT_APP_API_GOOGLEMAPS}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `280px`,
                      borderRadius: '6px',
                      overflow: 'hidden',
                      border: 'solid 1px black',
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            )}
            <OfficeHours data={office_hours} />

            <ContractStatus folderId={folderId} />
          </Collapse>
        </Collapse>

        {/* status */}
        <Collapse in={tab === 1}>
          <Grid container spacing={5}>
            {[...new Set(sensors.map(i => i.name))]
              .sort(SORT)
              .map((item, key) => {
                const sensorTypes = [
                  ...new Set(sensors.filter(f => f.name === item)),
                ].sort()

                let sensor_type = sensors.find(f => f.name === item)
                sensor_type = sensor_type.sensor_type || ''

                let time = sensors.find(f => f.name === item)
                time = time.time || ''

                let type_in_folder = sensors.find(f => f.name === item)
                type_in_folder = type_in_folder.type_in_folder || ''

                const hours =
                  new Date(Date.now() - new Date(time)).getTime() /
                  1000 /
                  60 /
                  60
                const days = Math.trunc(hours / 24)

                let severity = 'success'
                if (days >= 3) severity = 'error'
                if (days >= 1 && days < 3) severity = 'warning'
                if (days <= 0) severity = 'success'

                return (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={key}>
                    <Card sx={{ backgroundColor: 'lightgray' }}>
                      <CardHeader
                        avatar={
                          <Avatar>
                            <Box
                              alt={''}
                              component={'img'}
                              src={SensorIcon(
                                categoryByType(sensor_type, type_in_folder)
                              )}
                              sx={{ width: 40 }}
                            />
                          </Avatar>
                        }
                        title={item}
                      />
                      <CardContent>
                        {severity !== 'success' && (
                          <Alert severity={severity}>
                            {severity === 'warning' && (
                              <>zuletzt aktualisiert vor mehr als 24h</>
                            )}
                            {severity === 'error' && (
                              <>zuletzt aktualisiert vor mehr als 72h</>
                            )}
                          </Alert>
                        )}

                        <List>
                          {sensorTypes.map((item, key) => {
                            const {
                              entity_id,
                              is_active,
                              sensor_type,
                              status,
                              time,
                              type_in_folder,
                              value,
                              unit,
                            } = item

                            let image_url = SensorIcon(
                              normalizedSensorType(sensor_type, type_in_folder),
                              status
                            )

                            if (!image_url)
                              image_url = SensorIcon(
                                type_in_folder,
                                [error, success][is_active]
                              )

                            return (
                              <ListItem
                                button
                                key={key}
                                onClick={() =>
                                  operatorData?.isAdmin &&
                                  history.push(`/sensors/${entity_id}`)
                                }
                                sx={{
                                  cursor: operatorData?.isAdmin
                                    ? 'pointer'
                                    : 'default',
                                  ...(!operatorData?.isAdmin && {
                                    pointerEvents: 'none',
                                    backgroundColor: '#D3D3D3',
                                  }),
                                }}
                              >
                                <ListItemAvatar>
                                  <Avatar src={image_url} width={80} />
                                </ListItemAvatar>

                                <ListItemText
                                  primary={sensorType(
                                    sensor_type,
                                    type_in_folder
                                  )}
                                  secondary={`${value} ${unit} ${getRelativeDate(
                                    String(new Date(Date.parse(time)))
                                  )}`}
                                />
                              </ListItem>
                            )
                          })}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })}
          </Grid>
        </Collapse>

        {/* news */}
        <Collapse in={tab === 2}>
          <FolderNews news={news} />
        </Collapse>

        {/* coach */}
        <Collapse in={tab === 4}>
          <Coach bridgeId={bridgeSensor} />
        </Collapse>
      </>
    )
  }

  const renderSubFolder = () => {
    return !folderLoading && folderData?.folders?.length > 0 ? (
      <Collapse>
        <FormControl sx={{ mb: 5, mt: 10 }} fullWidth>
          <Typography component={'h6'} variant={'h6'}>
            Niederlassungen
          </Typography>
        </FormControl>

        <List>
          {folderData.folders.map((item, key) => {
            const { city, entity_id, image_url, name, street, zip } = item

            return (
              <ListItem
                button
                key={key}
                onClick={() => history.push(`/folder/${entity_id}`)}
              >
                <ListItemAvatar>
                  <Avatar src={image_url} width={80} />
                </ListItemAvatar>

                <ListItemText
                  primary={name}
                  secondary={`${street}, ${zip} ${city}`}
                />

                <IconButton
                  edge={'end'}
                  onClick={() => history.push(`/folder/${entity_id}/edit`)}
                >
                  <EditTwoTone />
                </IconButton>
              </ListItem>
            )
          })}
        </List>
      </Collapse>
    ) : (
      <Box />
    )
  }

  const [actionMenu, showActionMenu] = useState(null)
  const isPageLoading =
    folderLoading ||
    operatorLoading ||
    !folderData ||
    (folderData && Number(folderData.entity_id) !== folderId)
  const isPageError = folderError || operatorError

  if (operatorLoading) return <Box />

  return (
    <>
      <Preloader error={isPageError} isLoading={isPageLoading} />

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Gebäude'}
        open={Boolean(actionMenu)}
        onClose={() => showActionMenu(null)}
      >
        {[
          operatorData?.isAdmin && {
            caption: 'Bearbeiten',
            icon: <EditTwoTone />,
            fn: () => history.push(`/folder/${folderId}/edit`),
          },
          null,
          {
            caption: 'Leistung',
            icon: (
              <SVGStatus
                type={'performance'}
                status={folderStatus.performance}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/performance`),
          },
          {
            caption: 'Anlagen',
            icon: (
              <SVGStatus
                type={'installation'}
                status={folderStatus.installation}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/installation`),
          },
          {
            caption: 'Korrelation',
            icon: (
              <SVGStatus
                type={'correlation'}
                status={folderStatus.correlation}
              />
            ),
            fn: () => history.push(`/folder/${folderId}/home/correlation`),
          },
          {
            caption: 'Sicherheit',
            icon: (
              <SVGStatus type={'security'} status={folderStatus.security} />
            ),
            fn: () => history.push(`/folder/${folderId}/home/security`),
          },
          {
            caption: 'Wohlbefinden',
            icon: <SVGStatus type={'feeling'} status={folderStatus.feeling} />,
            fn: () => history.push(`/folder/${folderId}/home/feeling`),
          },
        ].map((item, key) =>
          item ? (
            <AMItem
              caption={item.caption}
              icon={
                <Stack alignItems={'center'} sx={{ width: 25 }}>
                  {item.icon}
                </Stack>
              }
              key={key}
              onClick={item.fn}
            />
          ) : (
            <Divider key={key} />
          )
        )}
      </AM>

      <KPage
        avatar={
          <Avatar>
            <Box
              component={'img'}
              src={folderImage}
              sx={{ width: 40, height: 40 }}
            />
          </Avatar>
        }
        action={
          <IconButton onClick={event => showActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        isLoading={isPageLoading}
        subheader={''}
        title={
          !folderLoading &&
          folderName + (folderShortName !== null ? ` (${folderShortName})` : '')
        }
      >
        {renderFolder()}
        {renderSubFolder()}
      </KPage>
    </>
  )
}
