import { useEffect, useState } from 'react'
import { useFolderPool } from '..'

export const useBridgeId = folderId => {
  const { data } = useFolderPool()

  const [bridgeSensor, setBridgeSensor] = useState(null)

  useEffect(() => {
    if (data.length === 0) return

    const record = data.find(folder => Number(folder.id) === Number(folderId))
    if (!record?.attributes.folder_sensors?.[0]) return

    const bridgeId = record.attributes.folder_sensors[0].sensor_id
    if (bridgeId) setBridgeSensor(bridgeId)
  }, [data, folderId])

  return bridgeSensor
}
