import {
  AddTwoTone,
  CheckTwoTone,
  DeleteTwoTone,
  MoreVertTwoTone,
  PersonTwoTone,
  SaveTwoTone,
  VpnKeyTwoTone,
} from '@mui/icons-material'
import {
  Avatar,
  Collapse,
  Divider,
  IconButton,
  ListSubheader,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { sensorActions, userActions } from '../../actions'
import { getStorage, isValidPassword, setStorage } from '../../api'
import {
  AM,
  AMItem,
  DlgUserDelete,
  Preloader,
  KPage,
  KTextField,
  DlgUserPicture,
} from '../../components'
import {
  useContractPool,
  useFolderPool,
  useOperator,
  useSensorPool,
  useUser,
} from '../../hooks'
import {
  UserCoreDataMask,
  UserFolderAssignments,
  UserSensorAssignments,
} from './components'

export const UserEdit = ({
  PAGE_CONFIG = 'settings_user_edit',
  serviceName = 'Benutzer',
}) => {
  const theme = useTheme()
  const { white: color } = theme.palette

  // input parameters
  const { id: userId } = useParams()

  // userId present = edit user mode
  // userId missing = create user mode
  const isEditMode = userId ? true : false

  const dispatch = useDispatch()

  // router
  const history = useHistory()
  const URL_USER_NEW = `/users/new`

  // page config

  const [actionMenu, setActionMenu] = useState(null)
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  // pools

  const { isLoading: isContractPoolLoading } = useContractPool()
  const { isLoading: isFolderPoolLoading } = useFolderPool()
  const { isLoading: isSensorPoolLoading } = useSensorPool()

  // operator
  const {
    data: operatorData,
    isError: operatorError,
    isLoading: operatorLoading,
  } = useOperator()

  // user to edit
  const {
    data: userData,
    isError: userError,
    isLoading: userLoading,
  } = useUser(userId)

  // default user object shape
  const [user, setUser] = useState({
    city: '',
    contracts: [],
    country: '',
    email: '',
    email_contact: '',
    fax: '',
    firstname: '',
    folders: [],
    homepage: '',
    image_url: '',
    is_active: true,
    lastname: '',
    password: '',
    role_id: '',
    sensors: [],
    street: '',
    telephone: '',
    telephone_mobile: '',
    zip: '',
  })

  const [isCurrent, setIsCurrent] = useState(false)
  //const [imagePreviewUrl, setImagePreviewUrl] = useState(avatar)

  useEffect(() => {
    if (!userData || !operatorData?.id) return

    const { entity_id } = userData

    setIsCurrent(Number(operatorData.id) === Number(entity_id))
  }, [userData, operatorData])

  useEffect(() => {
    if (!isEditMode || !userData) return
    const { country } = userData

    const countries = require('i18n-iso-countries')
    countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

    setUser({
      ...userData,
      country: country !== null ? country : 'DEU',
    })

    // profile picture
    //setImagePreviewUrl(image_url || avatar)
  }, [userData, isEditMode])

  const isPageLoading =
    operatorLoading ||
    (isEditMode && userLoading) ||
    isFolderPoolLoading ||
    isContractPoolLoading ||
    isSensorPoolLoading

  const isPageError = userError || operatorError

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />

      <DlgUserPicture
        open={!isPageLoading && config.editAvatar}
        onClose={() => setConfig(prev => ({ ...prev, editAvatar: false }))}
        userId={Number(userId)}
      />

      <DlgUserDelete
        onCancel={() => setConfig(prev => ({ ...prev, confirmDelete: false }))}
        onConfirm={() => {
          dispatch(userActions.deleteUser(userId))
          setConfig(prev => ({ ...prev, confirmDelete: false }))
        }}
        open={!isPageLoading && config.confirmDelete}
        id={userId}
        serviceName={serviceName}
      />

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={serviceName}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'users'}
      >
        <AMItem
          caption={'Neu'}
          disabled={!isEditMode}
          icon={<AddTwoTone />}
          onClick={() => history.push(URL_USER_NEW)}
        />

        {isEditMode && (
          <AMItem
            caption={'Speichern'}
            icon={<SaveTwoTone />}
            onClick={() => {
              dispatch(userActions.updateUser(userId, user))
              dispatch(sensorActions.setSensorsByUserId(userId, user.sensors))
            }}
          />
        )}

        <AMItem
          disabled={!isEditMode || isCurrent}
          caption={'Löschen'}
          icon={<DeleteTwoTone />}
          onClick={() => setConfig(prev => ({ ...prev, confirmDelete: true }))}
        />

        {!isEditMode && (
          <AMItem
            caption={'Speichern'}
            icon={<SaveTwoTone />}
            onClick={() => {
              const { firstname, lastname, email, password } = user

              if (firstname && lastname && email && password)
                dispatch(userActions.addUser(user))
            }}
          />
        )}

        <Divider />

        <ListSubheader>Status</ListSubheader>

        <AMItem
          disabled={isCurrent}
          caption={'Aktivieren'}
          icon={
            user.is_active ? <CheckTwoTone /> : <CheckTwoTone sx={{ color }} />
          }
          onClick={() =>
            setUser(prev => ({ ...prev, is_active: !user.is_active }))
          }
        />
      </AM>

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <PersonTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={
            isEditMode
              ? `${serviceName} ID ${userId} bearbeiten`
              : `Neuen ${serviceName} anlegen`
          }
        >
          <Tabs
            centered
            indicatorColor={'primary'}
            onChange={(event, tab) => setConfig(prev => ({ ...prev, tab }))}
            sx={{ mb: 5 }}
            textColor={'primary'}
            value={config.tab}
          >
            <Tab label={'Stammdaten'} />
            <Tab label={'Zuweisungen'} />
            {!isEditMode && <Tab label={'Passwort'} />}
          </Tabs>

          {/* Stammdaten */}
          <Collapse in={config.tab === 0}>
            <UserCoreDataMask
              user={user}
              setUser={setUser}
              isEditMode={isEditMode}
              isCurrent={isCurrent}
              onClickAvatar={() =>
                setConfig(prev => ({ ...prev, editAvatar: true }))
              }
            />
          </Collapse>

          {/* Zuweisungen */}
          <Collapse in={config.tab === 1}>
            {/* Gebäude */}
            <UserFolderAssignments
              config={config}
              setConfig={setConfig}
              user={user}
              setUser={setUser}
            />

            {/* Sensoren */}
            <UserSensorAssignments
              config={config}
              setConfig={setConfig}
              user={user}
              setUser={setUser}
            />
          </Collapse>

          {/* Password (create mode only) */}
          <Collapse in={config.tab === 2 && !isEditMode}>
            <KTextField
              error={!isValidPassword(user.password)}
              helperText={
                isValidPassword(user.password)
                  ? ''
                  : 'Mindestlänge 8, Zahlen, Groß- und Kleinbuchstaben erforderlich'
              }
              icon={<VpnKeyTwoTone />}
              label={'Password'}
              onChange={event =>
                setUser(prev => ({ ...prev, password: event.target.value }))
              }
              type={'password'}
            />
          </Collapse>
        </KPage>
      )}
    </>
  )
}

UserEdit.propTypes = {
  PAGE_CONFIG: PropTypes.string,
  serviceName: PropTypes.string,
}
