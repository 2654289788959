import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

export const Weather = ({ city }) => {
  if (city === '') city = 'Ludwigsburg'

  const appId = '4de3768c62b67fe359758977a3efc069'
  const lang = 'de'
  const units = 'metric'

  const [isLoaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [weather, setWeather] = useState([])

  useEffect(() => {
    const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${appId}&lang=${lang}&units=${units}`

    fetch(url)
      .then(result => result.json())
      .then(result => {
        const { main, name, weather } = result
        const { description, icon } = weather[0]
        const { temp } = main

        setWeather({ description, icon, name, temp })
      })
      .then(() => setLoaded(true))
      .catch(error => setError(error))
  }, [city])

  if (!isLoaded) return <>Wetterdaten werden geladen...</>
  if (error) return <>Keine Wetterdaten verfügbar</>

  return (
    <Box
      sx={{
        position: 'relative',
        mt: '15px',
        textAlign: 'right',
        m: '0 50px 0 0',
      }}
    >
      <Box>
        {weather.name}
        <Box
          alt={'Wetter Icon'}
          component={'img'}
          src={`https://openweathermap.org/img/wn/${weather.icon}.png`}
          sx={{ position: 'absolute', top: '-20px' }}
          title={'Wohlbefinden'}
        />
      </Box>
      <Box>
        {Math.round(weather.temp)}
        &deg;C, {weather.description}
      </Box>
    </Box>
  )
}

Weather.propTypes = {
  city: PropTypes.string,
}
