import {
  AdminPanelSettingsTwoTone,
  EmojiPeopleTwoTone,
  PersonTwoTone,
  SchoolTwoTone,
} from '@mui/icons-material'
import {
  Avatar,
  Badge,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { DDMMYYYYHHMM, getInitials, userLocales } from '../../api'
import { userConstants as UC } from '../../constants'

export const UserListRow = ({ user }) => {
  const theme = useTheme()
  const success = theme.palette.success.main
  const warning = theme.palette.warning.main
  const bgcolor = theme.palette.primary.main

  const history = useHistory()

  const { attributes, id } = user

  const {
    created_at,
    email,
    firstname,
    image_url,
    is_active,
    label,
    lastname,
    updated_at,
  } = attributes

  const anchorOrigin = { horizontal: 'right', vertical: 'bottom' }
  const badgeContent = (
    <span
      style={{
        color: '#666',
        backgroundColor: [warning, success][is_active],
        borderRadius: '50%', // Hier wird es rund
        padding: '1px', // Optional, um etwas Platz drum herum zu haben
        display: 'inline-block', // Damit das Padding auch wirklich wirkt
      }}
    >
      {label === UC.USER_ROLE_GUEST && <EmojiPeopleTwoTone />}
      {label === UC.USER_ROLE_USER && <PersonTwoTone />}
      {label === UC.USER_ROLE_COACH && <SchoolTwoTone />}
      {label === UC.USER_ROLE_ADMIN && <AdminPanelSettingsTwoTone />}
    </span>
  )

  const USERNAME = `${firstname} ${lastname}`
  const AVATAR_PHOTO = <Avatar alt={''} src={image_url} />
  const AVATAR_TEXT = <Avatar sx={{ bgcolor }}>{getInitials(USERNAME)}</Avatar>

  const CREATED_AT = new Date(created_at).toLocaleString(
    userLocales,
    DDMMYYYYHHMM
  )

  const UPDATED_AT = new Date(updated_at).toLocaleString(
    userLocales,
    DDMMYYYYHHMM
  )

  const gotoUserEdit = () => history.push(`/users/${id}/edit`)

  const userAvatar = image_url ? (
    <Badge
      anchorOrigin={anchorOrigin}
      badgeContent={badgeContent}
      overlap={'circular'}
    >
      {AVATAR_PHOTO}
    </Badge>
  ) : (
    <Badge
      anchorOrigin={anchorOrigin}
      badgeContent={badgeContent}
      overlap={'circular'}
    >
      {AVATAR_TEXT}
    </Badge>
  )

  return (
    <ListItem sx={{ p: 0 }}>
      <ListItemButton onClick={gotoUserEdit}>
        <ListItemAvatar>{userAvatar}</ListItemAvatar>

        <Divider flexItem orientation={'vertical'} />

        <ListItemText primary={USERNAME} sx={{ pl: 1, width: 0.2 }} />

        <ListItemText primary={`ID: ${id}`} />

        <ListItemText primary={email} sx={{ pl: 1, width: 0.3 }} />

        <ListItemText primary={`erstellt: ${CREATED_AT}`} />
        <ListItemText primary={`aktualisiert: ${UPDATED_AT}`} />
      </ListItemButton>
    </ListItem>
  )
}

UserListRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      aws_id: PropTypes.string,
      created_at: PropTypes.string,
      email: PropTypes.string,
      firstname: PropTypes.string,
      image_url: PropTypes.string,
      is_active: PropTypes.number,
      label: PropTypes.string,
      lastname: PropTypes.string,
      updated_at: PropTypes.string,
    }),
  }),
}
