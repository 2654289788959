import PropTypes from 'prop-types'
import React, { useEffect, useState, useContext, createContext } from 'react'
import { getStorage, setStorage } from '../../api'

const appConfigContext = createContext({})

export const ProvideAppConfig = ({ children }) => {
  const value = useConfig()

  return (
    <appConfigContext.Provider value={value}>
      {children}
    </appConfigContext.Provider>
  )
}
ProvideAppConfig.propTypes = { children: PropTypes.any }

export const useAppConfig = () => useContext(appConfigContext)

const useConfig = () => {
  const PAGE_CONFIG = 'settings_global'
  const [appConfig, setAppConfig] = useState({})

  const daytimeGreeting = () => {
    const h = new Date().getHours()

    if (h >= 0 && h < 10) return 'Guten Morgen'
    else if (h >= 10 && h < 18) return 'Guten Tag'
    else return 'Guten Abend'
  }

  useEffect(() => setAppConfig(getStorage(PAGE_CONFIG)), [])
  useEffect(() => setStorage(PAGE_CONFIG, appConfig), [appConfig])

  return { daytimeGreeting, appConfig, setAppConfig }
}
