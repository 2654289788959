import { GetAppTwoTone } from '@mui/icons-material'
import {
  Button,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const DlgReportResult = ({
  caption = 'Zusammenfassung',
  severity,
  status,
  title,
}) => {
  const [showDialog, setShowDialog] = useState(true)

  const onClose = () => setShowDialog(false)

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogTitle>{caption}</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Alert severity={severity}>
          <AlertTitle>{title}</AlertTitle>

          {severity === 'success' && (
            <form method={'get'} action={status}>
              <Button type={'submit'}>
                <GetAppTwoTone /> PDF
              </Button>
            </form>
          )}

          {severity === 'error' && status}
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Fertig</Button>
      </DialogActions>
    </Dialog>
  )
}

DlgReportResult.propTypes = {
  caption: PropTypes.string,
  severity: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,
}
