import { HomeTwoTone } from '@mui/icons-material'
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Brand } from '../components'
import { useOperator } from '../hooks'
import routes from '../routes/routes'
import { getStorage } from '../api'

export const MainMenuItem = ({ route }) => {
  const { icon, name, path } = route

  const theme = useTheme()
  const color = theme.palette.white

  const history = useHistory()
  const gotoPath = () => history.push(path)

  const selected = useLocation().pathname.includes(path)

  return (
    <ListItemButton onClick={gotoPath} selected={selected}>
      <ListItemIcon sx={{ color }}>{icon}</ListItemIcon>

      <ListItemText primary={name} sx={{ color }} />
    </ListItemButton>
  )
}

MainMenuItem.propTypes = {
  route: PropTypes.object.isRequired,
}

const DevReleaseInfo = () => {
  const theme = useTheme()
  const [buildInfo, setBuildInfo] = useState(null)
  useEffect(() => setBuildInfo(getStorage('buildInfo')), [])

  const isProd = buildInfo?.environment === 'production'
  const isMaster = buildInfo?.branch === 'master'
  const isRelease = isProd && isMaster

  return !isRelease ? (
    <Stack
      sx={{
        bgcolor: theme.palette.primary.dark,
        bottom: 0,
        color: theme.palette.primary.contrastText,
        position: 'absolute',
        px: 1,
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Typography sx={{ fontSize: 12 }}>
        branch : {buildInfo?.branch}
      </Typography>
      <Typography sx={{ fontSize: 12 }}>
        env : {buildInfo?.environment}
      </Typography>
      <Typography sx={{ fontSize: 12 }}>build : {buildInfo?.build}</Typography>
    </Stack>
  ) : null
}

export const Sidebar = () => {
  const theme = useTheme()
  const color = theme.palette.white

  const history = useHistory()
  const { data: operatorData, isLoading } = useOperator()

  const gotoHome = () => history.push('/')

  return (
    <Drawer anchor={'left'} open variant={'permanent'}>
      <Brand />

      {isLoading ? null : (
        <List sx={{ mt: 2, p: 0 }}>
          {/* Dashboard */}
          <ListItemButton onClick={gotoHome}>
            <ListItemIcon sx={{ color }}>
              <HomeTwoTone />
            </ListItemIcon>

            <ListItemText primary={'Gebäudeübersicht'} sx={{ color }} />
          </ListItemButton>

          {routes.map((route, key) => {
            const { hidden, isAdmin: adminRoute, redirect } = route

            const isHidden =
              hidden || redirect || (adminRoute && !operatorData?.isAdmin)

            return isHidden ? null : <MainMenuItem key={key} route={route} />
          })}
        </List>
      )}

      <DevReleaseInfo />
    </Drawer>
  )
}
