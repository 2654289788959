import { MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const GranularitySelector = ({
  data = [
    { title: 'Stündlich', value: 'd' },
    { title: 'Täglich', value: 'm' },
    { title: 'Monatlich', value: 'y' },
  ],
  title = 'Granularität',
  variant = 'standard',
  ...rest
}) => (
  <TextField label={title} select variant={variant} {...rest}>
    {data.map((item, key) => (
      <MenuItem key={key} value={item.value}>
        {item.title}
      </MenuItem>
    ))}
  </TextField>
)

GranularitySelector.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  variant: PropTypes.string,
}
