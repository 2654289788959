import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { consumptionActions } from '../../actions'

export const useMonthlyConsumptionToDate = folderId => {
  const dispatch = useDispatch()

  const endDate = new Date()
  endDate.setMonth(new Date().getMonth() - 1)

  const fromDate = endDate.toISOString().split('T')[0]
  const toDate = new Date().toISOString().split('T')[0]

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const { consumptionMonthly } = useSelector(state => state.consumption)

  useEffect(() => {
    if (Number(folderId) > 0) {
      setLoading(true)
      dispatch(consumptionActions.getAll(folderId, 'd', fromDate, toDate, true))
    }
  }, [dispatch, folderId, fromDate, toDate])

  useEffect(() => {
    if (!consumptionMonthly) return

    const { error, item } = consumptionMonthly

    if (item) {
      const { data } = item
      if (!data) return
      setData(
        data.map(item => ({
          ...item.attributes,
          entity_id: Number(item.id),
        }))
      )
      setLoading(false)
    }

    if (error) {
      const { message } = error

      setError(message)
      setLoading(false)
    }
  }, [consumptionMonthly])

  return { isLoading, isError, data }
}
