import { useEffect, useState } from 'react'
import { useUserPool } from '..'

export const useEmailDomains = () => {
  const [data, setData] = useState([])

  const { data: userPool } = useUserPool()

  useEffect(() => {
    const domains = [
      ...new Set(
        userPool
          .map(item => item.attributes.email)
          .map(email => email.substring(email.indexOf('@')))
          .sort()
      ),
    ]

    setData(domains)
  }, [userPool])

  return data
}
