import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import Chart from 'react-google-charts'
import { userLocales } from '../../../api'

export const TimeSeriesChart = ({
  backgroundColor = 'none',
  chartArea = { height: '75%', width: '80%' },
  chartType = 'AreaChart',
  curveType = 'function',
  data,
  height = '400px',
  legend = 'none',
  medium,
  minValue = 0,
  textStyle = { fontSize: 10 },
  unit,
  series,
}) => {
  const theme = useTheme()
  const correlation = theme.palette.correlation.main
  const colors = [correlation]

  if (data && data.length > 0) {
    // data records contain 2 columns: [date, value]
    // add 3rd column caption for the tooltip: [date, value, tooltip]
    if (data[0].length === 2)
      data[0].push({ role: 'tooltip', type: 'string', p: { html: true } })

    // flood all records with 3rd column
    for (let i = 1; i < data.length; i++)
      if (data[i].length === 2) {
        const timestamp = new Date(data[i][0]).toLocaleTimeString(userLocales, {
          weekday: 'long',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        const value = Number(data[i][1]).toLocaleString(userLocales)

        data[i].push(
          `<span style="margin:0px;padding:0px">${timestamp}: <strong>${value} ${unit}</strong></span>`
        )
      }
  }

  return (
    <Chart
      chartLanguage={'de_DE'}
      chartType={chartType}
      data={data}
      height={height}
      options={{
        backgroundColor,
        colors,
        chartArea,
        curveType,
        hAxis: { textStyle },
        legend,
        // render tooltip with html (vs svg)
        tooltip: { isHtml: true, trigger: 'visible' },
        vAxis: {
          format: medium !== 'humidity' ? `# ${unit}` : "#.#'%'",
          minValue,
          textStyle,
        },
        series: series ? series : {},
      }}
    />
  )
}

TimeSeriesChart.propTypes = {
  chartArea: PropTypes.object,
  curveType: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  chartType: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  height: PropTypes.string,
  legend: PropTypes.string,
  medium: PropTypes.string.isRequired,
  minValue: PropTypes.number,
  textStyle: PropTypes.object,
  unit: PropTypes.string.isRequired,
  series: PropTypes.object,
}
