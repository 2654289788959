import { HighlightOffTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const BtnClearFilter = ({ onClick, title = 'Filter löschen' }) => (
  <Tooltip title={title}>
    <IconButton onClick={onClick}>
      <HighlightOffTwoTone />
    </IconButton>
  </Tooltip>
)

BtnClearFilter.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
}
