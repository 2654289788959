import { MemoryTwoTone, TroubleshootTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { sensorType } from '../../api'
import { useSecuritySensors, useSensorIcon } from '../../hooks'
import { ToggleDateType } from '..'

export const SecurityRoomSensor = ({ folderId, onClick, sensorId }) => {
  const sSensors = useSecuritySensors(folderId)
  const record = sSensors.find(f => f.entity_id === sensorId)

  const {
    sensor_type,
    status,
    sub_sensor_target: sst,
    time,
    type_in_folder,
    value,
    unit,
  } = record ?? {}

  const image_url = useSensorIcon(sensorId, status)
  const hrType = sensorType(sensor_type, type_in_folder, sst)
  const hrUnit = unit ?? ''
  const timestamp = new Date(Date.parse(time))

  const SENSOR_SUPPORTS_HISTORY =
    sensor_type === 'pressure' ||
    sensor_type === 'temperature' ||
    sensor_type === 'temperature_0' ||
    sensor_type === 'temperature_1' ||
    sensor_type === 'Ni1000' ||
    sensor_type === 'degrees-celsius'

  const SENSOR_SUPPORTS_VALUE =
    sensor_type !== 'waterDetected' &&
    sensor_type !== 'smokeDetektor' &&
    sensor_type !== 'external_digital_button'

  return sSensors.length === 0 ? null : (
    <ListItem>
      <ListItemAvatar>
        <Avatar src={image_url} width={80}>
          {!image_url && <MemoryTwoTone />}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={hrType}
        secondary={<ToggleDateType date={timestamp} />}
        sx={{ width: 0.7 }}
      />

      <ListItemText
        disableTypography
        primary={SENSOR_SUPPORTS_VALUE && `${value} ${hrUnit}`}
        sx={{
          display: 'flex',
          fontWeight: 'bold',
          justifyContent: 'flex-end',
          mr: 2,
          width: 0.3,
        }}
      />

      {SENSOR_SUPPORTS_HISTORY && (
        <>
          <Divider flexItem orientation={'vertical'} sx={{ mr: 2 }} />

          <Tooltip title={`Details ${hrType}`}>
            <IconButton onClick={onClick}>
              <TroubleshootTwoTone />
            </IconButton>
          </Tooltip>
        </>
      )}
    </ListItem>
  )
}

SecurityRoomSensor.propTypes = {
  folderId: PropTypes.number,
  onClick: PropTypes.func,
  sensorId: PropTypes.number,
}
