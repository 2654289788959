import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sensorPriceActions } from '../../actions'

const sensorPriceContext = createContext({})

export const ProvideSensorPrices = ({ children }) => {
  const value = useSensorPrices()

  return (
    <sensorPriceContext.Provider value={value}>
      {children}
    </sensorPriceContext.Provider>
  )
}
ProvideSensorPrices.propTypes = { children: PropTypes.any }

export const useSensorPricePool = () => useContext(sensorPriceContext)

const useSensorPrices = () => {
  const dispatch = useDispatch()
  const { allSensorPrice } = useSelector(state => state.sensorPrice)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(() => {
    dispatch(sensorPriceActions.getAll())
    setLoading(true)
  }, [dispatch])

  useEffect(() => {
    if (allSensorPrice) {
      const { error, fetchDate, item } = allSensorPrice

      if (item) {
        const { data } = item

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    }
  }, [allSensorPrice])

  return { data, isError, isLoading, fetchDate }
}
