import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material'
import { AnnouncementTwoTone } from '@mui/icons-material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { DDMMYYYYHHMM, userLocales } from '../../api'
import { useContractPool, useFolderPool } from '../../hooks'

export const NewsTile = ({ news }) => {
  const theme = useTheme()
  const success = theme.palette.success.main
  const warning = theme.palette.warning.main

  const { data: folderPool } = useFolderPool()
  const { data: contractPool } = useContractPool()
  const history = useHistory()

  const { author, contract_id, folder_id, headline, label, start_time, title } =
    news.attributes

  const folderData = folderPool.find(f => Number(f.id) === folder_id)
  const contractData = contractPool.find(f => Number(f.id) === contract_id)

  let name = ''

  if (contractData) name += contractData.attributes.name
  else if (folderData) name += folderData.attributes.name
  else name = `(ID existiert nicht)`

  const RELEASED_AT = start_time
    ? new Date(start_time).toLocaleString(userLocales, DDMMYYYYHHMM)
    : 'unveröffentlicht'

  return (
    <ListItemButton onClick={() => history.push(`/news/${news.id}/edit`)}>
      <ListItemAvatar>
        <Tooltip title={`News ID: ${news.id}`}>
          <Avatar sx={{ bgcolor: !start_time ? warning : success }}>
            <AnnouncementTwoTone />
          </Avatar>
        </Tooltip>
      </ListItemAvatar>

      <ListItemText
        primary={title}
        secondary={headline}
        sx={{ width: '45%' }}
      />

      <Divider flexItem orientation={'vertical'} />

      <ListItemText
        primary={
          folder_id ? 'Gebäude' : contract_id ? 'Vertrag' : 'unspezifiziert'
        }
        secondary={name}
        sx={{ pl: 1, width: '15%' }}
      />

      <Divider flexItem orientation={'vertical'} />

      <ListItemText
        primary={'Rubrik'}
        secondary={label || 'unspezifiziert'}
        sx={{ pl: 1, width: '15%' }}
      />

      <Divider flexItem orientation={'vertical'} />

      <ListItemText
        primary={`released: ${RELEASED_AT}`}
        secondary={author && `Autor: ${author}`}
        sx={{ pl: 1, width: '25%' }}
      />
    </ListItemButton>
  )
}

NewsTile.propTypes = {
  news: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      author: PropTypes.string,
      contract_id: PropTypes.number,
      created_at: PropTypes.string,
      folder_id: PropTypes.number,
      headline: PropTypes.string,
      label: PropTypes.string,
      start_time: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
}
