import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'
import { svgConstants as SVG } from '../../constants'

export const useSensorIcon = (sensorId, status = 'neutral', isOpen) => {
  const [data, setData] = useState('')

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const result = sensorPool.find(sensor => Number(sensor.id) === sensorId)
    const sensorType = result?.attributes?.sensor_type
    const sst = result?.attributes?.sub_sensor_target

    // not enough info to continue
    if (!sensorType) return

    const getSensorIcon = () => {
      switch (sensorType) {
        case 'energy':
        case 'energy_detail':
        case 'gas':
        case 'gas_detail':
        case 'water':
        case 'water_detail':
          switch (status) {
            case 'green':
              return SVG.CONSUMPTION_GOOD

            case 'yellow':
              return SVG.CONSUMPTION_WARNING

            case 'red':
              return SVG.CONSUMPTION_ALERT

            default:
              return SVG.CONSUMPTION_INACTIVE
          }

        case 'co2':
          switch (status) {
            case 'green':
              return SVG.CO2_GOOD

            case 'yellow':
              return SVG.CO2_WARNING

            case 'red':
              return SVG.CO2_ALERT

            default:
              return SVG.CO2_INACTIVE
          }

        case 'humidity':
          switch (status) {
            case 'green':
              return SVG.HUMIDITY_GOOD

            case 'yellow':
              return SVG.HUMIDITY_WARNING

            case 'red':
              return SVG.HUMIDITY_ALERT

            default:
              return SVG.HUMIDITY_INACTIVE
          }

        case 'light':
          switch (status) {
            case 'green':
              return SVG.LIGHT_GOOD

            case 'yellow':
              return SVG.LIGHT_WARNING

            case 'red':
              return SVG.LIGHT_ALERT

            default:
              return SVG.LIGHT_INACTIVE
          }

        case 'temperature':
          switch (status) {
            case 'green':
              return SVG.TEMPERATURE_GOOD

            case 'yellow':
              return SVG.TEMPERATURE_WARNING

            case 'red':
              return SVG.TEMPERATURE_ALERT

            default:
              return SVG.TEMPERATURE_INACTIVE
          }

        // Rücklauf Temperatur und Anlagendruck
        case 'external_temp_1':
        case 'pressure':
        case 'temperature_0':
        case 'temperature_1':
        case 'Ni1000':
        case 'degrees-celsius':
          switch (status) {
            case 'green':
              return SVG.TEMPERATURE_GOOD

            case 'yellow':
              return SVG.TEMPERATURE_WARNING

            case 'red':
              return SVG.TEMPERATURE_ALERT

            default:
              return SVG.TEMPERATURE_INACTIVE
          }

        // Türschloss
        case 'tor1CurrentState':
        case 'tor1PreviousState':
          switch (status) {
            case 'green':
              return SVG.DOOR_LOCKED_GOOD

            case 'yellow':
              return SVG.DOOR_LOCKED_WARNING

            case 'red':
              return SVG.DOOR_LOCKED_ALERT

            default:
              return SVG.DOOR_LOCKED_INACTIVE
          }

        // Tür
        case 'door':
        case 'door_channel1':
        case 'door_channel2':
        case 'door_channel3':
        case 'door_channel4':
        case 'tor2CurrentState':
        case 'tor2PreviousState':
          switch (status) {
            case 'green':
              return isOpen ? SVG.DOOR_OPEN_GOOD : SVG.DOOR_CLOSED_GOOD

            case 'yellow':
              return isOpen ? SVG.DOOR_OPEN_WARNING : SVG.DOOR_CLOSED_WARNING

            case 'red':
              return isOpen ? SVG.DOOR_OPEN_ALERT : SVG.DOOR_CLOSED_ALERT

            default:
              return isOpen ? SVG.DOOR_OPEN_INACTIVE : SVG.DOOR_CLOSED_INACTIVE
          }

        // Wasserdetektor
        case 'waterDetected':
          switch (status) {
            case 'green':
              return SVG.WATER_DETECTOR_GOOD

            case 'yellow':
              return SVG.WATER_DETECTOR_WARNING

            case 'red':
              return SVG.WATER_DETECTOR_ALERT

            default:
              return SVG.WATER_DETECTOR_INACTIVE
          }

        // Aufzug
        case 'elevator':
          switch (status) {
            case 'green':
              return SVG.ELEVATOR_GOOD

            case 'red':
              return SVG.ELEVATOR_ALERT

            default:
              return SVG.ELEVATOR_INACTIVE
          }

        case 'external_digital_button':
          if (sst === 'elevator')
            switch (status) {
              case 'green':
                return SVG.ELEVATOR_GOOD

              case 'red':
                return SVG.ELEVATOR_ALERT

              default:
                return SVG.ELEVATOR_INACTIVE
            }
          if (sst === 'lifting')
            switch (status) {
              case 'green':
                return SVG.LIFTING_STATION_GOOD

              case 'red':
                return SVG.LIFTING_STATION_ALERT

              case 'yellow':
                return SVG.LIFTING_STATION_WARNING

              default:
                return SVG.LIFTING_STATION_INACTIVE
            }
          break

        // Technische Anlagen
        case 'lifting':
          switch (status) {
            case 'green':
              return SVG.LIFTING_STATION_GOOD

            case 'red':
              return SVG.LIFTING_STATION_ALERT

            case 'yellow':
              return SVG.LIFTING_STATION_WARNING

            default:
              return SVG.LIFTING_STATION_INACTIVE
          }

        // Rauchmelder
        case 'smokeDetektor':
        case 'Smoke_alarm':
        case 'Battery_end_of_life':
        case 'Removal_detection':
        case 'Acoustic_alarm_failure':
          switch (status) {
            case 'green':
              return SVG.SMOKE_DETECTOR_GOOD

            case 'red':
              return SVG.SMOKE_DETECTOR_ALERT

            case 'yellow':
              return SVG.SMOKE_DETECTOR_WARNING

            default:
              return SVG.SMOKE_DETECTOR_INACTIVE
          }

        default:
          console.error('unhandled sensor type', sensorType)
          return SVG.UNKNOWN_SENSOR
      }
    }

    setData(getSensorIcon())
  }, [sensorPool, sensorId, isOpen, status])

  return data
}

useSensorIcon.propTypes = {
  sensorId: PropTypes.number,
}
