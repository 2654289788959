export const sensorBenchmarkConstants = {
  BENCHMARK_DELETED: 'Benchmark gelöscht',
  BENCHMARK_ID_REQUIRED: 'ID erforderlich',
  BENCHMARK_NAME_REQUIRED: 'Name erforderlich',
  BENCHMARK_SAVED: 'Benchmark gespeichert',
  BENCHMARK_UPDATED: 'Benchmark aktualisiert',
  GET_BY_ID_ERROR: 'BENCHMARK_GET_BY_ID_ERROR',
  GET_BY_ID_REQUEST: 'BENCHMARK_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'BENCHMARK_GET_BY_ID_SUCCESS',
  REMOVE_BENCHMARK_ERROR: 'REMOVE_BENCHMARK_ERROR',
  REMOVE_BENCHMARK_REQUEST: 'REMOVE_BENCHMARK_REQUEST',
  REMOVE_BENCHMARK_SUCCESS: 'REMOVE_BENCHMARK_SUCCESS',
  SAVE_BENCHMARK_ERROR: 'SAVE_BENCHMARK_ERROR',
  SAVE_BENCHMARK_REQUEST: 'SAVE_BENCHMARK_REQUEST',
  SAVE_BENCHMARK_SUCCESS: 'SAVE_BENCHMARK_SUCCESS',
  UPDATE_BENCHMARK_ERROR: 'UPDATE_BENCHMARK_ERROR',
  UPDATE_BENCHMARK_REQUEST: 'UPDATE_BENCHMARK_REQUEST',
  UPDATE_BENCHMARK_SUCCESS: 'UPDATE_BENCHMARK_SUCCESS',
  GET_BENCHMARKS_BY_SENSOR_ID_ERROR: 'GET_BENCHMARK_BY_SENSOR_ID_ERROR',
  GET_BENCHMARKS_BY_SENSOR_ID_REQUEST: 'GET_BENCHMARK_BY_SENSOR_ID_REQUEST',
  GET_BENCHMARKS_BY_SENSOR_ID_SUCCESS: 'GET_BENCHMARK_BY_SENSOR_ID_SUCCESS',
}
