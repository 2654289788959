import { CloseTwoTone } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { HStack, ToggleDateType } from '../../components'
import { useNotificationPool } from '../../hooks'

const StatRecord = ({ label, value, ...rest }) => (
  <HStack spacing={2} sx={{ width: 1 }} {...rest}>
    <Stack sx={{ flex: 1, textAlign: 'right' }}>
      <Typography>{label}</Typography>
    </Stack>

    <Stack sx={{ flex: 1, textAlign: 'left' }}>
      <Typography>{value}</Typography>
    </Stack>
  </HStack>
)

StatRecord.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
}

const NotificationPoolStat = () => {
  const { data: notificationPool, fetchDate } = useNotificationPool()

  return (
    <Stack sx={{ mt: 3 }}>
      <StatRecord label={'Poolgröße'} value={notificationPool.length} />

      {[
        {
          label: 'Verwendung in Gebäuden',
          value: [...new Set(notificationPool.map(i => i.folder_id))].length,
        },
        {
          label: 'Überwachte Sensoren',
          value: [...new Set(notificationPool.map(i => i.sensor_id))].length,
        },
        {
          label: 'E-Mail Topics',
          value: [...new Set(notificationPool.map(i => i.email_topic))].length,
        },
        {
          label: 'Identifiers',
          value: [...new Set(notificationPool.map(i => i.identifier))].length,
        },
      ].map((item, key) => (
        <StatRecord key={key} label={item.label} value={item.value} />
      ))}

      <StatRecord
        label={'gefetcht'}
        sx={{ mb: 1, mt: 2, pr: 1, textAlign: 'right' }}
        value={<ToggleDateType date={fetchDate} />}
      />
    </Stack>
  )
}

export const DlgNotificationPoolInfo = ({
  onClose,
  open,
  title = 'Benachrichtigungen Pool Info',
}) => (
  <Dialog fullWidth open={open}>
    <DialogTitle
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ fontSize: 20 }}>{title}</Typography>

      <IconButton
        onClick={onClose}
        sx={{
          '&:hover': { transform: 'rotate(90deg)' },
          transform: 'rotate(0deg)',
          transition: 'transform 0.2s',
        }}
        variant={'close'}
      >
        <CloseTwoTone />
      </IconButton>
    </DialogTitle>

    <DialogContent>
      <NotificationPoolStat />
    </DialogContent>
  </Dialog>
)

DlgNotificationPoolInfo.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}
