import { TroubleshootTwoTone } from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  categoryByType,
  getRelativeDate,
  normalizedSensorType,
  SensorIcon,
  SVGStatus,
} from '../../api'

const InstallationMenuItem = ({ item, onClick }) => {
  const theme = useTheme()
  const success = theme.palette.success.main
  const error = theme.palette.error.main

  const {
    name,
    is_active,
    sensor_type,
    status,
    time,
    type_in_folder,
    value,
    unit,
  } = item

  const nst = normalizedSensorType(sensor_type, type_in_folder)

  let image_url = SensorIcon(nst, status)
  if (!image_url)
    image_url = SensorIcon(type_in_folder, [error, success][is_active])

  return (
    <ListItem
      button={sensor_type !== null || sensor_type !== undefined}
      onClick={onClick}
    >
      <ListItemAvatar>
        <Avatar src={image_url} width={80} />
      </ListItemAvatar>

      <ListItemText
        primary={name}
        secondary={`${value} ${unit ? unit : ''} ${getRelativeDate(
          String(new Date(Date.parse(time)))
        )}`}
      />
    </ListItem>
  )
}

InstallationMenuItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
}

export const InstallationMenu = ({
  folderId,
  installationId,
  name,
  status,
  severity,
  sensors,
}) => {
  const history = useHistory()

  const onGotoInstallation = () =>
    history.push(`/folder/${folderId}/installation/${installationId}`)

  return (
    <Card variant={'installation'}>
      <CardHeader
        avatar={
          <Avatar>
            <SVGStatus type={'installation'} status={status} />
          </Avatar>
        }
        action={
          <Tooltip title={`Details ${name}`}>
            <IconButton onClick={onGotoInstallation}>
              <TroubleshootTwoTone sx={{ mr: 1 }} />{' '}
            </IconButton>
          </Tooltip>
        }
        title={name}
        titleTypographyProps={{ variant: 'h6' }}
        variant={'installation_overview'}
      />
      <CardContent sx={{ p: '0px !important' }}>
        {severity !== 'success' && (
          <Alert severity={severity}>
            {severity === 'warning' && (
              <>zuletzt aktualisiert vor mehr als 24h</>
            )}
            {severity === 'error' && <>zuletzt aktualisiert vor mehr als 72h</>}
          </Alert>
        )}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Typography align={'center'} sx={{ mt: 1 }}>
              Messpunkte
            </Typography>
            <List>
              {sensors
                .filter(f => categoryByType(f.sensor_type) === 'performance')
                .map((item, key) => (
                  <InstallationMenuItem
                    item={item}
                    key={key}
                    onClick={onGotoInstallation}
                  />
                ))}
              {sensors
                .filter(
                  f =>
                    categoryByType(f.sensor_type) === 'feeling' ||
                    categoryByType(f.sensor_type) === 'security'
                )
                .map((item, key) => (
                  <InstallationMenuItem
                    item={item}
                    key={key}
                    onClick={onGotoInstallation}
                  />
                ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography align={'center'} sx={{ mt: 1 }}>
              Kennzahlen
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

InstallationMenu.propTypes = {
  folderId: PropTypes.number,
  installationId: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.string,
  sensors: PropTypes.array,
  severity: PropTypes.string,
}
