import { ExpandMoreTwoTone } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  List,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import {
  colorSchemeSecurity,
  normalizedSensorType,
  sensorType,
} from '../../api'
import { useFolderSensors } from '../../hooks'
import { CorrelationSecurityItem, HStack } from '..'

export const AccSecurity = ({
  folderId,
  sensor_type,
  securitySensors,
  selectedSensors,
  setSelectedSensors,
  selectedFeelingSensors,
  setFeelingSensorId,
  changeFeelingSensors,
  selectedBaselineFeelingSensors,
  setSelectedFeelingSensors,
  setSelectedBaselineFeelingSensors,
}) => {
  const folderSensors = useFolderSensors(folderId)

  const sSensors = securitySensors.filter(
    s => normalizedSensorType(s.sensor_type) === sensor_type
  )

  const ALL_SELECTED = sSensors.every(sensor =>
    selectedFeelingSensors.includes(sensor.entity_id)
  )

  const SOME_SELECTED = sSensors.some(sensor =>
    selectedFeelingSensors.includes(sensor.entity_id)
  )

  const onToggleAll = event => {
    event.stopPropagation()

    let _selectedSensors = [...selectedFeelingSensors]
    let _selectedBaselineFeelingSensors = [...selectedBaselineFeelingSensors]

    sSensors.forEach(sensor => {
      const sensorId = Number(sensor.entity_id)

      if (!ALL_SELECTED) {
        _selectedSensors.push(sensorId)
      } else {
        _selectedSensors = _selectedSensors.filter(item => item !== sensorId)
        _selectedBaselineFeelingSensors =
          _selectedBaselineFeelingSensors.filter(f => f.entity_id !== sensorId)
      }
    })

    setSelectedFeelingSensors(_selectedSensors)
    setSelectedBaselineFeelingSensors(_selectedBaselineFeelingSensors)
  }

  const borderLeft = `solid 10px ${colorSchemeSecurity[0]}`

  const isConsumptionSensor = ['energy', 'gas', 'water'].includes(sensor_type)

  const FILTER_SENSOR_TYPE = f =>
    normalizedSensorType(f.sensor_type, f.type_in_folder) === sensor_type ||
    normalizedSensorType(f.sensor_type, f.type_in_folder) ===
      `${sensor_type}_detail`

  const isSelected = id =>
    isConsumptionSensor
      ? selectedSensors.includes(id)
      : selectedFeelingSensors.includes(id)

  return (
    <Accordion sx={{ '&.Mui-expanded': { m: '0 !important' } }}>
      <AccordionSummary
        expandIcon={<ExpandMoreTwoTone />}
        sx={{ borderLeft, justifyContent: 'space-between' }}
      >
        <HStack justifyContent={'space-between'} sx={{ width: 1 }}>
          <Typography>{sensorType(sensor_type)}</Typography>

          <Checkbox
            checked={ALL_SELECTED}
            color={'primary'}
            indeterminate={SOME_SELECTED && !ALL_SELECTED}
            onClick={onToggleAll}
          />
        </HStack>
      </AccordionSummary>

      <AccordionDetails sx={{ borderLeft, p: 0 }}>
        <List sx={{ width: 1 }}>
          <Divider />

          {sSensors.filter(FILTER_SENSOR_TYPE).map((sensor, key) => {
            const sensorId = Number(sensor.entity_id)

            const onClick = event => {
              event.stopPropagation()

              const record = folderSensors.find(f => f.entity_id === sensorId)
              if (!record) return

              if (isConsumptionSensor)
                setSelectedSensors(prev =>
                  prev.includes(sensorId)
                    ? prev.filter(f => f !== sensorId)
                    : [...prev, sensorId]
                )
              else {
                setFeelingSensorId(sensorId)
                changeFeelingSensors(sensorId)
              }
            }

            return (
              <CorrelationSecurityItem
                key={key}
                onClick={onClick}
                selected={isSelected(sensorId)}
                sensorId={sensorId}
              />
            )
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

AccSecurity.propTypes = {
  folderId: PropTypes.number.isRequired,
  securitySensors: PropTypes.array.isRequired,
  selectedSensors: PropTypes.array.isRequired,
  sensor_type: PropTypes.string.isRequired,
  setSelectedSensors: PropTypes.func.isRequired,
  selectedFeelingSensors: PropTypes.array.isRequired,
  setFeelingSensorId: PropTypes.func.isRequired,
  changeFeelingSensors: PropTypes.func.isRequired,
  selectedBaselineFeelingSensors: PropTypes.array.isRequired,
  setSelectedFeelingSensors: PropTypes.func.isRequired,
  setSelectedBaselineFeelingSensors: PropTypes.func.isRequired,
}
