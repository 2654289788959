import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { folderActions } from '../../actions'

const folderContext = createContext({})

export const ProvideFolderPool = ({ children }) => {
  const value = useFolders()

  return (
    <folderContext.Provider value={value}>{children}</folderContext.Provider>
  )
}
ProvideFolderPool.propTypes = { children: PropTypes.any }

export const useFolderPool = () => useContext(folderContext)

const useFolders = () => {
  const dispatch = useDispatch()
  const { allFolders } = useSelector(state => state.folder)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(() => {
    dispatch(folderActions.getAll())
    setLoading(true)
  }, [dispatch])

  useEffect(() => {
    const { error, fetchDate, items } = allFolders

    if (items) {
      const { data } = items

      if (data) {
        setData(data)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    }

    if (error) {
      setError(error)
      setLoading(false)
      setFetchDate(fetchDate)
    }
  }, [allFolders])

  return { data, isError, isLoading, fetchDate }
}
