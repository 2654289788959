import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { virtualSensorsActions } from '../../actions'

const virtualSensorContext = createContext({})

export const ProvideVirtualSensorPool = ({ children }) => {
  const value = useVirtualSensors()

  return (
    <virtualSensorContext.Provider value={value}>
      {children}
    </virtualSensorContext.Provider>
  )
}
ProvideVirtualSensorPool.propTypes = { children: PropTypes.any }

export const useVirtualSensorPool = () => useContext(virtualSensorContext)

const useVirtualSensors = () => {
  const dispatch = useDispatch()
  const { allVirtualSensor } = useSelector(state => state.virtualSensor)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(() => {
    dispatch(virtualSensorsActions.getAll())
    setLoading(true)
  }, [dispatch])

  useEffect(() => {
    if (allVirtualSensor) {
      const { error, fetchDate, item } = allVirtualSensor

      if (item) {
        const { data } = item

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    }
  }, [allVirtualSensor])

  return { data, isError, isLoading, fetchDate }
}
