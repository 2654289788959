import { useEffect, useRef } from 'react'

export const useIdleLogout = (onLogout, timeout = 300000) => {
  const timerRef = useRef()

  const setLastActivity = () => {
    localStorage.setItem('KARMAlastActivity', Date.now().toString())
  }

  useEffect(() => {
    const checkIdleTime = () => {
      const lastActivity = parseInt(
        localStorage.getItem('KARMAlastActivity'),
        10
      )
      const now = Date.now()
      if (now - lastActivity > timeout) {
        onLogout()
      }
    }

    const resetTimer = () => {
      clearTimeout(timerRef.current)
      setLastActivity()
      timerRef.current = setTimeout(checkIdleTime, timeout)
    }

    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart']

    events.forEach(event => window.addEventListener(event, resetTimer))

    checkIdleTime()

    resetTimer()

    return () => {
      clearTimeout(timerRef.current)
      events.forEach(event => window.removeEventListener(event, resetTimer))
    }
  }, [onLogout, timeout])
}
