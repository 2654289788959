import {
  KeyboardDoubleArrowDownTwoTone,
  KeyboardDoubleArrowUpTwoTone,
  SettingsTwoTone,
} from '@mui/icons-material'
import {
  Collapse,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  germanDecimals,
  MMMMYYYY,
  removeUnits,
  sensorUnit,
  userLocales,
  WDDDMMYY,
  WDDDMMYYHHMM,
} from '../../api'
import { HStack, SheetSelector } from '../../components'

const useAbbreviation = input =>
  input.replace('Mitarbeiter', 'MA').replace('Zimmer', 'Zi.')

// 1000000 -> 1.000.000
const digitGrouping = digit =>
  Number(digit).toLocaleString(userLocales, germanDecimals)

export const ConsumptionTable = ({
  colors,
  cols,
  data,
  granularity,
  medium,
  roomsMode,
  unitConfig,
  ...rest
}) => {
  const { header: uch, values: ucv } = unitConfig

  const [selectedSheet, selectSheet] = useState(0)
  const [open, setOpen] = useState(true)

  const headerData = [
    { align: 'left', label: 'Datum', accessor: 'date' },
    {
      align: 'center',
      label: `Verbrauch ${uch === true ? ' (' + sensorUnit(medium) + ')' : ''}`,
      accessor: 'actual_consumption',
    },
    {
      align: 'center',
      label: `Trend ${uch === true ? '(%)' : ''}`,
      accessor: 'trend',
    },
    {
      align: 'center',
      label: `CO₂-Emissionen ${uch === true ? '(kg)' : ''}`,
      accessor: 'emissions',
    },
    {
      align: 'center',
      label: `Verbrauch/m² ${uch === true ? '(' + sensorUnit(medium) + ')' : ''}`,
      accessor: 'area_consumption',
    },
    {
      align: 'center',
      label: `Verbrauch/${useAbbreviation(roomsMode)} ${uch === true ? '(' + sensorUnit(medium) + ')' : ''}`,
      accessor: 'employee_consumption',
    },
    {
      align: 'center',
      label: `Kosten${uch === true ? ' (€)' : ''}`,
      accessor: 'actual_costs',
    },
    {
      align: 'center',
      label: `Kosten/m²${uch === true ? ' (€)' : ''}`,
      accessor: 'area_costs',
    },
    {
      align: 'center',
      label: `Benchmark${uch === true ? ' (%)' : ''}`,
      accessor: 'benchmark',
    },
    {
      align: 'center',
      label: 'Zählerstand',
      accessor: 'counter_reading',
    },
    {
      align: 'center',
      label: `Kosten/${useAbbreviation(roomsMode)}${uch === true ? ' (€)' : ''}`,
      accessor: 'employee_costs',
    },
  ]

  const sheetNames = Object.keys(data)

  useEffect(() => {
    if (selectedSheet !== 0 && selectedSheet + 1 > sheetNames.length) {
      selectSheet(sheetNames.length - 1)
    }
  }, [sheetNames, selectedSheet])

  return (
    <HStack>
      {sheetNames.map((item, key) => {
        return key === selectedSheet ? (
          <Paper key={key} elevation={0} sx={{ border: 'solid 1px #DDDDDD' }}>
            <Toolbar sx={{ backgroundColor: '#EEE' }}>
              <HStack
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ width: 1 }}
              >
                <HStack alignItems={'center'}>
                  <IconButton onClick={() => setOpen(prev => !prev)}>
                    {open ? (
                      <KeyboardDoubleArrowDownTwoTone />
                    ) : (
                      <KeyboardDoubleArrowUpTwoTone />
                    )}
                  </IconButton>

                  <SheetSelector
                    colors={colors}
                    onChange={sheet => selectSheet(sheet)}
                    data={sheetNames}
                    title={item}
                  />

                  <Typography sx={{ fontSize: 18, fontWeight: 700, ml: 2 }}>
                    {item}
                  </Typography>

                  <Typography sx={{ fontSize: 18, fontWeight: 700, ml: 1 }}>
                    {granularity}
                  </Typography>
                </HStack>

                <HStack>
                  <IconButton {...rest}>
                    <SettingsTwoTone />
                  </IconButton>
                </HStack>
              </HStack>
            </Toolbar>

            <Divider />

            <Collapse in={open}>
              <TableContainer sx={{ maxHeight: 'calc(80vh)' }}>
                <Table sx={{ maxWidth: 1, tableLayout: 'fixed' }}>
                  <TableHead
                    sx={{
                      position: 'sticky',
                      top: 0,
                      bgcolor: '#FFF',
                      borderBottom: 'solid 1px #000',
                    }}
                  >
                    <TableRow>
                      {headerData.map((cell, key) => {
                        const { accessor, align, label } = cell

                        return cols[accessor] ? (
                          <TableCell align={align} key={key}>
                            {label}
                          </TableCell>
                        ) : null
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data[item].map((row, key) => (
                      <TableRow key={key}>
                        {[
                          {
                            accessor: 'date',
                            align: 'left',
                            sx: null,
                            value:
                              typeof row[0] === 'string'
                                ? row[0]
                                : new Date(row[0]).toLocaleString(
                                    userLocales,
                                    granularity === 'Stündlich'
                                      ? WDDDMMYYHHMM
                                      : granularity === 'Täglich'
                                        ? WDDDMMYY
                                        : granularity === 'Monatlich'
                                          ? MMMMYYYY
                                          : WDDDMMYY
                                  ),
                          },
                          {
                            accessor: 'actual_consumption',
                            align: 'center',
                            sx: null,
                            value:
                              typeof row[1] !== 'string'
                                ? `${digitGrouping(
                                    row[1].toFixed(2)
                                  )} ${ucv === false ? '' : sensorUnit(medium)}`
                                : `${ucv === false ? removeUnits(row[1]) : row[1]}`,
                          },
                          {
                            accessor: 'trend',
                            align: 'center',
                            sx: {
                              color: row[2]?.includes('▲')
                                ? 'red'
                                : row[2]?.includes('▼')
                                  ? 'green'
                                  : 'black',
                            },
                            value: `${row[2]}${ucv === true && row[2] !== '-' ? ' %' : ''}`,
                          },
                          {
                            accessor: 'emissions',
                            align: 'center',
                            sx: null,
                            value: ucv === false ? removeUnits(row[3]) : row[3],
                          },
                          {
                            accessor: 'area_consumption',
                            align: 'center',
                            sx: null,
                            value: ucv === false ? removeUnits(row[4]) : row[4],
                          },
                          {
                            accessor: 'employee_consumption',
                            align: 'center',
                            sx: null,
                            value: ucv === false ? removeUnits(row[5]) : row[5],
                          },
                          {
                            accessor: 'actual_costs',
                            align: 'center',
                            sx: null,
                            value: ucv === false ? removeUnits(row[6]) : row[6],
                          },
                          {
                            accessor: 'area_costs',
                            align: 'center',
                            sx: null,
                            value: ucv === false ? removeUnits(row[7]) : row[7],
                          },
                          {
                            accessor: 'benchmark',
                            align: 'center',
                            sx: {
                              color: row[8]?.includes('▲')
                                ? 'red'
                                : row[8]?.includes('▼')
                                  ? 'green'
                                  : 'black',
                            },
                            value: `${row[8]}${ucv === true && row[8] !== '-' ? ' %' : ''}`,
                          },
                          {
                            accessor: 'counter_reading',
                            align: 'center',
                            sx: null,
                            value: row[9],
                          },
                          {
                            accessor: 'employee_costs',
                            align: 'center',
                            sx: null,
                            value:
                              ucv === false ? removeUnits(row[10]) : row[10],
                          },
                        ].map((cell, key) => {
                          const { accessor, align, sx, value } = cell

                          return cols[accessor] ? (
                            <TableCell align={align} key={key} sx={sx}>
                              {value}
                            </TableCell>
                          ) : null
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Paper>
        ) : (
          ''
        )
      })}
    </HStack>
  )
}

ConsumptionTable.propTypes = {
  colors: PropTypes.array.isRequired,
  cols: PropTypes.object,
  data: PropTypes.array.isRequired,
  granularity: PropTypes.string,
  medium: PropTypes.string,
  roomsMode: PropTypes.string,
  unitConfig: PropTypes.object,
}
