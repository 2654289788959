import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import {
  MMMMYYYY,
  removeUnits,
  sensorType,
  sensorUnit,
  userLocales,
  WDDDMMYYYY,
  WDDDMMYYYYHHMM,
} from '../../api'

const borderStyle = {
  top: { style: 'medium', color: { argb: 'FF000000' } },
  left: { style: 'medium', color: { argb: 'FF000000' } },
  bottom: { style: 'medium', color: { argb: 'FF000000' } },
  right: { style: 'medium', color: { argb: 'FF000000' } },
}

const fillStyle = {
  fgColor: { argb: 'FFC0C0C0' },
  pattern: 'solid',
  type: 'pattern',
}

const parseNumber = data =>
  typeof data === 'string'
    ? Number(data.replace('.', '').replace(',', '.'))
    : data

const handleNaN = data => (isNaN(data) ? String('-') : data)

const configureColumns = cols => {
  const data = [{}]

  if (cols.date === true) data.push({ width: 20 })
  if (cols.actual_consumption === true) data.push({ width: 20 })
  if (cols.trend === true) data.push({ width: 20 })
  if (cols.emissions === true) data.push({ width: 20 })
  if (cols.area_consumption === true) data.push({ width: 30 })
  if (cols.employee_consumption === true) data.push({ width: 30 })
  if (cols.actual_costs === true) data.push({ width: 20 })
  if (cols.area_costs === true) data.push({ width: 20 })
  if (cols.benchmark === true) data.push({ width: 20 })
  if (cols.counter_reading === true) data.push({ width: 20 })
  if (cols.employee_costs === true) data.push({ width: 20 })

  // add empty columns for better readability; we preformat them to get a wider width for readability
  const minAmountOfColumns = 11

  if (data.length < minAmountOfColumns)
    for (let i = data.length; i < minAmountOfColumns; i++)
      data.push({ width: 20 })

  return data
}

const generateCaptions = (cols, unit, uch) => {
  const data = ['', 'Datum']

  const consumptionUnit = uch ? ` (${unit})` : ''
  const currency = uch ? ' (€)' : ''
  const emissionsUnit = uch ? ' (kg)' : ''

  if (cols.actual_consumption === true)
    data.push(`IST Verbrauch${consumptionUnit}`)
  if (cols.trend === true) data.push('Trend (%)')
  if (cols.emissions === true) data.push(`CO₂-Emissionen${emissionsUnit}`)
  if (cols.area_consumption === true)
    data.push(`Verbrauch / m²${consumptionUnit})`)
  if (cols.employee_consumption === true)
    data.push(`Verbrauch / Mitarbeiter${consumptionUnit}`)
  if (cols.actual_costs === true) data.push(`IST Kosten${currency}`)
  if (cols.area_costs === true) data.push(`Kosten / m²${currency}`)
  if (cols.benchmark === true) data.push(`Benchmark (%)`)
  if (cols.counter_reading === true) data.push('Zählerstand')
  if (cols.employee_costs === true) data.push(`Kosten / Mitarbeiter${currency}`)

  return data
}

const generateRow = (cols, data, granularity, ucv, unit) => {
  const record = ['']

  if (cols.date === true)
    record.push(
      typeof data[0] === 'object'
        ? new Date(data[0]).toLocaleString(
            userLocales,
            granularity === 'd'
              ? WDDDMMYYYYHHMM
              : granularity === 'y'
                ? MMMMYYYY
                : WDDDMMYYYY
          )
        : 'Summe'
    )

  if (cols.actual_consumption === true)
    record.push(
      ucv === true
        ? `${handleNaN(parseNumber(removeUnits(data[3])))} ${unit}`
        : data[1]
    )

  if (cols.trend === true)
    record.push(ucv === true ? data[2] : removeUnits(data[2]))

  if (cols.emissions === true)
    record.push(
      ucv === true ? data[3] : handleNaN(parseNumber(removeUnits(data[3])))
    )

  if (cols.area_consumption === true)
    record.push(
      ucv === true ? data[4] : handleNaN(parseNumber(removeUnits(data[4])))
    )

  if (cols.employee_consumption === true)
    record.push(
      ucv === true ? data[5] : handleNaN(parseNumber(removeUnits(data[5])))
    )

  if (cols.actual_costs === true)
    record.push(
      ucv === true ? data[6] : handleNaN(parseNumber(removeUnits(data[6])))
    )

  if (cols.area_costs === true)
    record.push(
      ucv === true ? data[7] : handleNaN(parseNumber(removeUnits(data[7])))
    )

  if (cols.benchmark === true) record.push(data[8])
  if (cols.counter_reading === true) record.push(data[9])
  if (cols.employee_costs === true)
    record.push(
      ucv === true ? data[10] : handleNaN(parseNumber(removeUnits(data[10])))
    )

  return record
}

const generateSummary = cols => {
  const data = ['', 'Summe']

  if (cols.actual_consumption === true) data.push('')
  if (cols.trend === true) data.push('')
  if (cols.emissions === true) data.push('')
  if (cols.area_consumption === true) data.push('')
  if (cols.employee_consumption === true) data.push('')
  if (cols.actual_costs === true) data.push('')
  if (cols.area_costs === true) data.push('')
  if (cols.benchmark === true) data.push('')
  if (cols.counter_reading === true) data.push('')
  if (cols.employee_costs === true) data.push('')

  return data
}

export const exportToExcel = async (
  data,
  filename,
  prices,
  folderData,
  roomsMode,
  medium,
  granularity,
  viewCols,
  unitConfig
) => {
  const unit = sensorUnit(medium)

  console.log('viewCols', viewCols)
  console.log('unitConfig', unitConfig)
  const { header: uch, values: ucv } = unitConfig

  const workbook = new ExcelJS.Workbook()

  const worksheet = []

  // data contains all data for the different sensors
  if (Object.keys(data).length > 0)
    Object.keys(data).map(name => {
      const dataCount = data[name].length

      worksheet[name] = workbook.addWorksheet(name)

      const setupCell = (id, value, border = borderStyle, fill = fillStyle) => {
        worksheet[name].getCell(id).border = border
        worksheet[name].getCell(id).fill = fill
        worksheet[name].getCell(id).value = value
      }

      // define default width of each column
      worksheet[name].columns = configureColumns(viewCols)

      // intentially add empty row
      worksheet[name].addRow([])

      // add captions
      worksheet[name].addRow(generateCaptions(viewCols, unit, uch))

      // add data by row
      for (let i = 0; i < dataCount; i++) {
        const isUpBenchmark = String(data[name][i][8]).includes('▲')
        const isDownBenchmark = String(data[name][i][8]).includes('▼')
        const isUpTrend = String(removeUnits(data[name][i][2])).includes('▲')
        const isDownTrend = String(removeUnits(data[name][i][2])).includes('▼')

        const refCols = () => [
          '',
          '',
          ...Object.keys(viewCols).filter(key => viewCols[key] === true),
        ]

        const getLetter = num => String.fromCharCode(64 + num)

        // data summary row
        if (i === dataCount - 1) {
          const row = worksheet[name].addRow(generateSummary(viewCols))

          row.eachCell((cell, key) => {
            //console.log('refCols', refCols(), key, refCols()[key])

            const avgBenchmark =
              data[name]
                .map(item =>
                  parseFloat(item[8].replace('▲ ', '').replace('▼ ', ''))
                )
                .reduce((acc, val) => acc + val, 0) / dataCount ?? '-'

            const ln = dataCount + 1
            const col = getLetter(key)

            if (refCols()[key] === 'actual_consumption') {
              cell.value = {
                formula: `SUM(${col}3:${col}${ln})`,
                result: undefined,
              }
            }

            if (refCols()[key] === 'emissions') {
              cell.value = {
                formula: `SUM(${col}3:${col}${ln})`,
                result: undefined,
              }
            }

            if (refCols()[key] === 'area_consumption') {
              cell.value = {
                formula: `SUM(${col}3:${col}${ln})`,
                result: undefined,
              }
            }

            if (refCols()[key] === 'employee_consumption') {
              cell.value = {
                formula: `SUM(${col}3:${col}${ln})`,
                result: undefined,
              }
            }

            if (refCols()[key] === 'actual_costs') {
              cell.value = {
                formula: `SUM(${col}3:${col}${ln})`,
                result: undefined,
              }
            }

            if (refCols()[key] === 'area_costs') {
              cell.value = {
                formula: `SUM(${col}3:${col}${ln})`,
                result: undefined,
              }
            }

            if (refCols()[key] === 'benchmark') {
              cell.font = {
                color: {
                  argb: isUpBenchmark
                    ? 'FFAA0000'
                    : isDownBenchmark
                      ? 'FF00AA00'
                      : 'FF000000',
                },
              }
              cell.value =
                avgBenchmark > 0
                  ? `▲ ${avgBenchmark.toFixed(2)}`
                  : avgBenchmark < 0
                    ? `▼ ${avgBenchmark.toFixed(2)}`
                    : isNaN(avgBenchmark)
                      ? '-'
                      : `${avgBenchmark.toFixed(2)}`
            }

            if (refCols()[key] === 'employee_costs') {
              cell.value = {
                formula: `SUM(${col}3:${col}${ln})`,
                result: undefined,
              }
            }
          })
        } else {
          // Daten
          const row = worksheet[name].addRow(
            generateRow(viewCols, data[name][i], granularity, ucv, unit)
          )

          row.eachCell((cell, key) => {
            if (key > 1)
              cell.border = {
                left: { style: 'medium', color: { argb: 'FF000000' } },
                right: { style: 'medium', color: { argb: 'FF000000' } },
              }

            if (refCols()[key] === 'trend')
              cell.font = {
                color: {
                  argb: isUpTrend
                    ? 'FFAA0000'
                    : isDownTrend
                      ? 'FF00AA00'
                      : 'FF000000',
                },
              }

            if (refCols()[key] === 'benchmark')
              cell.font = {
                color: {
                  argb: isUpBenchmark
                    ? 'FFAA0000'
                    : isDownBenchmark
                      ? 'FF00AA00'
                      : 'FF000000',
                },
              }
          })
        }
      }

      // preallocate leerzeilen für kpi zeug, damit zellen zentrieren klappt
      for (let i = 0; i < 6; i++)
        worksheet[name].addRow(['', '', '', '', '', '', '', '', '', '', '', ''])

      // header border + fill
      worksheet[name].getRow(2).eachCell((cell, key) => {
        if (key > 1) {
          cell.border = borderStyle
          cell.fill = fillStyle
        }
      })

      // zusammenfassung border + fill
      worksheet[name].getRow(2 + dataCount).eachCell((cell, key) => {
        if (key > 1) {
          cell.border = borderStyle
          cell.fill = fillStyle
        }
      })

      if (name === 'Gesamtverbrauch') {
        const row = dataCount + 4
        const consumptionUnit = uch ? ` (${unit})` : ''

        setupCell(`B${row}`, 'Medium')
        setupCell(`C${row}`, 'Zähler')

        setupCell(`E${row}`, `Verbrauch${consumptionUnit}`)
        setupCell(`F${row}`, `Minimum${consumptionUnit}`)
        setupCell(`G${row}`, `Maximum${consumptionUnit}`)
        setupCell(`H${row}`, `Durchschnitt${consumptionUnit}`)

        // -----

        // Energietyp
        setupCell(`B${row + 1}`, sensorType(medium), borderStyle, null)
        // Name
        setupCell(`C${row + 1}`, name, borderStyle, null)
        // Verbrauch
        setupCell(
          `E${row + 1}`,
          { formula: `=C${dataCount + 2}`, result: undefined },
          borderStyle,
          null
        )
        // Minimum
        setupCell(
          `F${row + 1}`,
          { formula: `MIN(C3:C${dataCount + 1})`, result: undefined },
          borderStyle,
          null
        )
        // Maximum
        setupCell(
          `G${row + 1}`,
          { formula: `MAX(C3:C${dataCount + 1})`, result: undefined },
          borderStyle,
          null
        )
        // Durchschnitt
        setupCell(
          `H${row + 1}`,
          {
            formula: `ROUND(AVERAGE(C3:C${dataCount + 1}), 2)`,
            result: undefined,
          },
          borderStyle,
          null
        )

        //

        setupCell(`B${row + 3}`, 'Stammdaten')
        setupCell(`C${row + 3}`, '')

        // Stammdaten
        setupCell(`B${row + 4}`, roomsMode, borderStyle, null)

        // Zähler
        setupCell(
          `C${row + 4}`,
          folderData?.number_of_employees || '-',
          borderStyle,
          null
        )
      }

      if (name !== 'Gesamtverbrauch') {
        const sensorByName = name =>
          folderData?.sensors?.find(f => f.name === name)
        const pSensorId = sensorByName(name).entity_id
        const pRecord = prices.find(f => f.sensor_id === pSensorId)
        const row = dataCount + 4
        const currency = uch ? ' (€)' : ''

        setupCell(`B${row}`, `${sensorType(medium)}kosten / ${unit}${currency}`)

        setupCell(
          `C${row}`,
          pRecord?.price
            ? parseNumber(pRecord?.price.toLocaleString(userLocales))
            : '-',
          borderStyle,
          null
        )

        setupCell(`B${row + 1}`, `Fixkosten / Jahr${currency}`)

        setupCell(
          `C${row + 1}`,
          pRecord?.price_for_year
            ? parseNumber(pRecord?.price_for_year.toLocaleString(userLocales))
            : '-',
          borderStyle,
          null
        )
      }

      // zentrieren
      worksheet[name].eachRow(row => {
        row.eachCell({ includeEmpty: true }, cell => {
          cell.alignment = { horizontal: 'center', vertical: 'middle' }
        })
      })

      return null
    })

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, filename)
}
