import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useMeterNumber = sensorId => {
  const [data, setData] = useState('n/a')

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const sensorData = sensorPool.find(f => Number(f.id) === sensorId)
    if (!sensorData?.attributes?.meter_number) return

    const meterNumber = sensorData?.attributes?.meter_number

    if (meterNumber) setData(meterNumber)
  }, [sensorPool, sensorId])

  return data
}

useMeterNumber.propTypes = {
  sensorId: PropTypes.number,
}
