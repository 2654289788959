import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const AMItem = ({ caption, icon, ...rest }) => (
  <MenuItem {...rest}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText>{caption}</ListItemText>
  </MenuItem>
)

AMItem.propTypes = {
  caption: PropTypes.string,
  icon: PropTypes.object,
}
