import { CancelTwoTone } from '@mui/icons-material'
import { Button, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const BtnCancel = ({ color = 'secondary', onClick }) => (
  <Button
    color={color}
    onClick={onClick}
    sx={{ color: useTheme().palette.white, borderRadius: 1 }}
  >
    <CancelTwoTone sx={{ mr: 1 }} /> Abbrechen
  </Button>
)

BtnCancel.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
}
