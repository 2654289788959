import { EditTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const BtnEditPanel = ({
  icon = <EditTwoTone />,
  title = 'Einstellungen',
  ...rest
}) => (
  <Tooltip title={title}>
    <IconButton {...rest}>{icon}</IconButton>
  </Tooltip>
)

BtnEditPanel.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
}
