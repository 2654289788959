import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'
import { DDMMYYYYHHMM, userLocales } from '../../api'

export const useFolderMTime = (
  folderId,
  locales = userLocales,
  options = DDMMYYYYHHMM
) => {
  const [data, setData] = useState(null)

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.updated_at) return

    const updated_at = folderData?.attributes?.updated_at

    if (updated_at)
      setData(new Date(updated_at).toLocaleString(locales, options))
  }, [folderPool, folderId, locales, options])

  return data
}

useFolderMTime.propTypes = {
  folderId: PropTypes.number,
}
