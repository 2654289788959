import { AutorenewTwoTone, CloseTwoTone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { DDMMYYYYHHMMSS, getRelativeDate, userLocales } from '../../api'
import { useAlertsPool, useAppConfig, useSensorPool } from '../../hooks'
import { newsActions as NA } from '../../actions'

export const BtnTryAgain = ({
  color = 'info',
  onClick,
  title = 'Erneut versuchen',
  ...rest
}) => (
  <Button color={color} onClick={onClick} {...rest}>
    <AutorenewTwoTone sx={{ mr: 1 }} />
    {title}
  </Button>
)

BtnTryAgain.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
}

export const DashboardAlerts = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { appConfig, setAppConfig } = useAppConfig()
  const { alertsAcknowledged, alertsMax, mask, salutationMode, timeMode } =
    appConfig

  const { data, isLoading, isError } = useAlertsPool(alertsMax, mask)
  const { data: sensorPool } = useSensorPool()

  const [sensorIds, setSensorIds] = useState([])

  useEffect(() => {
    const sensorIds = sensorPool.map(sensor => Number(sensor.id))

    setSensorIds(sensorIds)
  }, [sensorPool])

  const statusToSeverity = { green: 'success', yellow: 'warning', red: 'error' }

  const reloadAlerts = () => {
    if (!appConfig?.alertsMax || !appConfig?.mask) return

    const status = 'red'
    const page = 1
    const query = `?page=${page}&limit=${appConfig.alertsMax}&status=${status}&mask=${appConfig.mask}`

    dispatch(NA.getAllAlerts(query))
  }

  return (
    <Stack {...rest}>
      {isLoading && (
        <Stack>
          <Typography sx={{ textAlign: 'center', mb: 5 }} variant={'h5'}>
            Ereignisse werden aggregiert ...
          </Typography>

          {Array.from({ length: alertsMax || 0 }).map((_, key) => (
            <Skeleton
              key={key}
              sx={{ mb: 2, height: 100 }}
              variant={'rectangular'}
            />
          ))}
        </Stack>
      )}

      {!isLoading && (
        <Stack>
          <Typography sx={{ textAlign: 'center', mb: 5 }} variant={'h5'}>
            In {salutationMode === 'default' ? 'Ihrer' : 'Deiner'} Abwesenheit
            gab es folgende relevante Ereignisse:
          </Typography>
        </Stack>
      )}

      {data
        .filter(f => sensorIds.includes(Number(f.attributes.sensor_id)))
        .slice(0, alertsMax)
        .map((alert, key) => {
          const { sensor_id, status, text, timestamp, title } = alert.attributes
          const severity = statusToSeverity[status]
          const to = `/sensors/${sensor_id}`
          const acknowledged = alertsAcknowledged?.includes(alert.id)
          const tsFormated =
            timeMode === 'relative'
              ? getRelativeDate(timestamp)
              : new Date(timestamp).toLocaleDateString(
                  userLocales,
                  DDMMYYYYHHMMSS
                )

          const onClick = () =>
            setAppConfig(prev => ({
              ...prev,
              alertsAcknowledged: [...prev.alertsAcknowledged, alert.id],
            }))

          const action = (
            <IconButton onClick={onClick}>
              <CloseTwoTone />
            </IconButton>
          )

          return (
            <Collapse in={!acknowledged} key={key}>
              <Alert action={action} severity={severity} sx={{ mb: 2 }}>
                <AlertTitle>
                  <Link to={to}>{title}</Link>
                </AlertTitle>

                <Typography>{text}</Typography>

                <Typography sx={{ fontSize: '0.85rem', fontStyle: 'italic' }}>
                  {tsFormated}
                </Typography>
              </Alert>
            </Collapse>
          )
        })}

      {!isLoading &&
        !isError &&
        data
          .slice(0, alertsMax)
          .filter(f => !alertsAcknowledged?.includes(f.id)).length === 0 && (
          <Alert severity={'success'}>
            <AlertTitle>Alles im grünen Bereich</AlertTitle>
            Aktuell liegen keine Alerts vor.
          </Alert>
        )}

      {!isLoading && isError !== null && data.length === 0 && (
        <Collapse in={!isLoading && isError !== null && data.length === 0}>
          <Alert severity={'error'} sx={{ position: 'relative' }}>
            <AlertTitle>Alerts konnten nicht geladen werden</AlertTitle>

            {String(isError)}

            <BtnTryAgain
              onClick={reloadAlerts}
              sx={{ position: 'absolute', right: 10, bottom: 10 }}
            />
          </Alert>
        </Collapse>
      )}
    </Stack>
  )
}
