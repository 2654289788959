import {
  AccountBalanceTwoTone,
  ArrowBackIosTwoTone,
  ArrowForwardIosTwoTone,
  CheckTwoTone,
  DownloadTwoTone,
  MoreVertTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import downloadjs from 'downloadjs'
import html2canvas from 'html2canvas'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  ASC,
  daysAgo,
  daysOnward,
  DDMMYYYY,
  DDMMYYYYHHMM,
  DMMMMYYYY,
  germanDecimals,
  getHoursArray,
  getMonthsArray,
  getSession,
  initialChartConfig,
  isFullMonth,
  isMoreThenSevenDays,
  isMoreThenSixMonths,
  MMMYY,
  monthsAgo,
  monthsOnward,
  sensorType,
  sensorUnit,
  setSession,
  userLocales,
  WDDDMMYY,
  WDDDMMYYHHMM,
  SVGStatus,
  MMMMYYYY,
  WDDDMMYYYY,
  WDDDMMYYYYHHMM,
} from '../../api'
import {
  AM,
  AMItem,
  ChartTypeSelector,
  ConsumptionTable,
  DayFilterSelector,
  DlgConsumptionExport,
  DlgConsumptionTableCols,
  DlgMediumDiagnostics,
  FromDateSelector,
  GranularitySelector,
  HStack,
  KPage,
  KPISelector,
  MediumSelector,
  Preloader,
  SensorSelector,
  StackSelector,
  ToDateSelector,
} from '../../components'
import {
  useCompareConsumptionByFolderId,
  useConsumptionByFolderId,
  useFolder,
  useFolderContract,
  useFolderName,
  useFolderSensorIds,
  useFolderStatus,
  useMeterReadingByFolderId,
  useOperator,
  useRoomsMode,
  useSensorPool,
} from '../../hooks'
import { KPIViewer } from './components'
import useRenderCount from '../../hooks/tools/useRenderCount'

// 1000000 -> 1.000.000
const digitGrouping = digit =>
  Number(digit).toLocaleString(userLocales, germanDecimals)

const fmtCol = column => digitGrouping(column.toFixed(2))

export const PerformanceDetail = ({
  PAGE_CONFIG = 'settings_performance_detail',
  title = 'Details Leistung',
}) => {
  const rc = useRenderCount()
  console.log('PD:', rc)
  const history = useHistory()

  const theme = useTheme()
  const { white: bgcolor } = theme.palette
  const black = theme.palette.black

  const { data: operatorData } = useOperator()

  const { id, type: initialMedium } = useParams()
  const folderId = Number(id)

  const folderStatus = useFolderStatus(folderId)

  const location = useLocation()

  const URLselectedSensors = React.useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    return (
      queryParams
        .get('selected')
        ?.split(',')
        .map(item => Number(item)) || []
    )
  }, [location.search])

  const URLselectedGranularity = React.useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get('g')
  }, [location.search])

  const URLselectedFromDate = React.useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get('from')
  }, [location.search])

  const URLselectedToDate = React.useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get('to')
  }, [location.search])

  // page config
  const [config, setConfig] = useState(getSession(PAGE_CONFIG))
  const [newParams, setNewParams] = useState(config)

  if (!config || !config?.chartFormat)
    setConfig(prev => ({
      ...prev,
      chartFormat: {
        d: 'EEE, dd.MM. HH:mm',
        m: 'EEE, dd.MM.',
        y: 'MMM yy',
        fontSize: 10,
      },
    }))

  // performance detail states

  const defaultSelectedDays = {
    workingdays: 1,
    weekends: 1,
    monday: 1,
    tuesday: 1,
    wednesday: 1,
    thursday: 1,
    friday: 1,
    saturday: 1,
    sunday: 1,
  }

  const [actionMenu, setActionMenu] = useState(null)
  const [order, setOrder] = useState(ASC)
  const [medium, setMedium] = useState(initialMedium)
  const [exportModal, setExportModal] = useState(false)
  const [fullWidth, setFullWidth] = useState(false)
  const [fileName, setFileName] = useState('')
  const [daysFilter, setDaysFilter] = useState({})
  const [selectedDays, setSelectedDays] = useState(defaultSelectedDays)
  const [kpiData, setKpiData] = useState({
    average: 0,
    max: 0,
    min: 0,
    total: 0,
    label: 'Verbrauch',
    unit: `${sensorUnit(initialMedium)}`,
    type: 'consumption',
  })
  const [periodData, setPeriodData] = useState({
    days: 0,
    months: 0,
    showWarning: false,
    warningText: '',
    title: '',
  })
  const [viewCols, setViewCols] = useState({
    date: true,
    actual_consumption: true,
    trend: true,
    emissions: true,
    area_consumption: true,
    employee_consumption: true,
    actual_costs: true,
    area_costs: true,
    benchmark: true,
    counter_reading: true,
    employee_costs: true,
  })

  const [unitConfig, setUnitConfig] = useState({
    header: false,
    values: true,
  })
  useEffect(() => {
    if (!operatorData?.view_options) return
    const { view_options } = operatorData
    if (!view_options?.[0]?.columns) return

    const columnsCfg = view_options[0].columns
    const columns = columnsCfg
      .split('x')[1]
      .split('')
      .map(item => Number(item))
      .join(',')

    const voFolderId = view_options[0].folder_id

    const updatedVC = {
      date: true,
      actual_consumption: true,
      trend: true,
      emissions: true,
      area_consumption: true,
      employee_consumption: true,
      actual_costs: true,
      area_costs: true,
      benchmark: true,
      counter_reading: true,
      employee_costs: true,
    }

    const keys = Object.keys(updatedVC)

    for (let col = 0; col < keys.length; col++)
      updatedVC[keys[col]] = Boolean(Number(columns.split(',')[col]))

    // only apply if view options are existing for current folderId or folderId=0 (all folders)
    if (voFolderId === folderId || voFolderId === 0) setViewCols(updatedVC)

    const uc = columnsCfg
      .split('x')[0]
      .split('')
      .map(f => Boolean(Number(f)))

    setUnitConfig({ header: uc[0], values: uc[1] })
  }, [operatorData, folderId])

  const [fromCompareDate, setFromCompareDate] = useState()
  const [toCompareDate, setToCompareDate] = useState()
  const [allSensors, setAllSensors] = useState([])
  const [sensorColors, setSensorColors] = useState([])
  const [selectedSensors, setSelectedSensors] = useState(URLselectedSensors)

  const [fillUpSensors, setFillUpSensors] = useState([])
  const [chartData, setChartData] = useState([
    [' ', 'Verbrauch'],
    [new Date(), 0],
    [new Date(), 0],
  ])
  const [chartDataWithToolTip, setChartDataWithToolTip] = useState([
    [' ', 'Verbrauch'],
    [new Date(), 0],
    [new Date(), 0],
  ])
  const [sensorConsumptionData, setSensorConsumptionData] = useState([])
  const [, setTableDataComparison] = useState([])
  const [compareChartData, setCompareChartData] = useState()
  const [tableDataExport, setTableDataExport] = useState([])

  // disabled states (handled by useEffect's)
  const [isStackDisabled, setStackDisabled] = useState(true)
  const [isBrowseBackDisabled, setBrowseBackDisabled] = useState(true)
  const [isBrowseForwardDisabled, setBrowseForwardDisabled] = useState(true)
  const [isDaysFilterDisabled, setDaysFilterDisabled] = useState(true)
  const [configChanged, setConfigChanged] = useState(false)

  // hooks (alphabetical order)

  const {
    data: compareConsumptionData,
    isLoading: isCompareConsumptionDataLoading,
  } = useCompareConsumptionByFolderId(
    folderId,
    config?.granularity,
    fromCompareDate,
    toCompareDate
  )

  const {
    data: consumptionData,
    //isLoading: isConsumptionDataLoading,
    isFactorError,
    factorErrorSensor,
  } = useConsumptionByFolderId(
    folderId,
    config?.granularity,
    config?.fromDate,
    config?.toDate
  )

  const {
    data: meterReadingData,
    isFactorError: isFactorErrorMeterReading,
    factorErrorSensor: factorErrorSensorMeterReading,
  } = useMeterReadingByFolderId(folderId, config?.fromDate, config?.toDate)

  const { data: folderData, isLoading: isFolderDataLoading } =
    useFolder(folderId)

  const { isLoading: sensorPoolIsLoading } = useSensorPool()

  // helper functions (alphabetical order)

  const createComparisonTable = (actual, procentual, calculated) => {
    if (procentual.includes('-') && procentual.length > 3)
      procentual = `▼ ${procentual}`

    if (!procentual.includes('-') && procentual.length > 3)
      procentual = `▲ ${procentual}`

    return { actual, procentual, calculated }
  }

  const getDeviation = (actual, calculated) =>
    ((actual - calculated) / calculated) * 100

  const granularityTitle = granularity =>
    granularity === 'd'
      ? 'Stündlich'
      : granularity === 'm'
        ? 'Täglich'
        : granularity === 'y'
          ? 'Monatlich'
          : granularity

  const isDayInDays = useCallback(
    day => {
      if (selectedDays.monday === 1 && day === 1) return true
      if (selectedDays.tuesday === 1 && day === 2) return true
      if (selectedDays.wednesday === 1 && day === 3) return true
      if (selectedDays.thursday === 1 && day === 4) return true
      if (selectedDays.friday === 1 && day === 5) return true
      if (selectedDays.saturday === 1 && day === 6) return true
      if (selectedDays.sunday === 1 && day === 0) return true

      return false
    },
    [selectedDays]
  )

  const createConsumptionValuesMap = (values, userLocales, format) => {
    const consumptionValuesMap = new Map(
      values.map(value => [
        new Date(value.timepoint).toLocaleString(userLocales, format),
        value,
      ])
    )
    return consumptionValuesMap
  }

  const formatDateToLocaleString = (date, userLocales, format) => {
    if (typeof date === 'string') return date
    return new Date(date).toLocaleString(userLocales, format)
  }

  const isBenchmarkOnDate = (benchmark, date) => {
    const targetDate = new Date(date)
    const periodStart = new Date(benchmark.attributes.period_start)
    const periodEnd = new Date(benchmark.attributes.period_end)
    return targetDate >= periodStart && targetDate <= periodEnd
  }

  // useEffect (dependency count order)

  useEffect(() => setSession(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  useEffect(() => {
    const { fromDate, toDate, granularity } = initialChartConfig
    const updatedConfig = { ...config, fromDate, toDate, granularity }
    setSession(PAGE_CONFIG, updatedConfig)
    const resetSession = () => {
      setSession(PAGE_CONFIG, { ...updatedConfig })
    }
    window.addEventListener('beforeunload', async event => {
      event.preventDefault()
      await resetSession()
      window.removeEventListener('beforeunload', resetSession)
    })
  }, [config, PAGE_CONFIG])

  useEffect(() => {
    setFillUpSensors([])
    setSelectedSensors([])
  }, [medium])

  useEffect(() => {
    setStackDisabled(selectedSensors.length < 2)
    setConfig(prev => ({ ...prev, selectedSensors }))
  }, [selectedSensors])

  useEffect(() => {
    setDaysFilterDisabled(newParams?.granularity !== 'm')
  }, [newParams])

  const operatorSensorIds = useFolderSensorIds(folderId)
  const folderName = useFolderName(folderId)

  useEffect(() => {
    if (!folderName || !medium) return

    setFileName(`${folderName}_${sensorType(medium)}`)
  }, [folderName, medium])

  const roomsMode = useRoomsMode(folderId)
  const contractData = useFolderContract(folderId)

  useEffect(() => {
    if (!contractData) return

    const showWarning = periodData?.showWarning
    const beforeContract = config?.fromDate < contractData?.date_begin
    const afterContract = config?.toDate > contractData?.date_expire
    const dateInFuture =
      config?.toDate > new Date(Date.now()).toISOString().split('T')[0]

    setBrowseBackDisabled(showWarning || beforeContract)
    setBrowseForwardDisabled(showWarning || afterContract || dateInFuture)
  }, [config?.fromDate, config?.toDate, contractData, periodData?.showWarning])

  useEffect(() => {
    if (!config?.fromDate || !config?.toDate) return

    const fmtDate = date =>
      new Date(date).toLocaleDateString(userLocales, DMMMMYYYY)

    const days = () => {
      const _1H = 60 * 60 * 1000
      const _24H = 24 * _1H
      const from = new Date(config?.fromDate).getTime()
      const to = new Date(config?.toDate).getTime()

      return (to - from) / _24H
    }

    const months = () => {
      const from = new Date(config?.toDate)
      const to = new Date(config?.fromDate)

      let range = (to.getFullYear() - from.getFullYear()) * 12
      range -= from.getMonth()
      range += to.getMonth()

      return range > 0 ? range : 1
    }

    const IS_YEARLY = newParams?.granularity === 'y'
    const IS_MONTHLY = newParams?.granularity === 'm'
    const IS_DAILY = newParams?.granularity === 'd'

    const IS_HALF_MONTH =
      isFullMonth(newParams?.fromDate, newParams?.toDate) === false

    const EXCEEDS_HALFYEAR =
      isMoreThenSixMonths(newParams?.fromDate, newParams?.toDate) === true

    const EXCEEDS_WEEK =
      isMoreThenSevenDays(newParams?.fromDate, newParams?.toDate) === true

    const showWarning =
      IS_YEARLY && IS_HALF_MONTH
        ? true
        : IS_MONTHLY && EXCEEDS_HALFYEAR
          ? true
          : IS_DAILY && EXCEEDS_WEEK
            ? true
            : false

    const title = `${fmtDate(config?.fromDate)} - ${fmtDate(config?.toDate)}`

    const warningText =
      IS_YEARLY && IS_HALF_MONTH
        ? 'Diese Ansicht ist nur bei Auswahl von vollen Monaten (Erster bis letzter Tag des Monats) verfügbar.'
        : IS_MONTHLY && EXCEEDS_HALFYEAR
          ? 'Diese Ansicht erlaubt eine Zeitspanne von maximal 6 Monaten.'
          : IS_DAILY && EXCEEDS_WEEK
            ? 'Diese Ansicht erlaubt eine Zeitspanne von maximal 7 Tagen.'
            : ''

    // set compare fromDate and toDate relative to value of periodData.days
    setFromCompareDate(daysAgo(config?.fromDate, days() + 1))
    setToCompareDate(daysAgo(config?.toDate, days() + 1))

    setPeriodData(prev => ({
      ...prev,
      days: days(),
      months: months(),
      showWarning,
      title,
      warningText,
    }))
  }, [config, newParams])

  useEffect(() => {
    if (chartData === undefined) return

    const addTooltipColumn = data => {
      const combined = []

      const dateFormat =
        config?.granularity === 'd'
          ? WDDDMMYYYYHHMM
          : config?.granularity === 'm'
            ? WDDDMMYYYY
            : config?.granularity === 'y'
              ? MMMMYYYY
              : DDMMYYYY

      data.forEach((row, i) => {
        if (i === 0) {
          combined.push([row[i]])

          row.forEach((col, j) => {
            if (j > 0)
              combined[i].push(col, {
                type: 'string',
                role: 'tooltip',
                p: { html: true },
              })
          })
        } else {
          combined[i] = [row[0]]
          row.forEach((col, j) => {
            if (j > 0) {
              const name = data[0][j]
              const unit = kpiData.unit
              const timestamp =
                combined[i][0] instanceof Date && !isNaN(combined[i][0])
                  ? combined[i][0].toLocaleString(userLocales, dateFormat)
                  : combined[i][0]

              const sensorByName = name =>
                sensorConsumptionData.find(f => f.name === name)

              const sensor = sensorByName(name)
              if (sensor) {
                const { consumption } = sensor
                const { values } = consumption

                const consumptionValuesMap = createConsumptionValuesMap(
                  values,
                  userLocales,
                  dateFormat
                )

                const timepoint = formatDateToLocaleString(
                  combined[i][0],
                  userLocales,
                  dateFormat
                )

                const data = consumptionValuesMap.get(timepoint)

                if (kpiData.type === 'co2') col = data?.co2 ?? 0
                if (kpiData.type === 'price') col = data?.cost ?? 0
              }

              combined[i][0] = timestamp
              combined[i].push(
                col,
                `<div style="padding:5px 10px; min-width:220px;"><p><strong>${timestamp}</strong></p><p>${name}: <strong>${digitGrouping(
                  col
                )} ${unit}</strong></p></div>`
              )
            }
          })
        }
      })

      return combined
    }

    setChartDataWithToolTip(addTooltipColumn(chartData))
  }, [
    chartData,
    config.granularity,
    kpiData.type,
    kpiData.unit,
    sensorConsumptionData,
  ])

  useEffect(() => {
    if (compareConsumptionData.length === 0 || operatorSensorIds.length === 0)
      return

    const IS_OPERATOR_SENSOR = f =>
      operatorSensorIds.includes(Number(f.entity_id))

    const IS_CONSUMPTION_SENSOR = f =>
      medium === 'energy'
        ? f.sensor_type === 'energy' || f.sensor_type === 'energy_detail'
        : medium === 'water'
          ? f.sensor_type === 'water' || f.sensor_type === 'water_detail'
          : medium === 'gas'
            ? f.sensor_type === 'gas' || f.sensor_type === 'gas_detail'
            : f.sensor_type === medium

    const _allSensors = compareConsumptionData
      .filter(IS_OPERATOR_SENSOR)
      .filter(IS_CONSUMPTION_SENSOR)
      .sort((a, b) => {
        const result = a.name.localeCompare(b.name)

        return order === ASC ? result : -result
      })

    const format =
      config.granularity === 'd'
        ? DDMMYYYYHHMM
        : config.granularity === 'm'
          ? DDMMYYYY
          : config.granularity === 'y'
            ? MMMYY
            : DDMMYYYY

    let columns = [granularityTitle(config.granularity)]
    let _data = []
    const _fillUps = []

    _allSensors
      .filter(f => selectedSensors.includes(f.id))
      .forEach(sensor => {
        const { consumption, id, name } = sensor

        columns = [...columns, name]

        consumption.values.forEach((item, key) => {
          const { timepoint, value: recValue } = item
          const value = Number(recValue.toFixed(2))

          _data[key] = _data[key] ? [..._data[key], value] : [timepoint, value]

          // missing sensor data needs to be filled up with 0 to render chart
          if (columns.length > _data[key].length) {
            const fillUps = new Array(columns.length - _data[key].length).fill(
              0
            )

            _data[key] = [..._data[key], ...fillUps]

            if (_fillUps.length === 0)
              _fillUps.push({ id, amount: fillUps.length })
          }
        })
      })

    setFillUpSensors(_fillUps)

    _data.sort((a, b) => (a[0] > b[0] ? 1 : -1))
    _data = _data.map(
      item => (item[0] = new Date(item[0]).toLocaleString(userLocales, format))
    )

    // add columns header (sensor names) on top of data
    _data = [columns, ..._data]

    // add dummy data if no data to render
    if (_data.length === 1) _data.push(columns.map(() => 0))

    // validate data integrity and add fillup data if needed
    for (const i in _data) {
      const fillUps = new Array(columns.length - _data[i].length).fill(0)

      _data[i] = [..._data[i], ...fillUps]
    }

    setCompareChartData(_data)
  }, [
    compareConsumptionData,
    config.granularity,
    medium,
    operatorSensorIds,
    order,
    selectedSensors,
  ])

  useEffect(() => {
    setConfigChanged(
      periodData.showWarning ||
        (newParams.granularity === config.granularity &&
          newParams.fromDate === config.fromDate &&
          newParams.toDate === config.toDate &&
          JSON.stringify(selectedDays) === JSON.stringify(daysFilter))
    )
  }, [config, daysFilter, newParams, periodData.showWarning, selectedDays])

  useEffect(() => {
    if (consumptionData.length === 0 || operatorSensorIds.length === 0) return

    const getDaysArray = (dateFrom, dateTo) => {
      const days = []
      const date = new Date(dateFrom)

      for (
        days;
        date <= dateTo.setHours(23, 59, 59);
        date.setDate(date.getDate() + 1)
      )
        if (isDayInDays(date.getDay())) days.push([new Date(date)])

      if (days.length === 0) days.push([new Date(date)])

      return days
    }

    const IS_OPERATOR_SENSOR = f =>
      operatorSensorIds.includes(Number(f.entity_id))

    const IS_CONSUMPTION_SENSOR = f =>
      medium === 'energy'
        ? f.type_in_folder === 'energy' || f.type_in_folder === 'energy_detail'
        : medium === 'water'
          ? f.type_in_folder === 'water' || f.type_in_folder === 'water_detail'
          : medium === 'gas'
            ? f.type_in_folder === 'gas' || f.type_in_folder === 'gas_detail'
            : f.type_in_folder === medium

    const IS_ENERGY_TYPE_SENSOR = f =>
      f.type_in_folder === medium || f.type_in_folder === `${medium}_detail`

    const _allSensors = consumptionData
      .filter(IS_OPERATOR_SENSOR)
      .filter(IS_CONSUMPTION_SENSOR)
      .sort((a, b) => {
        const result = a.name.localeCompare(b.name)

        return order === ASC ? result : -result
      })

    const _sensorColors = []

    const fittingSensors = consumptionData
      .filter(IS_OPERATOR_SENSOR)
      .filter(IS_ENERGY_TYPE_SENSOR)

    if (!fittingSensors[0])
      console.warn('no operator sensors for medium in consumption data')
    const allSensorConsumptionData = []

    const createBaseData = granularity => {
      let columns = [granularityTitle(granularity)]

      const format =
        granularity === 'd'
          ? WDDDMMYYHHMM
          : granularity === 'm'
            ? WDDDMMYY
            : granularity === 'y'
              ? MMMYY
              : WDDDMMYY

      let data =
        granularity === 'd'
          ? getHoursArray(new Date(config.fromDate), new Date(config.toDate))
          : granularity === 'm'
            ? getDaysArray(new Date(config.fromDate), new Date(config.toDate))
            : granularity === 'y'
              ? getMonthsArray(config.fromDate, config.toDate)
              : []

      if (granularity === 'd') data = data.map(day => [day])
      if (granularity === 'y') data = data.map(day => [day])

      _allSensors
        .filter(f => selectedSensors.includes(f.entity_id))
        .forEach(sensor => {
          const { consumption, name, chartColor } = sensor
          allSensorConsumptionData.push(sensor)

          const addConsumption = record => {
            const consumptionValuesMap = createConsumptionValuesMap(
              consumption.values,
              userLocales,
              format
            )
            const timepoint = formatDateToLocaleString(
              record[0],
              userLocales,
              format
            )

            const data = consumptionValuesMap.get(timepoint)

            const aggregated = data
              ? kpiData.type === 'consumption'
                ? data.value
                : data.value
              : 0

            return [...record, +aggregated.toFixed(2)]
          }

          columns = [...columns, name]

          data = data.map(record => addConsumption(record))
          _sensorColors[name] = chartColor
        })

      setAllSensors(_allSensors)
      setSensorColors(_sensorColors)

      data.sort((a, b) =>
        new Date(a[0]).getTime() > new Date(b[0]).getTime() ? 1 : -1
      )

      // fill columns data to minimum if no data
      if (data.length === 0) data = [granularity, granularity]

      // add columns header (sensor names) on top of data
      data = [columns, ...data]

      // add dummy data if no data to render
      if (data.length === 1) data.push(columns.map(() => 0))

      return data
    }
    setChartData(createBaseData(config.granularity))
    setSensorConsumptionData(allSensorConsumptionData)
  }, [
    config,
    consumptionData,
    isDayInDays,
    kpiData.type,
    medium,
    operatorSensorIds,
    order,
    selectedSensors,
  ])

  useEffect(() => {
    let average = 0
    let max = 0
    let min = 10000000
    let total = 0

    const createKpiData = () => {
      const maxSumObject = {}

      for (const entity_id of selectedSensors) {
        const sensor = allSensors.find(f => f.entity_id === entity_id)

        if (sensor) {
          const { consumption, name } = sensor
          for (let i = 0; i < consumption.values.length; i++) {
            const { timepoint, value, co2, cost } = consumption.values[i]
            if (
              !isDayInDays(new Date(timepoint).getDay()) &&
              config.granularity !== 'y'
            ) {
              continue
            }
            const amount =
              kpiData.type === 'consumption'
                ? value
                : kpiData.type === 'price'
                  ? cost
                  : kpiData.type === 'co2'
                    ? co2
                    : value
            total += amount

            if (config.isStacked) {
              maxSumObject[timepoint] = maxSumObject[timepoint]
                ? maxSumObject[timepoint] + amount
                : amount
            } else {
              maxSumObject[name + i] = amount
            }
          }
        }
      }

      Object.values(maxSumObject).forEach(peak => {
        if (peak < min) min = peak
        if (peak > max) max = peak
      })

      if (total > 0) {
        const sum = Object.values(maxSumObject).reduce(
          (acc, val) => acc + val,
          0
        )
        average = sum / Object.values(maxSumObject).length
      }

      if (min === 10000000) min = 0

      if (isNaN(total) || total === undefined) total = 0

      if (medium === 'water' && kpiData.type === 'co2')
        total = max = min = average = '-'
    }

    createKpiData(config.granularity)
    setKpiData(prev => ({ ...prev, average, max, min, total }))
  }, [allSensors, config, isDayInDays, kpiData.type, medium, selectedSensors])

  useEffect(() => {
    if (
      chartData === undefined ||
      folderData === null ||
      compareChartData === undefined ||
      compareChartData === null
    )
      return

    const result = []
    result['Gesamtverbrauch'] = []

    const format =
      config.granularity === 'd'
        ? WDDDMMYYHHMM
        : config.granularity === 'm'
          ? WDDDMMYY
          : config.granularity === 'y'
            ? MMMYY
            : WDDDMMYY

    const getTotalConsumption = data => {
      let dates = []
      const consumption = []

      data[0].forEach((sheet, key) => {
        const title = data[0][key + 1]
        if (!title) return

        result[title] = []

        for (const item of data) {
          if (!item || !item[0]) return

          const date = item[0]
          const value = item[key + 1]

          if (value !== undefined && typeof value !== 'string') {
            result[title].push([date, value])
            consumption.push([date, value])
            dates.push(date)
          }
        }
      })

      dates = [...new Set(dates)]
      dates.forEach((date, key) => {
        dates[key] = [date, 0]

        consumption.forEach(value => {
          if (value[0] === dates[key][0]) dates[key][1] += value[1]
        })
      })

      dates.sort((a, b) =>
        new Date(a[0]).getTime() > new Date(b[0]).getTime() ? 1 : -1
      )

      return dates
    }

    result['Gesamtverbrauch'] = getTotalConsumption(chartData)

    let comparisonRows = []
    let monthSum = 0

    const areaInSqm =
      folderData.area_in_sqm === null || folderData.area_in_sqm === undefined
        ? 1
        : folderData.area_in_sqm

    const {
      target_value_annual_electricity_consumption,
      target_value_annual_gas_consumption,
    } = folderData

    const comparisonValue =
      (medium === 'energy' || medium === 'energy_detail') &&
      target_value_annual_electricity_consumption !== null
        ? target_value_annual_electricity_consumption
        : medium === 'gas' && target_value_annual_gas_consumption !== null
          ? target_value_annual_gas_consumption
          : medium === 'water'
            ? 0
            : 1

    if (compareChartData.slice(1).length < 12) {
      compareChartData
        .slice(1)
        .forEach(entries =>
          entries.slice(1).forEach(record => (monthSum += record))
        )

      const calculatedSum =
        ((monthSum / compareChartData.slice(1).length) * 12) / areaInSqm

      const deviation = getDeviation(calculatedSum, comparisonValue)

      comparisonRows.push(
        createComparisonTable(
          `${fmtCol(calculatedSum)} ${sensorUnit(medium)}`,
          `${fmtCol(deviation)} %`,
          `${fmtCol(comparisonValue)} ${sensorUnit(medium)}`
        )
      )
    }

    if (compareChartData.slice(1).length < 6) {
      comparisonRows = []
      comparisonRows.push(
        createComparisonTable(
          ` - `,
          ` - `,
          `${comparisonValue.toFixed(2)} ${sensorUnit(medium)}`
        )
      )
    }

    if (compareChartData.slice(1).length >= 12) {
      compareChartData
        .slice(1)
        .forEach(entries =>
          entries.slice(1).forEach(record => (monthSum += record))
        )

      monthSum /= areaInSqm
      const deviation = getDeviation(monthSum, comparisonValue)

      comparisonRows.push(
        createComparisonTable(
          `${fmtCol(monthSum)} ${sensorUnit(medium)}`,
          `${fmtCol(deviation)} %`,
          `${fmtCol(comparisonValue)} ${sensorUnit(medium)}`
        )
      )
    }

    setTableDataComparison(comparisonRows)

    const co2Global = []
    const priceGlobal = []

    const sensorByName = name =>
      sensorConsumptionData.find(f => f.name === name)

    for (const key in result) {
      if (key === 'Gesamtverbrauch') continue

      const sheet = result[key]
      const sensor = sensorByName(key)

      if (!sensor) continue

      for (const [index, record] of sheet.entries()) {
        const date = record[0]
        const { consumptions } = sensor
        const { values } = consumptions
        const consumptionValuesMap = createConsumptionValuesMap(
          values,
          userLocales,
          format
        )
        const timepoint = formatDateToLocaleString(date, userLocales, format)
        const data = consumptionValuesMap.get(timepoint)

        if (!data) continue

        const co2 = data.co2
        co2Global[date] = (co2Global[date] || 0) + co2

        const amount = data.cost
        priceGlobal[index] = (priceGlobal[index] || 0) + amount
      }
    }

    for (const key in result) {
      const sheet = result[key]

      const sumPercent = 0
      let sumConsumption = 0
      let sumConsumptionBySqm = 0
      let sumConsumptionByEmployee = 0
      let sumCost = 0
      let sumCostBySqm = 0
      let sumCo2Consumption = 0
      let sumCostByEmployee = 0
      let consumptionGlobal = 1

      let totalCost = 0
      let consumptionPerSqm = 0
      let consumptionPerEmployee = 0
      let costPerSqm = 0
      let costPerEmployee = 0
      let co2Value = 0
      let costValue = 0
      let kpiBenchmark = 0

      const sensor = sensorByName(key)

      const checkAndReplaceNull = value => {
        return value === null ? '-' : value
      }

      const getValueOrDefault = (key, value) => {
        return key === 'Gesamtverbrauch' ? '-' : checkAndReplaceNull(value)
      }

      for (let i = 0; i < sheet.length; i++) {
        const record = sheet[i]
        const date = record[0]
        let consumption = record[1]

        if (sensor) {
          const { consumption, benchmarks } = sensor
          const { values } = consumption
          const consumptionValuesMap = createConsumptionValuesMap(
            values,
            userLocales,
            format
          )
          const timepoint = formatDateToLocaleString(
            record[0],
            userLocales,
            format
          )
          const data = consumptionValuesMap.get(timepoint)

          if (data) {
            const {
              co2,
              value_per_sqm,
              value_per_employee,
              cost,
              cost_per_sqm,
              cost_per_employee,
            } = data
            co2Value = co2
            costValue = cost
            consumptionPerSqm = value_per_sqm
            consumptionPerEmployee = value_per_employee
            costPerSqm = cost_per_sqm
            costPerEmployee = cost_per_employee

            const benchmarksOnDate = benchmarks.filter(benchmark =>
              isBenchmarkOnDate(benchmark, date)
            )

            if (benchmarksOnDate.length > 0) {
              kpiBenchmark = benchmarksOnDate[0].attributes.value
            }
          }
        }

        const percent =
          consumptionGlobal === 1
            ? 0
            : ((consumption - consumptionGlobal) / consumptionGlobal) * 100

        const trend =
          consumptionGlobal === 1
            ? 0
            : consumption > consumptionGlobal
              ? percent
              : percent

        consumptionGlobal = consumption

        consumption = checkAndReplaceNull(consumption)
        const costBySqm = getValueOrDefault(key, costPerSqm)
        const costByEmployee = getValueOrDefault(key, costPerEmployee)
        const consumptionBySqm = getValueOrDefault(key, consumptionPerSqm)
        const consumptionByEmployee = getValueOrDefault(
          key,
          consumptionPerEmployee
        )

        const consumptionCo2 =
          key === 'Gesamtverbrauch'
            ? co2Global[date] === undefined
              ? '-'
              : co2Global[date]
            : checkAndReplaceNull(co2Value)

        totalCost =
          key === 'Gesamtverbrauch'
            ? priceGlobal[i] === undefined
              ? '-'
              : priceGlobal[i]
            : checkAndReplaceNull(costValue)

        let meterReading = '-'

        const sensorConsumptions = consumptionData
          .filter(f => operatorSensorIds.includes(Number(f.entity_id)))
          .find(f => f.name === key)

        if (sensorConsumptions !== undefined && sensorConsumptions !== null) {
          const { meter_reading } = sensorConsumptions
          if (meter_reading !== undefined && meter_reading !== null) {
            const recordMeterReadingData = meterReadingData.find(
              f => f.name === key
            )

            if (recordMeterReadingData && config.granularity === 'y') {
              let withoutWeekDay = new Date(record[0])
              const compareDate = new Date(
                recordMeterReadingData.meter_reading_date
              )
              if (
                withoutWeekDay.getMonth() === compareDate.getMonth() &&
                withoutWeekDay.getFullYear() === compareDate.getFullYear()
              ) {
                withoutWeekDay = compareDate
              }

              if (
                withoutWeekDay >=
                new Date(recordMeterReadingData.meter_reading_date)
              ) {
                const lastDayDate = new Date(withoutWeekDay)
                lastDayDate.setUTCMonth(lastDayDate.getUTCMonth() + 1)
                lastDayDate.setUTCDate(1)
                lastDayDate.setUTCDate(lastDayDate.getUTCDate() - 1)

                const record =
                  recordMeterReadingData.meter_readings.values.find(
                    f =>
                      new Date(f.timepoint).toDateString() ===
                      lastDayDate.toDateString()
                  )

                if (record) meterReading = record.value
              }
            }
          }
        }

        const benchmark =
          config.granularity === 'y' && kpiBenchmark !== 0
            ? ((costBySqm - kpiBenchmark) / kpiBenchmark) * 100
            : 0

        sumConsumption += consumption
        sumConsumptionBySqm += consumptionBySqm
        sumCo2Consumption += consumptionCo2
        sumConsumptionByEmployee += consumptionByEmployee
        sumCost += totalCost
        sumCostBySqm += costBySqm
        sumCostByEmployee += costByEmployee

        sheet[i] = [
          date,
          trend,
          consumption,
          consumptionCo2,
          consumptionBySqm,
          consumptionByEmployee,
          totalCost,
          costBySqm,
          benchmark,
          meterReading,
          costByEmployee,
        ]
      }

      if (totalCost === 0 || isNaN(totalCost)) {
        if (isNaN(sumCost)) sumCost = '-'
        if (isNaN(sumCostBySqm)) sumCostBySqm = '-'
        if (isNaN(sumCostByEmployee)) sumCostByEmployee = '-'
      }

      if (isNaN(sumConsumption)) sumConsumption = '-'
      if (isNaN(sumCo2Consumption)) sumCo2Consumption = '-'
      if (isNaN(sumConsumptionBySqm)) sumConsumptionBySqm = '-'
      if (isNaN(sumConsumptionByEmployee)) sumConsumptionByEmployee = '-'
      if (isNaN(sumCost)) sumCost = '-'
      if (isNaN(sumCostBySqm)) sumCostBySqm = '-'
      if (isNaN(sumCostByEmployee)) sumCostByEmployee = '-'

      sheet.push([
        'Summe',
        sumPercent,
        sumConsumption,
        sumCo2Consumption,
        sumConsumptionBySqm,
        sumConsumptionByEmployee,
        sumCost,
        sumCostBySqm,
        '-',
        '-',
        sumCostByEmployee,
      ])
    }

    for (const key in result) {
      const sheet = result[key]
      const unit = sensorUnit(medium)

      for (let i = 0; i < sheet.length; i++) {
        const record = sheet[i]
        const date = record[0]
        const trend = fmtCol(record[1])

        const percent =
          record[1] > 0
            ? `▲ ${trend}`
            : record[1] < 0
              ? `▼ ${trend}`
              : record[1] === 0 || isNaN(record[1])
                ? '-'
                : record[1]

        const consumption = record[2]

        const consumptionCo2 =
          record[3] === undefined || record[3] === '-' || isNaN(record[3])
            ? record[3]
            : `${fmtCol(record[3])} kg`

        const consumptionBySqm =
          record[4] === '-' ? record[4] : `${fmtCol(record[4])} ${unit}`

        const consumptionByEmployee =
          record[5] === '-' ? record[5] : `${fmtCol(record[5])} ${unit}`

        const totalCost =
          record[6] === '-' || record[6] === undefined || isNaN(record[6])
            ? record[6]
            : `${fmtCol(record[6])} €`

        const costBySqm =
          record[7] === '-' || record[7] === undefined || isNaN(record[7])
            ? record[7]
            : `${fmtCol(record[7])} €`

        const bTrend = record[8] === '-' ? record[8] : fmtCol(record[8])
        const benchmark =
          record[8] > 0
            ? `▲ ${bTrend}`
            : record[8] < 0
              ? `▼ ${bTrend}`
              : record[8] === 0 || isNaN(record[8])
                ? '-'
                : record[8]

        const counterReading = record[9] === '-' ? record[9] : fmtCol(record[9])

        const costByEmployee =
          record[10] === '-' || record[10] === undefined || isNaN(record[10])
            ? record[10]
            : `${fmtCol(record[10])} €`

        sheet[i] = [
          date,
          consumption,
          percent,
          consumptionCo2,
          consumptionBySqm,
          consumptionByEmployee,
          totalCost,
          costBySqm,
          benchmark,
          counterReading,
          costByEmployee,
        ]
      }
    }

    setTableDataExport(result)
  }, [
    chartData,
    compareChartData,
    config.granularity,
    consumptionData,
    meterReadingData,
    folderData,
    medium,
    operatorSensorIds,
    sensorConsumptionData,
  ])

  // JSX handler (alphabetical order)

  const applyConfigChange = () => {
    const { granularity, fromDate, toDate } = newParams

    setConfig(prev => ({
      ...prev,
      granularity,
      fromDate,
      toDate,
    }))

    if (JSON.stringify(selectedDays) !== JSON.stringify(daysFilter))
      setSelectedDays(daysFilter)
  }

  const browseBack = () => {
    const { fromDate: npFromDate, toDate: npToDate } = newParams
    const { days, months } = periodData

    const fromDate =
      config.granularity !== 'y'
        ? daysAgo(npFromDate, days + 1)
        : monthsAgo(npFromDate, months, true)

    const toDate =
      config.granularity !== 'y'
        ? daysAgo(npToDate, days + 1)
        : monthsAgo(npToDate, months, false)

    setConfig(prev => ({ ...prev, fromDate, toDate }))
    setNewParams(prev => ({ ...prev, fromDate, toDate }))
  }

  const browseForward = () => {
    const { days, months } = periodData

    if (config.granularity !== 'y') {
      setConfig(prev => ({
        ...prev,
        fromDate: daysOnward(prev.fromDate, days + 1),
        toDate: daysOnward(prev.toDate, days + 1),
      }))

      setNewParams(prev => ({
        ...prev,
        fromDate: daysOnward(prev.fromDate, days + 1),
        toDate: daysOnward(prev.toDate, days + 1),
      }))
    } else {
      setConfig(prev => ({
        ...prev,
        fromDate: monthsOnward(prev.fromDate, months, true),
        toDate: monthsOnward(prev.toDate, months, false),
      }))

      setNewParams(prev => ({
        ...prev,
        fromDate: monthsOnward(prev.fromDate, months, true),
        toDate: monthsOnward(prev.toDate, months, false),
      }))
    }
  }

  const closeAM = () => setActionMenu(null)

  const closeDiagnostics = () =>
    setConfig(prev => ({ ...prev, diagnosis: false }))

  const closeExportDialog = () => {
    setExportModal(false)
    setFullWidth(false)
    //reset export table data
    setSelectedSensors([])
  }
  const closeTableRowsDialog = () =>
    setConfig(prev => ({ ...prev, tableRows: false }))

  const configureTableColumns = (cols, unitCfg) => {
    setViewCols(cols)
    setUnitConfig(unitCfg)
  }

  const exportAsJPEG = () => handleExportImage('jpg')

  const exportAsPNG = () => handleExportImage('png')

  const handleChartType = (event, chartType) => {
    if (chartType !== null) setConfig(prev => ({ ...prev, chartType }))
  }

  const handleDaysFilter = days => setDaysFilter(days)

  const handleExportImage = async type => {
    const chartElement = document.querySelector('.image-export')
    const canvas = await html2canvas(chartElement, { scale: 2 })
    const dataURL = canvas.toDataURL(`image/${type}`)

    downloadjs(dataURL, `${fileName}.${type}`, `image/${type}`)
  }

  const handleFromDate = event => {
    const fromDate = event.target.value

    setNewParams(prev => ({
      ...prev,
      fromDate,
      ...(fromDate > prev.toDate && { toDate: fromDate }),
    }))
  }

  const handleGranularity = event => {
    setNewParams(prev => ({ ...prev, granularity: event.target.value }))
  }

  const handleKpiType = event => {
    const type = event.target.value
    const unit =
      type === 'consumption'
        ? sensorUnit(medium)
        : type === 'price'
          ? '€'
          : type === 'co2'
            ? 'kg'
            : sensorUnit(medium)

    setKpiData(prev => ({ ...prev, type, unit }))
  }

  const handleMedium = event => {
    if (kpiData.type === 'consumption') {
      const unit = sensorUnit(event.target.value)
      setKpiData(prev => ({ ...prev, unit }))
    }
    setMedium(event.target.value)
  }

  const handleOrder = order => setOrder(order)

  const handleSelectedSensors = result => setSelectedSensors(result)

  const handleSettings = () => {
    if (operatorData?.isAdmin)
      setConfig(prev => ({ ...prev, showSettings: !prev.showSettings }))
  }

  const handleStackMode = (event, isStacked) => {
    if (isStacked !== null) setConfig(prev => ({ ...prev, isStacked }))
  }

  const handleToDate = event => {
    const toDate = event.target.value

    setNewParams(prev => ({
      ...prev,
      ...(toDate < prev.fromDate && { fromDate: toDate }),
      toDate,
    }))
  }

  const openAM = event => setActionMenu(event.currentTarget)

  const openDiagnostics = () =>
    setConfig(prev => ({ ...prev, diagnosis: true }))

  const openExportDialog = () => {
    setExportModal(true)
    if (selectedSensors.length > 6) setFullWidth(true)
  }

  const openTableRowsDialog = () =>
    setConfig(prev => ({ ...prev, tableRows: true }))

  const isPageLoading =
    //isConsumptionDataLoading ||
    isCompareConsumptionDataLoading ||
    isFolderDataLoading ||
    sensorPoolIsLoading === true

  const imageExport = false

  const colorList = () => {
    const selectedSensorColors = allSensors
      .filter(sensor => selectedSensors.includes(sensor.entity_id))
      .map(sensor => sensorColors[sensor.name])

    return selectedSensorColors.length > 0 ? selectedSensorColors : [black]
  }

  useEffect(() => {
    if (!URLselectedSensors || URLselectedSensors.length === 0) return
    setSelectedSensors(URLselectedSensors)
  }, [URLselectedSensors])

  useEffect(() => {
    if (URLselectedGranularity === null) return
    setConfig(prev => ({ ...prev, granularity: URLselectedGranularity }))
    setNewParams(prev => ({ ...prev, granularity: URLselectedGranularity }))
  }, [URLselectedGranularity])

  useEffect(() => {
    if (URLselectedFromDate === null) return
    setConfig(prev => ({ ...prev, fromDate: URLselectedFromDate }))
    setNewParams(prev => ({ ...prev, fromDate: URLselectedFromDate }))
  }, [URLselectedFromDate])

  useEffect(() => {
    if (URLselectedToDate === null) return
    setConfig(prev => ({ ...prev, toDate: URLselectedToDate }))
    setNewParams(prev => ({ ...prev, toDate: URLselectedToDate }))
  }, [URLselectedToDate])

  const gotoFolderDetails = () => history.push(`/folder/${folderId}`)
  const gotoPerformanceOverview = () =>
    history.push(`/folder/${folderId}/home/performance`)

  const gotoInstallationOverview = () => () =>
    history.push(`/folder/${folderId}/home/installation`)

  const gotoCorrelation = () =>
    history.push(`/folder/${folderId}/home/correlation`)

  const gotoSecurity = () => history.push(`/folder/${folderId}/home/security`)
  const gotoFeeling = () => history.push(`/folder/${folderId}/home/feeling`)

  return (
    <>
      <Preloader isLoading={isPageLoading} />

      <AM
        anchorEl={actionMenu}
        caption={title}
        open={Boolean(actionMenu)}
        onClose={closeAM}
        historyUrlTarget={'performance'}
        historyUrlId={Number(folderId)}
      >
        {[
          operatorData?.isAdmin && {
            caption: 'Diagnose',
            icon: <CheckTwoTone />,
            fn: openDiagnostics,
          },
          {
            caption: 'Details zum Gebäude',
            icon: <AccountBalanceTwoTone />,
            fn: gotoFolderDetails,
          },
          null,
          {
            caption: 'Leistung',
            icon: (
              <SVGStatus
                type={'performance'}
                status={folderStatus.performance}
              />
            ),
            fn: gotoPerformanceOverview,
          },
          {
            caption: 'Anlagen',
            icon: (
              <SVGStatus
                type={'installation'}
                status={folderStatus.installation}
              />
            ),
            fn: gotoInstallationOverview,
          },
          {
            caption: 'Korrelation',
            icon: (
              <SVGStatus
                type={'correlation'}
                status={folderStatus.correlation}
              />
            ),
            fn: gotoCorrelation,
          },
          {
            caption: 'Sicherheit',
            icon: (
              <SVGStatus type={'security'} status={folderStatus.security} />
            ),
            fn: gotoSecurity,
          },
          {
            caption: 'Wohlbefinden',
            icon: <SVGStatus type={'feeling'} status={folderStatus.feeling} />,
            fn: gotoFeeling,
          },
        ].map((item, key) =>
          item ? (
            <AMItem
              caption={item.caption}
              icon={
                <Stack alignItems={'center'} sx={{ width: 25 }}>
                  {item.icon}
                </Stack>
              }
              key={key}
              onClick={item.fn}
            />
          ) : (
            <Divider key={key} />
          )
        )}
      </AM>
      {/* action menu end */}

      {/* diagnose */}
      {!isPageLoading && operatorData?.isAdmin && (
        <DlgMediumDiagnostics
          allSensors={allSensors}
          medium={medium}
          fillUpSensors={fillUpSensors}
          folderId={folderId}
          onClose={closeDiagnostics}
          open={config.diagnosis || false}
          title={periodData.title}
        />
      )}
      {/* diagnose end */}

      {/* tabellen spalten konfiguration */}
      {!isPageLoading && (
        <DlgConsumptionTableCols
          onClose={closeTableRowsDialog}
          onConfirm={configureTableColumns}
          open={config.tableRows || false}
          folderId={folderId}
        />
      )}
      {/* tabellen spalten konfiguration end */}

      {/* exportieren */}
      {exportModal && (
        <DlgConsumptionExport
          open={exportModal}
          onClose={closeExportDialog}
          medium={medium}
          tableDataExport={tableDataExport}
          fileName={fileName}
          kpiData={kpiData}
          exportAsJPEG={exportAsJPEG}
          exportAsPNG={exportAsPNG}
          folderId={folderId}
          periodData={periodData}
          granularity={config?.granularity}
        />
      )}
      {/* exportieren end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={openAM}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar onClick={handleSettings}>
              <SVGStatus
                type={'performance'}
                status={folderStatus.performance}
              />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={title}
        >
          <Card variant={'performance'}>
            <CardContent sx={{ p: '0px !important' }}>
              <Box
                overflow={'auto'}
                sx={{ borderBottom: 'solid 1px #B9B9B9', px: 1 }}
              >
                <HStack justifyContent={'space-between'}>
                  <HStack alignItems={'center'} spacing={1}>
                    <MediumSelector
                      consumptionData={consumptionData}
                      folderId={folderId}
                      onChange={handleMedium}
                      sx={{ width: 150 }}
                      value={medium}
                    />

                    <KPISelector
                      onChange={handleKpiType}
                      sx={{ width: 120 }}
                      value={kpiData.type}
                    />

                    <StackSelector
                      disabled={isStackDisabled}
                      onChange={handleStackMode}
                      value={config.isStacked}
                    />

                    <ChartTypeSelector
                      onChange={handleChartType}
                      value={config.chartType}
                    />

                    <Tooltip title={'Exportieren'}>
                      <span>
                        <IconButton
                          disabled={selectedSensors.length === 0}
                          onClick={openExportDialog}
                        >
                          <DownloadTwoTone />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </HStack>

                  <HStack alignItems={'center'} spacing={1}>
                    <IconButton
                      disabled={isBrowseBackDisabled}
                      onClick={browseBack}
                    >
                      <ArrowBackIosTwoTone />
                    </IconButton>

                    <IconButton
                      disabled={isBrowseForwardDisabled}
                      onClick={browseForward}
                    >
                      <ArrowForwardIosTwoTone />
                    </IconButton>

                    <GranularitySelector
                      onChange={handleGranularity}
                      value={newParams.granularity}
                    />

                    <FromDateSelector
                      folderId={folderId}
                      onChange={handleFromDate}
                      value={newParams.fromDate}
                    />

                    <ToDateSelector
                      folderId={folderId}
                      onChange={handleToDate}
                      value={newParams.toDate}
                    />

                    <DayFilterSelector
                      disabled={isDaysFilterDisabled}
                      resetString={isDaysFilterDisabled ? true : false}
                      onChange={handleDaysFilter}
                      selectedDays={selectedDays}
                      defaultSelectedDays={defaultSelectedDays}
                    />

                    <Button
                      color={'primary'}
                      disabled={configChanged}
                      onClick={applyConfigChange}
                    >
                      Anwenden
                    </Button>
                  </HStack>
                </HStack>
              </Box>

              <Collapse in={periodData.showWarning}>
                <Alert severity={'warning'}>{periodData.warningText}</Alert>
              </Collapse>

              <Collapse in={isFactorError || isFactorErrorMeterReading}>
                <Alert severity={'warning'}>
                  {`Bitte prüfen Sie die Faktoren für Messpunkt ${
                    factorErrorSensor
                      ? factorErrorSensor
                      : factorErrorSensorMeterReading
                  }.`}
                </Alert>
              </Collapse>

              <Grid container className={'image-export'}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={fullWidth === true ? 12 : 9}
                  sx={{ bgcolor }}
                >
                  <Stack
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    sx={{ bgcolor }}
                  >
                    <Typography
                      sx={{ fontSize: 20, fontWeight: 'bold', my: 2 }}
                    >
                      {periodData.title}
                    </Typography>

                    <KPIViewer
                      medium={medium}
                      kpiData={kpiData}
                      sx={{ pt: 2 }}
                      exportModal={exportModal}
                    />
                  </Stack>
                  <Chart
                    chartLanguage={'de_DE'}
                    chartType={config.chartType}
                    chartEvents={[
                      {
                        eventName: 'ready',
                        callback: ({ chartWrapper, google }) => {
                          const chart = chartWrapper.getChart()
                          google.visualization.events.addListener(
                            chart,
                            'error',
                            errorMessage => {
                              google.visualization.errors.removeError(
                                errorMessage.id
                              )
                            }
                          )
                        },
                      },
                    ]}
                    data={
                      selectedSensors.length === 0
                        ? [
                            [granularityTitle(config.granularity), 'Verbrauch'],
                            [new Date(config.fromDate), 0],
                            [new Date(config.toDate), 0],
                          ]
                        : chartDataWithToolTip
                    }
                    height={`${
                      imageExport ? 620 * (selectedSensors.length - 7) : 500
                    }px`}
                    options={{
                      backgroundColor: 'white',
                      colors:
                        selectedSensors.length === 0 ? undefined : colorList(),
                      tooltip: { isHtml: true, trigger: 'visible' },
                      isStacked: config.isStacked,
                      legend: { position: 'none' },
                      vAxis: {
                        format: `#,###.## ${kpiData.unit}`,
                        viewWindow: {
                          min: 0,
                          max: selectedSensors.length === 0 ? 100 : kpiData.max,
                        },
                      },

                      // https://gist.githubusercontent.com/DNTech/716b6b2aa5992750b003d23caf592f0c/raw/b12cb8141d47f3273018ccb1326d4063d59d06af/hAxis%2520Properties.js
                      hAxis: {
                        format:
                          config?.granularity === 'd'
                            ? config?.chartFormat?.d
                            : config?.granularity === 'm'
                              ? config?.chartFormat?.m
                              : config?.granularity === 'y'
                                ? config?.chartFormat?.y
                                : config?.chartFormat?.m,
                        viewWindow: {
                          min:
                            selectedSensors.length === 0
                              ? new Date(config.fromDate)
                              : undefined,
                          max:
                            selectedSensors.length === 0
                              ? new Date(config.toDate)
                              : undefined,
                        },
                        textStyle: {
                          fontSize: config?.chartFormat?.fontSize,
                        },
                        ticks: [
                          ...new Set(
                            chartDataWithToolTip
                              .map((item, key) => {
                                if (key === 0) return null
                                return { v: item[0], f: item[0] }
                              })
                              .filter(f => f !== null)
                          ),
                        ],
                        gridlines: { count: 12 },
                      },
                    }}
                  />
                  <Collapse in={selectedSensors.length === 0}>
                    <Alert
                      severity={
                        selectedSensors.length === 0 ? 'warning' : 'success'
                      }
                    >
                      Bitte wählen Sie einen oder mehrere Sensoren aus.
                    </Alert>
                  </Collapse>
                </Grid>

                <Grid item xs={12} sm={12} md={fullWidth === true ? 12 : 3}>
                  <SensorSelector
                    data={allSensors}
                    exportModal={exportModal}
                    folderId={folderId}
                    fullWidth={fullWidth}
                    medium={medium}
                    onChange={handleSelectedSensors}
                    onOrderChange={handleOrder}
                    order={order}
                    selected={selectedSensors}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Collapse in={!periodData.showWarning}>
            <ConsumptionTable
              colors={sensorColors}
              cols={viewCols}
              data={tableDataExport}
              granularity={granularityTitle(config.granularity)}
              medium={medium}
              onClick={openTableRowsDialog}
              roomsMode={roomsMode}
              unitConfig={unitConfig}
            />
          </Collapse>
        </KPage>
      )}
    </>
  )
}

PerformanceDetail.propTypes = {
  PAGE_CONFIG: PropTypes.object,
  title: PropTypes.string,
}
