import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sensorFactorActions } from '../../actions'

export const useSensorFactor = sensorId => {
  const dispatch = useDispatch()
  const { allSensorFactor } = useSelector(state => state.sensorFactor)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(sensorFactorActions.getAll(sensorId))
    setLoading(true)
  }, [dispatch, sensorId])

  useEffect(() => {
    if (allSensorFactor) {
      const { error, item } = allSensorFactor

      if (item) {
        const { data } = item

        if (data) {
          setData(data)
          setLoading(false)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
      }
    }
  }, [allSensorFactor])

  return { data, isError, isLoading }
}
