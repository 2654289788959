import {
  Avatar,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SensorIcon } from '../../api'
import {
  useOperator,
  useSensorName,
  useSensorStatus,
  useSensorType,
} from '../../hooks'

export const CorrelationFeelingItem = ({
  color = 'primary',
  selected,
  sensorId,
  ...rest
}) => {
  const { data: operatorData } = useOperator()
  const sensorName = useSensorName(sensorId)
  const sensorStatus = useSensorStatus(sensorId)
  const sensorType = useSensorType(sensorId)

  const src = SensorIcon(sensorType, sensorStatus)
  const title = operatorData?.isAdmin ? `Sensor ID: ${sensorId}` : ''

  return (
    <ListItem sx={{ p: 0, width: 1 }}>
      <ListItemButton selected={selected} {...rest}>
        <ListItemAvatar>
          <Tooltip title={title}>
            <Avatar src={src} />
          </Tooltip>
        </ListItemAvatar>

        <ListItemText primary={sensorName} />

        <Checkbox color={color} checked={selected} />
      </ListItemButton>
    </ListItem>
  )
}

CorrelationFeelingItem.propTypes = {
  color: PropTypes.string,
  selected: PropTypes.bool,
  sensorId: PropTypes.number,
}
