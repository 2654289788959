import { SettingsRemoteTwoTone } from '@mui/icons-material'
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const VirtualSensorItem = ({ sensor, sensorId, ...rest }) => {
  const {
    combined_virtual_sensor_id: vSensorId,
    combined_virtual_sensor_device_id: vDeviceId,
    name,
    original_sensors_id,
    virtual_sensor_type: vSensorType,
  } = sensor.attributes

  const sensorName = name ?? ''
  const origSensorIds = original_sensors_id.replaceAll(',', ', ')

  const onClick = () => history.push(`/virtualsensors/${sensorId}/edit`)

  return (
    <ListItemButton onClick={onClick} {...rest}>
      <ListItemAvatar>
        <Tooltip title={`Virtuelle Sensor ID: ${sensorId}`}>
          <Avatar>
            <SettingsRemoteTwoTone />
          </Avatar>
        </Tooltip>
      </ListItemAvatar>

      <ListItemText
        primary={sensorName}
        secondary={`kombinierte Sensor ID: ${vSensorId}`}
        sx={{ width: 0.25 }}
      />

      <ListItemText
        primary={'Device ID'}
        secondary={vDeviceId}
        sx={{ width: 0.25 }}
      />

      <ListItemText
        primary={'Original Sensoren'}
        secondary={origSensorIds}
        sx={{ width: 0.25 }}
      />

      <ListItemText
        primary={'Sensor Typ'}
        secondary={vSensorType}
        sx={{ width: 0.25 }}
      />
    </ListItemButton>
  )
}

VirtualSensorItem.propTypes = {
  sensor: PropTypes.object.isRequired,
  sensorId: PropTypes.number.isRequired,
}
