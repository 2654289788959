import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  LinearProgress,
  List,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getStatusColor, normalizedSensorType, SVGStatus } from '../../api'
import { useFeelingSensors } from '../../hooks'
import { FeelingRoomSensor, IntervalAlert } from '..'

export const FeelingRoom = ({ folderId, title }) => {
  const history = useHistory()
  const fSensors = useFeelingSensors(folderId)
  const roomSensors = [
    ...new Set(fSensors.filter(f => f.name === title)),
  ].sort()
  const roomStatus = getStatusColor(roomSensors)

  const avatar = (
    <Avatar>
      <SVGStatus type={'feeling'} status={roomStatus} />
    </Avatar>
  )

  return (
    <Card variant={'feeling'}>
      <CardHeader
        avatar={avatar}
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        variant={'feeling'}
      />

      {fSensors.length === 0 ? (
        <LinearProgress />
      ) : (
        <CardContent sx={{ p: '0 !important' }}>
          <Collapse in={true}>
            {/* display interval alert, if applicable  */}
            <IntervalAlert title={title} sensors={fSensors} />

            <List>
              {roomSensors.map((item, key) => {
                const {
                  entity_id,
                  sensor_type,
                  sub_sensor_target,
                  type_in_folder,
                } = item
                const sensorId = Number(entity_id)

                const nst = normalizedSensorType(
                  sensor_type,
                  type_in_folder,
                  sub_sensor_target
                )
                const target = `/folder/${folderId}/feeling/${nst}/${sensorId}`
                const onClick = () => history.push(target)

                return (
                  <FeelingRoomSensor
                    folderId={folderId}
                    key={key}
                    onClick={onClick}
                    sensorId={sensorId}
                  />
                )
              })}
            </List>
          </Collapse>
        </CardContent>
      )}
    </Card>
  )
}

FeelingRoom.propTypes = {
  folderId: PropTypes.number,
  sensorId: PropTypes.number,
  title: PropTypes.string,
}
