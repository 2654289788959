import { API } from 'aws-amplify'
import axios from 'axios'
import { authHeader } from '../api'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const deleteVirtualSensor = async id => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/virtualsensor/combined/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('virtualSensorService.deleteVirtualSensor', error)
  }
}

const getAll = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/virtualsensor/combined`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    return { error: error.message }
  }
}

const save = async body => {
  const endpoint = decodeURIComponent(`/virtualsensor/combined`)
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

const update = async body => {
  const { combined_virtual_sensor_id } = body.data[0].attributes
  const endpoint = `/virtualsensor/combined/${combined_virtual_sensor_id}`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const virtualSensorService = {
  deleteVirtualSensor,
  getAll,
  save,
  update,
}
