import { MemoryTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Checkbox,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import {
  useFolderImage,
  useFolderName,
  useFolderSensors,
  useFolderShortName,
} from '../../hooks'

export const FolderListItem = ({ folderId, onClick, selected }) => {
  const folderName = useFolderName(folderId)
  const folderShortName = useFolderShortName(folderId)
  const folderImage = useFolderImage(folderId)
  const folderSensors = useFolderSensors(folderId)
  const fsCount = folderSensors?.length

  return (
    <ListItem>
      <ListItemButton selected={selected} onClick={onClick}>
        <ListItemAvatar>
          <Avatar src={folderImage} />
        </ListItemAvatar>

        <ListItemText
          primary={folderName}
          secondary={folderShortName}
          sx={{ width: 0.5 }}
        />

        <ListItemText
          primary={
            <Chip
              icon={<MemoryTwoTone />}
              label={fsCount}
              color={fsCount > 0 ? 'default' : 'warning'}
            />
          }
          sx={{ width: 0.5 }}
        />

        <ListItemIcon>
          <Checkbox checked={selected} />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  )
}

FolderListItem.propTypes = {
  folderId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}
