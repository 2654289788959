import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'

export const useRoomsMode = folderId => {
  const [data, setData] = useState('Mitarbeiter')

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.rooms_mode) return

    const mode = folderData?.attributes?.rooms_mode

    switch (mode) {
      case 'employees':
        setData('Mitarbeiter')
        break
      case 'rooms':
        setData('Zimmer')
        break
      default:
        setData('Mitarbeiter')
        break
    }
  }, [folderPool, folderId])

  return data
}

useRoomsMode.propTypes = {
  folderId: PropTypes.number,
}
