import { AddTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const BtnAddDashboard = ({
  icon = <AddTwoTone />,
  title = 'Neues Dashboard',
  ...rest
}) => (
  <Tooltip title={title}>
    <IconButton {...rest}>{icon}</IconButton>
  </Tooltip>
)

BtnAddDashboard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
}
