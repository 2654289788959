import { BedtimeTwoTone, CheckTwoTone } from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { userConstants as UC } from '../../constants'

const { USER_STATUS_ACTIVE: ACTIVE, USER_STATUS_INACTIVE: INACTIVE } = UC

export const UserStatusSelector = ({
  data = [
    { icon: CheckTwoTone, title: ACTIVE, value: ACTIVE },
    { icon: BedtimeTwoTone, title: INACTIVE, value: INACTIVE },
  ],
  onChange,
  value,
  ...rest
}) => (
  <ToggleButtonGroup onChange={onChange} value={value} {...rest}>
    {data.map((status, key) => (
      <Tooltip key={key} title={status.title}>
        <ToggleButton key={key} value={status.value}>
          <status.icon sx={{ mr: 1 }} /> {status.title}
        </ToggleButton>
      </Tooltip>
    ))}
  </ToggleButtonGroup>
)

UserStatusSelector.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}
