import {
  ArrowBackTwoTone,
  RefreshTwoTone,
  WidthWideTwoTone,
} from '@mui/icons-material'
import { Box, Divider, Fade, ListSubheader, Menu } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAppConfig } from '../../hooks'
import { AMItem } from './AMItem'

export const AM = ({
  caption,
  children,
  tiny = false,
  historyUrlTarget = '',
  historyUrlId,
  ...rest
}) => {
  const { appConfig, setAppConfig } = useAppConfig()

  const history = useHistory()
  let historyUrl = ''

  switch (historyUrlTarget) {
    case 'dashboard':
    case 'folder':
    case 'sensors':
    case 'news':
    case 'users':
    case 'notifications':
    case 'reports':
    case 'virtualsensors':
      historyUrl = `/${historyUrlTarget}`
      break

    case 'feeling':
    case 'security':
    case 'performance':
    case 'installation':
      historyUrl = `/folder/${historyUrlId}/home/${historyUrlTarget}`
      break

    default:
      historyUrl = '/dashboard'
  }

  // handler

  const reloadPage = () => window.location.reload()
  const goBack = () => historyUrl !== '' && history.push(historyUrl)
  const toggleWideScreen = () =>
    setAppConfig(prev => ({ ...prev, wideScreen: !prev.wideScreen }))

  return (
    <Menu {...rest} TransitionComponent={Fade}>
      <ListSubheader>{caption}</ListSubheader>

      {children}

      {!tiny && (
        <Box>
          <Divider />
          <ListSubheader>Seite</ListSubheader>
          <AMItem
            caption={'Wide Screen'}
            icon={<WidthWideTwoTone />}
            onClick={toggleWideScreen}
            selected={appConfig?.wideScreen || false}
          />
          <AMItem
            caption={'Neu laden'}
            icon={<RefreshTwoTone />}
            onClick={reloadPage}
          />
          <AMItem
            caption={'Zurück'}
            icon={<ArrowBackTwoTone />}
            onClick={goBack}
          />
        </Box>
      )}
    </Menu>
  )
}

AM.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.any,
  tiny: PropTypes.bool,
  historyUrlTarget: PropTypes.string,
  historyUrlId: PropTypes.number,
}
