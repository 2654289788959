import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { newsConstants as NC } from '../../constants'

const { NEWS_CATEGORY_CONTRACT, NEWS_CATEGORY_OBJECT } = NC

export const NewsCategorySelector = ({
  data = [
    { label: NEWS_CATEGORY_CONTRACT, value: NEWS_CATEGORY_CONTRACT },
    { label: NEWS_CATEGORY_OBJECT, value: NEWS_CATEGORY_OBJECT },
  ],
  onChange,
  value,
}) => (
  <ToggleButtonGroup onChange={onChange} value={value}>
    {data.map((role, key) => (
      <Tooltip title={role.label} key={key}>
        <ToggleButton key={key} value={role.value}>
          {role.label}
        </ToggleButton>
      </Tooltip>
    ))}
  </ToggleButtonGroup>
)

NewsCategorySelector.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}
