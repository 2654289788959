import { Alert } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const IntervalAlert = ({ sensors, title }) => {
  let time = sensors?.find(f => f.name === title)
  time = time?.time || ''

  const hours = new Date(Date.now() - new Date(time)).getTime() / 1000 / 60 / 60
  const days = Math.trunc(hours / 24)

  let severity = 'success'
  if (days >= 3) severity = 'error'
  if (days >= 1 && days < 3) severity = 'warning'
  if (days <= 0) severity = 'success'

  return severity !== 'success' ? (
    <Alert severity={severity}>
      {severity === 'warning' && <>zuletzt aktualisiert vor mehr als 24h</>}
      {severity === 'error' && <>zuletzt aktualisiert vor mehr als 72h</>}
    </Alert>
  ) : null
}

IntervalAlert.propTypes = {
  sensors: PropTypes.array,
  title: PropTypes.string,
}
