import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFolderPool } from '..'

export const useFolderParent = folderId => {
  const [data, setData] = useState(null)

  const { data: folderPool } = useFolderPool()

  useEffect(() => {
    const folderData = folderPool.find(f => Number(f.id) === folderId)
    if (!folderData?.attributes?.parent) return

    const parent = folderData?.attributes?.parent

    if (parent) setData(parent)
  }, [folderPool, folderId])

  return data
}

useFolderParent.propTypes = {
  folderId: PropTypes.number,
}
