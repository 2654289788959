import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { userActions } from './actions'
import { createStorageConfig, history } from './api'
import { Layout, Page } from './layouts'
import { theme } from './pages/karma.theme'
import { SnackbarProvider } from 'notistack'
import { useIdleLogout } from './hooks/user'

export const App = () => {
  const handleLogout = () => {
    userActions.logout()
    history.push('/login')
  }

  useIdleLogout(handleLogout, 12 * 60 * 60 * 1000)

  createStorageConfig()

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={5000}
        maxSnack={10}
      >
        <CssBaseline />

        <Router history={history}>
          <Switch>
            <Route component={Page} path={'/pages'} />
            <Route component={Layout} path={'/'} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
