import { CloseTwoTone, NotificationImportantTwoTone } from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { getStorage, normalizedSensorType, sensorUnit } from '../../api'
import { useAppConfig } from '../../hooks'
import { ColorPicker } from '../ColorPicker'
import { HStack } from '../HStack'

export const DlgFeelingLimits = ({
  maxLimit,
  medium,
  mediumSensors,
  minLimit,
  onChangeLineColor,
  onChangeMaxLimit,
  onChangeMinLimit,
  onClose,
  open,
  PAGE_CONFIG = 'settings_feeling_history',
  sensorId,
  title = 'Grenzwerte',
}) => {
  const { appConfig } = useAppConfig()
  const salutationMode = appConfig?.salutationMode

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const lineColor = config?.lineColor

  const theme = useTheme()
  const { main: bgcolor } = theme.palette.secondary
  const { white: color } = theme.palette

  const handleChange = lineColor => {
    setConfig(prev => ({ ...prev, lineColor }))

    onChangeLineColor(lineColor)
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle
        onClose={onClose}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Avatar sx={{ bgcolor, color, mr: 1 }}>
          <NotificationImportantTwoTone />
        </Avatar>

        <Typography sx={{ mr: 1, fontSize: 20 }}>{title}</Typography>

        <IconButton onClick={onClose} sx={{ bgcolor, color }}>
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Alert severity={'info'} sx={{ mt: 2 }}>
          Hier {salutationMode === 'default' ? 'können Sie' : 'kannst Du'}{' '}
          auswählen, ob für den ausgewählten Messpunkt Grenzwertlinien
          eingeblendetet werden sollen.
        </Alert>

        {mediumSensors
          ?.filter(f => Number(f.entity_id) === sensorId)
          .filter(f => normalizedSensorType(f.sensor_type) === medium)
          .map((sensor, key) => {
            const { name, attributes_values: av } = sensor

            const alarm_min = av?.find(
              f => f.attribute_code === 'alarm_min'
            )?.value

            const alarm_max = av?.find(
              f => f.attribute_code === 'alarm_max'
            )?.value

            const onChangeMin = () =>
              onChangeMinLimit(minLimit ? null : alarm_min)

            const onChangeMax = () =>
              onChangeMaxLimit(maxLimit ? null : alarm_max)

            const min = alarm_min
              ? `${alarm_min} ${sensorUnit(medium)}`
              : 'Nicht hinterlegt'

            const max = alarm_max
              ? `${alarm_max} ${sensorUnit(medium)}`
              : 'Nicht hinterlegt'

            return (
              <HStack
                justifyContent={'space-between'}
                spacing={2}
                key={key}
                sx={{ width: 1 }}
              >
                <List sx={{ width: 1 }}>
                  <ListItem>
                    <ListItemText primary={'Messpunkt'} secondary={name} />
                  </ListItem>

                  <ListItem>
                    <ListItemText primary={'Minimum'} secondary={min} />

                    <Switch
                      checked={minLimit !== null}
                      disabled={alarm_min === undefined}
                      onChange={onChangeMin}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText primary={'Maximum'} secondary={max} />

                    <Switch
                      checked={maxLimit !== null}
                      disabled={alarm_max === undefined}
                      onChange={onChangeMax}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText primary={'Linienfarbe'} />

                    <ColorPicker
                      color={lineColor}
                      onChange={color => handleChange(color)}
                    />
                  </ListItem>
                </List>
              </HStack>
            )
          })}
      </DialogContent>
    </Dialog>
  )
}

DlgFeelingLimits.propTypes = {
  maxLimit: PropTypes.any,
  medium: PropTypes.string,
  mediumSensors: PropTypes.array,
  minLimit: PropTypes.any,
  onClose: PropTypes.func,
  onChangeLineColor: PropTypes.func,
  onChangeMaxLimit: PropTypes.func,
  onChangeMinLimit: PropTypes.func,
  open: PropTypes.bool,
  PAGE_CONFIG: PropTypes.string,
  sensorId: PropTypes.number,
  title: PropTypes.string,
}
