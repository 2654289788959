import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppConfig, useOperator } from '../../../hooks'

export const OperatorGreeting = () => {
  const { data } = useOperator()
  const { daytimeGreeting, appConfig } = useAppConfig()
  const salutationMode = appConfig?.salutationMode

  const [salutation, setSalutation] = useState('')

  useEffect(() => {
    if (!data || !salutationMode) return

    const { first_name, last_name } = data
    const salutation =
      salutationMode === 'default' ? `${first_name} ${last_name}` : first_name

    setSalutation(salutation)
  }, [data, salutationMode])

  return salutationMode === 'noSalutation' ? null : (
    <Typography sx={{ textAlign: 'center', p: 5 }} variant={'h4'}>
      {daytimeGreeting()}, {salutation}!
    </Typography>
  )
}
