import { EmailTwoTone, FaceTwoTone } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from '../../actions'
import { isValidEmail, isValidPassword } from '../../api'
import { KTextField } from '..'

export const DlgRegistrationForm = ({ open, title = 'Registrieren' }) => {
  const dispatch = useDispatch()

  const [regForm, setRegForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  })
  const [termsChecked, setTermsChecked] = useState(false)

  const LICENSE_AGREEMENT_AND_PRIVACY_TEXT = (
    <small>
      ich stimme{' '}
      <a
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={'https://karma.de/agb'}
      >
        AGB
      </a>{' '}
      und{' '}
      <a
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={'https://karma.de/datenschutz'}
      >
        Datenschutzerklärung
      </a>{' '}
      zu
    </small>
  )

  const INVALID_EMAIL_TEXT = 'E-Mail Adresse muss ein gültiges Schema besitzen.'
  const INVALID_PASSWORD_TEXT =
    'Passwort muss mind. 8 Stellen haben, 1 Groß-, 1 Kleinbuchstaben und 1 Zahl enthalten.'

  const IS_INVALID_REGDATA =
    regForm.firstName === '' ||
    regForm.lastName === '' ||
    regForm.email === '' ||
    regForm.password === '' ||
    !isValidEmail(regForm.email) ||
    !isValidPassword(regForm.password) ||
    !termsChecked

  const changeFirstName = event =>
    setRegForm(prev => ({ ...prev, firstName: event.target.value }))
  const changeLastName = event =>
    setRegForm(prev => ({ ...prev, lastName: event.target.value }))
  const changeEmail = event =>
    setRegForm(prev => ({ ...prev, email: event.target.value.toLowerCase() }))
  const changePassword = event =>
    setRegForm(prev => ({ ...prev, password: event.target.value }))

  const submitRegistration = event => {
    event.preventDefault()

    dispatch(userActions.register(regForm))
  }

  return (
    <Dialog open={open} sx={{ zIndex: 3 }}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent sx={{ p: 1, mt: 1 }}>
        <form name={'form'} onSubmit={submitRegistration}>
          <KTextField
            helperText={
              regForm.firstName === '' && 'Der Vorname darf nicht leer sein.'
            }
            icon={<FaceTwoTone />}
            label={'Vorname'}
            onChange={changeFirstName}
            required
            value={regForm.firstName}
          />

          <KTextField
            helperText={
              regForm.lastName === '' && 'Der Name darf nicht leer sein.'
            }
            icon={<FaceTwoTone />}
            label={'Name'}
            onChange={changeLastName}
            required
            value={regForm.lastName}
          />

          <KTextField
            autoComplete={'email'}
            helperText={!isValidEmail(regForm.email) && INVALID_EMAIL_TEXT}
            icon={<EmailTwoTone />}
            label={'E-Mail'}
            onChange={changeEmail}
            required
            value={regForm.email}
          />

          <KTextField
            autoComplete={'current-password'}
            helperText={
              !isValidPassword(regForm.password) && INVALID_PASSWORD_TEXT
            }
            label={'Passwort'}
            onChange={changePassword}
            required
            type={'password'}
            value={regForm.password}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={termsChecked}
                onClick={() => setTermsChecked(!termsChecked)}
              />
            }
            label={LICENSE_AGREEMENT_AND_PRIVACY_TEXT}
            sx={{ mx: 0, mb: 2 }}
          />
          <Box sx={{ textAlign: 'center' }}>
            <Button
              disabled={IS_INVALID_REGDATA}
              type={'submit'}
              variant={'contained'}
            >
              Registrieren
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

DlgRegistrationForm.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
}
