import { DangerousTwoTone } from '@mui/icons-material'
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { sensorType, SensorIcon, getStatusColor } from '../../api'
import { useFolderSensorIds, useFolderSensors } from '../../hooks'
import { HStack } from '..'

// filter

const ENERGY_SENSORS = f =>
  f.sensor_target === 'performance' &&
  (f.sub_sensor_target === 'energy' || f.sub_sensor_target === 'energy_detail')

const WATER_SENSORS = f =>
  f.sensor_target === 'performance' &&
  (f.sub_sensor_target === 'water' || f.sub_sensor_target === 'water_detail')

const GAS_SENSORS = f =>
  f.sensor_target === 'performance' &&
  (f.sub_sensor_target === 'gas' || f.sub_sensor_target === 'gas_detail')

// component

export const MediumSelector = ({
  consumptionData,
  folderId,
  label = 'Energiemedium',
  size = 25,
  variant = 'standard',
  ...rest
}) => {
  const folderSensors = useFolderSensors(folderId)
  const fsIds = useFolderSensorIds(folderId)

  const [mediumTypes, setMediumTypes] = useState([])
  const [groupStatus, setGroupStatus] = useState([])

  // exceptions
  const noMedia = mediumTypes.length === 0

  useEffect(() => {
    const mediumTypes = [
      ...new Set(
        consumptionData
          // remove consumptionData not assigned to operator sensors
          .filter(f => fsIds.includes(f.entity_id))

          // get sensor types in consumptionData for available medium types
          .map(sensor => sensor.type_in_folder)

          // merge main & sub sensor types (there is no distinction in UI)
          .map(sensor => sensor.replace('_detail', ''))
      ),
    ]

    setMediumTypes(mediumTypes)
  }, [consumptionData, fsIds])

  useEffect(() => {
    if (folderSensors.length === 0 || fsIds.length === 0) return

    setGroupStatus(folderSensors.filter(f => fsIds.includes(f.entity_id)))
  }, [folderSensors, fsIds])

  return mediumTypes.length === 0 ? null : (
    <TextField label={label} select variant={variant} {...rest}>
      {noMedia && (
        <MenuItem disabled>
          <HStack>
            <ListItemIcon>
              <DangerousTwoTone color={'error'} />
            </ListItemIcon>

            <ListItemText>Kein Medium</ListItemText>
          </HStack>
        </MenuItem>
      )}

      {mediumTypes.map((medium, key) => {
        let status

        switch (medium) {
          case 'energy':
            status = groupStatus
              .filter(ENERGY_SENSORS)
              .map(sensor => ({ ...sensor, ...sensor.attributes }))
            break

          case 'water':
            status = groupStatus
              .filter(WATER_SENSORS)
              .map(sensor => ({ ...sensor, ...sensor.attributes }))
            break

          case 'gas':
            status = groupStatus
              .filter(GAS_SENSORS)
              .map(sensor => ({ ...sensor, ...sensor.attributes }))
            break

          default:
            console.error('unhandled medium:', medium)
            break
        }

        const color = getStatusColor(status)

        return (
          <MenuItem key={key} value={medium}>
            <HStack>
              <ListItemIcon>
                <Box
                  component={'img'}
                  src={SensorIcon(medium, color)}
                  sx={{ width: size }}
                />
              </ListItemIcon>

              <ListItemText>{sensorType(medium)}</ListItemText>
            </HStack>
          </MenuItem>
        )
      })}
    </TextField>
  )
}

MediumSelector.propTypes = {
  consumptionData: PropTypes.array.isRequired,
  folderId: PropTypes.number.isRequired,
  label: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
}
