import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useSensorType = sensorId => {
  const [data, setData] = useState('')

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const sensorData = sensorPool.find(f => Number(f.id) === sensorId)
    if (!sensorData?.attributes?.sensor_type) return

    const sensorType = sensorData?.attributes?.sensor_type
    if (sensorType) setData(sensorType)
  }, [sensorPool, sensorId])

  return data
}

useSensorType.propTypes = {
  sensorId: PropTypes.number,
}
