import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useSensorTarget = sensorId => {
  const [data, setData] = useState(null)

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const sensorData = sensorPool.find(f => Number(f.id) === sensorId)
    if (!sensorData?.attributes?.sensor_target) return

    const sensorTarget = sensorData?.attributes?.sensor_target

    if (sensorTarget) setData(sensorTarget)
  }, [sensorPool, sensorId])

  return data
}

useSensorTarget.propTypes = {
  sensorId: PropTypes.number,
}
