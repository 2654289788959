import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const DlgSensorDelete = ({
  onClose,
  onDelete,
  open,
  title = 'Löschen bestätigen',
}) => (
  <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>

    <DialogActions>
      <Button onClick={onDelete}>Löschen</Button>
      <Button onClick={onClose}>Abbrechen</Button>
    </DialogActions>
  </Dialog>
)

DlgSensorDelete.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}
