import { Collapse } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import {
  AboRecipients,
  AboutAboRecipients,
  AboutAbos,
  AddAboRecipient,
} from '../../../components'

export const AboSection = ({ mode, data, setRecipientsList }) => {
  const MODE_ABO = 1

  const onAdd = email => setRecipientsList(prev => [...prev, { email }])
  const onDelete = data => setRecipientsList(data)

  return (
    <Collapse in={mode === MODE_ABO}>
      <AboutAbos sx={{ my: 2 }} />
      <AboRecipients data={data} onDelete={onDelete} />
      <AboutAboRecipients sx={{ my: 2 }} />
      <AddAboRecipient onAdd={onAdd} />
    </Collapse>
  )
}

AboSection.propTypes = {
  mode: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  setRecipientsList: PropTypes.func.isRequired,
}
