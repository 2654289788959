import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from '../../actions'
import { useFolderPool, useOperator, useRoomsMode } from '../../hooks'

export const DlgConsumptionTableCols = ({
  folderId,
  onClose,
  onConfirm,
  open,
  title = 'Tabellenansicht',
}) => {
  const roomsMode = useRoomsMode(folderId)
  const { data: folderPool } = useFolderPool()
  const dispatch = useDispatch()
  const [userId, setUserId] = useState(null)
  const { data: operatorData } = useOperator()

  const data = [
    { label: 'Datum', accessor: 'date' },
    { label: 'Verbrauch', accessor: 'actual_consumption' },
    { label: 'Trend', accessor: 'trend' },
    { label: 'CO₂-Emissionen', accessor: 'emissions' },
    { label: 'Verbrauch / m²', accessor: 'area_consumption' },
    { label: `Verbrauch / ${roomsMode}`, accessor: 'employee_consumption' },
    { label: 'Kosten', accessor: 'actual_costs' },
    { label: 'Kosten / m²', accessor: 'area_costs' },
    { label: 'Benchmark', accessor: 'benchmark' },
    { label: 'Zählerstand', accessor: 'counter_reading' },
    { label: `Kosten / ${roomsMode}`, accessor: 'employee_costs' },
  ]

  const [useGlobalVO, setUseGlobalVO] = useState(null)

  const [cols, setCols] = useState({
    date: true,
    actual_consumption: true,
    trend: true,
    emissions: true,
    area_consumption: true,
    employee_consumption: true,
    actual_costs: true,
    area_costs: true,
    benchmark: true,
    counter_reading: true,
    employee_costs: true,
  })

  const [unitConfig, setUnitConfig] = useState({
    header: false,
    values: true,
  })

  useEffect(() => {
    if (operatorData === null) return

    const { id, view_options } = operatorData

    const data = [
      { label: 'Datum', accessor: 'date' },
      { label: 'Verbrauch', accessor: 'actual_consumption' },
      { label: 'Trend', accessor: 'trend' },
      { label: 'CO₂-Emissionen', accessor: 'emissions' },
      { label: 'Verbrauch / m²', accessor: 'area_consumption' },
      { label: `Verbrauch / ${roomsMode}`, accessor: 'employee_consumption' },
      { label: 'Kosten', accessor: 'actual_costs' },
      { label: 'Kosten / m²', accessor: 'area_costs' },
      { label: 'Benchmark', accessor: 'benchmark' },
      { label: 'Zählerstand', accessor: 'counter_reading' },
      { label: `Kosten / ${roomsMode}`, accessor: 'employee_costs' },
    ]

    // do we have view options for all folders? (view options but no folderId in record)
    const voAllFolders = view_options.find(f => f.folder_id === 0)

    // do we have view options for the current folder? (view options with folderId matching the folderId in record)
    const voCurrentFolder = view_options.find(f => f.folder_id === folderId)

    if (voAllFolders) setUseGlobalVO(true)
    if (voCurrentFolder) setUseGlobalVO(false)

    const cols = {
      date: true,
      actual_consumption: true,
      trend: true,
      emissions: true,
      area_consumption: true,
      employee_consumption: true,
      actual_costs: true,
      area_costs: true,
      benchmark: true,
      counter_reading: true,
      employee_costs: true,
    }

    if (voAllFolders) {
      const vo = voAllFolders.columns
        .split('x')[1]
        .split('')
        .map(f => Boolean(Number(f)))

      data.forEach((col, key) => (cols[col.accessor] = vo[key]))

      setCols(cols)

      const uc = voAllFolders.columns
        .split('x')[0]
        .split('')
        .map(f => Boolean(Number(f)))
      setUnitConfig({ header: uc[0], values: uc[1] })
    }

    if (voCurrentFolder) {
      const vo = voCurrentFolder.columns
        .split('x')[1]
        .split('')
        .map(f => Boolean(Number(f)))

      data.forEach((col, key) => (cols[col.accessor] = vo[key]))

      setCols(cols)

      const uc = voCurrentFolder.columns
        .split('x')[0]
        .split('')
        .map(f => Boolean(Number(f)))
      setUnitConfig({ header: uc[0], values: uc[1] })
    }

    setUserId(id)
  }, [operatorData, folderId, roomsMode])

  const onUpdate = () => {
    const { view_options } = operatorData

    const folder_id = useGlobalVO === true ? 0 : folderId
    const uCfg = `${Number(unitConfig.header)}${Number(unitConfig.values)}`
    console.log(uCfg)

    const columns =
      uCfg +
      'x' +
      Object.values(cols)
        .map(item => Number(Boolean(item)))
        .toString()
        .replace(/,/g, '')

    const vOptions =
      view_options.length > 0
        ? view_options.map(option => ({ ...option, folder_id, columns }))
        : [{ entity_id: null, user_id: userId, folder_id, columns }]

    const updatedData = { ...operatorData, view_options: vOptions }

    dispatch(userActions.updateOperator(userId, updatedData))
    onConfirm(cols, unitConfig)
    onClose()
  }

  return (
    <Dialog maxWidth={'lg'} open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Alert severity={'info'} sx={{ mt: 2 }}>
          <AlertTitle>Darstellen von Einheiten</AlertTitle>

          <Typography>
            Einheiten können im Spaltennamen und/oder hinter Werten angezeigt
            werden.
          </Typography>
        </Alert>

        <FormControl sx={{ m: 2, display: 'block' }}>
          <List>
            <ListItemButton
              onClick={() => {
                setUnitConfig(prev => ({ ...prev, header: !prev.header }))
              }}
              sx={{ p: 0, width: 1 }}
            >
              <ListItemText primary={'Einheit im Spaltennamen anzeigen'} />

              <Checkbox checked={unitConfig.header === true} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                setUnitConfig(prev => ({ ...prev, values: !prev.values }))
              }}
              sx={{ p: 0 }}
            >
              <ListItemText primary={'Einheit hinter Werten anzeigen'} />

              <Checkbox checked={unitConfig.values === true} />
            </ListItemButton>
          </List>
        </FormControl>

        <Alert severity={'info'} sx={{ mt: 2 }}>
          <AlertTitle>Spaltenkonfiguration</AlertTitle>

          <Typography>
            Bestimmen Sie hier, welche Spalten in der Tabelle angezeigt werden
            sollen.
          </Typography>

          <Typography>
            Die Spalte Datum ist erforderlich und lässt sich nicht deaktivieren.
          </Typography>
        </Alert>

        <List>
          {data.map((cell, key) => {
            const { accessor, label } = cell
            const checked = cols[accessor]

            const toggle = () => {
              setCols(prev => {
                const newVO = { ...prev }
                newVO[accessor] = !prev[accessor]

                return newVO
              })
            }

            return (
              <ListItem key={key} sx={{ p: 0 }}>
                <ListItemButton
                  disabled={key === 0}
                  onClick={toggle}
                  sx={{ py: 0 }}
                >
                  <ListItemText>{label}</ListItemText>

                  <Checkbox checked={checked} disabled={key === 0} />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>

        <Collapse in={folderPool.length > 1}>
          <Divider />

          <Alert severity={'info'}>
            <AlertTitle>Gültigkeit</AlertTitle>

            <Typography>
              Da Sie über mehrere Gebäude verfügen, können Sie die Gültigkeit
              der Spaltenkonfiguration reglementieren.
            </Typography>
          </Alert>

          <FormControl sx={{ m: 2 }}>
            <FormLabel>Anwenden auf</FormLabel>

            <RadioGroup value={useGlobalVO}>
              {[
                {
                  fn: () => setUseGlobalVO(false),
                  label: 'aktuelles Gebäude',
                  value: false,
                },
                {
                  fn: () => setUseGlobalVO(true),
                  label: 'alle meine Gebäude',
                  value: true,
                },
              ].map((item, key) => (
                <FormControlLabel
                  control={<Radio />}
                  key={key}
                  label={item.label}
                  onClick={item.fn}
                  value={item.value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Collapse>
      </DialogContent>

      <DialogActions>
        <Button disabled={useGlobalVO === null} onClick={onUpdate}>
          Speichern
        </Button>

        <Button onClick={onClose}>Abbrechen</Button>
      </DialogActions>
    </Dialog>
  )
}

DlgConsumptionTableCols.propTypes = {
  folderId: PropTypes.number,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
}
