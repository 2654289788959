import { CloseTwoTone, DownloadTwoTone } from '@mui/icons-material'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { getStorage, setStorage, userLocales } from '../../api'
import { exportToExcel } from '../../pages/Sensor/All.export'
import { HStack } from '../HStack'

export const DlgSensorsExport = ({
  filename = 'Sensor Übersicht',
  onClose,
  open,
  page,
  PAGE_CONFIG = 'settings_sensors_all',
  rows,
  title = 'Sensoren',
}) => {
  const theme = useTheme()
  const { main: bgcolor } = theme.palette.secondary
  const { white: color } = theme.palette

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const exportMode = config?.exportMode

  const [data, setData] = useState([])

  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  useEffect(() => {
    const data = exportMode === 'page' ? page : rows

    setData(
      data
        .map(data => data.original)
        .map(data => ({
          ['Sensor ID']: Number(data.entity_id),
          ['aktiviert']: Boolean(data.is_active),
          ['Sensor Name']: String(data.name),
          ['Device ID']: String(data.device_id),
          ['Sensor Typ']: String(data.sensor_type),
          ['Provider']: String(data.provider),
          ['Wert']: String(data.value),
          ['Gebäude']: String(data.folder_name),
          ['Status']: String(data.status),
          ['letzte Aktivität']: new Date(data.time).toLocaleString(userLocales),
        }))
    )
  }, [rows, page, exportMode])

  const onChangeExportMode = event => {
    const exportMode = event.target.value

    setConfig(prev => ({ ...prev, exportMode }))
  }

  const onDownloadExcel = () => exportToExcel(data, title, `${filename}.xlsx`)

  const options = [
    { count: rows.length, label: 'Dump', value: 'rows' },
    { count: page.length, label: 'Aktuelle Seite', value: 'page' },
  ]

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        onClose={onClose}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ mr: 1, fontSize: 20 }}>
          Exportieren: {title}
        </Typography>

        <IconButton onClick={onClose} sx={{ bgcolor, color }} variant={'close'}>
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <FormControl sx={{ mb: 1 }}>
          <FormLabel>Optionen</FormLabel>

          <RadioGroup onChange={onChangeExportMode} value={exportMode}>
            {options.map((option, key) => (
              <HStack key={key}>
                <FormControlLabel
                  control={<Radio />}
                  label={option.label}
                  value={option.value}
                />
                <Chip label={option.count} sx={{ ml: 1 }} />
              </HStack>
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <CSVLink
          data={data.map(item => [
            item['Sensor ID'],
            item['aktiviert'],
            item['Sensor Name'],
            item['Device ID'],
            item['Sensor Typ'],
            item['Provider'],
            item['Wert'],
            item['Gebäude'],
            item['Status'],
            item['letzte Aktivität'],
          ])}
          filename={`${filename}.csv`}
          headers={[
            'Sensor ID',
            'aktiviert',
            'Sensor Name',
            'Device ID',
            'Sensor Type',
            'Provider',
            'Wert',
            'GebäudeName',
            'Status',
            'Letzte Aktivität',
          ]}
          target={'_blank'}
        >
          <Button>
            <DownloadTwoTone />
            CSV
          </Button>
        </CSVLink>

        <Button onClick={onDownloadExcel}>
          <DownloadTwoTone />
          Excel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DlgSensorsExport.propTypes = {
  filename: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  page: PropTypes.array.isRequired,
  PAGE_CONFIG: PropTypes.string,
  rows: PropTypes.array.isRequired,
  title: PropTypes.string,
}
