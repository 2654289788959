import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notificationActions } from '../../actions'

const notificationPoolContext = createContext({})

export const ProvideNotificationPool = ({ children }) => {
  const value = useNotification()

  return (
    <notificationPoolContext.Provider value={value}>
      {children}
    </notificationPoolContext.Provider>
  )
}
ProvideNotificationPool.propTypes = { children: PropTypes.any }

export const useNotificationPool = () => useContext(notificationPoolContext)

const useNotification = () => {
  const dispatch = useDispatch()
  const { allNotifications } = useSelector(state => state.notifications)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(() => {
    dispatch(notificationActions.getAll())
    setLoading(true)
  }, [dispatch])

  useEffect(() => {
    const { error, fetchDate, item } = allNotifications

    if (item) {
      const { data } = item

      if (data) {
        setData(data)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    }

    if (error) {
      setError(error)
      setLoading(false)
      setFetchDate(fetchDate)
    }
  }, [allNotifications])

  return { data, isError, isLoading, fetchDate }
}
