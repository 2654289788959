import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSensorPool } from '..'

export const useSubSensorTarget = sensorId => {
  const [data, setData] = useState(null)

  const { data: sensorPool } = useSensorPool()

  useEffect(() => {
    const sensorData = sensorPool.find(f => Number(f.id) === sensorId)
    if (!sensorData?.attributes?.sub_sensor_target) return

    const subSensorTarget = sensorData?.attributes?.sub_sensor_target

    if (subSensorTarget) setData(subSensorTarget)
  }, [sensorPool, sensorId])

  return data
}

useSubSensorTarget.propTypes = {
  sensorId: PropTypes.number,
}
