import {
  ExitToAppTwoTone,
  PersonTwoTone,
  SettingsTwoTone,
  WidthWideTwoTone,
} from '@mui/icons-material'
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppConfig, useOperator } from '../../hooks'
import { userActions } from '../../actions'

export const OperatorMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { setAppConfig } = useAppConfig()
  const { data: operatorData } = useOperator()

  const [open, setOpen] = useState(null)

  const closeMenu = () => setOpen(null)

  const onGotoOperatorPage = () => {
    closeMenu()

    history.push('/user-page')
  }

  const onGotoSettingsPage = () => {
    closeMenu()

    setAppConfig(prev => ({ ...prev, showSettings: true }))
  }

  const onLogoutOperator = () => {
    closeMenu()

    dispatch(userActions.logout())

    history.push('/pages/login-page')
  }

  const onOpen = event => {
    setOpen(event.currentTarget)
  }

  const onToggleWideScreen = () => {
    closeMenu()

    setAppConfig(prev => ({ ...prev, wideScreen: !prev.wideScreen }))
  }

  const src = operatorData?.image_url

  const operatorName = operatorData
    ? `${operatorData.first_name} ${operatorData.last_name}`
    : ''

  const data = [
    { fn: onGotoOperatorPage, icon: PersonTwoTone, title: operatorName },
    { fn: onGotoSettingsPage, icon: SettingsTwoTone, title: 'Einstellungen' },
    { fn: onToggleWideScreen, icon: WidthWideTwoTone, title: 'Wide Screen' },
    { fn: onLogoutOperator, icon: ExitToAppTwoTone, title: 'Abmelden' },
  ]

  return (
    <>
      <IconButton onClick={onOpen}>
        <Avatar src={src}>{!src && <PersonTwoTone />}</Avatar>
      </IconButton>

      <Menu anchorEl={open} open={Boolean(open)} onClose={closeMenu}>
        {data.map((item, key) => (
          <MenuItem key={key} onClick={item.fn}>
            <item.icon sx={{ mr: 1 }} />
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
