import { HighlightOffTwoTone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Collapse,
  Container,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ASC, DESC, getStorage, setStorage } from '../../api'
import {
  DashboardAlerts,
  FolderStatus,
  HStack,
  KTextField,
  Preloader,
} from '../../components'
import { useAppConfig, useFolderPool, useMaintenanceMode } from '../../hooks'
import { OperatorGreeting } from './components'

export const Overview = ({ gap = 10, PAGE_CONFIG = 'settings_dashboard' }) => {
  const theme = useTheme()
  const { breakpoints } = theme
  const isXS = useMediaQuery(breakpoints.only('xs'))
  const isSM = useMediaQuery(breakpoints.only('sm'))
  const isMD = useMediaQuery(breakpoints.only('md'))
  const isLG = useMediaQuery(breakpoints.only('lg'))
  const isXL = useMediaQuery(breakpoints.only('xl'))
  const determineCols = () =>
    isXL ? 3 : isLG ? 2 : isMD ? 2 : isSM ? 1 : isXS ? 1 : 0

  const maintenanceMode = useMaintenanceMode()

  const { appConfig } = useAppConfig()
  const isWideScreen = appConfig?.wideScreen || false

  const { data, isLoading, isError } = useFolderPool()

  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))

  const [order, setOrder] = useState(ASC)
  const [orderby] = useState('name')

  const inputFilter = f =>
    f.attributes.name.toLowerCase().includes(config.objectFilter.toLowerCase())

  const sortFilter = (a, b) => {
    if (a.attributes[orderby] < b.attributes[orderby])
      return order === DESC ? 1 : -1

    return order === DESC ? -1 : 1
  }

  const onChangeFilter = event => {
    const objectFilter = event.target.value

    setConfig(prev => ({ ...prev, objectFilter }))
  }

  const onClearFilter = () => {
    const objectFilter = ''

    setConfig(prev => ({ ...prev, objectFilter }))
  }

  const onChangeOrder = event => setOrder(event.target.value)

  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  return (
    <Collapse in={!isLoading}>
      <Preloader error={isError} isLoading={isLoading} />

      <Container maxWidth={isWideScreen === true ? 'false' : 'xl'}>
        <OperatorGreeting />

        {maintenanceMode === 1 && (
          <Alert severity={'info'}>
            <AlertTitle>WARTUNGSARBEITEN</AlertTitle>

            <Typography sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
              {appConfig.maintenanceMessage}
            </Typography>
          </Alert>
        )}

        {appConfig.alertsEnabled && !isLoading && (
          <DashboardAlerts sx={{ my: 5 }} />
        )}

        <HStack sx={{ mb: 2, width: 1 }} justifyContent={'flex-end'}>
          <FormControl>
            <KTextField
              label={'Filter'}
              onChange={onChangeFilter}
              unit={
                config.objectFilter && (
                  <Tooltip title={'Filter löschen'}>
                    <IconButton onClick={onClearFilter}>
                      <HighlightOffTwoTone />
                    </IconButton>
                  </Tooltip>
                )
              }
              value={config.objectFilter}
            />
          </FormControl>

          <FormControl>
            <InputLabel>Reihenfolge</InputLabel>

            <Select
              //inputProps={{ name: 'order', id: 'order-helper' }}
              onChange={onChangeOrder}
              value={order}
            >
              {[
                { label: 'Aufsteigend', value: ASC },
                { label: 'Absteigend', value: DESC },
              ].map((item, key) => (
                <MenuItem key={key} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </HStack>

        {!isLoading && (
          <ImageList cols={determineCols()} gap={gap} sx={{ width: 1 }}>
            {data
              ?.filter(inputFilter)
              .sort(sortFilter)
              .map((folder, key) => (
                <ImageListItem key={key}>
                  <FolderStatus folderId={Number(folder.id)} />
                </ImageListItem>
              ))}
          </ImageList>
        )}
      </Container>
    </Collapse>
  )
}

Overview.propTypes = {
  gap: PropTypes.number,
  PAGE_CONFIG: PropTypes.string,
}
