import { Collapse, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SORT } from '../../api'
import { PackageUpgrade, SecurityRoom } from '../../components'
import { useBridgeId, useCoachData, useSecuritySensors } from '../../hooks'

export const Security = ({ folderId }) => {
  const securitySensors = useSecuritySensors(folderId)
  const bridgeSensor = useBridgeId(folderId)
  const coachData = useCoachData(bridgeSensor)

  const sSensorTypes = [
    ...new Set(securitySensors.map(sensor => sensor.name)),
  ].sort(SORT)

  const upgradeAvailable =
    securitySensors.length === 0 && bridgeSensor && coachData

  return (
    <Collapse in={true}>
      {upgradeAvailable && <PackageUpgrade coach={coachData} />}

      <Grid container spacing={5}>
        {sSensorTypes.map((item, key) => (
          <Grid item xs={12} md={6} lg={4} key={key}>
            <SecurityRoom folderId={Number(folderId)} title={item} />
          </Grid>
        ))}
      </Grid>
    </Collapse>
  )
}

Security.propTypes = {
  folderId: PropTypes.number,
}
