import { consumptionConstants as CC } from '../constants'
import { consumptionService as CS, tokenService as TS } from '../services'
import { alertActions as AA } from '.'

const getAll = (folderId, period, fromDate, toDate, isMultipleCall) => {
  let energyErrorType = CC.GET_ALL_ERROR
  let energyRequestType = CC.GET_ALL_REQUEST
  let energySuccessType = CC.GET_ALL_SUCCESS

  const failure = error => ({ type: energyErrorType, error })
  const request = () => ({ type: energyRequestType })
  const success = item => ({ type: energySuccessType, item })

  if (isMultipleCall)
    switch (period) {
      case 'm':
        energyErrorType = CC.GET_CONSUMPTION_ERROR_YEARLY
        energyRequestType = CC.GET_CONSUMPTION_REQUEST_YEARLY
        energySuccessType = CC.GET_CONSUMPTION_SUCCESS_YEARLY
        break

      case 'd':
        energyErrorType = CC.GET_CONSUMPTION_ERROR_MONTHLY
        energyRequestType = CC.GET_CONSUMPTION_REQUEST_MONTHLY
        energySuccessType = CC.GET_CONSUMPTION_SUCCESS_MONTHLY
        break

      case 'h':
        energyErrorType = CC.GET_CONSUMPTION_ERROR_24
        energyRequestType = CC.GET_CONSUMPTION_REQUEST_24
        energySuccessType = CC.GET_CONSUMPTION_SUCCESS_24
        break

      default:
        break
    }

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      CS.getAll(folderId, fromDate, toDate, period)
        .then(result => {
          const { error, data, status } = result

          if (error) {
            const httpStatus = status === undefined ? '' : ` (${status})`
            const message = `Consumptions Endpoint: ${error}${httpStatus}`

            dispatch(failure(message))
            dispatch(AA.error(message))
          }

          if (data) {
            dispatch(success(result))
          }
        })
        .catch(error => {
          console.log('result', error)
          dispatch(failure(error.message))
          dispatch(AA.error(error.message))
        })
    })
  }
}

const getAllMeterReadingByFolderId = (id, start, end) => {
  const failure = error => ({ type: CC.GET_ALL_METER_READING_ERROR, error })
  const request = () => ({ type: CC.GET_ALL_METER_READING_REQUEST })
  const success = item => ({ type: CC.GET_ALL_METER_READING_SUCCESS, item })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      CS.getAllMeterReading(id, start, end).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const getCompareConsumption = (folderId, period, fromDate, toDate) => {
  const failure = error => ({ type: CC.GET_COMPARE_CONSUMPTION_ERROR, error })
  const request = () => ({ type: CC.GET_COMPARE_CONSUMPTION_REQUEST })
  const success = item => ({ type: CC.GET_COMPARE_CONSUMPTION_SUCCESS, item })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      CS.getCompareConsumption(folderId, fromDate, toDate, period)
        .then(result => dispatch(success(result)))
        .catch(error => {
          dispatch(failure(error.message))
          dispatch(AA.error(error.message))
        })
    })
  }
}

export const consumptionActions = {
  getAll,
  getAllMeterReadingByFolderId,
  getCompareConsumption,
}
