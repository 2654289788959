import { useEffect, useState } from 'react'
import { useOperator } from './useOperator'

export const useViewOptions = folderId => {
  const { data: operatorData } = useOperator()

  const [columns, setColumns] = useState({
    date: true,
    actual_consumption: true,
    trend: true,
    emissions: true,
    area_consumption: true,
    employee_consumption: true,
    actual_costs: true,
    area_costs: true,
    benchmark: true,
    counter_reading: true,
    employee_costs: true,
  })
  const [header, setHeader] = useState(false)
  const [values, setValues] = useState(true)

  useEffect(() => {
    if (operatorData === null) return
    const { view_options } = operatorData

    // do we have view options for all folders? (view options but no folderId in record)
    const voAll = view_options.find(
      f => f.folder_id === null || f.folder_id === 0
    )

    // do we have view options for the current folder? (view options with folderId matching the folderId in record)
    const voCurrent = view_options.find(f => f.folder_id === folderId)

    const newViewCols = {
      date: true,
      actual_consumption: true,
      trend: true,
      emissions: true,
      area_consumption: true,
      employee_consumption: true,
      actual_costs: true,
      area_costs: true,
      benchmark: true,
      counter_reading: true,
      employee_costs: true,
    }

    if (voAll) {
      const vo = voAll.columns
        .split('x')[1]
        .split('')
        .map(item => Boolean(Number(item)))

      for (const [key, viewCol] of Object.keys(newViewCols).entries())
        newViewCols[viewCol] = vo[key]

      setColumns(newViewCols)

      const uc = voAll.columns
        .split('x')[0]
        .split('')
        .map(f => Boolean(Number(f)))

      setHeader(uc[0])
      setValues(uc[1])
    }

    if (voCurrent) {
      const vo = voCurrent.columns
        .split('x')[1]
        .split('')
        .map(item => Boolean(Number(item)))

      for (const [key, viewCol] of Object.keys(newViewCols).entries())
        newViewCols[viewCol] = vo[key]

      setColumns(newViewCols)

      const uc = voCurrent.columns
        .split('x')[0]
        .split('')
        .map(f => Boolean(Number(f)))

      setHeader(uc[0])
      setValues(uc[1])
    }
  }, [operatorData, folderId])

  return { columns, header, values }
}
