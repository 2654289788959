import { CancelTwoTone, FilterAltTwoTone } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { KSelect } from '../../components'
import { useEmailDomains } from '../../hooks'

export const DomainFilterSelector = ({
  filter,
  label = 'Domain Filter',
  onClear,
  onSelect,
}) => {
  const domains = useEmailDomains()

  return (
    <KSelect
      icon={<FilterAltTwoTone />}
      label={label}
      sx={{ minWidth: 110 }}
      value={filter}
    >
      <MenuItem disabled={filter === ''} onClick={onClear} value={''}>
        <CancelTwoTone sx={{ mr: 1 }} />
        Zurücksetzen
      </MenuItem>

      {domains.map((domain, key) => {
        const onClick = () => onSelect(domain)

        return (
          <MenuItem onClick={onClick} key={key} value={domain}>
            {domain}
          </MenuItem>
        )
      })}
    </KSelect>
  )
}

DomainFilterSelector.propTypes = {
  filter: PropTypes.string,
  label: PropTypes.string,
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
}
